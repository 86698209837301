@if (authGuard.showLoader$ | async) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="container" [ngClass]="{'fix-scroll':showContent}"
[class.compact-menu-container]="isCompactMenu">
  @if (((router.url.indexOf('/login') === -1) && (router.url.indexOf('/passwordchange') === -1) && (router.url.indexOf('/userConfirmation') === -1) && (router.url.indexOf('/report-dashboard') === -1)) && (router.url.indexOf('/forgotPassword') === -1) && (this.router.url.indexOf('/importorexportComponentData') === -1)&& (this.router.url.indexOf('/planning/download-report') === -1) && (this.router.url!=='/offline')) {
    <app-main-header
       [showExtraInfo]="router.url.indexOf('/sld-editor') !== -1"
       (compactmenuValue)="getCompactMenuValue($event)">
    </app-main-header>
  }
  <div class="main-content add-box" [class.compact-menu-main-content]="isCompactMenu"
    [ngClass]="{'show-content':showContent, 'login-page':router.url === '/' || router.url === '/login' || router.url === '/changePassword' || router.url === '/userConfirmation' || router.url === '/report-dashboard' ,'no-margin' : this.router.url ==='/offline'}">
    <router-outlet></router-outlet>
  </div>

  @if ((this.router.url.indexOf('/offline') === -1)) {
    <app-main-footer></app-main-footer>
  }
</div>


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from './application.service';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { ToastrService } from 'ngx-toastr';
import { ConstService } from 'src/services/const.service';
import { IndexDBService } from './indexdb.service';


@Injectable({ providedIn: 'root' })
export class GoToProjectService {
    constructor(private router: Router, private appSvc: ApplicationService,
        private toastr: ToastrService, private constService: ConstService,
        private mainHeaderService: MainHeaderService, private indexDBService: IndexDBService
        ) {
    }
    loading = false;

    goToProject(projState) {
    this.loading = true;
        this.appSvc.loginUserKeycloak().subscribe(res => {
        sessionStorage.setItem('UserId', res.userId);
        sessionStorage.setItem('UserRoles', res.roles);
        sessionStorage.setItem('previleges', JSON.stringify(res));
        sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
        this.mainHeaderService.onRolesUpdated();
        this.loading = false;
         // apart from Project manager no user has access to closed projects
    if (projState === "Closed") {
        // use tapToDismiss: false to disable close on clicking anywhere on toastr
      this.toastr.error('Except Project Manager, other user roles do not have the privilege to access the closed project.', null, {timeOut: 3000, toastClass: 'multiLine ngx-toastr'});
    }
    else {
       window.open(RouteHelper.addProjectPrefix(`/homepage`), '_blank');
    }
        sessionStorage.setItem('projectId', this.constService.emptyGuid);
        sessionStorage.setItem('projectName', '');
        this.appSvc.loginUserKeycloak().subscribe(res => {
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('UserRoles', res.roles);
            sessionStorage.setItem('previleges', JSON.stringify(res));
            sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
            this.mainHeaderService.onRolesUpdated();
        });
        });
    }
}

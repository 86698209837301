import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import modalConstant from '../../../../assets/modal-constants.json';
import { ApplicationService } from '../../../../services/application.service';
import { UserTypeResolverService } from '../../../../services/user-type-resolver.service';
import { DeleteAlertModalComponent } from '../../../components/delete-alert-modal/delete-alert-modal.component';
import { AllPrivilegsComponent } from '../modals/all-privileges/all-privileges.component';
import { AssignExecutionTeamComponent } from '../modals/assign-execution-team/assign-execution-team.component';
import { BackWithoutSaveAlertModalComponent } from '../modals/back-without-save-alert-modal/back-without-save-alert-modal.component';
import { EditSkillComponent } from '../modals/edit-skill/edit-skill.component';
import { NewRoleTemplateComponent } from '../modals/new-role-template/new-role-template.component';
import { SuccesspopupComponent } from '../modals/successpopup/successpopup.component';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { findIndex } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { UserRole } from 'src/app/models/userrole';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { RolesService } from 'src/services/roles.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditUserComponent implements OnInit {
  title = '';
  @Input() mode: 'full-screen' | 'as-modal' = 'full-screen';
  @Input() memberInput = null;
  member: any = {};
  addNewUser = false;
  loading = false;
  allRoles: any = [];
  userType: string;
  // defaultHourlyRate: string;
  selectedSkills: any = [];
  selectedSkillsForLength: any = [];
  hourlyRateMissing = false;
  currency;
  isFormValid = true;
  showMaintenanceInfo = false;
  @ViewChild('avatarInput', { static: false }) avatarInputRef: ElementRef;
  uploadFile: any;
  selectedRoles: any[] = [];
  countRoles = 0;
  public toast: any = {};
  base64;
  skillsList: any = [];
  userRolesObject: any = {};
  skillBase64;
  photoModified = false;
  IsdCode;
  submitted = false;
  systemRoles = false;
  isdcodeError = false;
  roleChangeList: any = [];
  isdCodeTitle;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  active = 1;
  rolesDescription = [
    {
      name: 'System Manager',
      description: [
        'Manage RelCareTM installed base',
        'Create and trigger new project delivery',
        'Manage and maintain default content',
      ]
    },

    {
      name: 'Policy Manager',
      description: [
        'Define / refine maintenance actions',
        'Define / refine original SWMS',
        'Manage recommendations from execution'
        ]
    },
    {
      name: 'Project Member',
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',
      ]
    },
    {
      name: UserRole.ProjectManager,
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',
      ]
    },
    {
      name: 'Project Support',
      description: [
        'Setup, configure, and manage project',
        'Manage users and roles',
        'Manage project communications',

      ]
    },
    {
      name: 'System Support',
      description: [
        'Manage RelCareTM installed base',
        'Create and trigger new project delivery',
        'Manage and maintain default content',

      ]
    },
    {
      name: UserRole.PolicySME,
      description: [
        'Define / refine maintenance actions',
        'Define / refine original SWMS',
        'Manage recommendations from execution'
      ]
    },
    {
      name: 'Quality SME',
      description: [
        'Initiate continuous improvement task and assign preparation and execution Owners',
        'Validate continuous improvement tasks'
      ]
    },
    {
      name: 'Maintenance Manager',
      description: [
        'Define project constraints (outage time and budget)',
        'Approve/dispatch or reject plan'
      ]
    },
    {
      name: 'Planner',
      description: [
        'Schedule tasks and assign them to execution teams and assign validators',
        'Make the plan ready for approval and dispatching'

      ]
    },
    {
      name: 'Execution Team Lead',
      description: [
        'Assign task preparation/execution Owner',
        'Refine execution schedule',
        'Validate tasks or assign task validators']
    },
    {
      name: 'Execution Owner',
      description: [
        'Prepare tasks and fine tune SWMS',
        'Download tasks to tablet, execute them, and sync them back to web application'
      ]
    },
    {
      name: 'Operation SME',
      description: [
        'Create remote operation scenario',
        'Define operation steps'
      ]
    },
    {
      name: 'Remote Operator',
      description: [
        'Record operation request',
        'Analyze and execute operation scenario',
        'Send out the operation report'
      ]
    },
    {
      name: 'Project Manager (C)',
      description: [
        'Manage knowledge flow in to RelCareTM',
        'Manage customer interactions',
        'Monitor dashboards and widgets'
      ]
    },


  ];
  constructor(
    private appSvc: ApplicationService, public activeModal: NgbActiveModal,
    private modalService: NgbModal, private userTypeResolverService: UserTypeResolverService,
    public router: Router, config: NgbModalConfig,
    private _FileSaverService: FileSaverService,
    private imageCompress: NgxImageCompressService, public rolesService: RolesService
  ) { config.backdrop = 'static'; }

  ngOnInit() {
    console.log("initialise app");
    this.userRolesObject = this.appSvc.getUserPrivilages();
    if (this.userRolesObject.roles === 'System Manager' || this.userRolesObject.roles === 'System Support') {
      this.systemRoles = true;
    }
    this.title = this.memberInput ? 'Edit member' : 'Add new member';
    if (this.title === 'Edit member') {
      this.member.userId = this.memberInput ? _.cloneDeep(this.memberInput) : {};
      this.loading = true;
      this.appSvc.getUserById(this.member.userId).subscribe({
        next: res => {
          this.loading = false;
          this.member = res.returnObj;
          this.currency = res.returnObj.currency;
          sessionStorage.setItem('roleList', this.member['userRolesDtoList']);
          this.roleChangeList = this.member['userRolesDtoList'];
          if (this.member.userProfilePhoto) {
            const base64sting = this.member.userProfilePhoto.split(',').pop();
            this.base64 = atob(base64sting);

            const imageName = this.member.userPhotoName;
            const imageExtension = this.member.userPhotoName.split('.').pop();

            // call method that creates a blob from dataUri
            const imageBlob = this.dataURItoBlob(this.base64, imageExtension);
            const imageFile = new File([imageBlob], imageName, { type: 'image/' + imageExtension });
            this.uploadFile = imageFile;
          }
          if (this.member['userRolesDtoList']) {
            const roleName = [];
            for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
              if (this.member['userRolesDtoList'][i].roleName === 'Execution Team Lead' ||
                this.member['userRolesDtoList'][i].roleName === 'Execution Owner') {
                this.showMaintenanceInfo = true;
              }
              // else {
              //   this.showMaintenanceInfo = false;
              // }
            }
            if (this.member['userRolesDtoList'].length === 0) {
              this.showMaintenanceInfo = false;
            }
          }

          if (this.member['userSkillsDtoList']) {
            for (let i = 0; i < this.member['userSkillsDtoList'].length; i++) {
              if (this.member['userSkillsDtoList'][i].skillCertificateFile) {
                this.skillBase64 = this.member['userSkillsDtoList'][i].skillCertificateFile;
                const base64string = this.skillBase64.split(',').pop();
                this.base64 = atob(base64string);
                // Replace extension according to your media type
                const docname = this.member['userSkillsDtoList'][i].skillCertificateName.split('.').pop();
                // call method that creates a blob from dataUri
                const imageBlob = this.showPdf(this.base64, docname);
                if (docname === 'pdf') {
                  const typefordoc = 'application/' + docname;
                  const imageFile = new File([imageBlob], this.member['userSkillsDtoList'][i].skillCertificateName,
                    { type: typefordoc });
                  this.member['userSkillsDtoList'][i].skillCertificateFile = imageFile;

                } else {
                  const typefordoc = 'image/' + docname;
                  const imageFile = new File([imageBlob], this.member['userSkillsDtoList'][i].skillCertificateName,
                    { type: typefordoc });
                  this.member['userSkillsDtoList'][i].skillCertificateFile = imageFile;

                }
                this.selectedSkills.push(this.member['userSkillsDtoList'][i]);
                this.selectedSkillsForLength.push(this.member['userSkillsDtoList'][i]);
              } else {
                this.selectedSkillsForLength.push(this.member['userSkillsDtoList'][i]);

              }
            }
            this.selectedSkills = _.uniqBy(this.selectedSkills, 'skillId');
            this.skillsList = this.selectedSkills.map(role => ({
              skillId: role.skillId, skillCertificateFile: role.skillCertificateFile
            }));
          }
        }, error: () => {
          this.loading = false;
        }
      });
    }
    this.addNewUser = !this.memberInput;
    this.loading = true;
    this.appSvc.getRolesinUser().subscribe({
      next: res => {
        // this.loading = false;
        this.loading = false;
        this.allRoles = res.map(role => ({
          selected: false, roleName: role.roleName, roleId: role.roleId,
          rolePrivilegesSecurable: role.rolePrivilegesSecurable, assignDescriptions: "", eventSelected: false
        }));
        if (this.userRolesObject.roles === 'System Manager') {
          let role = this.allRoles.filter(role => role.roleName === 'System Manager');
          if (role !== undefined && role !== null && role.length !== 0) {
            this.allRoles.splice(findIndex(this.allRoles.filter(role => role.roleName === 'System Manager')), 1);
          }
        }
        this.allRoles.forEach(element => {
          element.assignDescriptions = this.rolesDescription.filter(role => role.name === element.roleName)
        });
        ////////////////////
        // Base64 url of image
        // Naming the image
      }, error: () => {
        this.loading = false;
      }
    });

    this.loading = true;
    this.appSvc.getCommonProject()
      .subscribe({
        next: res => {
        }, error: (error) => {
          this.loading = false;
        }
      });
    this.loading = true;
    this.appSvc.GetAllCountriesIsdCode().subscribe({
      next: res => {
        this.loading = false;
        this.IsdCode = res;
        this.isdCodeTitle = res.filter((element) => element.countryId === this.member['countryId']);
      }, error: () => {
        this.loading = false;
      }
    });
  }
  isNoRoleSelected() {
    return this.submitted && this.allRoles.filter((item) => item.selected).length === 0;
  }
  validateForm() {
    this.checkMaintenanceInfoVisbility();
    this.isFormValid = this.member['userName'] && this.member['surName'] &&
      this.member['employer'] && this.member['jobPosition'] &&
      (this.isEmail(this.member['emailId']))
      && this.member['userRolesDtoList'].length > 0;
    return this.isFormValid;
  }
  showPdf(dataURI, docname) {
    const byteString = (dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    if (docname === 'pdf') {
      const typefordoc = 'application/' + docname;
      const blob = new Blob([arrayBuffer], { type: typefordoc });
      return blob;
    } else {
      const blob = new Blob([arrayBuffer], { type: 'image/' + docname });
      return blob;
    }
  }
  dataURItoBlob(dataURI, imageExtension) {
    const byteString = (dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image' + imageExtension });
    return blob;
  }
  roleValues(role) {
  }

  onSelectFile(event) {
    if (event.target.files[0].size >= 1572864) {
      let fileSize = (event.target.files[0].size / 1048576).toFixed(2);
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Message';
      modalRef.componentInstance.message = 'Current file size is '+ fileSize +'MB. Please upload file less than 1.5 MB!' ;
    } else {
      if (event) {
        this.photoModified = true;
      }
      this.loading = true;
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        this.uploadFile = event.target.files[0];

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (events) => {
          const readerResult = reader.result as string;
          this.imageCompress.compressFile(readerResult, DOC_ORIENTATION.Default , 50).then(result => {
            this.imgResultAfterCompress = result;
            this.member['userProfilePhoto'] = this.imgResultAfterCompress;
            // this.member['userProfilePhoto'] = reader.result;
            this.loading = false;
          });
          if (this.uploadFile) {
            this.loading = false;
          }
        };
      }
    }
  }
  roleSelected(event,role) {
   let selectedRole = this.allRoles.find(role => role.selected);
  if((selectedRole && selectedRole.roleId == role.roleId) || !selectedRole) {
      role.selected = !role.selected;
       return;
    }
  }
  isUserSelectedPMC() {
    for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
      if (this.member['userRolesDtoList'][i].roleName === 'Project Manager (C)' || this.member['userRolesDtoList'][i].roleName === 'Project Member' ) {
        return true;
      }
    }
  }

  isUserSelectedProjectMember() {
    for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
      if (this.member['userRolesDtoList'][i].roleName === 'Project Member' ) {
        return true;
      }
    }
  }
  isUserSelectedNotPMC() {
    if (this.member['userRolesDtoList'].length === 0) {
      return true;
    }
    // for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
    if (this.member['userRolesDtoList'][0].roleName === 'Project Manager (C)') {
      return true;
    } else {
      return false;
    }
    // }
  }

  checkMaintenanceInfoVisbility() {
    if (this.addNewUser) {
      this.member['userRolesDtoList'] = this.allRoles.filter(role => role.selected).map(role =>
        ({ roleName: role.roleName, roleId: role.roleId }));

    } else {
      this.showMaintenanceInfo = false;
      if (this.member['userRolesDtoList']) {
        for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
          if (this.member['userRolesDtoList'][i].roleName === 'Execution Team Lead' ||
            this.member['userRolesDtoList'][i].roleName === 'Execution Owner') {
            this.showMaintenanceInfo = true;
          } else if (this.member['userRolesDtoList'].length === 0) {
            this.showMaintenanceInfo = false;
          }
        }
        if (this.member['userRolesDtoList'].length === 0) {
          this.showMaintenanceInfo = false;
        }
      }
    }
  }


  isEmail(str) {
    const regex = /^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$/;
    return regex.test(str);
  }

  isPhone(str) {
    return /^\d{1,}$/.test(str);
  }
  isRole(roles: any[]): boolean {
    return roles && roles.length > 0; 
  }

  changeIsd(event) {
    this.isdCodeTitle = this.IsdCode.filter((element) => element.countryId === event);
    this.isdcodeError = false;
  }
  downloadFile(data, name) {
    const blob = new Blob([data], { type: data.type.toString() });
    //const url = window.URL.createObjectURL(blob);
    this._FileSaverService.save(blob, name);
    // window.open(url);
    // const reader = new FileReader();
    // reader.onload = function (e) {
    //   $scope.encoded_file = btoa(e.target.result.toString());
    // };
    // const blob = new Blob([data], { type: 'application/pdf' });
    // const file = reader.readAsBinaryString(blob);
    // const blob = new Blob([JSON.stringify(data)], { type: 'application/pdf' });

    // FileSaver.saveAs(blob, name);
    // const url = window.URL.createObjectURL(blob);
    // window.open(url);
  }
  assignNewRole() {

    const modalComponent = this.userType === 'PM' ? AllPrivilegsComponent : NewRoleTemplateComponent;
    const modalRef = this.modalService.open(modalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.title = 'Assign role';
    modalRef.componentInstance.id = this.member.userId;
    modalRef.componentInstance.isPMCToBeAdded = this.isUserSelectedNotPMC();
    modalRef.result.then(res => {
      if (res instanceof Array) {
        const newRoles = res.map(role => ({ roleName: role.roleName, roleId: role.roleId }));

        if (this.member['userRolesDtoList']) {
          this.member['userRolesDtoList1'] = _.uniq(this.member['userRolesDtoList'].concat(newRoles));
        } else {
          this.member['userRolesDtoList1'] = _.uniq(newRoles);
        }

        this.member['userRolesDtoList1'] = _.uniqBy(this.member['userRolesDtoList1'], 'roleId');
        this.countRoles = this.member['userRolesDtoList1'].length;
        if (this.countRoles !== 1 && this.rolesService.hasRole(this.rolesService.SYSTEM_MANAGER)) {
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'User cannot be assigned with multiple roles at System level';
          return false;
        }

        if (this.member['userRolesDtoList']) {
          this.member['userRolesDtoList'] = _.uniq(this.member['userRolesDtoList'].concat(newRoles));
        } else {
          this.member['userRolesDtoList'] = _.uniq(newRoles);
        }
      }
      this.member['userRolesDtoList'] = _.uniqBy(this.member['userRolesDtoList'], 'roleId');

      this.validateForm();
    }, () => { });
  }

  deleteRole(role) {
    this.member['userRolesDtoList'].splice(this.member['userRolesDtoList'].indexOf(role), 1);
    this.validateForm();
    if (this.member['userRolesDtoList']) {
      const roleName = [];
      for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
        if (this.member['userRolesDtoList'][i].roleName === 'Execution Team Lead' ||
          this.member['userRolesDtoList'][i].roleName === 'Execution Owner') {
          this.showMaintenanceInfo = true;
        } else if (this.member['userRolesDtoList'].length === 0) {
          this.showMaintenanceInfo = false;
        }
        // else {
        //   this.showMaintenanceInfo = false;
        // }
      }
      if (this.member['userRolesDtoList'].length === 0) {
        this.showMaintenanceInfo = false;
      }
    }
  }

  assignNewTeam() {
    const modalRef = this.modalService.open(AssignExecutionTeamComponent, { centered: true, size: 'lg' });
    modalRef.result.then(res => {
      if (res instanceof Array) {
        const memberTeams = this.member['teamUsersDtoList'] ? this.member['teamUsersDtoList'] : [];
        const memberTeamNames = memberTeams.map(t => t.name);
        const newTeams = res
          .filter(team => memberTeamNames.indexOf(team.teamName) === -1)
          .map(team => ({
            teamName: team.teamName, teamId: team.teamId,
          }));
        this.member['teamUsersDtoList'] = memberTeams.concat(newTeams);
        this.member['teamUsersDtoList'] = _.uniqBy(this.member['teamUsersDtoList'], 'teamId');

        this.validateForm();
      }
    }, () => { });
  }

  removeFromTeam(team: any) {
    this.member['teamUsersDtoList'].splice(this.member['teamUsersDtoList'].indexOf(team), 1);
    this.validateForm();
  }

  assignOrEditSkill(skill) {
    const modalRef = this.modalService.open(EditSkillComponent, { centered: true });
    modalRef.componentInstance.skillInput = skill;
    if (skill) {
      skill.selected = !skill.selected;
      for (let i = 0; i < this.selectedSkillsForLength.length; i++) {
        if (skill.skillId === this.selectedSkillsForLength[i].skillId) {
          this.selectedSkillsForLength.splice(this.selectedSkillsForLength.indexOf(this.selectedSkillsForLength[i]), 1);
        }
      }
    }
    modalRef.result.then(editedSkill => {
      if (!editedSkill && !skill) { return; }
      // if (!editedSkill) {
      //   return this.member['userSkillsDtoList'].splice(this.member['userSkillsDtoList'].indexOf(skill), 1);
      // }
      if (editedSkill === 'close') {
        if (skill) {
          this.selectedSkillsForLength.push(skill);
          this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
        }
      }
      if (editedSkill === 'delete') {
        if (skill) {
          for (let i = 0; i < this.selectedSkillsForLength.length; i++) {
            if (skill.skillId === this.selectedSkillsForLength[i].skillId) {
              this.selectedSkillsForLength.splice(this.selectedSkillsForLength.indexOf(this.selectedSkillsForLength[i]), 1);
            }
          }
          this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
        }
      } else if (editedSkill !== 'close' && editedSkill !== 'delete') {
        // if (editedSkill) {
        for (let i = 0; i < this.selectedSkillsForLength.length; i++) {
          if (editedSkill.skillId === this.selectedSkillsForLength[i].skillId) {
            this.selectedSkillsForLength.splice(this.selectedSkillsForLength.indexOf(this.selectedSkillsForLength[i]), 1);
          }
        }
        for (let i = 0; i < this.skillsList.length; i++) {
          if (editedSkill.skillId === this.skillsList[i].skillId) {
            this.skillsList.splice(this.skillsList.indexOf(this.skillsList[i]), 1);
          }
        }
        this.selectedSkillsForLength.push(editedSkill);
        this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
      }
      // for (let i = 0; i < this.selectedSkills.length; i++) {
      //   if (this.selectedSkills[i].skillId === editedSkill.skillId) {
      //     if (this.selectedSkills[i].skillCertificateName === null) {
      //       this.selectedSkills.splice(this.selectedSkills.indexOf(this.selectedSkills[i]), 1);
      //     }
      //   }
      // }
      // this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
      // if (editedSkill.isSkillDocModified) {
      for (let i = 0; i < this.skillsList.length; i++) {
        if (skill) {
          if (skill.skillId === this.skillsList[i].skillId) {
            this.skillsList.splice(this.skillsList.indexOf(this.skillsList[i]), 1);
          }
        }
      }
      this.skillsList.push(editedSkill);
      this.skillsList = this.skillsList.map(role => ({
        skillId: role.skillId, skillCertificateFile: role.skillCertificateFile
      }));
      // }
      this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
      this.member['userSkillsDtoList'] = this.selectedSkillsForLength;
      this.selectedSkills = this.member['userSkillsDtoList'];
      return this.member['userSkillsDtoList'];
    }, () => { });
  }

  backWithoutSave() {
    if (JSON.stringify(this.member) !== JSON.stringify(this.memberInput)) {
      this.modalService.open(BackWithoutSaveAlertModalComponent, { centered: true }).result.then(ret => {
        if (ret === 'leave') {
          this.activeModal.dismiss('back');
        }
      }, () => { });
    } else {
      this.activeModal.dismiss('back');
    }
  }
  save2() {
    this.submitted = true;
    // if (this.validateForm()) {
    // }
    // this.allRoles.forEach(element => {
    //   if (element.selected) {
    //     this.countRoles++;
    //   }
    // });
    //     if (this.member['hourlyRate'].length === 0) {
    // return false;
    //     }
    this.countRoles = this.member['userRolesDtoList'].length;
    // if (this.countRoles === 0) {
    //   const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    //   modalRef.componentInstance.message = 'Please Select Role';
    // }

    if (this.countRoles > 0) {
      let rolePresent = false;
      for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
        if (this.member['userRolesDtoList'][i].roleName === 'Execution Team Lead' ||
          this.member['userRolesDtoList'][i].roleName === 'Execution Owner') {
          // if (this.member['hourlyRate'].length === 0) {

          //   return false;
          // }
          if (this.member['hourlyRate'] === null || this.member['hourlyRate'] === '' || this.member['hourlyRate'] === undefined || this.member['hourlyRate'] === 0) {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
            modalRef.componentInstance.title = 'Message';
            modalRef.componentInstance.message = modalConstant.hourlyRate.message;
            return false;
          }

          rolePresent = true;
        }
      }
      if (rolePresent === false) {
        this.selectedSkillsForLength = [];
        this.selectedSkills = [];
        this.skillsList = [];
        this.member['hourlyRate'] = '';
        this.member['teamUsersDtoList'] = [];
        this.member['userSkillsDtoList'] = [];
      }
    }
    if (!this.member['userRolesDtoList'] && this.allRoles.length > 0) {
      this.member['userRolesDtoList'].push(this.allRoles.filter(role => role.selected).map(role =>
        ({ roleName: role.roleName, roleId: role.roleId })));
    }
    // }
    this.member['userRolesDtoList'] = _.uniqBy(this.member['userRolesDtoList'], 'roleId');
    if (this.member['userSkillsDtoList']) {
      if (this.member['userSkillsDtoList'].length > 0) {
        for (let i = 0; i < this.member['userSkillsDtoList'].length; i++) {
          delete this.member['userSkillsDtoList'][i].skillCertificateFile;
        }
      }
    }
    const formDataUser = JSON.stringify({
      'UserId': this.member.userId,
      'UserName': this.member['userName'],
      'FirstName': this.member['userName'],
      'SurName': this.member['surName'],
      'Employer': this.member['employer'],
      'JobPosition': this.member['jobPosition'],
      'EmailId': this.member['emailId'],
      'HourlyRate': this.member['hourlyRate'],
      'Phone': this.member['phone'],
      'IsPhotoModified': this.photoModified,
      'CountryId': this.member['countryId'],
      'TeamUsersDtoList': this.member['teamUsersDtoList'],
      'UserRolesDtoList': this.member['userRolesDtoList'],
      'UserSkillsDtoList': this.member['userSkillsDtoList']
    });
    const formValue = new FormData();

    for (let i = 0; i < this.skillsList.length; i++) {
      formValue.append(this.skillsList[i].skillId, this.skillsList[i].skillCertificateFile);
    }
    formValue.append("files.emailId",this.member['emailId']);
    formValue.append("files.file", this.uploadFile);
    formValue.append('userDto', (formDataUser));
    if (this.countRoles > 0 && this.isFormValid) {
      // tslint:disable-next-line:max-line-length
      // tslint:disable-next-line:max-line-length
      // const roleVAlue = this.roleChangeList.length === this.member['userRolesDtoList'].length && this.roleChangeList.sort().every(function(value, index) { return value === this.member['userRolesDtoList'].sort()[index]});
      this.loading = true;
      this.appSvc.updateUser(formValue).subscribe({
        next: res => {
          this.activeModal.close(this.member);
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = this.member['userName'] + ' ' + modalConstant.Member.updated;
          this.loading = false;
          ////sessionStorage.clear();
          //localStorage.clear();
          // }
          // this.router.navigate(['/']);
          // this.appSvc.logOut().subscribe(res => {
          //   //this.activeModal.dismiss();
          //   this.loading = false;
          //   sessionStorage.clear();
          //   localStorage.clear();
          //   // }
          //   this.router.navigate(['/']);
          //   //this.activeModal.dismiss();
          // }, error:() => {
          //   this.loading = false;
          // }});

        }, error: () => {
          this.loading = false;
          // this.activeModal.dismiss('back');
        }
      });


    }
  }
  save() {
    this.submitted = true;
    this.validateForm();
    // if (this.validateForm()) {
    // }
    // this.allRoles.forEach(element => {
    //   if (element.selected) {
    //     this.countRoles++;
    //   }
    // });
    // if (this.countRoles === 0) {
    //   const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    //   modalRef.componentInstance.message = 'Please Select Role';
    // }
    if (!this.member['userRolesDtoList'] && this.allRoles.length > 0) {
      this.member['userRolesDtoList'] = this.allRoles.filter(role => role.selected).map(role =>
        ({ roleName: role.roleName, roleId: role.roleId }));
    }
    this.member['userRolesDtoList'] = _.uniqBy(this.member['userRolesDtoList'], 'roleId');
    this.countRoles = this.member['userRolesDtoList'].length;
    let isSytemSupport;
    let isProjectManager;
    for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
      if (this.member['userRolesDtoList'][i].roleName === 'System Support') {
        isSytemSupport = true;
      }
    }
    if (isSytemSupport) {
      for (let i = 0; i < this.member['userRolesDtoList'].length; i++) {
        if (this.member['userRolesDtoList'][i].roleName === 'Project Manager') {
          isProjectManager = true;
        }
      }
      if (isProjectManager && isSytemSupport) {
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Message';
        modalRef.componentInstance.message = 'User cannot be assigned as System support and Project manager';
        return false;
      }
    }

    // if (this.countRoles > 1 && this.member['userRolesDtoList'][0].roleName==='System Support' &&  this.member['userRolesDtoList'][1].roleName==='Project Manager'){

    // }


    if (this.member['userSkillsDtoList']) {
      if (this.member['userSkillsDtoList'].length > 0) {
        for (let i = 0; i < this.member['userSkillsDtoList'].length; i++) {
          delete this.member['userSkillsDtoList'][i].skillCertificateFile;
        }
      }
    }
    if (!this.member['countryId']) {
      this.isdcodeError = true;
      return;
    }
    const formDataUser = JSON.stringify({
      'UserName': this.member['userName'],
      'FirstName': this.member['userName'],
      'SurName': this.member['surName'],
      'Employer': this.member['employer'],
      'JobPosition': this.member['jobPosition'],
      'EmailId': this.member['emailId'],
      'HourlyRate': this.member['hourlyRate'],
      'Phone': this.member['phone'],
      'CountryId': this.member['countryId'],
      'TeamUsersDtoList': this.member['teamUsersDtoList'],
      'UserRolesDtoList': this.member['userRolesDtoList'],
      'UserSkillsDtoList': this.member['userSkillsDtoList']
    });
    const formValue = new FormData();

    for (let i = 0; i < this.skillsList.length; i++) {
      formValue.append(this.skillsList[i].skillId, this.skillsList[i].skillCertificateFile);
    }
    formValue.append("files.emailId",this.member['emailId']);
    formValue.append("files.file", this.uploadFile);
    formValue.append('userDto', (formDataUser));
    if (this.countRoles > 0 && this.isFormValid) {
      this.loading = true;
      this.appSvc.createUser(formValue).subscribe({
        next: res => {
          if (res) {
            this.activeModal.close(this.member);
            this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard`)]);
            this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/members-privileges`)]);
          }
          this.loading = false;
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = this.member['userName'] + ' ' + modalConstant.Member.success;
        }, error: () => {
          this.loading = false;
          // this.activeModal.dismiss('back');
        }
      });
    }
  }


  toggleSkill(skill) {
    skill.selected = !skill.selected;
    this.selectedSkills = this.member['userSkillsDtoList'].filter(s => s.selected);
  }

  deleteSelectedSkills(a) {
    const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
    modalRef.componentInstance.deleteItemName = 'skill';
    modalRef.result.then(ret => {
      if (ret === 'delete') {
        const selectedSkills = a;
        if (selectedSkills) {
          for (let i = selectedSkills.length - 1; i >= 0; i--) {
            let skillId = '';
            if (selectedSkills[i].selected === true) {
              skillId = selectedSkills[i].skillId;
              for (let k = 0; k < this.member['userSkillsDtoList'].length; k++) {
                if (skillId === this.member['userSkillsDtoList'][k].skillId) {
                  this.member['userSkillsDtoList'].splice(this.member['userSkillsDtoList'].indexOf(this.member['userSkillsDtoList'][k]), 1);
                }
              }
              for (let j = 0; j < this.skillsList.length; j++) {
                if (this.skillsList[j].skillId === skillId) {
                  this.skillsList.splice(this.skillsList.indexOf(this.skillsList[j]), 1);

                }
              }
            }
          }
        }
        // this.member['userSkillsDtoList'] = this.member['userSkillsDtoList'].filter(skill => !skill.selected);
        this.selectedSkills = [];
        // this.selectedSkillsForLength = this.member['userSkillsDtoList'];
        // this.selectedSkillsForLength = _.uniqBy(this.selectedSkillsForLength, 'skillId');
        // this.skillsList = this.selectedSkillsForLength;
        // this.skillsList = this.selectedSkillsForLength.map(role => ({
        //   skillId: role.skillId, skillCertificateFile: role.skillCertificateFile
        //  }));
      }
    });
  }

  addAvatar() {
    this.avatarInputRef.nativeElement.click();
  }

  onAvatarFileSelected(event) {
    const file = event.target.files[0];
    if (!file) { return; }
    const reader = new FileReader();
    reader.onload = e => this.member['avatar'] = (e.target as any).result;
    reader.readAsDataURL(file);
  }
  omit_special_char(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || k === 46 || k === 44);
  }
  allow_special_char(event: any) {
    console.log("allow special char")
    // let k;
    // k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    // return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k > 38 && k < 42) || (k > 43 && k < 47) ||
    // k === 8 || k === 32 || k === 95);
  }
  only_numbers(event: any) {
    let k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return (k > 47 && k < 58);
  }
  allow_emailCharacters(event: any) {
    let k;
    k = event.charCode;
    return ((k > 96 && k < 123) || (k > 64 && k < 91) || (k > 45 && k < 58) || k === 64 || k === 95 || k === 45);
  }
}

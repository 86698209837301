import { KeycloakService } from 'keycloak-angular';
import { Subscription, fromEvent, map, merge, of } from 'rxjs';
import { SettingsHttpService } from 'src/services/settings.http.service';

 export function initializeKeycloak(keycloak: KeycloakService, appConfig: SettingsHttpService ): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {

      //Check the status of user
      // if (!navigator.onLine) { console.log('bypasing'); return Promise.resolve(true); }
      let networkStatus = navigator.onLine;

      let config1: any;
      let realmName;
      try {
        let splitUrl = window.location.hostname;
        let realm12 =  splitUrl.split('.')[0];
        if(realm12 ==  'ui-1') {
           let pathname = window.location.pathname;
           realmName = pathname.split('/')[5];
            sessionStorage.setItem('realmName', realmName);
        }else {
          realmName = realm12;
          sessionStorage.setItem('realmName', realm12);
        }
 
          config1 = await appConfig.initializeApp();
        if(config1.IsFromSettings === "false") {
        config1.managesystemApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/ManageSystem';
        config1.manageProjectApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/ManageProject';
        config1.managePlanApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/ManagePlan';
        config1.manageMaintenanceApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/managemaintenanceservice';
        config1.SLDApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/SldService';
        config1.loadDataApiURL = 'https://'+ realm12 + '.'+ config1.domain +'/LoadData';
        config1.tenantAdminAPIUrl ='https://' +realm12 + '.'+ config1.domain +'/TenantAdmin';
        config1.operationCenter ='https://' +realm12 + '.'+ config1.domain +'/InstBase';
        config1.exaURL = config1.manageProjectApiURL +'/SWMS';
        }
        sessionStorage.setItem('apiURL', JSON. stringify(config1));
      } catch (error) {
        // Here you should properly deal with the error        
        if(networkStatus){
        reject(new Error('Config not loaded'));
        return;
        } else{
          //If user is offline then navigate without keyclock authentication
          resolve(true);
          return;
        } 
      }
      
      if(networkStatus && window.location.href.indexOf('offline') === -1){
          try {
          await keycloak.init({
              config: {
                  url: config1.KeycloakAuthority,
                  realm: realmName,
                  clientId: config1.KeycloakClient
              },
              initOptions: {
                onLoad: 'check-sso',
                flow:'standard',
              pkceMethod:'S256'
            },
            bearerExcludedUrls: [],
            });
          resolve(true);
        } catch (error) {
        // Here you should properly deal with the error        
          {
            if(networkStatus)
            {
            reject(new Error('Keycloak not loaded'));
            }else
            {
              resolve(true);
              return
            }
          }      
        }
      }else
      {
        resolve(true);
        return
      }        
    });
  };
}

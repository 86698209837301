import { v4 as uuid }  from 'uuid';
import { DisplayDataModel, WidgetConfig, WidgetDashboardConfig, WidgetSourceConfig, WidgetType, WidgetTypeName } from 'src/app/components/dashboard/widgets/models/widget';
import { Observable, Subscription, of, Subject } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DashboardEditMode } from 'src/app/models/dashboard-edit-mode-dictionary';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ManageProjectService } from './manageProject.service';
import { WidgetConditionalLoading } from 'src/app/models/widget-conditional-loading';
import { WidgetSystemTypes } from '../app/components/dashboard/widgets/edit-widget-dashboard/systemWidgets';
import { ConstService } from '../services/const.service';
import { RolesService } from './roles.service';
import * as _ from 'lodash';
import moment from 'moment';
// import { compileBaseDefFromMetadata } from '@angular/compiler';

const Jwthelper = new JwtHelperService();
const MOCK_API_DELAY = 0;

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  returnObject: any = {};
  returnObject1: any = {};
  SyatemAdmin: any;
  SyatemRole: any;
  SyatemUserRole: any;
  setSelectedTaskData: any;
  isUserLogin: boolean;
  subscription: Subscription;
  public param1 = "";
  public param2 = "";
  priority = "";
  taskStatusName = "";
  equipment = "00000000-0000-0000-0000-000000000000";
  substation = "00000000-0000-0000-0000-000000000000";
  plan = "00000000-0000-0000-0000-000000000000";
  region = "00000000-0000-0000-0000-000000000000";
  bay = "00000000-0000-0000-0000-000000000000";

  planPlanner= '00000000-0000-0000-0000-000000000000';
  tab="";
  taskStatusId: any;
  filterData : any;
  notificationId: any;
  alarmId: any;
  irregular:any;
  isBudgetArea: boolean ;
  public alarms = [];
  public allAlarms = [];
  public notifications = [];
  public allNotifications = [];
  public isSaveSLD = false;
  public isSaved = false;
  private subject = new Subject();

  constructor(
    private helper: HelperService,
    private manageProjectHelper: ManageProjectService,
    private http: HttpClient,
    private constService: ConstService,
    public rolesService: RolesService) {
  }

  /**
   * gets the applications
   * @returns  the applications data
   */

  getUserRoles() {
    return Jwthelper.decodeToken(sessionStorage.getItem('AccessToken'));
  }
  sendFilterList(reqObject){
    this.subject.next(reqObject);
  }
  getFilterList(): Observable<any> {
    return this.subject.asObservable();
  }

  downloadLatestReport(reportName: string, dashboardId: uuid) {
    return this.helper.get(`/PerformanceMonitoring/DownloadLatestReport/${reportName}/${dashboardId}`, { responseType: 'blob' });
  }

  getScenario(id: string) {
    return this.helper.getdd('/assets/data/GetScenarios.json').pipe(delay(MOCK_API_DELAY))
      // MOCK: find id
      .pipe(map(resp => {
        return resp.find(f => f.id === id);
      }));
  }

  getScenarios() {
    return this.helper.getdd('/assets/data/GetScenarios.json').pipe(delay(MOCK_API_DELAY));
  }

  getSearchPeople(term: string) {
    // if no search term return empty array
    if (term === '') {
      return of([]);
    }
    // return this.helper.getdd('/assets/data/GetSearchPeople.json').pipe(delay(MOCK_API_DELAY));
    return this.helper.get('/PerformanceMonitoring/GetDistributionList?searchText=' + term).pipe(delay(MOCK_API_DELAY));
  }

  getWidgetTypeNameForUrl(widgetType: WidgetType): string {
    let name: any;
    switch (widgetType) {
      case WidgetType.DISPLAY:
        name = WidgetTypeName.DISPLAY;
        break;
      case WidgetType.GAUGE:
        name = WidgetTypeName.GAUGE;
        break;
      case WidgetType.PIE:
        name = WidgetTypeName.PIE;
        break;
      case WidgetType.LINE:
        name = WidgetTypeName.LINE;
        break;
      case WidgetType.COLUMN_REGULAR:
        name = WidgetTypeName.COLUMN_REGULAR;
        break;
      case WidgetType.BAR_HORIZONTAL:
        name = WidgetTypeName.BAR_HORIZONTAL;
        break;
      case WidgetType.COLUMN_STACK:
        name = WidgetTypeName.COLUMN_STACK;
        break;
      case WidgetType.COLUMN_CLUSTER:
        name = WidgetTypeName.COLUMN_CLUSTER;
        break;
      case WidgetType.COMBO:
        name = WidgetTypeName.COMBO;
        break;
      case WidgetType.DOUGHNUT:
        name = WidgetTypeName.DOUGHNUT;
        break;
      case WidgetType.RISK_MATRIX:
        name = WidgetTypeName.RISK_MATRIX;
        break;
      default:
        throw new Error("Widget type name doesn't exist");
    }
    return name;
  }

  getSystemWidgetTypeNameForUrl(widgetSystemType: WidgetSystemTypes): string {
    switch (widgetSystemType) {
      case WidgetSystemTypes.assetByManufacturer:
        return "AssetByManufacturer";
      case WidgetSystemTypes.assetAgeDistribution:
        return "AssetAgeDistribution";
      case WidgetSystemTypes.assetStatus:
        return "AssetStatus";
      case WidgetSystemTypes.taskStatus:
        return "TaskStatus";
      case WidgetSystemTypes.yearlyExpenditure:
        return "YearlyExpenditure";
      case WidgetSystemTypes.yearlyHoursOfMaintenance:
        return "YearlyHoursOfMaintenance";
      case WidgetSystemTypes.riskDistribution:
        return "RiskDistribution";
      case WidgetSystemTypes.assetAgeEvolution:
        return "AssetAgeEvolution";
      case WidgetSystemTypes.loadForAssets:
        return "LoadForAssets";
      default:
        throw new Error("WidgetSystemType name doesn't exist");
    }
  }

  setQueryParametersBasedOnDisplayConfig(displayConfiguration: WidgetConditionalLoading): Array<string> {
    var queryParams = [];
    if (displayConfiguration.mode) {
      queryParams.push("mode=" + displayConfiguration.mode);
    }
    if (displayConfiguration.assetId) {
      queryParams.push('assetId=' + displayConfiguration.assetId);
    }
    if (displayConfiguration.roleName) {
      queryParams.push('roleName=' + displayConfiguration.roleName);
    }
    return queryParams;
  }
// Keycloak POC
getUserPrivilages(){
  const token = JSON.parse(sessionStorage.getItem('previleges'));

   // const token = Jwthelper.decodeToken(sessionStorage.getItem('AccessToken'));
     this.returnObject.CreateProjectsEdit = token.priviledges.CreateprojectsEdit !== undefined;
     this.returnObject.CreateProjectsView = token.priviledges.CreateprojectsView !== undefined;

     this.returnObject.DeployProjectsEdit = token.priviledges.DeployprojectsEdit !== undefined;
     this.returnObject.DeployProjectsView = token.priviledges.DeployprojectsView !== undefined;

     this.returnObject.ProjectDefinitionEdit = token.priviledges.OutagetypesEdit !== undefined;
     this.returnObject.ProjectDefinitionView = token.priviledges.OutagetypesView !== undefined;
     this.returnObject.ProjectDefinitionHide = token.priviledges.ProjectdefinitionHide !== undefined;


     this.returnObject.RiskMatrixEdit = token.priviledges.RiskmatrixEdit !== undefined;
     this.returnObject.RiskMatrixView = token.priviledges.RiskmatrixView !== undefined;

     this.returnObject.OpexEdit = token.priviledges.OPEXEdit !== undefined;
     this.returnObject.OpexView = token.priviledges.OPEXView !== undefined;

     this.returnObject.OngoingQ4sEdit = token.priviledges.OngoingQ4sEdit !== undefined;
     this.returnObject.OngoingQ4sView = token.priviledges.OngoingQ4sView !== undefined;

     this.returnObject.CapexEdit = token.priviledges.CAPEXEdit !== undefined;
     this.returnObject.CapexView = token.priviledges.CAPEXView !== undefined;

     this.returnObject.GISSLDEditorEdit = token.priviledges.GISSLDEditorEdit !== undefined;
     this.returnObject.GISSLDEditorView = token.priviledges.GISSLDEditorView !== undefined;
     this.returnObject.GISSLDEditorHide = token.priviledges.GISSLDEditorHide !== undefined;

     this.returnObject.BudgetAreasEdit = token.priviledges.BudgetareasEdit !== undefined;
     this.returnObject.BudgetAreasView = token.priviledges.BudgetareasView !== undefined;
     this.returnObject.BudgetAreasHide = token.priviledges.BudgetareasHide !== undefined;

     this.returnObject.MemberAndPriviledge = token.priviledges.MemberAndPriviledge !== undefined;
     this.returnObject.MembersPrivilegesView = token.priviledges.MembersPrivilegesView !== undefined;

     this.returnObject.OutageAreasEdit = token.priviledges.OutageareasEdit !== undefined;
     this.returnObject.OutageAreasView = token.priviledges.OutageareasView !== undefined;
     this.returnObject.OutageAreasHide = token.priviledges.OutageareasHide !== undefined;

     this.returnObject.TaskNavigatorEdit = token.priviledges.TasknavigatorEdit !== undefined;
     this.returnObject.TaskNavigatorView = token.priviledges.TasknavigatorView !== undefined;
     this.returnObject.TaskNavigatorHide = token.priviledges.TasknavigatorHide !== undefined;

     this.returnObject.KnowledgeBankEdit = token.priviledges.KnowledgebankEdit !== undefined;
     this.returnObject.KnowledgeBankView = token.priviledges.KnowledgebankView !== undefined;

     this.returnObject.TaskCriteriasPriviledgeEdit = token.priviledges.PerformanceratingEdit !== undefined;
     this.returnObject.TaskCriteriasPriviledgeView = token.priviledges.PerformanceratingView !== undefined;

     this.returnObject.TaskOverduePriviledgeEdit = token.priviledges.TaskoverdueEdit !== undefined;
     this.returnObject.TaskOverduePriviledgeView = token.priviledges.TaskoverdueView !== undefined;

     this.returnObject.ApproveDispatchEdit = token.priviledges.ApproveDispatchEdit !== undefined;
     this.returnObject.ApproveDispatchView = token.priviledges.ApproveDispatchView !== undefined;

     this.returnObject.ValidatePlanningEdit = token.priviledges.ValidatePlanningEdit !== undefined;
     this.returnObject.ValidatePlanningView = token.priviledges.ValidatePlanningView !== undefined;

     this.returnObject.ConstraintDefinitionEdit = token.priviledges.ConstraintDefinitiongEdit !== undefined;
     this.returnObject.ConstraintDefinitionView = token.priviledges.ConstraintDefinitionView !== undefined;

     this.returnObject.ExecutionTeamEdit = token.priviledges.ExecutionTeamEdit !== undefined;
     this.returnObject.ExecutionTeamView = token.priviledges.ExecutionTeamView !== undefined;
     this.returnObject.ExecutionTeamHide = token.priviledges.ExecutionteamsHide !== undefined;

     this.returnObject.PolicySMEEdit = token.priviledges.ProjectpolicyEdit !== undefined;
     this.returnObject.PolicySMEView = token.priviledges.ProjectpolicyView !== undefined;

     this.returnObject.UnplannedEdit = token.priviledges.UnplannedEdit !== undefined;
     this.returnObject.UnplannedView = token.priviledges.UnplannedView !== undefined;

     this.returnObject.PerfromancemodelparametrizationEdit = token.priviledges.PerfromancemodelparametrizationEdit !== undefined;
     this.returnObject.PerfromancemodelparametrizationView = token.priviledges.PerfromancemodelparametrizationView !== undefined;
     this.returnObject.ProjectpolicyEdit = token.priviledges.ProjectpolicyEdit !== undefined;
     this.returnObject.ProjectpolicyView = token.priviledges.ProjectpolicyView !== undefined;
     this.returnObject.ProjectdefinitionEdit = token.priviledges.ProjectdefinitionEdit !== undefined;
     this.returnObject.ProjectdefinitionView = token.priviledges.ProjectdefinitionView !== undefined;
     this.returnObject.RiskmatrixEdit = token.priviledges.RiskmatrixEdit !== undefined;
     this.returnObject.RiskmatrixView = token.priviledges.RiskmatrixView !== undefined;
     this.returnObject.SkillsEdit = token.priviledges.SkillsEdit !== undefined;
     this.returnObject.SkillsView = token.priviledges.SkillsView !== undefined;
     this.returnObject.OutagetypesEdit = token.priviledges.OutagetypesEdit !== undefined;
     this.returnObject.OutagetypesView = token.priviledges.OutagetypesView !== undefined;
     this.returnObject.KnowledgebankEdit = token.priviledges.KnowledgebankEdit !== undefined;
     this.returnObject.KnowledgebankView = token.priviledges.KnowledgebankView !== undefined;
     this.returnObject.GISSLDEditorEdit = token.priviledges.GISSLDEditorEdit !== undefined;
     this.returnObject.GISSLDEditorView = token.priviledges.GISSLDEditorView !== undefined;
     this.returnObject.OutageareasEdit = token.priviledges.OutageareasEdit !== undefined;
     this.returnObject.OutageareasView = token.priviledges.OutageareasView !== undefined;
     this.returnObject.BudgetareasEdit = token.priviledges.BudgetareasEdit !== undefined;
     this.returnObject.BudgetareasView = token.priviledges.BudgetareasView !== undefined;
     this.returnObject.PerfromanceratingEdit = token.priviledges.PerformanceratingEdit !== undefined;
     this.returnObject.PerfromanceratingView = token.priviledges.PerformanceratingView !== undefined;
     this.returnObject.TaskoverdueEdit = token.priviledges.TaskoverdueEdit !== undefined;
     this.returnObject.TaskoverdueView = token.priviledges.TaskoverdueView !== undefined;
     this.returnObject.OPCEdit = token.priviledges.OPCEdit !== undefined;
     this.returnObject.OPCView = token.priviledges.OPCView !== undefined;
     this.returnObject.ManageusersEdit = token.priviledges.ManageusersEdit !== undefined;
     this.returnObject.ManageusersView = token.priviledges.ManageusersView !== undefined;
     this.returnObject.ExecutionteamsEdit = token.priviledges.ExecutionteamsEdit !== undefined;
     this.returnObject.ExecutionTeamsView = token.priviledges.ExecutionteamsView !== undefined;
     this.returnObject.ManagerolesEdit = token.priviledges.ManagerolesEdit !== undefined;
     this.returnObject.ManagerolesView = token.priviledges.ManagerolesView !== undefined;
     this.returnObject.ConstraintdefinitionEdit = token.priviledges.ConstraintdefinitionEdit !== undefined;
     this.returnObject.ConstraintdefinitionView = token.priviledges.ConstraintdefinitionView !== undefined;
     this.returnObject.TasknavigatorEdit = token.priviledges.TasknavigatorEdit !== undefined;
     this.returnObject.TasknavigatorView = token.priviledges.TasknavigatorView !== undefined;
     this.returnObject.ApprovedispatchEdit = token.priviledges.ApprovedispatchEdit !== undefined;
     this.returnObject.ApprovedispatchView = token.priviledges.ApprovedispatchView !== undefined;
     this.returnObject.ValidateplanningEdit = token.priviledges.ValidateplanningEdit !== undefined;
     this.returnObject.ValidateplanningView = token.priviledges.ValidateplanningView !== undefined;
     this.returnObject.AcknowlegdetasksEdit = token.priviledges.AcknowlegdetasksEdit !== undefined;
     this.returnObject.AcknowlegdetasksView = token.priviledges.AcknowlegdetasksView !== undefined;
     this.returnObject.PreparetasksEdit = token.priviledges.PreparetasksEdit !== undefined;
     this.returnObject.PreparetasksView = token.priviledges.PreparetasksView !== undefined;
     this.returnObject.TasksexecutioninfieldEdit = token.priviledges.TasksexecutioninfieldEdit !== undefined;
     this.returnObject.TasksexecutioninfieldView = token.priviledges.TasksexecutioninfieldView !== undefined;
     this.returnObject.SynctasksfromdesktopEdit = token.priviledges.SynctasksfromdesktopEdit !== undefined;
     this.returnObject.SynctasksfromdesktopView = token.priviledges.SynctasksfromdesktopView !== undefined;
     this.returnObject.SysnctasksfrommobileEdit = token.priviledges.SysnctasksfrommobileEdit !== undefined;
     this.returnObject.SysnctasksfrommobileView = token.priviledges.SysnctasksfrommobileView !== undefined;
     this.returnObject.IrregulartasksEdit = token.priviledges.IrregulartasksEdit !== undefined;
     this.returnObject.IrregulartasksView = token.priviledges.IrregulartasksView !== undefined;
     this.returnObject.TasksvalidationandratingEdit = token.priviledges.TasksvalidationandratingEdit !== undefined;
     this.returnObject.TasksvalidationandratingView = token.priviledges.TasksvalidationandratingView !== undefined;
     this.returnObject.CreateCItaskEdit = token.priviledges.CreateCItaskEdit !== undefined;
     this.returnObject.CreateCItaskView = token.priviledges.CreateCItaskView !== undefined;
     this.returnObject.AcknowledgeCItaskEdit = token.priviledges.AcknowledgeCItaskEdit !== undefined;
     this.returnObject.AcknowledgeCItaskView = token.priviledges.AcknowledgeCItaskView !== undefined;
     this.returnObject.ProblemstatementpreparationEdit = token.priviledges.ProblemstatementpreparationEdit !== undefined;
     this.returnObject.ProblemstatementpreparationView = token.priviledges.ProblemstatementpreparationView !== undefined;
     this.returnObject.PerformaCItask4QEdit = token.priviledges.PerformaCItask4QEdit !== undefined;
     this.returnObject.PerformaCItask4QView = token.priviledges.PerformaCItask4QView !== undefined;
     this.returnObject.PerformaCItaskvalidationEdit = token.priviledges.PerformaCItaskvalidationEdit !== undefined;
     this.returnObject.PerformaCItaskvalidationView = token.priviledges.PerformaCItaskvalidationView !== undefined;
     this.returnObject.ReportssettingEdit = token.priviledges.ReportssettingEdit !== undefined;
     this.returnObject.ReportssettingView = token.priviledges.ReportssettingView !== undefined;
     this.returnObject.NotificationcenterEdit = token.priviledges.NotificationcenterEdit !== undefined;
     this.returnObject.NotificationcenterView = token.priviledges.NotificationcenterView !== undefined;
     this.returnObject.InformationexplorerEdit = token.priviledges.InformationexplorerEdit !== undefined;
     this.returnObject.InformationexplorerView = token.priviledges.InformationexplorerView !== undefined;
     this.returnObject.PerfromancemonitoringEdit = token.priviledges.PerfromancemonitoringEdit !== undefined;
     this.returnObject.PerfromancemonitoringView = token.priviledges.PerfromancemonitoringView !== undefined;
     this.returnObject.DashboardsEdit = token.priviledges.DashboardsEdit !== undefined;
     this.returnObject.DashboardsView = token.priviledges.DashboardsView !== undefined;

     //  for Main Tab

     this.returnObject.MemberAndPriviledge =
       this.returnObject.ManageusersEdit || this.returnObject.ManageusersView ||
       this.returnObject.ManagerolesEdit || this.returnObject.ManagerolesView ||
       this.returnObject.ExecutionteamsEdit || this.returnObject.ExecutionteamsView ||
       this.returnObject.SkillsEdit || this.returnObject.SkillsView;


     this.returnObject.roles = token.roles;
     this.returnObject.roles = this.returnObject.roles.constructor === Array ? this.returnObject.roles[0] : this.returnObject.roles;
     this.returnObject.IsSystemRole = token.isSystemRole;
     return this.returnObject;
   }


  isUserExecutionOwner() {
    const token = JSON.parse(sessionStorage.getItem('previleges'));

    this.returnObject1.CreateProjectsEdit = token.priviledges.CreateprojectsEdit !== undefined;
    this.returnObject1.CreateProjectsView = token.priviledges.CreateprojectsView !== undefined;

    this.returnObject1.DeployProjectsEdit = token.priviledges.DeployprojectsEdit !== undefined;
    this.returnObject1.DeployProjectsView = token.priviledges.DeployprojectsView !== undefined;

    this.returnObject1.ProjectDefinitionEdit = token.priviledges.OutagetypesEdit !== undefined;
    this.returnObject1.ProjectDefinitionView = token.priviledges.OutagetypesView !== undefined;
    this.returnObject1.ProjectDefinitionHide = token.priviledges.ProjectdefinitionHide !== undefined;


    this.returnObject1.RiskMatrixEdit = token.priviledges.RiskmatrixEdit !== undefined;
    this.returnObject1.RiskMatrixView = token.priviledges.RiskmatrixView !== undefined;

    this.returnObject1.OpexEdit = token.priviledges.OPEXEdit !== undefined;
    this.returnObject1.OpexView = token.priviledges.OPEXView !== undefined;

    this.returnObject1.OngoingQ4sEdit = token.priviledges.OngoingQ4sEdit !== undefined;
    this.returnObject1.OngoingQ4sView = token.priviledges.OngoingQ4sView !== undefined;

    this.returnObject1.CapexEdit = token.priviledges.CAPEXEdit !== undefined;
    this.returnObject1.CapexView = token.priviledges.CAPEXView !== undefined;

    this.returnObject1.GISSLDEditorEdit = token.priviledges.GISSLDEditorEdit !== undefined;
    this.returnObject1.GISSLDEditorView = token.priviledges.GISSLDEditorView !== undefined;
    this.returnObject1.GISSLDEditorHide = token.priviledges.GISSLDEditorHide !== undefined;

    this.returnObject1.BudgetAreasEdit = token.priviledges.BudgetareasEdit !== undefined;
    this.returnObject1.BudgetAreasView = token.priviledges.BudgetareasView !== undefined;
    this.returnObject1.BudgetAreasHide = token.priviledges.BudgetareasHide !== undefined;

    this.returnObject1.MemberAndPriviledge = token.priviledges.MemberAndPriviledge !== undefined;
    this.returnObject1.MembersPrivilegesView = token.priviledges.MembersPrivilegesView !== undefined;

    this.returnObject1.OutageAreasEdit = token.priviledges.OutageareasEdit !== undefined;
    this.returnObject1.OutageAreasView = token.priviledges.OutageareasView !== undefined;
    this.returnObject1.OutageAreasHide = token.priviledges.OutageareasHide !== undefined;

    this.returnObject1.TaskNavigatorEdit = token.priviledges.TasknavigatorEdit !== undefined;
    this.returnObject1.TaskNavigatorView = token.priviledges.TasknavigatorView !== undefined;
    this.returnObject1.TaskNavigatorHide = token.priviledges.TasknavigatorHide !== undefined;

    this.returnObject1.KnowledgeBankEdit = token.priviledges.KnowledgebankEdit !== undefined;
    this.returnObject1.KnowledgeBankView = token.priviledges.KnowledgebankView !== undefined;

    this.returnObject1.TaskCriteriasPriviledgeEdit = token.priviledges.PerformanceratingEdit !== undefined;
    this.returnObject1.TaskCriteriasPriviledgeView = token.priviledges.PerformanceratingView !== undefined;

    this.returnObject1.TaskOverduePriviledgeEdit = token.priviledges.TaskoverdueEdit !== undefined;
    this.returnObject1.TaskOverduePriviledgeView = token.priviledges.TaskoverdueView !== undefined;

    this.returnObject1.ApproveDispatchEdit = token.priviledges.ApproveDispatchEdit !== undefined;
    this.returnObject1.ApproveDispatchView = token.priviledges.ApproveDispatchView !== undefined;

    this.returnObject1.ValidatePlanningEdit = token.priviledges.ValidatePlanningEdit !== undefined;
    this.returnObject1.ValidatePlanningView = token.priviledges.ValidatePlanningView !== undefined;

    this.returnObject1.ConstraintDefinitionEdit = token.priviledges.ConstraintDefinitiongEdit !== undefined;
    this.returnObject1.ConstraintDefinitionView = token.priviledges.ConstraintDefinitionView !== undefined;

    this.returnObject1.ExecutionTeamEdit = token.priviledges.ExecutionTeamEdit !== undefined;
    this.returnObject1.ExecutionTeamView = token.priviledges.ExecutionTeamView !== undefined;
    this.returnObject1.ExecutionTeamHide = token.priviledges.ExecutionteamsHide !== undefined;

    this.returnObject1.PolicySMEEdit = token.priviledges.ProjectpolicyEdit !== undefined;
    this.returnObject1.PolicySMEView = token.priviledges.ProjectpolicyView !== undefined;

    this.returnObject1.UnplannedEdit = token.priviledges.UnplannedEdit !== undefined;
    this.returnObject1.UnplannedView = token.priviledges.UnplannedView !== undefined;

    this.returnObject1.PerfromancemodelparametrizationEdit = token.priviledges.PerfromancemodelparametrizationEdit !== undefined;
    this.returnObject1.PerfromancemodelparametrizationView = token.priviledges.PerfromancemodelparametrizationView !== undefined;
    this.returnObject1.ProjectpolicyEdit = token.priviledges.ProjectpolicyEdit !== undefined;
    this.returnObject1.ProjectpolicyView = token.priviledges.ProjectpolicyView !== undefined;
    this.returnObject1.ProjectdefinitionEdit = token.priviledges.ProjectdefinitionEdit !== undefined;
    this.returnObject1.ProjectdefinitionView = token.priviledges.ProjectdefinitionView !== undefined;
    this.returnObject1.RiskmatrixEdit = token.priviledges.RiskmatrixEdit !== undefined;
    this.returnObject1.RiskmatrixView = token.priviledges.RiskmatrixView !== undefined;
    this.returnObject1.SkillsEdit = token.priviledges.SkillsEdit !== undefined;
    this.returnObject1.SkillsView = token.priviledges.SkillsView !== undefined;
    this.returnObject1.OutagetypesEdit = token.priviledges.OutagetypesEdit !== undefined;
    this.returnObject1.OutagetypesView = token.priviledges.OutagetypesView !== undefined;
    this.returnObject1.KnowledgebankEdit = token.priviledges.KnowledgebankEdit !== undefined;
    this.returnObject1.KnowledgebankView = token.priviledges.KnowledgebankView !== undefined;
    this.returnObject1.GISSLDEditorEdit = token.priviledges.GISSLDEditorEdit !== undefined;
    this.returnObject1.GISSLDEditorView = token.priviledges.GISSLDEditorView !== undefined;
    this.returnObject1.OutageareasEdit = token.priviledges.OutageareasEdit !== undefined;
    this.returnObject1.OutageareasView = token.priviledges.OutageareasView !== undefined;
    this.returnObject1.BudgetareasEdit = token.priviledges.BudgetareasEdit !== undefined;
    this.returnObject1.BudgetareasView = token.priviledges.BudgetareasView !== undefined;
    this.returnObject1.PerfromanceratingEdit = token.priviledges.PerformanceratingEdit !== undefined;
    this.returnObject1.PerfromanceratingView = token.priviledges.PerformanceratingView !== undefined;
    this.returnObject1.TaskoverdueEdit = token.priviledges.TaskoverdueEdit !== undefined;
    this.returnObject1.TaskoverdueView = token.priviledges.TaskoverdueView !== undefined;
    this.returnObject1.OPCEdit = token.priviledges.OPCEdit !== undefined;
    this.returnObject1.OPCView = token.priviledges.OPCView !== undefined;
    this.returnObject1.ManageusersEdit = token.priviledges.ManageusersEdit !== undefined;
    this.returnObject1.ManageusersView = token.priviledges.ManageusersView !== undefined;
    this.returnObject1.ExecutionteamsEdit = token.priviledges.ExecutionteamsEdit !== undefined;
    this.returnObject1.ExecutionTeamsView = token.priviledges.ExecutionteamsView !== undefined;
    this.returnObject1.ManagerolesEdit = token.priviledges.ManagerolesEdit !== undefined;
    this.returnObject1.ManagerolesView = token.priviledges.ManagerolesView !== undefined;
    this.returnObject1.ConstraintdefinitionEdit = token.priviledges.ConstraintdefinitionEdit !== undefined;
    this.returnObject1.ConstraintdefinitionView = token.priviledges.ConstraintdefinitionView !== undefined;
    this.returnObject1.TasknavigatorEdit = token.priviledges.TasknavigatorEdit !== undefined;
    this.returnObject1.TasknavigatorView = token.priviledges.TasknavigatorView !== undefined;
    this.returnObject1.ApprovedispatchEdit = token.priviledges.ApprovedispatchEdit !== undefined;
    this.returnObject1.ApprovedispatchView = token.priviledges.ApprovedispatchView !== undefined;
    this.returnObject1.ValidateplanningEdit = token.priviledges.ValidateplanningEdit !== undefined;
    this.returnObject1.ValidateplanningView = token.priviledges.ValidateplanningView !== undefined;
    this.returnObject1.AcknowlegdetasksEdit = token.priviledges.AcknowlegdetasksEdit !== undefined;
    this.returnObject1.AcknowlegdetasksView = token.priviledges.AcknowlegdetasksView !== undefined;
    this.returnObject1.PreparetasksEdit = token.priviledges.PreparetasksEdit !== undefined;
    this.returnObject1.PreparetasksView = token.priviledges.PreparetasksView !== undefined;
    this.returnObject1.TasksexecutioninfieldEdit = token.priviledges.TasksexecutioninfieldEdit !== undefined;
    this.returnObject1.TasksexecutioninfieldView = token.priviledges.TasksexecutioninfieldView !== undefined;
    this.returnObject1.SynctasksfromdesktopEdit = token.priviledges.SynctasksfromdesktopEdit !== undefined;
    this.returnObject1.SynctasksfromdesktopView = token.priviledges.SynctasksfromdesktopView !== undefined;
    this.returnObject1.SysnctasksfrommobileEdit = token.priviledges.SysnctasksfrommobileEdit !== undefined;
    this.returnObject1.SysnctasksfrommobileView = token.priviledges.SysnctasksfrommobileView !== undefined;
    this.returnObject1.IrregulartasksEdit = token.priviledges.IrregulartasksEdit !== undefined;
    this.returnObject1.IrregulartasksView = token.priviledges.IrregulartasksView !== undefined;
    this.returnObject1.TasksvalidationandratingEdit = token.priviledges.TasksvalidationandratingEdit !== undefined;
    this.returnObject1.TasksvalidationandratingView = token.priviledges.TasksvalidationandratingView !== undefined;
    this.returnObject1.CreateCItaskEdit = token.priviledges.CreateCItaskEdit !== undefined;
    this.returnObject1.CreateCItaskView = token.priviledges.CreateCItaskView !== undefined;
    this.returnObject1.AcknowledgeCItaskEdit = token.priviledges.AcknowledgeCItaskEdit !== undefined;
    this.returnObject1.AcknowledgeCItaskView = token.priviledges.AcknowledgeCItaskView !== undefined;
    this.returnObject1.ProblemstatementpreparationEdit = token.priviledges.ProblemstatementpreparationEdit !== undefined;
    this.returnObject1.ProblemstatementpreparationView = token.priviledges.ProblemstatementpreparationView !== undefined;
    this.returnObject1.PerformaCItask4QEdit = token.priviledges.PerformaCItask4QEdit !== undefined;
    this.returnObject1.PerformaCItask4QView = token.priviledges.PerformaCItask4QView !== undefined;
    this.returnObject1.PerformaCItaskvalidationEdit = token.priviledges.PerformaCItaskvalidationEdit !== undefined;
    this.returnObject1.PerformaCItaskvalidationView = token.priviledges.PerformaCItaskvalidationView !== undefined;
    this.returnObject1.ReportssettingEdit = token.priviledges.ReportssettingEdit !== undefined;
    this.returnObject1.ReportssettingView = token.priviledges.ReportssettingView !== undefined;
    this.returnObject1.NotificationcenterEdit = token.priviledges.NotificationcenterEdit !== undefined;
    this.returnObject1.NotificationcenterView = token.priviledges.NotificationcenterView !== undefined;
    this.returnObject1.InformationexplorerEdit = token.priviledges.InformationexplorerEdit !== undefined;
    this.returnObject1.InformationexplorerView = token.priviledges.InformationexplorerView !== undefined;
    this.returnObject1.PerfromancemonitoringEdit = token.priviledges.PerfromancemonitoringEdit !== undefined;
    this.returnObject1.PerfromancemonitoringView = token.priviledges.PerfromancemonitoringView !== undefined;
    this.returnObject1.DashboardsEdit = token.priviledges.DashboardsEdit !== undefined;
    this.returnObject1.DashboardsView = token.priviledges.DashboardsView !== undefined;

    //  for Main Tab

    this.returnObject1.MemberAndPriviledge =
      this.returnObject1.ManageusersEdit || this.returnObject1.ManageusersView ||
      this.returnObject1.ManagerolesEdit || this.returnObject1.ManagerolesView ||
      this.returnObject1.ExecutionteamsEdit || this.returnObject1.ExecutionteamsView ||
      this.returnObject1.SkillsEdit || this.returnObject1.SkillsView;

    let isExecutionOwner = false;
    this.returnObject1.roles = token.roles;

  //  this.returnObject1.roles = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
   // this.returnObject1.roles = this.returnObject1.roles.constructor === Array ? this.returnObject1.roles[0] : this.returnObject1.roles;
    if (this.returnObject1.roles.constructor === Array) {
      for(let i = 0 ; i<= this.returnObject1.roles.length; i++) {
        if (this.returnObject1.roles[i] === 'Execution Owner') {
          isExecutionOwner = true;
        }
      }
    } else {
      if (this.returnObject1.roles === 'Execution Owner') {
        isExecutionOwner = true;
      }
    }
    this.returnObject1.IsSystemRole = token.isSystemRole;
    return isExecutionOwner;
  }

  setUserLogin(val: boolean) {
    this.isUserLogin = val;
  }
  getUserLogin() {
    return this.isUserLogin;
  }
  setSelectedTask(item: any) {
    this.setSelectedTaskData = item;
  }

  getSelectedTask() {
    return this.setSelectedTaskData;
  }
  setPriority(item: string) {
    this.priority = item;
  }
  getPriority() {
    return this.priority;
  }
  setTaskStatusName(item: any) {
    this.taskStatusName = item;
  }
  getTaskStatusName() {
    return this.taskStatusName;
  }
  setTaskStatusId(item: any) {
    this.taskStatusId = item;
  }
  getTaskStatusId() {
    return this.taskStatusId;
  }
  setfilterData(item: any) {
    this.filterData = item;
  }
  getfilterData() {
    return this.filterData;
  }
  setEquipment(item: string) {
    this.equipment = item;
  }
  getEquipment() {
    return this.equipment;
  }
  setPlanId(item: string) {
    this.plan = item;
  }
  getPlanId() {
    return this.plan;
  }

  setRegionId(item: string) {
    this.region = item;
  }
  getRegionId() {
    return this.region;
  }


  setBayId(item: string) {
    this.bay = item;
  }
  getBayId() {
    return this.bay;
  }

  setPlannerPlanId(item: string) {
    this.planPlanner = item;
  }
  getPlannerPlanId() {
    return this.planPlanner;
  }
  setSubstation(item: string) {
    this.substation = item;
  }
  getSubstation() {
    return this.substation;
  }
  setPlanTabName(item: any) {
    this.taskStatusId = item;
  }
  getPlanTabName() {
    return this.taskStatusId;
  }
  setIrregulartask(item: any) {
    this.irregular = "irregularTasks";
  }
  getIrregulartask() {
    return "irregularTasks";
  }
  setmaintenance(item: any) {
    this.irregular = "maintenance";
  }
  getmaintenance() {
    return "maintenance";
  }

  loginUser(data) {
    return this.helper.postLogin('/Login/Login', data);
  }
  loginUserKeycloak() {
    return this.helper.getLogIn('/Login/GetLoggedinUserDetails');
  }
  OPCClientandPfModelLogin(data) {
    return this.helper.postLogin('/Login/OPCClientandPfModelLogin', data);
  }
  logOut() {
    this.setPriority('');
    this.setTaskStatusName('');
    this.setEquipment('00000000-0000-0000-0000-000000000000');
    this.setSubstation('00000000-0000-0000-0000-000000000000');
    return this.helper.get('/Login/Logout');
  }
  forgotPassword(email) {
    return this.helper.postLogin('/Login/ForgotPassword/' + email, email);
  }
  changePassword(userId) {
    // return this.http.get('http://localhost:56388/ManageSystem/Login/ChangePasswordUserClicks/' + userId);
    return this.helper.getLink('/Login/ChangePasswordUserClicks/' + userId);

  }
  resetPassword(userGuid){
    // http://localhost:56388/ManageSystem/Login/ValidatePasswordResetLink/34073503-a49f-4131-adaa-114b3b005f12
    return this.helper.getLink('/Login/ValidatePasswordResetLink/'+ userGuid)
  }
  resetPasswordUpdated(data){
    // http://localhost:56388/ManageSystem/Login/ChangePasswordByResetLink
    return this.helper.postLogin('/Login/ChangePasswordByResetLink', data)
  }
  getCurrentBuildVersion() {
    //    http://localhost:56388/ManageSystem/APIVersion/GetCurretBuildVersion
    // return this.http.get('http://localhost:56388/ManageSystem/Login/ChangePasswordUserClicks/' + userId);
    return this.helper.getLink('/APIVersion/GetCurretBuildVersion');
  }

  changePasswordAccountlock(data) {
    return this.helper.postLogin('/Login/ChangePasswordForAccountLockOut', data);
  }

  changePasswordConfirmation(data) {
    return this.helper.postLogin('/Login/ChangePassword', data);
  }
  getSingleUserDetails() {
    return this.helper.get('/assets/data/GetSingleUserDetails.json');
  }
  getAllKnowledgeBanks() {
    return this.helper.get('/assets/data/GetAllKnowledgeBanks.json');
  }
  getProjectModalling() {
    return this.helper.get('/assets/data/GetProjectModalling.json');
  }

  // getAssets(pageSize: number, pageNumber: number = 0, searchText: string = '', filterItems = []) {

  //   return this.helper.get('/assets/data/GetAssets.json')
  //     .pipe(map(ret => {
  //       const data = ret.filter(item => {
  //         if (!searchText) {
  //           return item;
  //         }
  //         return filterItems.some(filterItem => {
  //           let searchTxt = item[filterItem.prop] || '';
  //           if (!(item[filterItem.prop] instanceof String)) {
  //             searchTxt = JSON.stringify(item[filterItem.prop]);
  //           }
  //           return searchTxt.toLowerCase().indexOf(searchText) !== -1;
  //         });
  //       });
  //       return {
  //         data: data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
  //         totalElements: data.length,
  //         pafeSize: pageSize,
  //         pageNumber: pageNumber
  //       };
  //     }));
  // }


  /////// NEWLY ADDED///
  /////
  getProjectOutage() {
    return this.helper.get('/OutageType/GetAllOutages');
  }
  createOutage(data) {
    return this.helper.post('/OutageType/AddOutageType', data);
  }
  deleteOutage(data) {
    return this.helper.put('/OutageType/DeleteOutageTypes', data);
  }
  editOutage(id, data) {
    return this.helper.patch('/OutageType/UpdateOutageType?OutageId=' + id, data);
  }
  getRiskMatrix() {
    return this.helper.get('/ManageRiskMatrix/GetFlexibleRiskMatrix');
  }
  GetAssetColorForShadeByRisk(subId){
    return this.helper.get('/InformationExplorer/GetAssetColorForShadeByRisk?substationId='+subId);
  }
  getAbsoluteRiskMatrix() {
    return this.helper.get('/ManageRiskMatrix/GetRiskMatrix');
  }
  createRiskMatrix(data) {
    return this.helper.post('/ManageRiskMatrix/CreateRiskMatrix', data);
  }
  deleteRiskMatrix(data) {
    return this.helper.delete('/ManageRiskMatrix/DeleteRiskMatrix', data);
  }
  editRiskMatrix(data) {
    return this.helper.put('/ManageRiskMatrix/UpdateFlexibleRiskMatrix', data);
  }
  editAbsoluteRiskMatrix(data) {
    return this.helper.put('/ManageRiskMatrix/UpdateRiskMatrix', data);
  }
  getAllDocuments() {
    return this.helper.get('/DocumentCategory/GetAllDocumentCategories');
  }
  createDocument(data) {
    return this.helper.post('/DocumentCategory/CreateDocumentCategory', data);
  }
  updateDocument(data) {
    return this.helper.put('/DocumentCategory/UpdateDocumentCategory', data);
  }
  deleteDocumentCategory(docId) {
    return this.helper.put('/DocumentCategory/DeleteDocumentCategoryByIds', docId);
  }
  getAllImages() {
    return this.helper.get('/ImageCategory/GetAllImageCategories');
  }
  createImage(data) {
    return this.helper.post('/ImageCategory/CreateImageCategory', data);
  }
  updateImage(data) {
    return this.helper.put('/ImageCategory/UpdateImageCategory', data);
  }
  deleteImageCategory(imageId) {
    return this.helper.put('/ImageCategory/DeleteImageCategories', imageId);
  }
  getImagesById(id) {
    return this.helper.get('/ImageCategory/GetImageCategoryById/' + id);
  }
  getDocumentById(id) {
    return this.helper.get('/DocumentCategory/GetDocumentCategoryById/' + id);
  }

  // Reliabilty center APIs
  getMaintTaskTrends(id) {
    return this.helper.get('/MaintTaskOverdue/GetMaintTaskTrends?planId='+id);
  }

  getBudgetAllocationData(id) {
    return this.helper.get('/BudgetPlan/GetAllBudgetAllocationData?planId='+id);
  }

  GetProjectSubstationsRiskForGrid(data: string) {
    return this.manageProjectHelper.get('/GridModeling/GetProjectSubstationsRiskForGrid?projectId=' + data);
  }

  GetSubstationLocationInfo(substationId: string) {
    return this.manageProjectHelper.patch('/GridModeling/GetSubtationInfoSubCategory', {
      substationId,
      ssClassId: "fd5d27ff-244c-48c7-9fd4-7381143a2b73",
      substationPropCategoryId: "118a79e7-0cca-432d-8f44-6822abe58006",
      subCategoryName: "Location"
    });
  }
  importExistingInstallations(data) {
    return this.manageProjectHelper.postimportSubstation('/GridModeling/ImportExistingInstallations', data);
  }
  getPdf(taskId: string) {
    return this.manageProjectHelper.get('/PDFCreator/GetPdf?taskId=' + taskId + '&isExecute=true');
  }

  getTasksPDF(documentId: string, maintenanceType: string) {
    return this.manageProjectHelper.get(`/PDFCreator/GetTasksPdf?documentId=${documentId}&maintType=${maintenanceType}`);
  }

  getCIPdf(taskId: string) {
    return this.manageProjectHelper.get('/PDFCreator/GetCIPdf?taskId=' + taskId);
  }

  exportExcel(projectId: string) {
    return this.manageProjectHelper.getFile('/SubstationConnect/GetSubstationConnectionsForExcel?projectId=' + projectId);
  }
  importExcelFile(formData: any) {
    return this.manageProjectHelper.post('/SubstationConnect/SaveSubstationConnectionsFromExcel', formData);
  }
  ImportCommonDataExcel(formData: any) {
    return this.helper.postData('/LoadDataDH/ImportCommonDataExcel', formData);
  }

  GetAllSubstationsListForHistoricalDataLoading() {
    return this.helper.getData('/LoadDataDH/GetAllSubstationsForHistoricalDataLoading');
  }
  getEqptCategory(formData: any) {
    return this.helper.postData('/LoadDataDH/GetEqptCategory', formData);
  }

  getEqptCategoryType(formData: any) {
    return this.helper.postData('/LoadDataDH/GetEqptCategoryType', formData);
  }

  exportExcelForHistorizationData(formData: any) {
    return this.helper.postData('/LoadDataDH/ExportExcelForHistorizationData', formData);
  }
  getDownloadExcelFormJobDataHistoriztion(id, isExport,Type) {
    return this.helper.getData('/LoadDataDH/DownloadExcelFormJob?substationId='+id+'&isExport='+isExport+ '&Type='+Type );
  }
  ////// EXECUTION TEAM //////
  getAllExecutionTeam() {
    return this.helper.get('/ExecutionTeams/GetExecutionTeamsByTeamType?teamType=internal&includeDetails=true');
  }
  getInternalExecutionTeams() {
    return this.helper.get('/ExecutionTeams/GetExecutionTeamsByTeamType?teamType=Internal&includeDetails=false');
  }
  getExternalExecutionTeams() {
    return this.helper.get('/ExecutionTeams/GetExecutionTeamsByTeamType?teamType=External&includeDetails=false');
  }
  getMixedExecutionTeams() {
    return this.helper.get('/ExecutionTeams/GetExecutionTeamsByTeamType/?teamType=Mixed&includeDetails=false');
  }
  createExecutionTeam(data) {
    return this.helper.post('/ExecutionTeams/AddExecutionTeam', data);
  }
  getExecutionById(id) {
    return this.helper.get('/ExecutionTeams/GetExecutionTeamById/' + id);
  }
  updateExecutionTeam(data) {
    return this.helper.patch('/ExecutionTeams/UpdateExecutionTeam', data);
  }
  deleteExecutionTeam(id) {
    return this.helper.delete('/ExecutionTeams/DeleteExecutionTeamById/' + id);
  }
  GetAllRolesToCompare() {
    return this.helper.get('/Roles/GetAllRolesToCompare');
  }
  /////////////////// ALL USER//////////////////////////

  getAllPlannerUsers(pageSize: number, pageNumber: number = 0) {

    return this.helper.get('/User/GetAllUsers?requiredData=Planner&includeDetails=true&pageIndex=' + pageNumber)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }

  getAllUsers(pageSize: number, pageNumber: number = 0) {

    return this.helper.get('/User/GetAllUsers?requiredData=alluser&includeDetails=true&pageIndex=' + pageNumber)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getAllUsersInTeam(teamId, pageSize: number, pageNumber: number = 0) {

    return this.helper.get('/User/GetAllUsers?requiredData=TeamAllUsers&includeDetails=true&pageIndex=' + pageNumber + '&teamId=' + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getAllProjectManagers(teamId, pageSize: number, pageNumber: number = 0) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectAllUsers_Managers&includeDetails=true&pageIndex=' + pageNumber + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getAllABBProjectManagers(teamId, pageSize: number, pageNumber: number = 0) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectMember&includeDetails=true&pageIndex=' + pageNumber + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getAllProjectUsers(teamId, pageSize: number, pageNumber: number = 0, searchText = "") {
    return this.helper.get('/User/GetAllProjectUsers?requiredData=ProjectMember&includeDetails=true&pageIndex=' + pageNumber + '&searchText=' + encodeURIComponent(searchText) + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getAllProjectSupports(teamId, pageSize: number, pageNumber: number = 0) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectAllUsers_Support&includeDetails=true&pageIndex=' + pageNumber + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllUsers(teamId, pageSize: number, searchText) {

    return this.helper.get('/User/GetAllProjectUsers?requiredData=alluser&includeDetails=true&pageIndex=' + pageSize + '&searchText=' + encodeURIComponent(searchText) +  '&teamId=' + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllUsersInTeam(teamId, pageSize: number, searchText) {

    return this.helper.get('/User/GetAllUsers?requiredData=TeamAllUsers&includeDetails=true&pageIndex=' + encodeURIComponent(searchText) + '&teamId=' + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllProjectManagers(teamId, pageSize: number, searchText) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectAllUsers_Managers&includeDetails=true&pageIndex=' + encodeURIComponent(searchText) + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllABBProjectManagers(teamId, pageSize: number, searchText) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectMember&includeDetails=true&pageIndex=' + searchText + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllProjectUsers(teamId, pageSize: number, searchText) {
    return this.helper.get('/User/GetAllProjectUsers?requiredData=ProjectMember&includeDetails=true&pageIndex=' + pageSize  + '&searchText=' + encodeURIComponent(searchText) + '&teamId=' + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  searchGetAllProjectSupports(teamId, pageSize: number, searchText) {
    return this.helper.get('/User/GetAllUsers?requiredData=ProjectAllUsers_Support&includeDetails=true&pageIndex=' + encodeURIComponent(searchText) + '&teamId='
      + teamId)
      .pipe(map(ret => ({
        data: ret.returnData,
        totalElements: ret.totalCount,
        pafeSize: pageSize,
        // pageNumber: pageNumber,
        allData: ret,
      })));
  }
  getUserById(id) {
    return this.helper.get('/User/GetUserById/' + id);
  }
  createUser(data) {
    return this.helper.post('/User/CreateUser', data);
  }
  updateUser(data) {
    return this.helper.put('/User/UpdateUser', data);
  }
  deleteUser(userId) {
    return this.helper.put('/User/DeleteMultipleUsers', userId);
  }
  getChangePasswordConfirmation(id) {
    //return this.helper.putUserConfirm('/User/UpdateUserConfirmation/' + id, id);
    return this.helper.get('/User/ChangePasswordUserClicks/' + id, id);
  }
  ////////////////// Roles/////////////
  getRoles() {
    return this.helper.get('/Roles/GetAllRoles?pageName=roles');
  }
  getAllRoles(id) {
    return this.helper.get('/Roles/GetAllRoles?pageName=roles&userId=' + id);
  }
  getRolesinUser() {
    return this.helper.get('/Roles/GetAllRoles?pageName=aa');
  }
  getRolesinEditUser(id) {
    return this.helper.get('/Roles/GetAllRoles?pageName=aa&userId=' + id);
  }
  createRole(data) {
    return this.helper.post('/Roles/AddRole', data);
  }
  getRoleById(id) {
    return this.helper.get('/Roles/GetRoleById/' + id);
  }
  updateRole(data) {
    return this.helper.patch('/Roles/UpdateRole', data);
  }
  deleteRole(id) {
    return this.helper.delete('/Roles/DeleteRoleById/' + id);
  }
  getAllPrivileges() {
    return this.helper.get('/PermissionsMatrix/GetAllPrivileges');
  }

  getAllSecurablesWithGroup() {
    return this.helper.get('/PermissionsMatrix/GetAllSecurablesWithGroups');
  }
  ////////// get default Customer /////////////
  GetDefaultProject() {
    return this.manageProjectHelper.get('/Customer/GetDefaultProject');
  }
  GetDefaultProjectForPS() {
    return this.manageProjectHelper.get('/Customer/GetDefaultProjectForPS');
  }
  GetAllCountries() {
    return this.helper.get('/Common/GetAllCountries');
  }
  GetAllCurrencies() {
    return this.helper.get('/Common/GetAllCurrencies');
  }
  GetAreas() {
    return this.helper.get('/Common/GetAllAreas');
  }
  GetAllCities() {
    return this.helper.get('/Common/GetAllCities');
  }
  GetAllStates() {
    return this.helper.get('/Common/GetAllStates');
  }
  GetAllProjectTypes() {
    return this.helper.get('/Common/GetAllProjectTypes');
  }
  GetAllDiagramSymbols() {
    return this.helper.get('/Common/GetAllDiagramSymbols');
  }
  GetAllDecimalDelimiters() {
    return this.helper.get('/Common/GetAllDecimalDelimiters');
  }
  GetAllTaskTypes() {
    return this.helper.get('/Common/GetAllTaskTypes');
  }
  GetStatesByCountryById(id) {
    return this.helper.get('/Common/GetStatesByCountryById?countryId=' + id);
  }
  GetCitiesByStateId(id) {
    return this.helper.get('/Common/GetCitiesByStateId?stateId=' + id);
  }
  GetCountryByAreaId(id) {
    return this.helper.get('/Common/GetCountriesByAreaId?areaId=' + id);
  }
  GetRegionForPlanReview() {
    return this.helper.get('/Common/GetRegionForPlanReview');
  }

  //////// ********************AUDITLOGS*************************/////////////////
  getAuditLogs(Pagenumber, PageSize, SortColumn,SortOrder,searchText) {
    return this.helper.getAuditLog('/ElasticSearch/ElasticSearchUserOperations?PageNumber=' + Pagenumber + `&PageSize=` + PageSize +
     `&SortColumn=` + SortColumn + `&SortOrder=` + SortOrder + `&SearchText=` + searchText);
  }
  searchAuditLogs(Pagenumber, PageSize, searchText) {
    return this.helper.getAuditLog('/ElasticSearch/ElasticSearchUserOperations?PageNumber=' + Pagenumber + `&PageSize=` + PageSize +
    `&SearchText=` + searchText);
  }
  downloadLogs(startDateTime, endDateTime) {
    return this.helper.getAuditFile('/ElasticSearch/ExportExcel?startDate=' + startDateTime + '&endDate=' + endDateTime);
  }
  downloadAuditExcelFile(auditExcelFile) {
    return this.helper.getAuditFile('/ElasticSearch/ElasticSearchDownloadFile?fileName=' + auditExcelFile);
  }
  //////// ********************MANAGEPROJECT*************************/////////////////
  getAllProjects(Pagenumber, PageSize, searchText, SortOrder,SortColumn, isMyProject) {
    return this.manageProjectHelper.get('/Customer/GetAllCustomers?PageNumber=' + Pagenumber + `&PageSize=` + PageSize +
    `&SearchText=` + searchText + `&SortOrder=` + SortOrder + `&SortColumn=` + SortColumn + `&IsMyProject=` + isMyProject);
  }

  GetDefaultCustomerDetails() {
    return this.manageProjectHelper.getLogIn('/Customer/GetDefaultCustomerDetails');
  }
  // getProject() {
  //   return this.manageProjectHelper.get('/Project/GetAllProjects');
  // }
  createProject(data) {
    return this.manageProjectHelper.post('/Customer/AddCustomer', data);
  }
  editProject(data) {
    return this.manageProjectHelper.put('/Customer/EditProject', data);
  }
  getProjectById(id) {
    return this.manageProjectHelper.get('/Customer/GetProjectByIdWithCustomer/' + id);
  }
  CloseProjectById(id) {
    return this.manageProjectHelper.put('/Customer/CloseProjectById/'+id,id);
  }
  OpenProjectById(id){
    return this.manageProjectHelper.put('/Customer/OpenProjectById/'+id,id)
  }
  getAllPerformanceRatings(id) {
    return this.helper.get('/PerformanceRatings/GetAllPerformanceRatingsById?TaskTypeId=' + id);
  }
  getSkills() {
    return this.manageProjectHelper.get('/Skills/GetAllSkills');
  }
  // getAssessmentFields() {
  //   return this.helper.get(`/assets/data/BayWeightAssessmentCriteria.json`);
  // }
  // getAssessmentFieldsForAPI() {
  //   return this.helper.get(`/assets/data/BayWeightAssessmentCriteriaForAPI.json`);
  // }
  createSkill(data) {
    return this.manageProjectHelper.post('/Skills/CreateSkill', data);
  }
  updateSkill(data) {
    return this.manageProjectHelper.put('/Skills/UpdateSkill', data);
  }
  deleteSkill(skillId) {
    return this.manageProjectHelper.put('/Skills/DeleteSkillByMultipleIds', skillId);
  }
  getRegions() {
    return this.helper.get('/Common/GetRegions');
  }
  getAllSubstationTypes() {
    return this.helper.get('/Common/getAllSubstationTypes');
  }
  getAllVoltageTypes() {
    return this.helper.get('/Common/GetAllVoltageTypes');
  }
  getAllSwitchgearTypes() {
    return this.helper.get('/Common/GetAllSwitchgearTypes');
  }
  GetAllRatingLevels() {
    return this.helper.get('/PerformanceRatings/GetAllRatingLevels');
  }

  GetDownloadProjectSettings(id) {
    return this.helper.get('/Common/GetDownloadProjectSettings?projectId=' + id);
  }

  GetDownloadProjectSettingsNoReturnFile(id, kenralId): Observable<Blob> {
    return this.helper.get('/Common/GetDownloadProjectSettings_NoReturnFile?projectId=' + id + '&kversionId=' + kenralId,
    { responseType: 'blob'});
  }

  GetDownloadProjectSettingsFileContentResult(id, kenralId): Observable<Blob> {
    return this.helper.get('/Common/GetDownloadProjectSettings_FileContentResult?projectId=' + id + '&kversionId=' + kenralId,
     { responseType: 'blob'});
  }

  GetDownloadProjectSettingsFile(id, kenralId): Observable<Blob> {
    return this.helper.get('/Common/GetDownloadProjectSettings_File?projectId=' + id + '&kversionId=' + kenralId,
    { responseType: 'blob'});
  }

  GetDownloadProjectSettingsCopyToAsync(id, kenralId): Observable<Blob> {
    return this.helper.get('/Common/GetDownloadProjectSettings_CopyToAsync?projectId=' + id + '&kversionId=' + kenralId,
    { responseType: 'blob'});
  }

  GetDownloadProjectSettingsDownloadFile(id, kenralId): Observable<Blob> {
    return this.helper.get('/Common/GetDownloadProjectSettings_DownloadFile?projectId=' + id + '&kversionId=' + kenralId,
    { responseType: 'blob'});
  }

  // Manage Project API's   '+'?PageNumber='+num+'&PageSize='+tot);

  getProjectModallingArea(type, BudgetAreaId,outage, PageNumber, PageSize, SearchText, SortOrder, SortColumn) {
    return type === 'Outage' ? this.manageProjectHelper.get(`/OutageArea/GetAllOutages?OutageAreaId=`+ outage +`&PageNumber=` + PageNumber + `&PageSize=` + PageSize +
      `&SortOrder=` + SortOrder + `&SortColumn=` + SortColumn) :
      this.manageProjectHelper.get(`/BudgetArea/GetAllBudgetAreas?BudgetAreaId=` + BudgetAreaId + `&PageNumber=` + PageNumber + `&PageSize=` + PageSize +
        `&SearchText=` + SearchText + `&SortOrder=` + SortOrder + `&SortColumn=` + SortColumn);
  }

  // RefreshUnplannedAssetMaterializedView() {
  //   return this.manageProjectHelper.get('/Substation/RefreshUnplannedAssetMaterializedView');
  // }

  GetUnAssignedBudgetAssets(pageSize: number, pageNumber: number = 0, searchText, filterItems = []) {
    return this.manageProjectHelper.get('/BudgetArea/GetUnAssignedBudgetAssets?pageIndex=' + pageNumber + '&searchText=' + searchText)
      // .pipe(map(ret => {
      //   const data = ret.filter(item => {
      //     if (!searchText) {
      //       return item;
      //     }
      //     return filterItems.some(filterItem => {
      //       let searchTxt = item[filterItem.prop] || '';
      //       if (!(item[filterItem.prop] instanceof String)) {
      //         searchTxt = JSON.stringify(item[filterItem.prop]);
      //       }
      //       return searchTxt.toLowerCase().indexOf(searchText) !== -1;
      //     });
      //   });
      //   return {
      //     data: data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
      //     totalElements: data.length,
      //     pafeSize: pageSize,
      //     pageNumber: pageNumber
      //   };
      // }));
      .pipe(map(ret => ({
        data: ret.sldAssestGetDto,
        totalElements: ret.assetCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));

  }
  deleteSingleAssetFromOutageArea(DaletData) {
    return this.manageProjectHelper.put('/OutageArea/DeleteSingleAssetFromOutageArea', DaletData);
  }
  deleteSingleAssetFromBudgetArea(DaletData) {
    return this.manageProjectHelper.put('/BudgetArea/DeleteSingleAssetFromBudgetArea', DaletData);
  }
  removeOutageAreaById(DaleteId) {
    return this.manageProjectHelper.delete('/OutageArea/RemoveOutageAreaById/' + DaleteId);
  }
  removeBudgetAreaById(DaleteId) {
    return this.manageProjectHelper.delete('/BudgetArea/DeleteBudgetAreaById/' + DaleteId);
  }

  getAssets(pageSize: number, pageNumber: number = 0, searchText, filterItems = []) {
    return this.manageProjectHelper.get('/OutageArea/GetUnAssignedOutageAssets?pageIndex=' + pageNumber + '&searchText=' + searchText)
      // .pipe(map(ret => {
      //   const data = ret.filter(item => {
      //     if (!searchText) {
      //       return item;
      //     }
      //     return filterItems.some(filterItem => {
      //       let searchTxt = item[filterItem.prop] || '';
      //       if (!(item[filterItem.prop] instanceof String)) {
      //         searchTxt = JSON.stringify(item[filterItem.prop]);
      //       }
      //       return searchTxt.toLowerCase().indexOf(searchText) !== -1;
      //     });
      //   });
      //   return {
      //     data: data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize),
      //     totalElements: data.length,
      //     pafeSize: pageSize,
      //     pageNumber: pageNumber
      //   };
      // }));
      .pipe(map(ret => ({
        data: ret.sldAssestGetDto,
        totalElements: ret.assetCount,
        pafeSize: pageSize,
        pageNumber: pageNumber,
        allData: ret,
      })));

  }
  getAllProject() {
    return this.manageProjectHelper.get('/Customer/GetDefaultProject');
  }

  GetDefaultProjectId() {
    return this.manageProjectHelper.get('/Customer/GetDefaultProjectId');
  }

  getProjectIdFromProjectName(projectName: string) {
    return this.manageProjectHelper
      .post(
        '/Customer/GetProjectIdbyProjectName',
        `\"${projectName}\"`,
        { headers: { 'ProjectId': '00000000-0000-0000-0000-000000000000', 'Content-Type': 'application/json-patch+json' } }
      )
      .pipe(map(res => res.returnObj));
  }

  getCommonProject() {
    return this.helper.get('/Common/GetAllCommonProjectsMasterData');
  }
  GetAllCountriesIsdCode() {
    // return this.helper.getdd('/assets/data/getAllcountriesIsd.json').pipe(delay(MOCK_API_DELAY));
    return this.helper.get('/Common/GetAllCountriesIsdCode');

  }
  DisablePerformanceRating(DaleteId) {
    return this.helper.put('/PerformanceRatings/DisablePerformanceRating', DaleteId);
  }
  CreateOutageArea(formData) {
    return this.manageProjectHelper.post('/OutageArea/CreateOutageArea', formData);
  }
  CreateBudgetArea(formData) {
    return this.manageProjectHelper.post('/BudgetArea/AddBudgetArea', formData);
  }
  updateOutageArea(formData) {
    return this.manageProjectHelper.put('/OutageArea/UpdateOutageAreaById', formData);
  }
  updateBudgetArea(formData) {
    return this.manageProjectHelper.put('/BudgetArea/UpdateBudgetAreaById', formData);
  }
  deleteProject(id) {
    return this.manageProjectHelper.delete('/Project/DeleteProjectById/' + id);
  }
  saveSubstation(formData, type) {
    if (type === 'edit' || type === 'editSld') {
      return this.manageProjectHelper.put('/Substation/UpdateSubstation', formData);
    } else {
      return this.manageProjectHelper.post('/Substation/AddSubstation', formData);
    }
  }
  // deleteSubstationById(id) {
  //   return this.manageProjectHelper.delete('/Substation/DeleteSubstationById/' + id);
  // }

  // Grid Model Start
  get() {
    return this.helper.getdd('/assets/data/db.json').pipe(delay(MOCK_API_DELAY));
  }
  // getMapMarkersLocal() {
  //   return this.helper.getdd(`/assets/data/GetMapMarkers.json`).pipe(delay(MOCK_API_DELAY));
  // }

  getMasterData() {
    return this.helper.getdd('/assets/data/GetMasterData.json').pipe(delay(MOCK_API_DELAY));
  }

  getAssetInfoForNodes(nodeName: string) {
    return this.helper.getdd(`/assets/data/snippet_properties_${nodeName}.json`).pipe(delay(0));
  }
  getEquipmentCategoryType() {
    return this.helper.getdd(`/assets/data/equipment_category_type.json`).pipe(delay(0));
  }
  getEquipmentFields() {
    return this.helper.getdd(`/assets/data/snippet_properties.json`).pipe(delay(0));
  }

  /* ----------------- */
  getMapMarkers(projectId) {
    return this.manageProjectHelper.get('/GridModeling/GetGridModel/?projectId=' + projectId);
  }

  addAndUpdateSource(formData) {
    return this.manageProjectHelper.post('/GridModeling/SaveSource', formData);
  }
  addAndUpdateLoad(formData) {
    return this.manageProjectHelper.post('/GridModeling/SaveLoad', formData);
  }
  addAndUpdateJoint(formData) {
    return this.manageProjectHelper.post('/GridModeling/SaveJoint', formData);
  }
  addAndUpdateSubstation(formData) {
    return this.manageProjectHelper.post('/GridModeling/SaveSubstation', formData);
  }
  deleteSubstationInformation(id) {
    return this.manageProjectHelper.put('/GridModeling/DeleteSubstationInformation', id);
  }

  CreateSubstationDocument(formData) {
    return this.manageProjectHelper.post('/GridModeling/CreateSubstationDocument', formData);

  }
  getMasterSubstationData() {
    return this.manageProjectHelper.get('/GridModeling/GetSsAcceptableValues?className=Substation');
  }

  DeleteDocumentsByIds(Ids) {
    return this.manageProjectHelper.put('/GridModeling/DeleteDocumentsByIds?documentIds=', Ids);
  }

  // Grid Model End


  getAllCommonSubstationMasterData() {
    return this.helper.get('/Common/GetAllCommonSubstationMasterData');
  }
  createSubstationConnect(formData) {
    return this.manageProjectHelper.post('/SubstationConnect/CreateSubstationConnect', formData);
  }
  GetAllSubstationConns(id) {
    return this.manageProjectHelper.get('/SubstationConnect/GetAllSubstationConns/' + id);
  }
  deleteSubstationConnectionById(formData) {
    return this.manageProjectHelper.put('/SubstationConnect/DeleteSubstationConns', formData);
  }
  GetSubtationInfoByPropCategory(formData) {
    return this.manageProjectHelper.patch('/GridModeling/GetSubtationInfoByPropCategory', formData);
  }
  SaveSubtationInfoByPropCategory(formData) {
    return this.manageProjectHelper.patch('/GridModeling/SaveSubstationInfoByCategory', formData);
  }
  GetSubtationBasicInfoByPropCategory(formData) {
    return this.manageProjectHelper.patch('/GridModeling/GetSubtationBasicInfoByPropCategory', formData);
  }
  SaveSubstationBasicInfoByCategory(formData) {
    return this.manageProjectHelper.patch('/GridModeling/SaveSubstationBasicInfoByCategory', formData);
  }
  DeleteImagesByDocumentIds(formData) {
    return this.manageProjectHelper.put('/GridModeling/DeleteDocumentsByIds', formData);
  }
  GetSubstationCategoriesById(ssClassId) {
    return this.manageProjectHelper.get('/GridModeling/GetSubstationCategoriesById?ExternalNodeTypeId=' + ssClassId);
  }
  GetDocumentByCategory(subStationId) {
    return this.manageProjectHelper.get('/GridModeling/GetDocumentByCategory?SubstationId=' + subStationId);
  }
  // policy



  // getAllPolicies(id) {
  //   return this.helper.getdd(`/assets/data/GetAllPolicies.json`);
  // }

  // getPolicy(id) {
  //   return this.helper.getdd(`/assets/data/GetPolicy.json`);
  // }


  // Policy  screen\
  DefineBudgetAreaAsCompleted(data) {
    return this.helper.put('/Planning/DefineBudgetAreaAsCompleted', data);
  }

  // Policy Variant API calls
  VariantGetMaintenanceActionById(maintActionId, variantType) {
    return this.helper.get(`/Policy/GetMaintenanceActionById/` + maintActionId + `?variantType=` + variantType);
  }
  GetStandardVariant(maintActionId, maintRefId) {
    return this.helper.get('/Policy/GetStandardVariant?maintActionId='+ maintActionId +'&maintActionRef='+maintRefId);
  }
  GetGlobalVariants(maintActionId, maintRefId) {
    return this.helper.get(`/Policy/GetGlobalVariants?maintActionId=`+ maintActionId +`&maintActionRef=`+maintRefId);
  }
  GetProjectVariants(maintActionId, maintRefId) {
    return this.helper.get(`/Policy/GetProjectVariants?maintActionId=`+ maintActionId +`&maintActionRef=`+maintRefId);
  }
  GetPreviouslyUsedVariants(maintActionId, maintRefId) {
    return this.helper.get(`/Policy/GetPreviousUsedVariants?maintActionId=`+ maintActionId +`&maintActionRef=`+maintRefId);
  }
  GetVariantInUse(maintActionId, maintRefId) {
    return this.helper.get('/Policy/GetVariantInUse?maintActionId=' + maintActionId + '&maintActionRef=' + maintRefId);
  }

  MaintActionChangeStatus(maintActionId,status){
    return this.helper.post('/Policy/MaintActionChangeStatus?maintActionId='+maintActionId+'&isCompleted='+status, null);
  }
  UpdateMaintActionVariant(data) {
    return this.helper.put('/Policy/UpdateMaintActionVariant', data, null, { '422': true });
  }
  SaveAsNewMAVariant(data) {
    return this.helper.post('/Policy/SaveAsNewMAVariant', data);
  }
  DeleteMaintActionVariant(data) {
    return this.helper.put('/Policy/UpdateMaintActionVariant', data);
  }
  PromoteToGlobal(maintActionId) {
    return this.helper.postMsUrl(`/Policy/PromoteToGlobal`, "'"+maintActionId+"'");
  }
  PromoteToStandard(maintActionId) {
    return this.helper.postMsUrl(`/Policy/PromoteToStandard`, "'"+maintActionId+"'");
  }
  UseVariant(mainActionId, mainRefId, variantType) {
    return this.helper.post('/Policy/UseVariant?maintActionId='+mainActionId+'&maintactionref='+mainRefId +'&varianttype='+variantType, null);
  }

  GetAllCategories() {
    return this.helper.get(`/Policy/GetAllCategories`);
  }
  GetAllEquipmentsByCategoryId(id) {
    return this.helper.get(`/Policy/GetAllEquipmentsByCategoryId/` + id);
  }
  getAllEquipmentsForPolicies() {
    return this.helper.get(`/Policy/GetAllEquipmentsForPolicies/`);
  }

  GetAllMaintPlanningActionTypes() {
    return this.helper.get(`/Policy/GetAllMaintPlanningActionTypes`);
  }
  GetAllMaintenanceActionsByEqptId(id, pageIndex, searchText) {
    return this.helper.get(`/Policy/GetAllMaintenanceActionsByEqptId?equipmentId=` + id + '&pageIndex=' + pageIndex + '&searchText=' + encodeURIComponent(searchText));
  }
  GetAllMaintenanceActionsByEqptIdSusbSystem(id, pageIndex, searchText) {
    return this.helper.get(`/Policy/GetAllMaintenanceActionsByEqptId?equipmentId=` + id + '&pageIndex=' + pageIndex + '&searchText=' + encodeURIComponent(searchText) + '&isSubSystem=true');
  }
  createNewMaintenanceActionsByEqptId(formData, isSubsystem) {
    return this.helper.post(`/Policy/AddMaintenanceAction?isSubSystem=` + isSubsystem, formData);
  }
  duplicateMaintenanceActionsByEqptId(formData, isSubsystem) {
    return this.helper.post(`/Policy/SaveDuplicateMaintenanceActions?isSubsystem=` + isSubsystem, formData);
  }
  getMaintenanceActionById(id) {
    return this.helper.get(`/Policy/GetMaintenanceActionById/` + id);
  }
  SavePlanningMaintActions(data) {
    return this.helper.put(`/Policy/SavePlanningMaintActions`, data);
  }
  GetAllMaintenanceActionsPlanningById(id) {
    return this.helper.get(`/Policy/GetAllMaintenanceActionsPlanningById/?MaintActionId=` + id);
  }

  getAllMaintActionAssesment(id) {
    return this.helper.get(`/Policy/GetAllMaintActionAssesment/?MaintActionId=` + id);
  }
  saveMaintActionAssesment(data, id) {
    return this.helper.post(`/Policy/SaveMaintActionAssesment/?MaintActionId=` + id, data);
  }

  DeleteQuestionariesByMaintaincetionId(id, isReset) {
    return this.helper.put('/Policy/DeleteQuestionariesByMaintaincetionId?maintionActionId=' + id + '&isReset=' + isReset, id);
  }

  getMasterDataForAdvancedByEqptId(equip) {
    return this.helper.get(`/Policy/GetMasterDataForAdvancedByEqptId/` + equip);
  }

  // priority
  GetAllMaintActionPriority(id) {
    return this.helper.get(`/Policy/GetAllMaintActionPriority?MaintActionId=` + id);
  }
  UpdateMultipleMaintActionPriority(data) {
    return this.helper.put(`/Policy/UpdateMultipleMaintActionPriority`, data);
  }
  SaveMaintActionPriority(data, id) {
    return this.helper.post(`/Policy/SaveMaintActionPriority?MaintActionId=` + id, data);
  }
  MultipleDeleteMaintActionPriority(data) {
    return this.helper.put(`/Policy/MultipleDeleteMaintActionPriority`, data);
  }
  updateMaintenanceAction(formData) {
    return this.helper.put(`/Policy/UpdateMaintenanceAction`, formData);
  }
  deleteMaintenanceAction(formData) {
    return this.helper.put(`/Policy/DeleteMaintenanceActions`, formData);
  }
  deleteVariants(formData) {
    return this.helper.putd(`/Policy/DeleteVariants`, formData);
  }
  GetEquipmentPropByMaintActionId(id) {
    return this.helper.get(`/Policy/GetEquipmentPropByMaintActionId/` + id);
  }
  getNextMaintActionRefNum(data) {
    return this.helper.post(`/Policy/GetNextMaintActionRefNum`, data);
  }

  getAdvancedActionDataById(id) {
    return this.helper.get(`/Policy/GetAdvancedActionDataById/` + id);
  }

  updateAdvancedActionData(data) {
    return this.helper.put(`/Policy/UpdateAdvancedActionData`, data);
  }

  updateMaintActionFlag(data) {
    return this.helper.put(`/Policy/UpdateMaintActionFlag`, data);
  }

  addRationaleForMaintenanceAction(data) {
    return this.helper.post(`/Policy/AddRationaleForMaintenanceAction`, data);
  }
  getRationalesByMaintenanceActionId(id) {
    return this.helper.get(`/Policy/GetRationalesByMaintenanceActionId/` + id);
  }
  deleteRationalesForMaintenanceAction(data) {
    return this.helper.put(`/Policy/DeleteRationalesForMaintenanceAction`, data);
  }
  getRationaleAttachmentById(id) {
    return this.helper.get(`/Policy/GetRationaleAttachmentById/` + id, { responseType: 'blob' });
  }

  duplicateMaintenanceActionToAnotherEquipment(data) {
    return this.helper.post(`/Policy/DuplicateMaintenanceActionToAnotherEquipment`, data);
  }

  getSubstationSubSystems(id) {
    return this.helper.get(`/Substation/GetSubstationSubSystems/` + id);
  }

  updateAssetsSubSystems(data) {
    return this.helper.post(`/Substation/UpdateSubstationSubSystems`, data);
  }

  getAssetSubstationInformation(id: any) {
    return this.helper.getdd('/assets/data/GetAssetSubstationInformation.json');
  }

  getOperationalDiagnostics(id: any) {
    return this.helper.getdd('/assets/data/operational_diagnostics.json').pipe(delay(0));
  }

  getBasicInformation(id: any) {
    return this.helper.getdd('/assets/data/basic_information.json').pipe(delay(0));
  }

  getTechnicalInformation(id: any) {
    return this.helper.getdd('/assets/data/technical_information.json').pipe(delay(0));
  }


  getPerformance(id: any) {
    return this.helper.getdd('/assets/data/performance.json').pipe(delay(0));
  }

  getMaintenance(id: any) {
    return this.helper.getdd('/assets/data/maintenance.json').pipe(delay(0));
  }
  getComponentInformation() {
    return this.helper.getdd('/assets/data/snippet_properties_SubStation.json').pipe(delay(0));
  }

  // getAssetDetails() {
  //   return this.helper.get('/assets/data/GetAssetDetails.json');
  // }

  // outage review sample json
  // getSamplePlanOutageOverview() {
  //   return this.helper.getdd('/assets/data/GetPlan.json');
  // }

  /* Planning API */
  uploadExcelTasksForPlan(data) {
    return this.helper.post(`/Planning/UploadExcelTasksForPlan`,data);
  }
  getPlan(num,tot) {
    // getPlan(pageIndex) {
    return this.helper.get('/Planning/GetAllPlans?PageNumber='+num+'&PageSize='+tot);
    // return this.helper.get('/Planning/GetAllPlans?pageIndex=' + pageIndex);
  }
  GetAllFlaggedMaintTasksByPlanId(planId,startdate,endDate,num,tot) {
    return this.helper.get('/Planning/GetAllFlaggedMaintTasksByPlanId?PlanId='+planId+'&StartDate=' + startdate + '&EndDate=' + endDate+'&PageNumber='+num+'&PageSize='+tot);
  }
  GetPlanById(planId) {
    return this.helper.get('/Planning/GetPlanById/' + planId);
  }
  GetPlanSubstation(data){
    return this.helper.patch('/Planning/GetPlanSubstation',data);
  }
  GetAllSubstations(pageNum,PageSize,search,sortOrder,sortcolumn){
    return this.helper.get('/Planning/GetAllSubstations?PageNumber='+pageNum+'&PageSize='
    +PageSize+'&SearchText='+search+'&SortOrder='+sortOrder+'&SortColumn='+sortcolumn);
  }
  UpdatePlanToCompleted(data){
    return this.helper.put('/Planning/UpdatePlanToCompleted?planId=' + data,'');
  }
  GetPlans(data,subId){
    return this.helper.get('/Planning/GetPlans?substationId='+subId+'&IsForPlanning='+data);
  }
  GetRegiononPriority(subId) {
    return this.helper.get('/Execution/GetAllRegions?substationId='+subId);
  }
  GetPlanDetailsById(id) {
    return this.helper.get('/Planning/GetPlanDetailsById?planId=' + id, '');
  }
  GetBay(substationId){
    return this.helper.get('/Execution/GetAllBays?substationId='+substationId)
  }
  DeletePlan(data) {
    return this.helper.put('/Planning/DeletePlan', data);
  }
  GetAllPlannedOutagesAndBudgetsCount(id) {
    return this.helper.get('/Planning/GetAllPlannedOutagesAndBudgetsCount?planId=' + id);
  }

  GetAllBudgetAreaByPlanId(id) {
    return this.helper.get('/Planning/GetAllBudgetAreaByPlanId/' + id);
  }

  getPlanReviewbyPlanId(id) {
    return this.helper.get('/BudgetPlan/GetBudgetAreasByPlanId/' + id);
  }
  getAllMaintTasksByPlanId(planId, regionId, subId, startD, endD, type, num, tot) {
    return this.helper.get('/Planning/GetAllMaintTasksByPlanId?PlanId=' + planId +
      '&RegionId=' + regionId + '&SubstationId=' + subId + '&StartDate=' + startD + '&EndDate=' + endD +
      '&TaskType=' + type + '&PageNumber=' + num + '&PageSize=' + tot);
  }
  addPlan(formData) {
    return this.helper.post(`/Planning/AddPlan`, formData);
  }
  // GetPlanStartDate() {
  //   return this.helper.get('/Planning/GetPlanStartDate');
  // }

  updatePlan(formData) {
    return this.helper.put(`/Planning/UpdatePlan`, formData);
  }

  getPlanningHorizonYears(formData) {
    return this.helper.post(`/Planning/GetPlanningHorizonYears`, formData);
  }
  getAllOutageAreasByPlanId(id,pageNumber,pageSize,search,sortcolumn,sortOrder) {
    // getAllOutageAreasByPlanId(id, pageIndex) {
    // return this.helper.get('/Planning/GetAllOutageAreasByPlanId?planId=' + id + '&pageIndex=' + pageIndex);
    return this.helper.get('/Planning/GetAllOutageAreasByPlanId?planId=' + id + '&PageNumber='
     +pageNumber + '&PageSize='+pageSize +'&SearchText='+search+'&SortOrder='+sortcolumn+'&SortColumn='+sortOrder);
  }
  getBudgetPlanningOverview(id) {
    return this.helper.get('/BudgetPlan/GetPlanOverviewForBudget?planId=' + id);
  }
  getAllOutageForPlanningYear(id) {
    return this.helper.get('/Planning/GetAllOutageForPlanningYear?planId=' + id);
  }
  getReportGenerationStatus(id) {
    return this.helper.get('/Planning/GetReportGenerationStatus?planId=' + id);
  }
  getAllAssignedBudgetInBudgetArea(id, planId) {
    return this.helper.get('/BudgetPlan/AllAssignedBudgetInBudgetArea/' + id + '/' + planId);
  }
  GetAssetsInBudgetArea(id, year, planId,size,tot) {
    return this.helper.get('/BudgetPlan/GetAssetsInBudgetArea?budgetAreaId=' + id + '&budgetYear=' + year
    + '&planId=' + planId + '&PageNumber='+size+'&PageSize='+tot).pipe(delay(MOCK_API_DELAY));
  }

  GetAssetsInBudgetAreaWithBudgetAndCost(id, planId) {
    return this.helper.get('/Planning/GetAssetsInBudgetAreaWithBudgetAndCost?budgetAreaId=' + id + '&planId=' + planId).pipe(delay(MOCK_API_DELAY));
  }
  getBudgetAreaByBudgetAreaId(budgetAreaId, planId) {
    // tslint:disable-next-line:max-line-length
    return this.helper.get('/Planning/GetBudgetAreaByBudgetAreaId/' + budgetAreaId + '/' + planId).pipe(delay(MOCK_API_DELAY));
  }
  assignAsset(data) {
    return this.helper.post('/BudgetPlan/AssignBudgetToAsset/', data);
  }
  GetPlanWithOutageAreasAndOutagesForPlanId(id) {
    return this.helper.get('/Planning/GetPlanWithOutageAreasAndOutagesForPlanId?planId=' + id);
  }
  GetPlanOverviewForBudget(id, budgetAreaId) {
    return this.helper.get('/BudgetPlan/GetPlanOverviewForBudget?planId=' + id + '&budgetAreaId=' + budgetAreaId);
  }
  getBudgetRiskMatrixData(planId, budgetAreaId) {
    return this.helper.get('/Planning/GetRiskMatrixForBudgetArea?budgetAreaId=' + budgetAreaId + '&planId=' + planId);
  }
  getPlannedAndUnplannedTasksForOutageAreaWidget(planId, ouageAreaId) {
    // tslint:disable-next-line:max-line-length/
    return this.helper.get('/Planning/GetPlannedAndUnplannedTasksForOutageAreaWidget?planId=' + planId + '&outageAreaId=' + ouageAreaId);
  }

  getPlannedAndUnplannedTasksForPlanningHorizonWidget(planId) {
    // tslint:disable-next-line:max-line-length/
    return this.helper.get('/Planning/GetPlannedAndUnplannedTasksForPlanningHorizonWidget?planId=' + planId);
  }
  getAssetTaskDetails(assetId, planId, subrefId) {
    return this.helper.get('/Planning/GetAssetTaskDetails/' + assetId + '/' + planId + '/' + subrefId);
  }

  getAllOutageAndNonOutageTasksByAssetId(assetId, planId, isOutage, fromDate, toDate, isFilterApplied,subrefId,num,tot,text,order,col) {
    // tslint:disable-next-line: max-line-length
    if (isFilterApplied) {
      return this.helper.get('/Planning/GetAllOutageAndNonOutageTasksByAssetId?assetId=' + assetId + '&planId=' + planId + '&isOutage=' + isOutage +
        '&fromDate=' + fromDate + '&toDate=' + toDate+ '&isSubsystem=' + subrefId +'&PageNumber='+num+'&PageSize='+tot
        +'&SearchText='+text+'&SortOrder='+order+'&SortColumn='+col);
    } else {
      return this.helper.get('/Planning/GetAllOutageAndNonOutageTasksByAssetId?assetId=' + assetId + '&planId=' + planId+ '&isSubsystem=' + subrefId + '&isOutage=' + isOutage +'&PageNumber='+num+'&PageSize='+tot+'&SearchText='+text+'&SortOrder='+order+'&SortColumn='+col);
    }
  }

  GetFlaggedOutageTasksAndPlanCommentByPlanId(planId, assetId) {
    return this.helper.get('/Planning/GetFlaggedOutageTasksAndPlanCommentByPlanId?planId=' + planId + '&assetId=' + assetId);
  }
  // SAMple

  getOutageAreaforplanOverview(data) {
    return this.helper.post('/Planning/GetOutageAreasForPlanHorizonBasedOnFilter', data);
    // return this.helper.getdd('/assets/data/planOverview.json').pipe(delay(MOCK_API_DELAY));
  }

  UpdateOutageFlag(formData) {
    return this.helper.put('/Planning/UpdateOutageFlag', formData);
  }
  // getPlanEdit() {
  //   return this.helper.getdd('/assets/data/GetPlanEdit.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getPlanningLanding() {
  //   return this.helper.getdd('/assets/data/GetPlanningLanding.json').pipe(delay(MOCK_API_DELAY));
  // }

  ApprovalPlan(planId) {
    return this.helper.put('/Planning/ApprovalPlan?planId=' + planId, '');
  }
  SendBackToPlanner(data) {
    return this.helper.put('/Planning/SendBackToPlanner', data);
  }

  SendPlanForApproval(planId) {
    return this.helper.put('/Planning/SendPlanForApproval?planId=' + planId, '');
  }
  // Outages start
  AddOutage(data) {
    return this.helper.post(`/Planning/AddOutage`, data);
  }

  updateOutage(data) {
    return this.helper.put(`/Planning/UpdateOutages`, data);
  }

  deleteOutageData(id) {
    return this.helper.put('/Planning/DeleteOutageByOutageId/' + id, id);
  }

  getOutageArea(outageId, planId) {
    return this.helper.get('/Planning/GetOutageAreaByOutageAreaIdAndPlanId?outageAreaId=' + outageId + '&planId=' + planId).pipe(delay(MOCK_API_DELAY));
  }

  duplicateOutagesArea(data) {
    return this.helper.post(`/Planning/CreateDuplicateOutageInOutageArea`, data);
  }
  addOutagetoAnotherOutage(data) {
    return this.helper.post(`/Planning/AddOutageToAnotherOutageAreas`, data);
  }

  defineOutage(data) {
    return this.helper.put('/Planning/DefineOutageAreaAsCompleted', data).pipe(delay(MOCK_API_DELAY));
  }

  GetOutageAreaRiskMatrix(outageAreaId, planId) {
    return this.helper.get('/Planning/GetOutageAreaRiskMatrix/' + outageAreaId + '?planId=' + planId);
  }


  generateExcelForPlan(id) {
    return this.helper.get('/Planning/GenerateExcelForPlan?planId=' + id);
  }
  // getInplanningPlanId() {
  //   return this.helper.get('/Planning/GetInplanningPlanId');
  // }
  // Outages end
  // getBudgetsArea() {
  //   return this.helper.getdd('/assets/data/GetBudgetsArea.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getBudget() {
  //   return this.helper.getdd('/assets/data/GetBudget.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getRiskMatrixTabel() {
  //   return this.helper.getdd('/assets/data/GetRiskMatrixTable.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getRiskMatrixData() {
  //   return this.helper.getdd('/assets/data/GetRiskMatrixData.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getPlanReview() {
  //   return this.helper.getdd('/assets/data/GetPlanReview.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getAssetDetails() {
  //   return this.helper.getdd('/assets/data/GetAssetDetails.json').pipe(delay(MOCK_API_DELAY));
  // }

  // getManHour() {
  //   return this.helper.getdd('/assets/data/GetManHour.json').pipe(delay(0));
  // }

  // getManageTask() {
  //   return this.helper.getdd('/assets/data/GetManageTask.json').pipe(delay(MOCK_API_DELAY));
  // }

  // Planning new change

  getDataDropdown() {
    return this.helper.getdd('/assets/data/data-dropdown.json').pipe(delay(0));
  }



  // Execution API start
  getExecutionLanding(formData) {
    if (formData.TaskStatusId && formData.EquipmentId === '' && formData.SubstationId === '') {
      return this.helper.get('/Execution/GetAllTasksOverview?PriorityRange=' + formData.PriorityRange
        + '&TaskStatusId=' + formData.TaskStatusId
        + '&PageNumber=' + formData.PageNumber + '&PageSize=' + formData.PageSize).pipe(delay(0));
    } else if (formData.TaskStatusId && formData.EquipmentId && formData.SubstationId === '') {
      return this.helper.get('/Execution/GetAllTasksOverview?PriorityRange=' + formData.PriorityRange
        + '&TaskStatusId=' + formData.TaskStatusId
        + '&EquipmentId=' + formData.EquipmentId
        + '&PlanId=' + formData.planId
        + '&bayId=' + formData.bayId
        + '&PageNumber=' + formData.PageNumber + '&PageSize=' + formData.PageSize).pipe(delay(0));
    } else if (formData.TaskStatusId && formData.EquipmentId && formData.SubstationId) {
      return this.helper.get('/Execution/GetAllTasksOverview?PriorityRange='
        + formData.PriorityRange
        + '&TaskStatusId=' + formData.TaskStatusId
        + '&EquipmentId=' + formData.EquipmentId
        + '&SubstationId=' + formData.SubstationId
        + '&PlanId=' + formData.planId
        + '&bayId=' + formData.bayId
        + '&PageNumber=' + formData.PageNumber + '&PageSize=' + formData.PageSize
        + '&SearchText=' + formData.SearchText
        + '&SortOrder=' + formData.SortOrder + '&SortColumn=' + formData.SortColumn).pipe(delay(0));
    } else {
      return this.helper.get('/Execution/GetAllTasksOverview?PriorityRange=' + formData.PriorityRange);
    }

    // return this.helper.get('/assets/data/GetExecutionLanding.json').pipe(delay(MOCK_API_DELAY));
  }

  loadloadCIProposals() {
    //CI proposal Integration
    return this.helper.getdd('/assets/data/GetExecutionLanding.json').pipe(delay(MOCK_API_DELAY));
  }

  // getResponsibilityList() {
  //   return this.helper.getdd('/assets/data/GetResponsibilityList.json').pipe(delay(MOCK_API_DELAY));
  // }

  getExecutionPlan() {
    return this.helper.getdd('/assets/data/GetExecutionPlan.json').pipe(delay(MOCK_API_DELAY));
  }

  // RescheduleTask by assigning new date taskowver and preparator -8.1.3
  rescheduleMaintTaskByAssignNewDate(mainTaskRescheduleUpdateDto) {
    return this.helper.post('/Execution/RescheduleMaintTaskByAssignNewDate', mainTaskRescheduleUpdateDto);
  }

  // Reject Task by assigning new date taskowver and preparator
  updateTaskStatusAsRejected(maintainanceTaskRejectDto) {
    return this.helper.put('/Execution/UpdateTaskStatusAsRejected', maintainanceTaskRejectDto);
  }

  // Save Task by assigning new date taskowver and preparator
  editMainTaskReviewExecutionTeamLead(mainTaskReviewEditDto) {
    return this.helper.post('/Execution/EditMainTaskReviewExecutionTeamLead', mainTaskReviewEditDto);
  }

  // AcknowledgeTask by assigning new date taskowver and preparator(Button Acknowledge hide in Cancelled Task Status)
  updateTaskStatusAsAcknowledged(maintTaskAcknowledgeDto) {
    return this.helper.put('/Execution/UpdateTaskStatusAsAcknowledged', maintTaskAcknowledgeDto);
  }

  updateTaskRating(data) {
    return this.helper.post('/Execution/UpdateMaintTaskValidatorTaskRating', data);
  }

  // Under preparation tab select one task and mark it as planned
  updateTaskAsPlanned(updatetasktoplandto) {
    return this.helper.put('/Execution/UpdateTaskAsPlanned', updatetasktoplandto);
  }

  // Under execution tab select one task and map its data feilds**
  getExecutionDetail(id) {
    return this.helper.get('/Execution/GetExecutionTaskById?taskId=' + id);
  }
  getTaskDetails(id) {
    return this.helper.get('/Execution/GetTaskDetailByTaskId?taskId=' + id);
  }
  taskOwnerTaskSync(formData) {
    return this.helper.post('/Execution/TaskOwnerTaskSync', formData);
  }
  getAllSubstationsDropDownData(regionId) {
    var url = `/Execution/GetAllSubstationsDropDownData?regionId=`+regionId;
    return this.helper.get(url);
  }
  getAllEquipmentsDataDropDown(substationId,bayId) {
    return this.helper.get('/Execution/GetAllEquipmentsDataDropDown?substationId=' + substationId + '&bayId=' +bayId);
  }
  getTaskRatingCriteria() {
    return this.helper.get('/Execution/GetTaskRatingCriteria');
  }
  getTaskDetailForValidatorByTaskId(id) {
    return this.http.get('/assets/data/task_validation_sample.json');
    return this.helper.get('/Execution/GetTaskDetailForValidatorByTaskId/' + id);
  }
  getInProgressPlan(isIrregularTask:string) {
    return this.helper.get('/Planning/GetInProgressPlan?isIrregularTask=' + isIrregularTask);
  }
  getAllTaskValidatorMembers(id, size, tot, text, order, colum, currentSelectedRole) {
    // special character (%) not supported on filtering the data in sql
    const encodedSyntax = encodeURIComponent(text); //To get special character in API
    return this.helper.get('/Execution/GetAllTaskValidatorMembers?taskId=' + id + '&PageNumber=' + size + '&PageSize='
    +tot+'&SearchText=' + encodedSyntax + '&SortOrder=' + order + '&SortColumn=' + colum + '&CurrentRole=' + currentSelectedRole);

  }
  getAllTaskCountforPriority(minPriority, maxPriority) {
    return this.helper.get('/Execution/GetAllTaskCountforPriority?minPriority=' + minPriority + '&maxPriority=' + maxPriority);
  }
  getExecutionOverview(data) {
    return this.helper.put('/Execution/GetExecutionOverview', data);
  }
  updateMaintTaskValidatorTaskRating(data) {
    return this.helper.post('/Execution/UpdateMaintTaskValidatorTaskRating', data);
  }
  getLoggedInUserTeamRole(){
    return this.helper.get('/Execution/GetLoggedInUserTeamRole');
  }
  //  Execution API End





  getPlanReviewAssetDetails(id) {
    return this.helper.get('/BudgetPlan/GetBudgetAreasByPlanId?planId=' + id);
  }

  getMonthlyOutagesForPlanningHorizon(regionId, substationId, planId) {
    return this.helper.get('/Planning/GetMonthlyOutagesForPlanningHorizon?RegionId='
      + regionId + '&SubstationId=' + substationId + '&PlanId=' + planId);
  }

  getRiskMatrixForPlaningHorizon(id) {
    return this.helper.get('/Planning/GetRiskMatrixForPlaningHorizon?planId=' + id);
  }
  getRiskMatrixForPlaningHorizonbasedonSubstationId(planId, substationId,isBudgetArea) {
    return this.helper.get('/Planning/GetRiskMatrixForPlaningHorizon?planId=' + planId + '&substationId=' + substationId + '&isBudgetArea=' + isBudgetArea);
  }
  assignBudgetToAsset(formData) {
    return this.helper.post(`/BudgetPlan/AssignBudgetToAsset`, formData);
  }
  AssignBudgetForBudgetArea(data) {
    return this.helper.post('/BudgetPlan/AssignBudgetForBudgetArea', data);
  }
  /// need to integrate
  GetAllRejectedMaintTasks() {
    return this.helper.get('/Planning/GetAllRejectedMaintTasks');
  }
  GetIrregularTaskRecommendations() {
    return this.helper.get('/IrregularTask/GetIrregularTaskRecommendations');
  }
  GetAllAssesmentsByTaskId(taskId) {
    return this.helper.get('/IrregularTask/GetAllAssesmentsByTaskId?taskId='+taskId);
  }
  AddIrregularTaskAssessment(data) {
    return this.helper.post('/IrregularTask/AddIrregularTaskAssessment',data);
  }
   GetAllAssesmentsByEqptId(eqptId,eqptcatId) {
    return this.helper.get('/IrregularTask/GetAllAssesmentsByEqptId?eqptId='+eqptId+'&eqptCatgId='+eqptcatId);
  }
  GetAllPlannedAndUnplannedAssets(id,num, tot, text, order, col) {
    return this.helper.get('/Execution/GetAllPlannedAndUnplannedAssets?PlanId='+id + '&PageNumber=' + num + '&PageSize=' + tot
      + '&SearchText=' + text + '&SortOrder=' + order + '&SortColumn=' + col);
  }

  getTaskReviewDetails(user) {
    return this.helper.getdd(`/assets/data/GetTaskReviewDetails_${user}.json`).pipe(delay(MOCK_API_DELAY));
  }


  GetMaintTaskById(id) {
    return this.helper.get('/Planning/GetMaintTaskById?taskId=' + id);
  }
  GetAllMaintTaskStatus() {
    return this.helper.get('/Planning/GetAllMaintTaskStatus');
  }

  GetRiskMatrixForSingleAsset(assetId, planId) {
    return this.helper.get('/Planning/GetRiskMatrixForSingleAsset/' + assetId + '?planId=' + planId);
  }
  SaveTask(data) {
    return this.helper.put('/Planning/SaveTask', data);
  }


  GetAssetBudgetAssignmentYearly(planId, assetId) {
    return this.helper.get('/Planning/GetAssetBudgetAssignmentYearly?planId='
      + planId + '&assetId=' + assetId);
  }
  DeleteEquipmentImageById(id){
    return this.helper.delete('/EquipmentImage/DeleteEquipmentImageById?eqptId='+id);

  }
  GetOutageAreasForPlanHorizonBasedOnFilter(allOutageAreasInputDto) {
    return this.helper.post('/Planning/GetOutageAreasForPlanHorizonBasedOnFilter', allOutageAreasInputDto);
  }
  getTraceabilityByPlanAndAsset(planId, assetId, subrefId, num,tot) {
    return this.helper.get('/Planning/GetTraceabilityByPlanAndAsset?planId=' + planId + '&assetId=' + assetId + '&isSubsystem=' + subrefId + '&PageNumber=' + num + '&PageSize=' + tot);
  }
  getTraceabilityByPlanAndAssetTaskId(planId, assetId, taskId,num,tot) {
    return this.helper.get('/Planning/GetTraceabilityByPlanAndAsset?planId=' + planId + '&assetId=' + assetId + '&taskId=' + taskId + '&PageNumber=' + num + '&PageSize=' + tot);
  }
  generateTasksByPlanId(planId) {
    return this.helper.get('/Planning/GenerateTasksByPlanId/' + planId);
  }
  GetSLDImageById(subId, outId) {
    return this.helper.get('/Planning/GetSLDImageById?substationId=' + subId + '&outageAreaId=' + outId);
  }
  GetSubstationsByRegionId(planId) {
    return this.helper.get('/Planning/GetSubstationsByRegionId?planId='+ planId);
  }
  GetPlanYearsByPlanId(planId) {
    return this.helper.get('/Planning/GetPlanYearsByPlanId/' + planId);
  }
  getPlanById(planId) {
    return this.helper.get('/Planning/GetPlanById/' + planId);
  }
  getTotalAndRemainingHoursForOutage(planId, planYear) {
    return this.helper.get('/Planning/GetTotalAndRemainingHoursForOutage/' + planId + '/' + planYear);
  }
  GetAllBudgetAreasByPlanId(planId) {
    return this.helper.get('/BudgetPlan/GetAllBudgetAreasByPlanId?planId=' + planId);
  }
  UpdateTaskFlag(data) {
    return this.helper.put('/Planning/UpdateTaskFlag', data);
  }
  getSubCategory(categoryId, equipmentId): Observable<any> {
    const subCatURL = '/GetPropSubCatgByPropCatg?id=' + categoryId + '&equipmentId=' + equipmentId;
    return this.helper.getSample(subCatURL).pipe(delay(MOCK_API_DELAY));
  }
  getAllHazards() {
    return this.helper.getSample('/GetAllHazards').pipe(delay(0));
  }

  getAllSeverity() {
    return this.helper.getSample('/GetAllSeverities').pipe(delay(0));
  }

  GetAllProbabilities() {
    return this.helper.getSample('/GetAllProbabilities').pipe(delay(0));
  }

  GetMitigationRiskMatrix() {
    return this.helper.getSample('/GetMitigationRiskMatrix').pipe(delay(0));
  }

  GetRiskMatrix() {
    return this.helper.getSample('/GetRiskMatrix').pipe(delay(0));
  }

  GetAllDocumentCategories() {
    return this.helper.getSample('/GetAllDocumentCategories').pipe(delay(MOCK_API_DELAY));
  }

  getLatestExecutionTaskSwmsName(taskId: string) {
    const url = `/GetLatestExecutionTaskSwmsVersion?TaskId=${encodeURIComponent(taskId)}`;
    return this.helper.getSample(url);
  }

  getSwms(params) {
    var url = '/GetSWMS?';
    url = url + params.param1 + '=' + encodeURIComponent(params.param2);
    this.param1 = params.param1;
    this.param2 = params.param2;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  GetStatementTypes() {
    return this.helper.getSample('/GetAllStatementTypes').pipe(delay(MOCK_API_DELAY));
  }

  createSwms(params) {
    // return this.http.get('/assets/data/GetSwms.json').pipe(map((res: any) => res.returnObj.swms));
    let url = '/GetSWMS?';
    this.param1 = params.param1;
    this.param2 = params.param2;
    url = url + params.param1 + '=' + encodeURIComponent(params.param2);
    if (params.param3 != "" && params.param3 != undefined) {
      url = url + "&prefillSWMSName=" + params.param3
    }
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  AddSwms(formData) {
    return this.helper.postSample('/SaveSWMS', formData);
  }

  AddDocuments(formData) {
    return this.helper.uploadSample('/UploadDocToStg', formData).pipe(delay(MOCK_API_DELAY));
  }

  getallSwmsSkillName() {
    return this.helper.getSample('/GetAllSwmsSkills').pipe(delay(MOCK_API_DELAY));
  }
  getRecommendationsForSWMS(taskId,number,size,text,order,column) {
    return this.helper.getSample('/GetRecommendationsForSWMS?taskId=' + taskId+
    '&PageNumber='+number+'&PageSize='+size+'&SearchText='+text+'&SortOrder='+order+'&SortColumn='+column).pipe(delay(MOCK_API_DELAY));
  }
  updateRecommendationForSWMS(data) {
    return this.helper.putSample('/UpdateRecommendationForSWMS', data);
  }
  getCategory(equipmentId) {
    const getCategoryURL = '/GetAllPropertyCategories?equipmentId=' + equipmentId;
    return this.helper.getSample(getCategoryURL).pipe(delay(MOCK_API_DELAY));
  }
  getCategoryAdvanced(equipmentId) {
    const getCategoryURL = '/GetAllPropertyCategories?equipmentId=' + equipmentId + '&isAdvanced=true';
    return this.helper.getSample(getCategoryURL).pipe(delay(MOCK_API_DELAY));
  }

  getPropertyAdvanced(formData) {
    let url = '/GetPropertiesByPropCatg?';
    url = url + formData.mode + '=' + formData.modeId + '&' + 'propCategory' + '=' +
      formData.categoryId + '&' + 'propSubCategory=' + formData.subCategoryId + '&isAdvanced=true';
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }
  getSubCategoryAdvanced(categoryId, equipmentId) {
    const subCatURL = '/GetPropSubCatgByPropCatg?id=' + categoryId + '&equipmentId=' + equipmentId + '&isAdvanced=true';
    return this.helper.getSample(subCatURL).pipe(delay(MOCK_API_DELAY));
  }

  // the document can lie in system or project env db
  // so we need to specify the project id in the header based on where the document is
  // for system env, empty guid is to be passed
  getDocument(docId, projectId?: string) {
    var docURL = '/DownloadSWMSDoc?id=' + docId;
    return this.helper.getSample(docURL, projectId ? { headers: { 'ProjectId': projectId } } : null).pipe(delay(MOCK_API_DELAY));
  }

  getProperty(formData) {
    let url = '/GetPropertiesByPropCatg?';
    url = formData?.eqptId ? url = url + 'task=null&' + 'propCategory' + '=' + formData.categoryId + '&' + 'propSubCategory=' + formData.subCategoryId + '&assetId=' + formData.assetId + '&eqptId=' + formData.eqptId :
      url + formData.mode + '=' + encodeURIComponent(formData.modeId) + '&' + 'propCategory' + '=' + formData.categoryId + '&' + 'propSubCategory=' + formData.subCategoryId;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  getPropertyDetails(formData) {
    let url = '/GetPropertyDetails?';
    url = url + 'propId=' + formData.propertyId + '&' + 'eqptId=' + formData.equipmentId + '&isAdvanced=true';
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  getRecommendations(data) {
    let url = '/GetRecommendationsForSWMS?';
    url = url + 'task=' + data.TaskRefNo;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  getLinkDocumentInformation() {
    let url = '/GetAssetDocsForMaintTask?';
    url += 'task=' + this.param2;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  GetSwmsVersion(Param) {
    const url = '/GetSwmsVersion?action=' + Param;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));

  }
  addHazard(formData) {
    return this.helper.postSample('/SaveHazard', formData).pipe(delay(MOCK_API_DELAY));
  }
  getPolicy(id) {
    return this.helper.get(`/assets/data/GetPolicy.json`).pipe(delay(MOCK_API_DELAY));
  }
  getLatestVersionSWMSTask(Param) {
    const url = '/GetLatestVersionSWMSTask?mainTaskReference=' + encodeURIComponent(Param);
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }
  getEquipmentIdByMaintTaskId(Param) {
    const url = '/GetEquipmentIdByMaintTaskId?mainTaskId=' + Param;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }
  GetValidAssetIdByAssetId(Param) {
    const url = '/GetValidAssetIdByAssetId?assetId=' + Param;
    return this.helper.getSample(url).pipe(delay(MOCK_API_DELAY));
  }

  GetCurrencyDetails() {
    return this.helper.get('/Common/GetCurrencyDetails');
  }

  getProjectCurrencyDelimiter() {
    return this.helper.get('/Common/GetProjectCurrencyDelimiter');
  }

  DownloadDocument(docId) {
    return this.helper.get('/KnowledgeBank/DownloadDocument?documentId=' + docId);
  }

  getEquipmentIdByAssetId(assetId: any) {
    return this.helper.get('/Widget/GetEquipmentIdByAssetId/' + assetId);
  }

  getPerformanceWidgetList() {
    return this.helper.get('/Widget/GetPerformanceWidgetList');
  }

  getPerformanceWidgetSourceTabDataByEqptId(eqptId: uuid) {
    return this.helper.get('/Widget/GetPerformanceWidgetSourceTabDataByEqptId/' + eqptId);
  }

  getDataCustomizationTabDataRecordForPropertyByEqptId(eqptId: uuid, categoryId: uuid, subCategoryId: uuid, eqptPropertyId: uuid) {
    var queryParams = [];
    if (categoryId) {
      queryParams.push("categoryId=" + categoryId);
    }
    if (subCategoryId) {
      queryParams.push("subCategoryId=" + subCategoryId);
    }
    if (eqptPropertyId) {
      queryParams.push("eqptPropertyId=" + eqptPropertyId);
    }
    let url = '/Widget/GetDataCustomizationTabDataRecordForPropertyByEqptId/' + eqptId + "?" + queryParams.join("&");
    return this.helper.get(url);
  }

  LoadSourceTab(sourceConfig: WidgetSourceConfig) {
    return this.helper.post(`/Widget/LoadSourceTab/`, sourceConfig);
  }

  GetPresetTabDataForConfig(sourceConfig: WidgetSourceConfig) {
    return this.helper.post(`/Widget/GetPresetTabDataForConfig/`, sourceConfig);
  }

  getDataCustomizationRecord(eqptId: uuid, item: DisplayDataModel) {
    return this.helper.post(`/Widget/GetDataCustomizationRecord/` + eqptId, item);
  }

  getPropertyRangeValuesByEqptPropId(propertyId: string) {
    return this.helper.get('/Widget/GetPropertyRangeValuesByEqptPropId/' + propertyId);
  }

  getWidgetPresetsLookup() {
    return this.helper.getdd('/assets/data/GetWidgetPresetsLookup.json').pipe(delay(MOCK_API_DELAY));
  }

  getDefinedWidgets(widgetType = '') {
    return this.helper.get('/Widget/GetDefinedWidgets/' + widgetType);
  }

  getDefinedWidgetsByEqId(eqId) {
    return this.helper.get('/Widget/GetDefinedWidgetsByEqptId/' + eqId);
  }

  getDefinedWidget(eqptHasChartId, assetId = null) {
    return this.helper.get('/Widget/GetDefinedWidget/' + eqptHasChartId + (assetId ? `?assetId=${assetId}` : ''));
  }
  getWidgetCategories() {
    return this.helper.get('/Widget/GetWidgetCategories');
  }

  getSourceLookupData() {
    return this.helper.get(`/Widget/GetSourceLookupDto/`);
    //return this.helper.getdd('/assets/data/GetSourceLookupData.json').pipe(delay(MOCK_API_DELAY));
  }

  saveDashboard(data: WidgetDashboardConfig) {
    return this.helper.post(`/Widget/SaveDashboardState/`, data);
    //return this.helper.getdd('/assets/data/GetSourceLookupData.json').pipe(delay(MOCK_API_DELAY));
  }

  getPerformanceWidgetsCollectionByEqptId(eqptId: uuid, assetId?: uuid, isPreview?: boolean, numberOfWidgets?: number, startingFrom?: number) {
    var queryParams = [];
    if (assetId != null && assetId != undefined) {
      queryParams.push("assetId=" + assetId);
    }
    if (numberOfWidgets != null && numberOfWidgets != undefined) {
      queryParams.push("noOfWidgets=" + numberOfWidgets);
    }
    if (startingFrom != null && startingFrom != undefined) {
      queryParams.push("startingFrom=" + startingFrom);
    }
    if (isPreview != null && isPreview != undefined) {
      queryParams.push('isPreview=' + isPreview);
    }
    return this.helper.get(`/Widget/GetPerformanceWidgetsCollectionByEqptId/${eqptId}?` + queryParams.join('&'));
  }

  getWidgetsCollectionByRoleName(roleName, isPreview?: boolean) {
    return this.helper.get(`/Widget/GetWidgetsCollectionByRoleName/${roleName}?isPreview=${isPreview}`);
  }

  getWidgetsCollectionByCurrentUser(roleName: string, isPreview?: boolean) {
    return this.helper.get(`/Widget/GetWidgetsCollectionByCurrentUser/${roleName}?isPreview=${isPreview}`);
  }

  getCurrentConfigByUserDashboardId(dashboardId: uuid, isPreview?: boolean) {
    return this.helper.get(`/Widget/GetCurrentConfigByUserDashboardId/${dashboardId}?isPreview=${isPreview}`);
  }

  saveWidget(dashboardId: uuid, eqptId: uuid, roleName: string, dashboardEditMode: DashboardEditMode, widgetConfig: WidgetConfig) {
    return this.helper.post('/Widget/SaveWidget/', { dashboardId: dashboardId, eqptId: eqptId, widget: widgetConfig, roleName: roleName, dashboardEditMode: dashboardEditMode });
  }

  removeWidget(widgetId: uuid, dashboardEditMode: DashboardEditMode) {
    return this.helper.get('/Widget/RemoveWidget/' + widgetId + '?mode=' + dashboardEditMode);
  }

  getPropertyLookupData() {
    return this.helper.getdd('/assets/data/GetPropertyLookup.json').pipe(delay(MOCK_API_DELAY));
  }

  getlineChartData(config?: any) {
    return this.helper.getdd('/assets/data/lineChartData.json').pipe(delay(MOCK_API_DELAY));
  }

  getWidgetDataByWidgetId(id: string, widgetType: WidgetType, displayConfiguration: WidgetConditionalLoading) {
    const name = this.getWidgetTypeNameForUrl(widgetType);
    const queryParams = this.setQueryParametersBasedOnDisplayConfig(displayConfiguration);
    return this.helper.get(`/Widget/Get${name}WidgetData/` + id + '?' + queryParams.join('&'));
  }

  getSystemWidgetDataByWidgetId(id: string, widgetSystemType: WidgetSystemTypes) {
    const name = this.getSystemWidgetTypeNameForUrl(widgetSystemType);
    return this.helper.get(`/Widget/Get${name}SystemWidgetData/${id || ''}`);
  }

  getWidgetFakeData(widgetType: WidgetType, widgetConfig: WidgetConfig) {
    const name = this.getWidgetTypeNameForUrl(widgetType);
    return this.helper.post(`/Widget/Get${name}WidgetFakeData/`, widgetConfig);
  }

  getDisplayWidgetData(config: any) {
    return this.helper.getdd('/assets/data/GetDisplayWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }

  getPieWidgetData(config?: any) {
    return this.helper.getdd('/assets/data/GetPieWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }

  getComboWidgetData(config: any) {
    return this.helper.getdd('/assets/data/GetComboWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }

  getBarWidgetData(config: any) {
    return this.helper.getdd('/assets/data/GetBarWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }

  getStackedColumnWidgetData(config: any) {
    return this.helper.getdd('/assets/data/GetStackedColumnWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }

  getColumnClusterWidgetData(config: any) {
    return this.helper.getdd('/assets/data/GetColumnClusterWidgetData.json').pipe(delay(MOCK_API_DELAY));
  }
  getGroupedBarChartData(config: any) {
    return this.helper.getdd('/assets/data/GetGroupedBarChartData.json').pipe(delay(MOCK_API_DELAY));
  }

  getPerformanceWidgetDashboardInfo(id) {
    return this.helper.getdd('/assets/data/GetPerformanceWidgetDashboardInfo.json').pipe(delay(MOCK_API_DELAY));
  }

  getPerformanceModels() {
    return this.helper.get(`/EquipmentImage/GetAllEquipmentImagesList`).pipe(delay(MOCK_API_DELAY));
  }

  getDefaultEquipmentImages() {
    return this.helper.get('/EquipmentImage/GetAllEquipmentImagesList').pipe(delay(MOCK_API_DELAY));
  }

  getEquipmentImageById(eqptId) {
    return this.helper.get('/EquipmentImage/GetEquipmentImageById?eqptId=' + eqptId).pipe(delay(MOCK_API_DELAY));
  }

  updateEquipmentImageById(data) {
    return this.helper.put('/EquipmentImage/UpdateEquipmentImageById', data);
  }

  getDefaultColumnsTables() {
    return this.helper.get('/TablesAndColumns/GetDefaultColumnsAndTablesForAllPages').pipe(delay(MOCK_API_DELAY));
  }
  UpdateCustomerLogoById(data) {
    return this.manageProjectHelper.put('/Customer/UpdateCustomerLogoById',data).pipe(delay(MOCK_API_DELAY));
  }
  GetCustomerImageById(id) {
    return this.manageProjectHelper.get('/Customer/GetCustomerImageById?custId='+id);
  }
  UpdateDefaultColumnsAndTablesForAllPages(data) {
    return this.helper.put('/TablesAndColumns/UpdateDefaultColumnsAndTablesForAllPages', data).pipe(delay(MOCK_API_DELAY));
  }

  // getEquipment1() {
  //   return this.helper.getdd(`/assets/data/GetEquipment.json`).pipe(delay(MOCK_API_DELAY));
  // }
  GetAllPerformanceModelsDataForAnEqpt(equipId, boolean, kernelVersionId) {
    // tslint:disable-next-line:max-line-length
    return this.helper.get(`/PerformanceModels/GetAllPerformanceModelsDataForAnEqpt?eqptId=` + equipId
      + '&isNewSubsystem=' + boolean + '&kernelVersionId=' + kernelVersionId).pipe(delay(MOCK_API_DELAY));
  }

  GetAllControlValueDataForAnEqpt(kernelVersionId, equipId) {
    // tslint:disable-next-line:max-line-length
    return this.helper.get(`/KernelPameterization/GetControlValueLibraryForAnEqpt?kernelVersionId=` + kernelVersionId
     + '&eqptId=' + equipId).pipe(delay(MOCK_API_DELAY));
  }

  UpdateControlValueLibrary(data, kernelversionId, iskernelCreated, forceToCreateNewKernelVersion, isUpdate) {
    return this.helper.put(`/KernelPameterization/UpdateControlValueLibrary?kernelVersion=` + kernelversionId + '&createNewKernelVersion=' + iskernelCreated + '&forceToCreateNewKernelVersion='  + forceToCreateNewKernelVersion + '&isUpdate=' + isUpdate, data);
  }

  exportExcelControlValue(kernelversionId: string) {
    return this.helper.get('/KernelPameterization/ExportExcelForControlValue?kernelVersionId=' + kernelversionId);
  }
  ImportControlValuesPropertiesFromExcel(data) {
    return this.helper.post('/KernelPameterization/ImportControlValuesPropertiesFromExcel',data);
  }
  UpdateSubsystem(postData, kernelVersionId) {
    return this.helper.put(`/PerformanceModels/UpdateSubsystem?kernelVersionId=` + kernelVersionId, postData).pipe(delay(MOCK_API_DELAY));
  }

  AddNewSubsystem(postData) {
    return this.helper.post(`/PerformanceModels/AddNewSubsystem`, postData).pipe(delay(MOCK_API_DELAY));
  }

  DeletePerformanceModel(data) {
    return this.helper.put('/PerformanceModels/DeletePerformanceModel', data);
  }
  UpdatePropValuesForSubsystem(data, kernelversionId, iskernelCreated, forceToCreateNewKernelVersion) {
    // tslint:disable-next-line:max-line-length
    return this.helper.put('/PerformanceModels/UpdatePropValuesForSubsystem?kernelVersion=' + kernelversionId + '&createNewKernelVersion=' + iskernelCreated + '&' + 'forceToCreateNewKernelVersion=' + forceToCreateNewKernelVersion, data);
  }

  GetPropertiesByPropCatgAndPropSubCatgForAnEqpt(eqptId, propCatgId, propSubCatgId) {

    return this.helper.get('/PerformanceModels/GetPropertiesByPropCatgAndPropSubCatgForAnEqpt/'
      + eqptId + '/' + propCatgId + '/' + propSubCatgId);

  }

  // dummyAPItosaveData(data) {
  //   return this.helper.post(`/PerformanceModels/UpdateSubs1`, data);
  // }

  getPerformanceMonitoringDashboardList() {
    return this.helper.get('/PerformanceMonitoring/GetPerformanceMonitoringDashboardList');
  }

  addOrUpdatePerformanceMonitoringDashboard(data) {
    return this.helper.post('/PerformanceMonitoring/AddOrUpdatePerformanceMonitoringDashboard', data);
  }

  removePerformanceMonitoringDashboard(dashboardId: uuid) {
    return this.helper.get(`/PerformanceMonitoring/RemovePerformanceMonitoringDashboard/${dashboardId}`);
  }

  getOPCProperties() {
    return this.helper.getdd('/assets/data/GetOPCProperties.json').pipe(delay(MOCK_API_DELAY));
  }

  getApplicationMetadata() {
    return this.helper.getdd('/assets/data/GetApplicationMetadata.json');
  }

  getScadaServers() {
    return this.helper.get('/OPCMapping/GetScadaServers');
  }

  updateScadaServers(model) {
    return this.helper.post('/OPCMapping/UpdateScadaServers', model);
  }

  getOpcClientInstancesAsSelectItem() {
    return this.helper.get('/OPCMapping/GetOpcClientInstancesAsSelectItem');
  }

  checkServerCapabilities(model) {
    return this.helper.post('/OPCMapping/CheckServerCapabilities', model);
  }

  getDetailsForScadaServer(model) {
    return this.helper.post('/OPCMapping/GetDetailsForScadaServer', model);
  }

  getDataForPropertyPicker() {
    return this.helper.get('/OPCMapping/GetDataForPropertyPicker');
  }

  GetAllImageGallery(pageNum,PageSize,search,sortOrder,sortcolumn) {
    return this.helper.get('/KnowledgeBank/GetAllImageGallery?PageNumber='+pageNum+'&PageSize='
    +PageSize+'&SearchText='+search+'&SortOrder='+sortOrder+'&SortColumn='+sortcolumn);
  }

  GetAllForTechnicalDocumentation() {
    return this.helper.get('/KnowledgeBank/GetAllForTechnicalDocumentation');
  }

  GetAllMaintenancePolicies() {
    return this.helper.get('/KnowledgeBank/GetAllMaintenancePolicies');
  }

  GetAllForPerformanceReports() {
    return this.helper.get('/KnowledgeBank/GetAllForPerformanceReports');
  }

  GetAllForPerformanceReports1(reportId) {
    return this.helper.get('/KnowledgeBank/GetAllForPerformanceReports?pmReportId=' + reportId);
  }

  GetForContinuousImprovement() {
    return this.helper.get('/KnowledgeBank/GetForContinuousImprovement');
  }

  GetImpCalcMethodsAndParams(id) {
    return this.helper.get('/KernelPameterization/GetImpCalcMethodsAndParams?kernelVersionId=' + id);
  }
  GetSubsystemName(formData, isSubsystem) {
    return this.helper.post('/Policy/GetNextMaintActionRefNum?isSubsystem=' + isSubsystem, formData);
  }

  UpdateImportanceCalculationsForce(data, name, boolean) {
    // tslint:disable-next-line:max-line-length
    return this.helper.put('/KernelPameterization/UpdateImportanceCalculations?kernelVersion='
      + name + '&' + 'createNewKernelVersion=' + boolean + '&' + 'forceToCreateNewKernelVersion=true', data);
  }
  UpdateImportanceCalculations(data, name, boolean) {
    // tslint:disable-next-line:max-line-length
    return this.helper.put('/KernelPameterization/UpdateImportanceCalculations?kernelVersion=' + name + '&' + 'createNewKernelVersion=' + boolean, data);
  }

  GetAllKernelVersions() {
    return this.helper.getLogIn('/KernelPameterization/GetAllKernelVersions');
  }

  GetAllForInformationExplorer(id) {
    return this.helper.get('/InformationExplorer/GetAllForInformationExplorer?projectId=' + id);
  }

  GetAllForInformationExplorerBySubstation(id, num, tot, search, order, column) {
    return this.helper.get('/InformationExplorer/GetAllForInformationExplorerBySusbstation?substationId=' + id +
      '&PageNumber=' + num + '&PageSize=' + tot + '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + column);
  }



  GetAllPerformanceReports(id) {
    return this.helper.get('/PerformanceMonitoring/GetAllPerformanceReportsByDashaboardId?dashboardId=' + id);
  }

  GetAllPmFrequencies() {
    return this.helper.get('/PerformanceMonitoring/GetAllPmFrequencies');
  }

  addReport(formData) {
    return this.helper.post('/PerformanceMonitoring/AddReport', formData);
  }

  deletePmReport(id) {
    return this.helper.delete('/PerformanceMonitoring/DeletePmReport?pmReportId=' + id);
  }

  getDistributionList(query) {
    return this.helper.get('/PerformanceMonitoring/GetDistributionList?searchText=' + query);
  }

  CopyMaintTaskSwmsToMaintTask(maintTaskName, maintTaskId) {
    return this.helper.postSample('/CopyMaintActionSWMSToMaintTask?maintTaskName=' + maintTaskName + '&maintTaskId=' + maintTaskId, null);
  }

  getAllOpcScenariosForASubstation(id) {
    return this.helper.get('/Operations/GetAllOpcScenariosForASubstation?substationId=' + id);
  }

  getOperationScenarioBySubstationId(id) {
    return this.helper.get('/Operations/GetOperationScenarioBySubstationId?substaionId=' + id);
  }

  DeleteScenario(id) {
    return this.helper.delete('/Operations/DeleteScenarioStepById?stepId=' + id);
  }

  getAllMasterDataForOpcScenarios() {
    return this.helper.get('/Operations/GetAllMasterDataForOpcScenarios');
  }

  addOrUpdateOpcScenario(formData) {
    return this.helper.post('/Operations/AddOrUpdateOpcScenario', formData);
  }

  addOperationMode(formData) {
    return this.helper.post('/Operations/AddOperationMode', formData);
  }


  GetAssetTypesForAnEqpt(equipid, substationid) {
    return this.helper.get('/Operations/GetAssetTypesForAnEqpt?eqpuipmentTypeId=' + equipid + '&substationId=' + substationid);
  }

  GetAssetsForAnEqptType(equipid, substationid) {
    return this.helper.get('/Operations/GetAssetsForAnEqptType?eqptId=' + equipid + '&substationId=' + substationid);
  }

  GetAllOpcScenariosForASubstation(id) {
    return this.helper.get('/Operations/GetAllOpcScenariosForASubstation?substationId=' + id);
  }

  GetAssetPropertiesByAssetId(id) {
    return this.helper.get('/Operations/GetAssetPropertiesByAssetId?assetId=' + id, null);
  }

  getScenarioExecutionById(id) {
    return this.helper.get('/Operations/GetScenarioExecutionById?scenarioId=' + id);
  }

  getScenarioReport(id, isExecution) {
    return this.helper.get('/Operations/GetScenarioReport?opcOperationModeID=' + id + '&isExecution=' + isExecution);
  }

  emailScenarioReport(id, isExecution, email) {
    return this.helper.post('/Operations/EmailScenarioReport?opcOperationModeID=' + id + '&isExecution=' + isExecution, email);
  }

  OPCScenarioExecLogin(username, data) {
    return this.helper.postLogin('/Login/OPCScenarioExecLogin?UserName=' + username + '&Password=' + data, data);
  }

  getLocalSwmsByMaintActionId(id) {
    return this.helper.get('/Policy/GetLocalSwmsByMaintActionId/' + id);
  }

  getCurrencyDelimiterData() {
    return this.helper.getdd('/assets/data/CurrencyAndDelimiter.json');
  }

  cloneSWMS(originalSwmsName, swmsName, maintActionId) {
    return this.helper.postSample('/CloneSWMS?originalSwmsName=' + originalSwmsName + '&swmsName=' + swmsName + '&maintActionId=' + maintActionId, null);
  }


  getPerformanceWidgetSourceTabDataByEqptIdUrl(eqptId: uuid) {
    return this.helper.getUrl('/Widget/GetPerformanceWidgetSourceTabDataByEqptId/' + eqptId);
  }

  getDefinedWidgetsUrl(widgetType = '') {
    return this.helper.getUrl('/Widget/GetDefinedWidgets/' + widgetType);
  }

  getDefinedWidgetsByEqIdUrl(eqId) {
    return this.helper.getUrl('/Widget/GetDefinedWidgetsByEqptId/' + eqId);
  }

  getDefinedWidgetUrl(eqptHasChartId, assetId = null) {
    return this.helper.getUrl('/Widget/GetDefinedWidget/' + eqptHasChartId + (assetId ? `?assetId=${assetId}` : ''));
  }

  getWidgetCategoriesUrl() {
    return this.helper.getUrl('/Widget/GetWidgetCategories');
  }

  getSourceLookupDataUrl() {
    return this.helper.getUrl(`/Widget/GetSourceLookupDto/`);
    //return this.helper.getdd('/assets/data/GetSourceLookupData.json').pipe(delay(MOCK_API_DELAY));
  }

  getPerformanceWidgetsCollectionByEqptIdUrl(eqptId: uuid, assetId?: uuid, isPreview?: boolean) {
    var queryParams = [];
    if (assetId != null && assetId != undefined) {
      queryParams.push("assetId=" + assetId);
    }
    if (isPreview != null && isPreview != undefined) {
      queryParams.push('isPreview=' + isPreview);
    }
    return this.helper.getUrl(`/Widget/GetPerformanceWidgetsCollectionByEqptId/${eqptId}?` + queryParams.join('&'));
  }

  getWidgetsCollectionByRoleNameUrl(roleName, isPreview?: boolean) {
    return this.helper.getUrl(`/Widget/GetWidgetsCollectionByRoleName/${roleName}?isPreview=${isPreview}`);
  }

  getWidgetsCollectionByCurrentUserUrl(roleName: string, isPreview?: boolean) {
    return this.helper.getUrl(`/Widget/GetWidgetsCollectionByCurrentUser/${roleName}?isPreview=${isPreview}`);
  }

  getCurrentConfigByUserDashboardIdUrl(dashboardId: uuid, isPreview?: boolean) {
    return this.helper.getUrl(`/Widget/GetCurrentConfigByUserDashboardId/${dashboardId}?isPreview=${isPreview}`);
  }

  getWidgetDataByWidgetIdUrl(id: string, widgetType: WidgetType, displayConfiguration: WidgetConditionalLoading) {
    const name = this.getWidgetTypeNameForUrl(widgetType);
    const queryParams = this.setQueryParametersBasedOnDisplayConfig(displayConfiguration);
    return this.helper.getUrl(`/Widget/Get${name}WidgetData/` + id + '?' + queryParams.join('&'));
  }

  getSystemWidgetDataByWidgetIdUrl(id: string, widgetSystemType: WidgetSystemTypes) {
    const name = this.getSystemWidgetTypeNameForUrl(widgetSystemType);
    return this.helper.getUrl(`/Widget/Get${name}SystemWidgetData/${id || ''}`);
  }

  getIsSWMSConfirmed(taskId) {
    return this.helper.get('/Execution/isSWMSConfirmed?taskId=' + taskId);
  }

  getProjectInformation(projectId) {
    return this.helper.get('/Execution/getProjectInformation?projectId=' + projectId);
  }

  revokeTasks(formData) {
    return this.helper.post('/Execution/RevokeTasks', formData);
  }
  //task generation status
  getTaskGenarationStatus() {
    return this.helper.get('/Planning/GetTaskGenarationStatus');
  }
  GenerateTaskForFailedAssets(planId) {
    return this.helper.get('/Planning/GenerateTaskForFailedAssets?PlanId=' + planId);
  }
  GetPdfForReport(planId) {
    return this.helper.get('/Planning/GetPdfForReport?PlanId=' + planId);
  }
  DownloadGroupReport(planId) {
    return this.helper.get('/Planning/DownloadGroupReport?PlanId=' + planId);
  }
  DownloadReportDocument(groupreportid) {
    return this.helper.get('/Planning/DownloadReportDocument?groupreportid=' + groupreportid);
  }
  GetPlanExportData(planId, isExport) {
    return this.helper.get('/Planning/GetPlanExportData?planId=' + planId + '&isExport=' + isExport);
  }
  DownloadExcelFile(documentId) {
    return this.helper.get('/Planning/DownloadExcelFile?documentId=' + documentId);
  }

  GetPropertyValidationInfo(num, size, search, order, col) {
    // tslint:disable-next-line:max-line-length
    return this.helper.getPmProperty('/PropertyValidation/GetPropertyValidationInfo?PageNumber=' + num + '&PageSize=' + size + '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + col);
}
getTaskPlanStatus(taskId) {
  return this.helper.get('/Execution/GetTaskPlanStatus?taskId=' + taskId);
}

GetAggregationValidationPropertyDetails(type, num, size, search, order, col) {
  // tslint:disable-next-line: max-line-length
  return this.helper.getPmProperty('/Aggregation/GetAggregationValidationDetails?aggregationType=' + type + '&PageNumber=' + num + '&PageSize=' + size +  '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + col);
}

GetAggregationValidationSubsystemDetails(type, num, size, search, order, col) {
  // tslint:disable-next-line: max-line-length
  return this.helper.getPmSubsystem('/Aggregation/GetAggregationValidationDetails?aggregationType=' + type + '&PageNumber=' + num + '&PageSize=' + size +  '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + col);
}

DeleteProjectSupportUserFromProject(userId){
  return this.manageProjectHelper.delete('/ProjectUser/DeleteProjectSupportUserFromProject/'+userId);
}
////APM API
GetExternalSelectionOptions() {
  return this.helper.get(`/PerformanceModels/GetExternalSelectionOptions`);
}
DownloadAssetsForExternal(eptId,propId,sourceId) {
  return this.helper.get('/PerformanceModels/DownloadAssetsForExternal?EqptId='+eptId+'&ExtConditionSrcId='+propId+'&ExtPropertySrcId='+sourceId);
}
UploadAssetsForExternal(data) {
  return this.helper.post(`/PerformanceModels/UploadAssetsForExternal`,data);
}


///COPYRIGHT
GetCopyrightDetails() {
  return this.helper.getLink(`/Common/GetCopyrightDetails`);
}
GetEnvironmetalForAPMVariables() {
  return this.helper.get('/APIVersion/GetEnvironmetalForAPMVariables');
}

GetCouplingsForScadaServer(data) {
  return this.helper.post('/OPCMapping/GetCouplingsForScadaServer', data);
}

GetAlarmsAndEventsCouplings(data) {
    //return this.helper.post('/OPCMapping/GetCouplingsForScadaServer', data);
    return this.helper.post('/OPCMapping/GetAlarmsAndEventsCouplings', data);
 }      //    /OPCMapping/GetAlarmsAndEventsCouplings

getRefreshTags(data) {
  return this.helper.post('/OPCMapping/GetRefreshTags', data);
}
GetReportingAccessToken(tenantName){
  return this.helper.getreportaccess('/CommonData/GetReportingAccessToken?TenantName='+tenantName);
}
GetAllTenants(pageNumber,PageSize,SearchText,sortOrder,sortcolumn){
  return this.helper.getTenantAdmin('/Dashboard/getAllTenants?PageNumber='+pageNumber+'&PageSize='+PageSize+'&SearchText='+SearchText+'&SortOrder='+sortOrder+'&SortColumn='+sortcolumn);
}
getTenantById(id){
  return this.helper.getTenantAdmin('/Dashboard/getTenantById?tenantId='+id);
 }
UpdateTenant(id,data){
  return this.helper.putTenantadmin('/Dashboard/UpdateTenantById/' +id ,data);
}
CreateTenant(data){
  return this.helper.postTenantAdmin('/Dashboard/CreateTenant',data);
}
deleteTenant(id){
 return this.helper.deleteTenantAdmin('/Dashboard/DeleteTenantById/'+id);
}
getAllCommonTenantMasterData() {
  return this.helper.getTenantAdmin('/Dashboard/GetAllCommonTenantMasterData')
}
resetAllHangfireJobs(){
  return this.helper.deleteTenantAdmin('/Dashboard/ResetAllHangfireJobs');
}
//Multiproject
GetProjectDeploymentTypeHasHostingType(id){
  return this.manageProjectHelper.get('/Project/GetProjectDeploymentTypeHasHostingType?projectDeploymentTypeId='+id)
}
SaveMaintActionOutages(data) {
  return this.helper.post('/Policy/SaveMaintActionOutages',data);
}
GetAllProjectsforOperationCenter(data){
  return this.helper.patchOperationCenter('/OperationCenter/GetAllProjectsforOperationCenter',data)
}
GetAllInstallationsforInstalledBase(data){
  return this.helper.patchOperationCenter('/OperationCenter/GetAllInstallationsforOperationCenter',data);
}
getAssetsDetails(data) { 
  return this.helper.patchOperationCenter('/OperationCenter/GetAllAssetsforInstBase',data);
}
GetAssetsMasterData(projectId= this.constService.emptyGuid ){
  return this.helper.getInstBase('/InstallationBase/GetAssetsMasterData?projectId=' + projectId);
}
getInstallationsDetails(data) { 
  return this.helper.patchOperationCenter('/InstallationBase/GetAllInstallationsforInstalledBase',data);
}
getAllInstallationdforGridModellings(data) { 
  return this.helper.patchOperationCenter('/InstallationBase/GetAllInstallationdforGridModelling',data);
}
getProjectAssetsDetails(data) { 
  return this.helper.patchOperationCenter('/InstallationBase/GetAllProjectAssetsForInstalledBase',data);
}
getProjectInstallationsDetails(data) { 
  return this.helper.postInstBase('/InstallationBase/GetAllProjectInstallationsForInstalledBase',data);
}
GetInstalledBaseInstallationsMasterData(){
  return this.helper.getOpCenter('/InstallationBase/InstalledBaseInstallationsMasterData')
}

GetProjectsMasterDataForOperationCenter(){
  return this.helper.getOpCenter('/OperationCenter/GetProjectsMasterDataForOperationCenter')
}

GetInstallationsMasterDataForOperationCenter(projectId= this.constService.emptyGuid){
  return this.helper.getOpCenter('/OperationCenter/GetInstallationsMasterDataForOperationCenter?projectId=' + projectId)
}
GetInstallationsMasterDataForGridModelling(projectId) {
  return this.helper.getOpCenter('/InstallationBase/GetInstallationsMasterDataForGridModelling?projectId=' + projectId)
}
getInstallationData(reqObj){
  return this.helper.patchOperationCenter('/OperationCenter/GetAllInstallationsforOperationCenterBasedUponProjectId', reqObj);
}
uploadExternalData(formData: any){
  return this.helper.postInstBase('/InstallationBase/UploadInstallationDataFromExcel', formData);
}
GetOperationCenterMapDetails() {
  return this.helper.getOpCenter('/OperationCenter/GetOperationCenterMapDetails');
}
getProjectJobDetails() {
  return this.manageProjectHelper.get('/Project/GetProjectJobDetails?projectId='+sessionStorage.getItem('projectId'));
}

getInstallationsAndEndDateForProject() {
  const projectId = sessionStorage.getItem('projectId') || this.constService.emptyGuid;
  return this.manageProjectHelper
    .get(`/CommonMaintenance/substations-and-project-enddate?projectId=${projectId}`);
}

getAssetFiltersMasterData(installationIds: string[]) {
  return this.manageProjectHelper.post(`/CommonMaintenance/backlog-filters`, { installationIds });
}

getAssetsGroupOverview(filtersData: any, searchText: string, groupConfig: string[], installations: string[]) {
  // Object.keys(filtersData).forEach(key => filtersData[_.lowerFirst(key)] = filtersData[key]);
  return this.manageProjectHelper.post('/CommonMaintenance/backlog-group-overview',
    { groupConfig, filtersData, searchText, installations }
  )
  .pipe(map(res => res.returnObj))
  .pipe(map(overview => {
    const recursiveReplace = (group: any) => {
      if (!group.hasOwnProperty('count')) { return; }
      group.itemCount = group.count;
      delete group.count;
      (group.children || []).forEach(group => recursiveReplace(group));
    }
    recursiveReplace(overview);
    return overview;
  })).pipe(tap(overview => {
    // helper fn to add the additional info & hyper links for each group recursively
    const recursiveAdditionalInfo = (item, parentGroups) => {
      // backend sends the additional info in a special object for each group
      // we need to flatten it for convenience to display in table
      if (item.additionalInfo) {
        Object.assign(item, item.additionalInfo);
        delete item.additionalInfo;
      }

      // recursion step, do the same thing for children
      if (!item.children) { return; }
      // we keep track of the parents, which is needed by the hyperlink fn
      item.children.forEach(child => recursiveAdditionalInfo(child, parentGroups.concat([item])));
    }

    recursiveAdditionalInfo(overview, []);
  }));
}

getPaginatedAssets(filtersData: any, searchText: string, start: number, limit: number, installations: string[]) {
  return this.manageProjectHelper.post(
    `/CommonMaintenance/paginated-asset-details`,
    { filtersData, searchText, startLimit: start + 1, endLimit: start + limit, installations }
  ).pipe(map(res => res.returnObj.paginatedAssets));
}

getAssetsForCart(filtersData: any, searchText: string, cart: any, installations: string[]) {
  // if there are no selected groups, there is no point making an API call
  if (!cart.selectedGroups.length) { return of([]); }

  return this.manageProjectHelper.post(
    `/CommonMaintenance/add-assets-to-cart`,
    { filtersData, searchText, cart, installations }
  ).pipe(map(res => res.returnObj.selectedAssets));
}

getAllPoliciesAndDefaultDueDatesForAssets(assetIds: {assetId: string, isAsset: boolean}[], backlogStartDate: Date): Observable<{ [key: string]: any }> {
  if (!assetIds.length) { return of([]); }
  return this.manageProjectHelper.post(
    `/CommonMaintenance/getpolicydetailsforselectedassets?backlogStartDate=${moment(backlogStartDate).format('YYYY-MM-DD')}`,
    assetIds
  ).pipe(map(res => res.returnObj.assetPoliciesMapping));
}

}

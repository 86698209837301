@if (message) {
  <div class="modal-container">
    <div class="modal-header">
      <span class="modal-title" id="modal-basic-title">{{title}}</span>
      <button type="button" class="btn-close shadow-none" data-coreui-dismiss="modal" (click)="close()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <div class="row center-class ">
          <div class="col-md-8 col-sm-6 ms-auto" >
          <qrcode [qrdata]="value" [width]="450" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <div class="col-md-4 col-sm-6 ms-auto comment-banner" >
          <h4 class="heading-style">How to access this RelCare project on your mobile</h4>
          <br>
            <ol class="point-list">
              <li>1.Open RelCare application on your device</li>
              <br>
                <li>2.Click on Add environment button, to initiate QR code scanning</li>
                <br>
                  <li>3.Point the camera to this screen to capture the displayed QR code</li>
                  <br>
                    <li>4.Once the QR captured, follow the steps on the mobile device</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../../services/application.service';
import { IndexDBService } from 'src/services/indexdb.service';
@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit {
  message;
  loading = false;
  mode: string;

  constructor(
    public appSvc: ApplicationService, private indexDbService: IndexDBService) {
  }

  ngOnInit() {
    this.mode = window.location.href.indexOf('offline') === -1 ? 'online' : 'offline';

    const getCopyrightDetailsObservable = this.mode === 'online'
      ? this.appSvc.GetCopyrightDetails()
      : this.indexDbService.getMiscellaneousData('copyright');
    
    getCopyrightDetailsObservable.subscribe({
      next: (res) => {
        this.message = res.returnObj.item2;
      },
      error: (error) => {
        console.error('Error loading task:', error);
      }
    });
  }

}

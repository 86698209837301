import { ConstService } from 'src/services/const.service';
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationService } from '../../../services/application.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import * as _ from 'lodash';
import * as RouteHelper from 'src/app/utility/routing.helper';

@Component({
  selector: 'app-installation-table',
  templateUrl: './installation-table.component.html',
  styleUrls: ['./installation-table.component.scss']
})
export class InstallationTableComponent implements OnInit {
  @Input() projectId = ''; 
  @Input() type = '';
  @Input() from: string; 
  fileUploadForm: FormGroup;
  fileInputLabel: string;
  loading: boolean;
  reqObj: any;
  installationData: any = [];
  installationConfigPlan;
  pageSize;
  filterStr = "";
  configPlan: {
    id: string;
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
  };
  SortOrder: string;
  SortColumn: string;
  data: any;
  @ViewChild("UploadFileInput", { static: false }) uploadFileInput: ElementRef;

  //projectId: string | Blob;
  
  constructor(
    private dataService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private constService: ConstService
    ) { }

  ngOnInit(): void {
    
    this.fileUploadForm = this.formBuilder.group({
      myfile: [""],
    });
    this.installationConfigPlan = {
      id: "pagination1",
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
    this.getInstallationData();
  }
  getInstallationData() {
    this.reqObj = {
      projectId: this.projectId,
      deploymentType: this.type,
      pageNumber: this.installationConfigPlan.currentPage,
      pageSize: 10,
      searchText: this.filterStr,
      sortOrder: "ASC",
      sortColumn: "Technology",
      filtersdata: [],
    };
    this.installationData = [];
    this.loading = true;
    if(this.projectId !== '')
    this.dataService.getInstallationData(this.reqObj).subscribe({
      next: (res) => {
        this.loading = false;
        if (res !== null) {
          this.installationData = res.returnObj.totalInstallations;
          this.installationConfigPlan.totalItems =
            res.returnObj.totalNoOfSubstations;
        }
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  pageChange(event: any) {
    this.installationConfigPlan.currentPage = event;
    this.getInstallationData();
  }

  onSort(event) {
    this.updateSortDirection(event);
    this.SortColumn = this.updateSortColumn(event.sorts[0].prop).toLowerCase();
    this.getInstallationData();
  }

  updateSortDirection(event) {
    if (
      this.SortOrder == "ascending" &&
      this.updateSortColumn(event.sorts[0].prop).toLowerCase() ==
        this.SortColumn
    ) {
      this.SortOrder = "descending";
      document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
      document
        .getElementsByClassName("sort-btn")[0]
        .classList.add("datatable-icon-up");
    } else {
      this.SortOrder = "ascending";
      document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
      document
        .getElementsByClassName("sort-btn")[0]
        .classList.add("datatable-icon-up");
    }
  }
  updateSortColumn(sortColumn): string {
    sortColumn = sortColumn.slice(
      sortColumn.indexOf(".") + 1,
      sortColumn.length
    );
    return sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1);
  }

  isSpecialCharacter(event: any) {
    let char = event.key;
    const specailCharRegexp: RegExp = /[!@#$%^&*()~`+\=\[\]{};':"\\|,.<>\/?]/;
    if (specailCharRegexp.test(char)) {
      return true;
    } else {
      return false;
    }
  }

  updateFilter(event, searchKey) {
    this.loading = true;
    if (!this.isSpecialCharacter(searchKey) && searchKey.keyCode != 16) {
      this.reqObj.searchText = this.filterStr;
      this.getInstallationData();
    }
    this.loading = false;
  }

  enableUpload() {
    return (
      sessionStorage.getItem("currentRole") ===  "System Manager" || 
      sessionStorage.getItem("currentRole") === "System Support"
    );
  }

openInstallationInfo(event) {
  if(this.type === "External") {
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    modalRef.componentInstance.title = 'Message';
    modalRef.componentInstance.message = 'Access to installation information file is available only for internal projects.';
  }else {
    this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)],
    { queryParams: { substationId: event.installationId, classId: this.constService.substationClassId,
      equipmentName: this.constService.markerType, name: event.installation, isOpCenter: true, from: this.from, country: event.country } });
      sessionStorage.setItem('projectIdfromOpCenter', event.projectId);
      sessionStorage.setItem('projectNamefromOpCenter', event.project);
    }
}

uploadExternalFile() {
  this.uploadFileInput.nativeElement.click();
}

onFileSelect(event) {
  let af = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    // console.log(file);

    if (!_.includes(af, file.type)) {
      alert("Only EXCEL Docs Allowed!");
    } else {
      this.fileInputLabel = file.name;
      this.fileUploadForm.get("myfile").setValue(file);
      this.onFormSubmit();
    }
  }
}

onFormSubmit() {
  if (!this.fileUploadForm.get("myfile").value) {
    alert("Please fill valid details!");
    return false;
  }
  this.loading = true;

  const formData = new FormData();
  formData.append("files", this.fileUploadForm.get("myfile").value);
  formData.append("projectId", this.projectId);

  this.dataService.uploadExternalData(formData).subscribe({
    next: () => {
      this.getInstallationData();
      this.loading = false;
      const modalRef = this.modalService.open(SuccesspopupComponent, {
        centered: true,
      });
      modalRef.componentInstance.title = "Message";
      modalRef.componentInstance.message = "Data uploaded successfully";
    },
    error: () => {
      this.loading = false;
      const modalRef = this.modalService.open(SuccesspopupComponent, {
        centered: true,
      });
      modalRef.componentInstance.title = "Message";
      modalRef.componentInstance.message = "Data upload failed";
    },
  });
}


  onActivate(event) {
    if (event.cellIndex !== 9) {
      if (event.type === 'click') {
        this.openInstallationInfo(event.row);
      }
  }
 }
}

<div class="modal-container">
  <div class="modal-header">
    @if (edit) {
      <span class="modal-title" i18n>Editing installation</span>
    }
    @if (!edit) {
      <span class="modal-title" i18n>Create new installation</span>
    }
    <button type="button" class="close" (click)="deleteMarker()">
    </button>
  </div>
  <form [formGroup]="form">
    <div class="modal-body">
      <h2 class="h2-title" i18n>Details</h2>
      <div class="row">

        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Substation Reference</label>
            <input [class.invalid]="validation('name') || specialCharValidation('name')" formControlName="name" type="text"  class="form-input" />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>{{subStationTypes.property}}</label>
            <select [class.invalid]="validation('type')" formControlName="type" class="form-input">
              <option value=""> </option>
              @for (item of subStationTypes?.options; track item) {
                <option [value]="item.acceptableValue">
                  {{item.acceptableValue}}
                </option>
              }
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Owner</label>
            <input [class.invalid]="validation('owner')" formControlName="owner" type="text" class="form-input" />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Administrator</label>
            <input [class.invalid]="validation('administer')" formControlName="administer" type="text" class="form-input" />
          </div>
        </div>
      </div>
      <h2 class="h2-title" i18n>Location</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Longitude (°)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('longitude')" formControlName="longitude" type="text" class="form-input"
              />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Latitude (°)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('latitude')" formControlName="latitude" type="text" class="form-input" />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Altitude (m)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('altitude')" formControlName="altitude" type="text" class="form-input" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Construction area (m²)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('constructionArea')" formControlName="constructionArea" type="text" class="form-input"
              />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Total area (m²)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('totalArea')" formControlName="totalArea" type="text" class="form-input"
              />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Free space (m²)</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('freeSpace')" formControlName="freeSpace" type="text" class="form-input"
              />
          </div>
        </div>
      </div>
      <h2 class="h2-title" i18n>Technical</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>Control rooms</label>
            <input maxlength="50" appNumberOnly [class.invalid]="validation('controlRooms')" formControlName="controlRooms" type="text"
              class="form-input" />
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n>{{switchgearTypes.property}}</label>
            <select formControlName="switchgear" class="form-input">
              <option value="">select</option>
              @for (item of switchgearTypes?.options; track item) {
                <option [value]="item.acceptableValue">
                  {{item.acceptableValue}}
                </option>
              }
            </select>
          </div>
        </div>

      </div>
      <h2 class="h2-title" i18n>Contract</h2>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Year of installation</label>
            <select [class.invalid]="validation('installationYear')" formControlName="installationYear" class="form-input">
              <option value="">-</option>
              @for (item of years; track item) {
                <option [value]="item">{{item}}</option>
              }
            </select>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Year of commissioning</label>
            <select [class.invalid]="validation('commisioningYear')" formControlName="commisioningYear" class="form-input">
              <option value="">-</option>
              @for (item of years; track item) {
                <option [value]="item">{{item}}</option>
              }
            </select>
          </div>
        </div>

      </div>
      <div class="action-buttons">
      </div>

    </div>
    @if (userRolesObject.GISSLDEditorEdit) {
      <div class="modal-footer">
        @if (edit) {
          <button (click)="deleteMarker()" type="button" class="btn btn-link" i18n>Delete substation</button>
        }
        @if (!edit) {
          <button (click)="onSubmit()" type="button" class="btn btn-primary" i18n>Save</button>
        }
        @if (edit) {
          <button type="submit" class="btn btn-primary" i18n>Edit SLD</button>
        }
        @if (!edit) {
          <button (click)="goToSLD()" type="submit" class="btn btn-primary" i18n>Create SLD</button>
        }
      </div>
    }

  </form>
</div>
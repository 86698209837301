import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatListOption } from '@angular/material/list';
import { ApplicationService } from '../../../services/application.service';

@Component({
  selector: 'csp-common-filter',
  templateUrl: './csp-common-filter.component.html',
  styleUrls: ['./csp-common-filter.component.scss'],
})
export class CspCommonFilterComponent implements OnInit {
  @ViewChild('filterTemplate') filterTemplate!: TemplateRef<any>;
  @ViewChild('search1') searchInput!: ElementRef;
  checkedValues: any[] = [];
  selectedOptions: any;
  selectedList: any = {};
  multipleSelectedOptions: any;
  selectedValue: any;
  selectedFinalArray: any = {};
  searchedText:any
  @Input() isPopup: string;
  @Input() spareGroupFilterData:any
  @Input() tabName: any;
  @Input() totalOptionLength: any;
  @Input() filterInput: any;
  @Input() finalValue!: any[];
  @Input() isFullScreen = false;
  @Input() ActualFilterInput: any;
  @Input() projectId: any;
  @Input() filterAPICallType: string;
  @Input() filterStyles:any={'button':15, 'space': 75 , 'search': 10};
  // do the pill boxes for applied filters show only field names or values also
  @Input() pillBoxSpecificity: 'values' | 'fields' = 'fields';

  @Output() expandFilterView = new EventEmitter();
  @Output()  filterItemEmit = new EventEmitter();
  isOpen = false;
  filterItem: any;
  rightSelectedValues: any = {};
  mockSelectedList: any = {};
  masterData2 = [];
  loading = false;
  colorList = [
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
    '#808080',
  ];
  @Output() filterDataEmitter = new EventEmitter();
  finalList: string[] = [];
  multiFinalList: any[] = [];
  totalLength: number = 0;
  isOptionSelected=false;

  constructor(private appSvc: ApplicationService) {}
 @Input() filterOptions = [
    {
      key: 'EquipmentCategory',
      value: 'Equipment Category',
    }
  ];

@Input() multipleFilterOptions = [
    {
      menuId: 'EquipmentCategory',
      menuValues: {
        '1acd': 'Active',
        '2sca': 'Classic',
        '3sve': 'Limited',
        '4dav': 'Obsolete',
        '1sVfeE': 'Active EquipmentCategory',
        '2eegsedv': 'Classic EquipmentCategory',
        '3drgssb': 'Limited EquipmentCategory',
        '4sgrb': 'Obsolete EquipmentCategory',
      },
    }
  ];
  dictionary:any={}
  multiDictionary:any={}

  filteredValues: any;
  ngOnInit(): void {
    if(this.filterOptions){
  this.dictionary = Object.fromEntries(
    this.filterOptions?.map(({ key, ...rest }) => [key, rest])
  );
    }
    if(this.multipleFilterOptions){
  this.multiDictionary = Object.fromEntries(
    this.multipleFilterOptions.map(({ menuId, ...rest }) => [menuId, rest])
  );
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.filterOptions){
    this.dictionary = Object.fromEntries(
      this.filterOptions?.map(({ key, ...rest }) => [key, rest])
    );
    }if(this.multipleFilterOptions){
    this.multiDictionary = Object.fromEntries(
      this.multipleFilterOptions.map(({ menuId, ...rest }) => [menuId, rest])
    );
    }
    for (const propName in changes) {
      if (propName === 'filterInput') {
        this.rightSelectedValues = { ...this.filterInput?.filterReq };
        this.finalValue = { ...this.rightSelectedValues };
        for (var propName1 in this.finalValue) {
          if (this.multiDictionary[propName1] === undefined) {
            delete this.finalValue[propName1];
            delete this.rightSelectedValues[propName1];
          }
          if (this.multiDictionary[propName1] !== undefined) {
            this.rightSelectedValues[propName1] = this.finalValue[propName1] =
              Object.values(this.multiDictionary[propName1]?.menuValues).filter(
                (x: any) => {
                  return x === this.finalValue[propName1][0];
                }
              );
            if (
              this.finalValue[propName1] === null ||
              this.finalValue[propName1].length === 0
            ) {
              delete this.finalValue[propName1];
              delete this.rightSelectedValues[propName1];
            }
          }
        }
        this.finalList = Object.keys(this.finalValue);
        this.multiFinalList = Object.values(this.finalValue);
        var array1: any = [].concat.apply([], this.multiFinalList);
        this.totalLength = array1.length;
      }
    }

    if (this.finalValue) {
      const appliedFiltersToRemove = [];
      Object.keys(this.finalValue).forEach(prop => {
        if (this.filterOptions.map(opt => opt.key).indexOf(prop) === -1) {
          appliedFiltersToRemove.push(prop);
        }
      });
      appliedFiltersToRemove.forEach(prop => {
        this.removeFieldFromFilter(prop, true);
      });
    }
  }
  closeDialog() {
    this.isOpen = false;
    this.isOptionSelected=false
  }

  search(value: any) {
    let filter:any={}
    Object.keys(this.mockSelectedList).map((item: any) =>
    this.mockSelectedList[item] != null? (this.mockSelectedList[item].toLowerCase().includes(value.toLowerCase()) ? filter[item]=this.mockSelectedList[item] : ''): ''
    );
    this.selectedList = filter;
    return this.selectedList;
  }
  onSelection(event: any, selectedOptions: any) {
    this.isOptionSelected=true
    this.searchInput.nativeElement.value = '';
    this.selectedValue = selectedOptions;
    this.selectedList = this.multipleFilterOptions.filter((x: any) => {
      return x['menuId'] === this.selectedValue;
    })[0]
      ? this.multipleFilterOptions.filter((x: any) => {
          return x['menuId'] === this.selectedValue;
        })[0]['menuValues']
      : [];
    this.mockSelectedList = {...this.selectedList};
  }

  onMultipleSelection(event: any, selectedOptions: any, selectedcolumn: any) {
    this.checkedValues = [];
    this.selectedFinalArray[selectedcolumn] = null;
    this.multipleSelectedOptions = selectedOptions;
    for (let i = 0; i < this.multipleSelectedOptions.length; i++) {
      this.checkedValues.push(this.multipleSelectedOptions[i]._value);
    }
    this.selectedFinalArray[selectedcolumn] = this.checkedValues;
    this.rightSelectedValues = { ...this.selectedFinalArray };
  }

  applyFilter() {
    this.finalValue = { ...this.rightSelectedValues };
    for (var propName in this.finalValue) {
      if (this.finalValue[propName] === null || this.finalValue[propName].length === 0) {
        delete this.finalValue[propName];
      }
    }
    this.finalList = Object.keys(this.finalValue);
    this.multiFinalList = Object.values(this.finalValue);
    var array1: any = [].concat.apply([], this.multiFinalList);
    this.totalLength = array1.length
    this.sendDataToFilter(this.finalValue)
    this.isOpen = false;
    this.isOptionSelected=false
  }

  toggleOverlay() {
    this.rightSelectedValues = { ...this.finalValue };
    this.selectedFinalArray = { ...this.finalValue };
    this.selectedList={}
    this.isOpen = true;
    if(!this.isFullScreen) {
      this.expandFilterView.emit();
    }
  }
  getRandomColor(index: any) {
    if (index < this.colorList.length) {
      return this.colorList[index];
    } else {
      return this.colorList[index - this.colorList.length];
    }
  }

  // remove a specific value from the applied filters
  removeValueFromFilter(option: any, value: string) {
    // if only one value has been set for this filed, we basically need to remove the entire field
    if (this.finalValue[option].length === 1) { return this.removeFieldFromFilter(option); }

    this.finalValue[option].splice(this.finalValue[option].indexOf(value), 1);
    this.totalLength -= 1;
    this.sendDataToFilter(this.finalValue);
  }

  // remove an entire filter field from applied filters
  removeFieldFromFilter(option: any, silent?: boolean) {
    this.finalList = this.finalList.filter((x: any) => {
      return x !== option;
    });
    delete this.finalValue[option];
    this.multiFinalList = Object.values(this.finalValue);
    var array1: any = [].concat.apply([], this.multiFinalList);
    this.totalLength = array1.length
    if (!silent) {
      this.sendDataToFilter(this.finalValue)
    }
  }

  clearFilter() {
    this.rightSelectedValues = {};
    this.selectedFinalArray = {};
  }

  getObjectLength(key: any) {
    if (this.multiDictionary[key]?.menuValues) {
      return Object.keys(this.multiDictionary[key]?.menuValues).length;
    }
    return 0;
  }

  isEllipsisActive(e:MatListOption): boolean {
    var element=(e['_element'] as ElementRef).nativeElement['__ngContext__'][13][24]
    return element ? element.offsetWidth < element.scrollWidth : false;
  }
  sendSearchData(value:any){
    this.searchedText=value
    this.sendDataToFilter(this.finalValue)
  }

        // Removing all empty array filter if no filter selected to get all records
  mainFilterCount(req){
        let flag = 0;
        Object.keys(req).forEach(key =>{
          if(req[key].length > 0)
                flag++;
        });
    return flag;
  }
  sendDataToFilter(data:any){
    let req:any={}
    if(data !== undefined){
    this.multipleFilterOptions.map((item:any)=>{
     req[item.menuId]= data[item.menuId]?.length>0 ? data[item.menuId] : [];
    })
    if(req['Linked']?.length>0){
      req['Linked']=req['Linked']?.map((e:any) => e === 'true')
    }
  }
  else{
    req=null
  }
    let reqObject={
      filterReq: req,
      searchData: this.searchedText,
      apiCall: this.isPopup
    }
    this.filterItem = {
      filterListCount:this.totalOptionLength,
      compFilterList: this.multipleFilterOptions,
      mainFilterList:this.filterOptions,
      filterDataList:req
    }
    this.filterItemEmit.emit(this.filterItem);
    // this.appSvc.setfilterData(this.filterItem);
    this.appSvc.sendFilterList(reqObject);
    this.filterDataEmitter.emit(reqObject)
  }
  getSelectedValues(key:any,selectedValue:any){
  return this.rightSelectedValues[selectedValue] ? this.rightSelectedValues[selectedValue].indexOf(key) !== -1 : false
  }

  getErrorNoRecords(){
    if(Object.keys(this.selectedList).length <= 0 && this.isOptionSelected){
    return true
    }
    return false
  }

}

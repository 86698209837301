import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, switchMap, map } from 'rxjs';
import { db, User, Project, Task, TaskDetails, Filters } from 'src/app/db';
import { CryptoService } from './crypto.service';
import { Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import Dexie from 'dexie';
import moment from 'moment';
type MiscellaneousDataKey = 'commonSafetySwms' | 'teams' | 'hazards' | 'severity' | 'probabilities' | 'riskmatrix' 
| 'copyright';

@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  public password: string;  

  constructor(private cryptoService: CryptoService) { }

  login(email: string, password: string): Observable<User> {
    return new Observable((observer) => {
      db.users.where('email').equals(email).first().then(user => {
        this.cryptoService.decrypt(user.passwordChecker, password).then(() => {
          observer.next(user);
          observer.complete();
        }).catch(() => observer.error('password error'));
      }).catch(() => observer.error('username error'));
    });
  }

  getUsers(): Observable<User[]> {
    return new Observable((observer) => {
      db.users.toArray().then((users) => {
        observer.next(users);
        observer.complete();
      });
    });
  }


  getProjects(): Observable<Project[]> {
    const userId = sessionStorage.getItem('UserId');

    return new Observable((observer) => {
      db.projects.where('userId').equals(userId).toArray().then((projects) => {
        observer.next(projects);
        observer.complete();
      });
    });
  }

  getProjectMetaInformation(projectId: string): Observable<any> {
    return this.getTasks(projectId).pipe(map(tasks => ({
        technology: this.getUniqueArrayString(tasks, "technology").join(),
        totalInstallations: this.getUniqueArrayString(tasks, "installation").length,
        openTaskCount: tasks.length,
      })
    ))
    };

  getUniqueArrayString(arr:any[], key:string){
    return [...new Set(arr.map(task => task[key]))]
  }

  getFiltersMasterData(): Observable<Filters> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.filters.where('userId').equals(userId).filter(filters => filters.projectId === projectId).first().then((filters) => {
        this.cryptoService.decrypt(filters.encryptedData).then(data => {
          observer.next(data);
          observer.complete();
        }).catch(err => observer.error(err));
      }).catch(err => observer.error(err));
    });
  }

  getTasks(projectId?:string): Observable<Task[]> {
    const userId = sessionStorage.getItem('UserId');
    projectId = projectId || sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.tasks.where('userId').equals(userId).filter(task => task.projectId === projectId).toArray().then((tasks) => {
        Promise.all(tasks.map(task => this.cryptoService.decrypt(task.encryptedData))).then(data => {
          observer.next(data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getTaskOfflineState(taskId: string): Observable<string> {
    return new Observable((observer) => {
      db.tasks.where('id').equals(taskId).first().then(task => {
        this.cryptoService.decrypt(task.encryptedData).then(data => {
          observer.next(data.offlineState);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getTaskDetails(taskId: string): Observable<TaskDetails> {
    const userId = sessionStorage.getItem('UserId');

    return new Observable((observer) => {
      db.taskDetails.where('userId').equals(userId).filter(taskDetails => taskDetails.id === taskId).first().then((taskDetails) => {
        this.cryptoService.decrypt(taskDetails.encryptedData).then(data => {
          observer.next(data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getCommonSafetySwms(): Observable<any> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.miscellaneous.where('userId').equals(userId).filter(commonSafetySwms => commonSafetySwms.projectId === projectId).first().then((commonSafetySwms) => {
        this.cryptoService.decrypt(commonSafetySwms.encryptedData).then(data => {
          observer.next(data.commonSafetyStepsSwms);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateUser(user: Partial<User>, password?: string): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(uuid(), password).then(encryptedData => {
        // assign a random string that will be used to check if decryption with a given password is successful or not
        user.passwordChecker = encryptedData;
        db.users.put(user as User).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err))
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateProject(projectData: Partial<Project>): Observable<void> {
    let project={...projectData}
    project.userId = sessionStorage.getItem('UserId');
    project.userRoles = sessionStorage.getItem('UserRoles')
    return new Observable((observer) => {
      db.projects.put(project as Project).then(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  addOrUpdateFiltersMasterData(filters: any): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(filters).then(encryptedData => {
        db.filters.put({ userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateTask(task: Partial<Task>): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(task).then(encryptedData => {
        db.tasks.put({ id: task.id, userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  changeTaskState(taskId: string, newState: string): Observable<void> {
    return new Observable((observer) => {
      db.tasks.where('id').equals(taskId).first().then(task => {
        this.cryptoService.decrypt(task.encryptedData).then(decryptedData => {
          decryptedData.offlineState = newState;
          firstValueFrom(this.addOrUpdateTask(decryptedData)).then(() => {
            observer.next();
            observer.complete();
          }).catch((err) => observer.error(err));
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateTaskDetails(taskDetails: Partial<TaskDetails>): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(taskDetails).then(encryptedData => {
        db.taskDetails.put({ id: taskDetails.id, userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  deleteTask(taskId: string): Observable<void> {
    return new Observable((observer) => {
      db.taskDetails.where('id').equals(taskId).delete().then(() => {
        db.tasks.where('id').equals(taskId).delete().then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      });
    });
  }

  async getBackupObject() {
    const backup = {};
    for (const table of db.tables) {
      const records = await table.toArray();
      backup[table.name] = records;
    }
    return backup;
  }

  async downloadDBBackupAsFile() {
    const backup = await this.getBackupObject();

    const jsonData = JSON.stringify(backup, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    const currentDate = moment();
    const dateformat = moment(currentDate).format('DD-MM-YYYY')
    link.download = 'relcare-offline-execution-backup-'+dateformat+'.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async clearAllTables() {
    for (const table of db.tables) {
      await table.clear();
    }
  }

  async importFromBackupObject(backup: any) {
    const safetyBackup = await this.getBackupObject();
    await this.clearAllTables();

    try {
      for (const tableName in backup) {
        // console.log(tableName);
        if (backup.hasOwnProperty(tableName)) {
          const table = db.table(tableName);
          await table.bulkAdd(backup[tableName]);
        }
      }
    } catch (error) {
      console.error('Failed to import', error);
      this.importFromBackupObject(safetyBackup);
      throw new Error(error);
    }
  }

  async importBackupFromFile(file: File) {
    console.log(file);
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const data = JSON.parse(reader.result as string);
        console.log(data);
        await this.importFromBackupObject(data);
      } catch (error) {
        console.error('Failed to import data:', error);
      }
    };

    reader.readAsText(file);
  }

  getMiscellaneousData(key?: MiscellaneousDataKey): Observable<any> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');
 
    return new Observable((observer) => {
      db.miscellaneous.where('userId').equals(userId).filter(miscellaneous => miscellaneous.projectId === projectId).first().then((miscellaneous) => {
        this.cryptoService.decrypt(miscellaneous.encryptedData).then(data => {
          observer.next(key ? data[key] : data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch(() => {
        observer.next(key ? null : {});
        observer.complete();
      });
    });
  }

  addOrUpdateMiscellaneousData(key: MiscellaneousDataKey, data: any): Observable<void> {
    return new Observable((observer) => {
      firstValueFrom(this.getMiscellaneousData()).then((miscelleanous) => {
        miscelleanous[key] = data;
        this.cryptoService.encrypt(miscelleanous).then(encryptedData => {
          db.miscellaneous.put({ userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
            observer.next();
            observer.complete();
          }).catch((err) => observer.error(err));
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }
}

import Dexie, { Table } from 'dexie';

export interface User {
  id: string;
  name: string;
  email: string;
  userRoles: string
  passwordChecker: string;
};

export interface Project {
  userId: string;
  id: string;
  userRoles: string;
  location: string;
  openTaskCount: number;
  projectId: string;
  projectName: string;
  technology: string;
  totalInstallations: number;
}
export interface Task {
  id: string;
  userId: string;
  projectId: string;
  encryptedData?: any;
}

export interface TaskDetails {
  id: string;
  userId: string;
  projectId: string;
  encryptedData?: any;
}

export interface Image {
  id: string;
  userId: string;
  encryptedData?: any;
}

export interface Filters {
  userId: string;
  projectId: string;
  encryptedData?: any;
}

export interface Miscellaneous {
  userId: string;
  projectId: string;
  encryptedData?: any;
}

export class AppDB extends Dexie {
  users!: Table<User, string>;
  projects!: Table<Project, string>;
  tasks!: Table<Task, string>;
  taskDetails!: Table<TaskDetails, string>;
  images!: Table<Image, string>;
  filters!: Table<Filters, string>;
  miscellaneous!: Table<Miscellaneous, string>;

  constructor() {
    super('OfflineExecutionDB');

    this.version(6).stores({
      users: 'id, email',
      projects: '[id+userId], userId',
      tasks: '[id+userId], projectId, userId',
      taskDetails: '[id+userId], projectId, userId',
      image: '[id+userId], userId, taskId, projectId',
      filters: '[userId+projectId], userId, projectId',
      miscellaneous: '[userId+projectId], userId, projectId',
    });
  }
}

export const db = new AppDB();

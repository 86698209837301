import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { firstValueFrom, Observable } from 'rxjs';
import { db, User } from 'src/app/db';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IndexdbPasswordModalComponent } from 'src/app/components/indexdb-password-modal/indexdb-password-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private _password: string;
  public getMPinAPI: () => Observable<any>;

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) {}

  setPasswordInMemory(password: string) {
    this._password = password;
  }

  async checkPassword(userId: string, password: string): Promise<boolean> {
    try {
      const user: User = await db.users.where('id').equals(userId).first();
      await this.decrypt(user.passwordChecker, password);
      return true;

    } catch (error) {
      return false;
    }
  }


  async fetchPassword(): Promise<void> {
    if (this._password) { return; }

    this._password = null;

    if (this.router.url.indexOf('offline') === -1) {
      let passObj =await firstValueFrom(this.getMPinAPI());
      this._password = passObj.pin
      if (!this._password) {
        const modalRef = this.modalService.open(IndexdbPasswordModalComponent, { centered: true });
        modalRef.componentInstance.mode = 'set';
        const password = await modalRef.result;
        this._password = password;
        return;
      }

      return;
    }

    const modalRef = this.modalService.open(IndexdbPasswordModalComponent, { centered: true });
    const password = await modalRef.result;

    if (await this.checkPassword(sessionStorage.getItem('UserId'), password)) {
      return this._password = password;
    }

    return this.fetchPassword();
  }

  async encrypt(value: any, key?: string) {
    if (!key) {
      await this.fetchPassword();
      key = this._password;
    }

    const iv = CryptoJS.lib.WordArray.random(16);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
  }

  async decrypt(value: any, key?: string): Promise<any> {
    if (!key) {
      await this.fetchPassword();
      key = this._password;
    }

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
}

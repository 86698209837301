import { ConstService } from 'src/services/const.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import modalConstant from '../../../assets/modal-constants.json';
import { ApplicationService } from '../../../services/application.service';
import { AssignMemberComponent } from '../../containers/dashboard/modals/assign-member/assign-member.component';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import tzList from 'timezones-list';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import { FileSaverService } from 'ngx-filesaver';
import { Location } from '@angular/common';
import { GoToProjectService } from 'src/services/go-to-project.service';
import { RolesService } from 'src/services/roles.service';

interface Option {
  recurringJobTypesId: number;
  recurringJobTypesName: string;
}
@Component({
  selector: "app-add-edit-project",
  templateUrl: "./add-edit-project.component.html",
  styleUrls: ["./add-edit-project.component.scss"],
})
export class AddEditProjectComponent implements OnInit {
  remoteMonitoring:boolean;
  form: FormGroup;
  id: any = null;
  projectName: string;
  loading: boolean;
  masterData: any = {
    days: _.range(1, 32),
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: _.range(1989, 2051),
  };
  data: any = {
    systemManagers: [],
    projectManagers: [],
  };
  title;
  subTitle;
  status;
  public toast: any = {};
  getAllCountries: any = [];
  getAllStates: any = [];
  getAllCities: any = [];
  getAllProjectTypes: any = [];
  getAllCurrencies: any = [];
  getAllDecimalDelimiters: any = [];
  storeAllDecimalDelimiters: any = [];
  currencyId;
  getAllDiagramSymbols: any = [];
  getAllProjectTypeDto: any = [];
  getAllregions: any = [];
  getAllOperatingUnit: any = [];
  recurringJobTypesDto: any = [];
  projectHasJobsDtoList: any = [];
  submitted = false;
  showforNotManager = false;
  month;
  emonth;
  ProjectDefinitionPriviledge = false;
  Privilages: any = [];
  projectHasUserDtoListnew: any = [];
  projectHasUserDtoListExisting: any = [];
  userIdDto: any = [];
  showState = true;
  showCity = true;
  showAreaError = false;
  openingSWMSOption: any = [];
  timeZoneList: any = [];
  timeZoneSelected: string;
  timeZoneOffset: any;
  showButton: boolean = false;
  enableFields: boolean = true;
  enableEditableFields: boolean = true;
  isProjectRole: boolean = false;
  ProjectStatusList;
  viewMode = false;
  showicon = false;
  type;
  active: any = 1;
  disable = false;
  fromPage: string;
  enableDiscardButton = true;
  isDateValid : boolean;
  isMyProject : boolean;
  isPolicyManager: boolean = false;
  constructor(
    private dataService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private mainHeaderService: MainHeaderService,
    private modalService: NgbModal,
    private _FileSaverService: FileSaverService,
    config: NgbModalConfig,
    private route: ActivatedRoute,
    private location: Location,
    private goToProjectSvc: GoToProjectService,
    public rolesService: RolesService) {
    config.backdrop = 'static';
    this.Privilages = this.dataService.getUserPrivilages();
    this.ProjectDefinitionPriviledge = this.Privilages.GISSLDEditorEdit;
    this.isPolicyManager = this.rolesService.hasRole(this.rolesService.POLICY_MANAGER);
  }
  editProjectLocationDetails = [];
  editAreaName;
  editcountryName;
  editStateName;
  decimalDelimitter = ["."];
  projectDecimalDelimiter;
  getHostingDto;
  changeMember = false;
  isExternalLink = false;
  activeTabNumber: number
  isDiscardCont = false;
  showProjectJob = false;
  selectedOptions: { recurringJobTypesId: number; isEnabled: boolean }[] = [];
  recurringJobTypeDtoList:any = [];
  ngOnInit() {  
   sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
   this.activeTabNumber = Number(this.route.snapshot.queryParams['tab']);
   this.fromPage = (this.route.snapshot.queryParams['from']);
   if(this.activeTabNumber){
     this.active = this.activeTabNumber;
   } 
    this.timeZoneList = tzList;
    this.mainHeaderService.onRolesUpdated();
    this.setProjectData({});
    this.dataService.GetAllCountries().subscribe({
      next: (res) => {
        this.getAllOperatingUnit = res;
      },
    });
    const currentUrl = this.location.path();
    if (currentUrl.includes("viewProject")) {
      this.viewMode = true;
    }
    this.route.params.subscribe({
      next: (params) => {
        this.id = params["id"];
        this.loading = true;
        this.dataService.getCommonProject().subscribe({
          next: (res) => {
            this.loading = false;
            this.getAllCountries = res[0].countryDto;
            this.getAllStates = res[0].stateDto;
            this.getAllCities = res[0].cityDto;
            this.getAllProjectTypes = res[0].typeDto;
            this.getAllCurrencies = res[0].currencyDto;
            this.getAllDecimalDelimiters = res[0].decimalDelimitersDto;
            this.storeAllDecimalDelimiters = res[0].decimalDelimitersDto;
            this.getAllregions = res[0].areaDto;
            this.getAllDiagramSymbols = res[0].diagramSymbolDto;
            this.getAllProjectTypeDto = res[0].projectDeploymentTypeDto;
            this.recurringJobTypesDto = res[0].recurringJobTypesDto;
            this.ProjectStatusList = res[0].projectStatusDto;
            setTimeout(() => {
              this.selectCurrency(this.currencyId);
            }, 3000);
            if (this.id) {
              this.loading = true;
              this.editProjectLocationDetails = [];
              this.projectHasUserDtoListnew = [];
              this.dataService.getProjectById(this.id).subscribe({
                next: (res) => {
                  this.loading = true;
                  this.dataService.GetProjectDeploymentTypeHasHostingType(res.projectDeployment.projectDeploymentTypeId)
                    .subscribe({
                      next: (response) => {
                        this.getHostingDto = response;
                        this.loading = false;
                        this.setProjectData1.bind(res);
                        this.setProjectData1(res);
                        this.getAllProjectTypeDto.forEach((element) => {
                          if (element.projectDeploymentTypeId === res.projectDeployment.projectDeploymentTypeId) {
                            this.type = element.projectDeploymentTypeName;
                            if(this.type == 'Internal') {
                              this.showProjectJob = true;
                              this.remoteMonitoring = res.recurringJobTypeDtoList[0].isEnabled;
                              } else {
                              this.showProjectJob = false;
                            }
                          }
                        });
                        this.data.projectManagers = res.projectHasUserDtoList;
                        this.currencyId = res.projCurrencyId;
                        for (let i = 0; i < this.data.projectHasUserDtoList.length; i++) {
                          if (this.data.projectHasUserDtoList[i].isProjectMember === true) {
                            this.projectHasUserDtoListnew.push(this.data.projectHasUserDtoList[i]);
                          }
                          if (this.data.projectHasUserDtoList[i].isAbbManager === true) {
                            this.projectHasUserDtoListnew.push(this.data.projectHasUserDtoList[i]);
                          }
                          if (this.data.projectHasUserDtoList[i].isCustomerManager === true) {
                            this.projectHasUserDtoListExisting.push(this.data.projectHasUserDtoList[i]);
                          }
                          if (this.data.projectHasUserDtoList[i].isProjectSupport === true) {
                            this.projectHasUserDtoListExisting.push(this.data.projectHasUserDtoList[i]);
                          }
                        }
                        this.title = res.projName;
                        this.subTitle = "Project information";
                        this.loading = false;
                        this.enableFields = true;
                        this.enableEditableFields = true;
                        if (this.rolesService.hasAnyRoles([this.rolesService.PROJECT_MEMBER, this.rolesService.CONTROL_ROOM])) {
                          this.isProjectRole = true;
                        }
                        this.isMyProject = res.isMyProject;
                        this.ProjectStatusList.forEach((element) => {
                          if (element.projectStatusId === res.projectStatusId) {
                            this.status = element.projectStatusName;
                            if (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && 
                              (this.status === "Active" || this.status === "Draft")) {
                              this.showicon = true;
                            }
                          }
                        });
                      },
                      error: () => {
                        this.loading = false;
                      },
                    });
                },
                error: () => {
                  this.loading = false;
                },
              });
            } else {
              this.title = "New Project";
              this.subTitle = "Project information (edit)";
              this.status = "Draft";
              this.enableFields = false;
              this.enableEditableFields = false;
              this.showicon = false;
            }
          },
          error: () => {
            this.loading = false;
          },
        });
      },
      error: () => {
        this.loading = false;
      },
    });
    

  }
  backWithoutSave() {
    if (this.form.touched && !this.submitted) {
      const modelRef = this.modalService.open(AlertModalComponent, {
        centered: true,
      });
      modelRef.componentInstance.heading = "Confirmation";
      modelRef.componentInstance.message = "Save changes?";
      modelRef.componentInstance.masterButton = "Save";
      modelRef.result.then((ret) => {
        if (ret == "continue") {
          if (this.status === "Draft") {
            this.save();
          } else {
            this.saveChanges();
          }
          this.router.navigate(["/projectdashboard"]);
        } else if (ret == "cancel") {
          this.router.navigate(["/projectdashboard"]);
        }
      });
    } else {
      this.router.navigate(["/projectdashboard"]);
    }
  }
  onChangeProjectType(event) {
    let type;
    if (event) {
      this.getHostingDto = [];
      this.getAllProjectTypeDto.forEach((element) => {
        if (element.projectDeploymentTypeId === event) {
          type = element.projectDeploymentTypeName;
          this.type = element.projectDeploymentTypeName;
        }
      });
      this.loading = true;
      this.dataService.GetProjectDeploymentTypeHasHostingType(event).subscribe({
        next: (res) => {
          this.getHostingDto = res;
          if (type === "Internal") {
            this.remoteMonitoring = null;
            this.isExternalLink = false;
            this.showProjectJob = true;
            if (this.form.value.projectName) {
              this.form.patchValue({
                directlink:
                  window.location.origin +
                  "/" +
                  this.form
                    .get("projectName")
                    ?.value.replace(/\s/g, "")
                    .toLowerCase(),
                hosting: res[0].projectHostingTypeId,
              });
            } else {
              this.form.patchValue({
                hosting: res[0].projectHostingTypeId,
              });
            }
          } else {
            this.isExternalLink = true;
            this.showProjectJob = false;
            this.form.patchValue({
              hosting: "",
            });
            this.form.patchValue({
              directlink: "",
            });
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
    }
  }
  onChangeArea(event) {
    const areaId = this.getAllregions
      .filter(function (item) {
        return item.areaId === event;
      })
      .map(function (item) {
        return item.areaId;
      });
    const areaName = [];
    areaName.push(event);
    if (areaName[0] === event) {
      this.form.controls["country"].setValue("");
      this.form.controls["state"].setValue("");
      this.form.controls["city"].setValue("");
    }
    if (areaId[0] !== undefined) {
      this.loading = true;
      this.dataService.GetCountryByAreaId(event).subscribe({
        next: (res) => {
          this.loading = false;
          this.getAllCountries = res;
        },
        error: () => {
          this.loading = false;
        },
      });
    }
  }

  onChange(event) {
    const countryId = this.getAllCountries
      .filter(function (item) {
        return item.countryId === event;
      })
      .map(function (item) {
        return item.countryId;
      });
    const countryName = [];
    countryName.push(event);
    if (countryName[0] === event) {
      this.form.controls["state"].setValue("");
      this.form.controls["city"].setValue("");
    }
    if (countryId[0] !== undefined) {
      this.loading = true;
      this.dataService.GetStatesByCountryById(event).subscribe({
        next: (res) => {
          this.loading = false;
          this.getAllStates = res;
          this.showState = false;
        },
        error: () => {
          this.getAllStates = null;
          this.getAllCities = null;
          this.loading = false;
        },
      });
    }
  }
  onChangestate(event) {
    const stateId = this.getAllStates
      .filter(function (item) {
        return item.stateId === event;
      })
      .map(function (item) {
        return item.stateId;
      });
    const stateName = [];
    stateName.push(event);
    if (stateName[0] === event) {
      this.form.controls["city"].setValue("");
    }
    if (stateId[0] !== undefined) {
      this.loading = true;
      this.dataService.GetCitiesByStateId(event).subscribe({
        next: (res) => {
          this.loading = false;
          this.getAllCities = res;
          this.showCity = false;
        },
        error: () => {
          this.getAllCities = null;
          this.loading = false;
        },
      });
    }
  }

  onRemoteMonitoringSelection(option: Option, status: string) {
    const selectedOptionIndex = this.recurringJobTypesDto.findIndex(
      opt => opt.recurringJobTypesId === option.recurringJobTypesId
    );
  
    if (selectedOptionIndex !== -1) {
      this.recurringJobTypesDto[selectedOptionIndex].isEnabled = status === 'included';
    }
  
    this.recurringJobTypeDtoList = this.recurringJobTypesDto.map(opt => ({
      recurringJobTypeId: opt.recurringJobTypesId,
      isEnabled: opt.isEnabled
    }));
  }

  setProjectData1(data) {
    this.data = data;
    this.projectName = data.projName;
    /** Based on the Areas, (country , cities , states) are getting called*/
    this.dataService
      .GetCountryByAreaId(data.custAreaId)
      .toPromise()
      .then(
        (country) => {
          this.getAllCountries = country;
          if (country.length > 0) {
            this.dataService
              .GetStatesByCountryById(data.custCountryId)
              .toPromise()
              .then(
                (states) => {
                  this.getAllStates = states;
                  if (states.length > 0) {
                    this.dataService
                      .GetCitiesByStateId(data.custStateId)
                      .toPromise()
                      .then(
                        (cities) => {
                          this.getAllCities = cities;
                        },
                        (err) => {
                          this.loading = false;
                        }
                      );
                  }
                },
                (err) => {
                  this.loading = false;
                }
              );
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    if (data.startMonth === 1) {
      this.month = "Jan";
    }
    if (data.startMonth === 2) {
      this.month = "Feb";
    }
    if (data.startMonth === 3) {
      this.month = "Mar";
    }
    if (data.startMonth === 4) {
      this.month = "Apr";
    }
    if (data.startMonth === 5) {
      this.month = "May";
    }
    if (data.startMonth === 6) {
      this.month = "Jun";
    }
    if (data.startMonth === 7) {
      this.month = "Jul";
    }
    if (data.startMonth === 8) {
      this.month = "Aug";
    }
    if (data.startMonth === 9) {
      this.month = "Sep";
    }
    if (data.startMonth === 10) {
      this.month = "Oct";
    }
    if (data.startMonth === 11) {
      this.month = "Nov";
    }
    if (data.startMonth === 12) {
      this.month = "Dec";
    }
    if (data.endMonth === 1) {
      this.emonth = "Jan";
    }
    if (data.endMonth === 2) {
      this.emonth = "Feb";
    }
    if (data.endMonth === 3) {
      this.emonth = "Mar";
    }
    if (data.endMonth === 4) {
      this.emonth = "Apr";
    }
    if (data.endMonth === 5) {
      this.emonth = "May";
    }
    if (data.endMonth === 6) {
      this.emonth = "Jun";
    }
    if (data.endMonth === 7) {
      this.emonth = "Jul";
    }
    if (data.endMonth === 8) {
      this.emonth = "Aug";
    }
    if (data.endMonth === 9) {
      this.emonth = "Sep";
    }
    if (data.endMonth === 10) {
      this.emonth = "Oct";
    }
    if (data.endMonth === 11) {
      this.emonth = "Nov";
    }
    if (data.endMonth === 12) {
      this.emonth = "Dec";
    }
    this.form = this.formBuilder.group({
      projectName: [data.projName, Validators.required],
      projectType: [data.projTypeId || "", Validators.required],
      projectCurrency: [data.projCurrencyId || "", Validators.required],
      diagramSymbols: [data.projDiagSymbolId || "", Validators.required],
      decimalDelimiter: [data.decimalDelimiter || "", Validators.required],
      openingSWMS: [
        data.isOpeningSwms === "Y" || "" ? "Mandatory" : "Not mandatory",
        Validators.required,
      ],
      operatingUnit: [data.projCountryId || "", Validators.required],
      timeZone: [data.timeZone || "", Validators.required],
      country: [data.custCountryId || "", Validators.required],
      state: [data.custStateId || "", Validators.required],
      name: [data.custName, Validators.required],
      area: [data.custAreaId || "", Validators.required],
      city: [data.custCityId || "", Validators.required],
      startDate: [{value: data.projStartDate || "", disabled: this.enableFields}, [Validators.required]],
      endDate: [{value: data.projEndDate || "", disabled: this.enableEditableFields}, [Validators.required]],
      directlink: [data.directlink || "", [Validators.required]],
      projectStatus: [data.projectStatusId],
      projectDeploymentType: [
        data.projectDeployment.projectDeploymentTypeId || "",
        [Validators.required],
      ],
      hosting: [
        data.projectDeployment.projectHostingTypeId || "",
        [Validators.required],
      ],
      description: [data.description || "", Validators.required],
     // remoteMonitoring: ["", Validators.required]
    });
  }

  /**
   * Set project data
   * @param data - project data
   */
  setProjectData(data) {
    this.data = data;
    this.projectName = data.projName;
    this.openingSWMSOption = [
      { Id: 1, DisplayValue: "Mandatory", ContentValue: "Mandatory" },
      { Id: 2, DisplayValue: "Not mandatory", ContentValue: "Not mandatory" },
    ];
    this.form = this.formBuilder.group({
      projectName: [data.projectName, Validators.required],
      projectType: [data.projectType || "", Validators.required],
      projectCurrency: [data.projectCurrency || "", Validators.required],
      diagramSymbols: [data.diagramSymbols || "", Validators.required],
      decimalDelimiter: [data.decimalDelimiter || "", Validators.required],
      openingSWMS: [data.isOpeningSwms || "", Validators.required],
      operatingUnit: [data.projCountryId || "", Validators.required],
      timeZone: [data.timeZone || "", Validators.required],
      country: [data.country || "", Validators.required],
      state: [data.state || "", Validators.required],
      name: [data.name, Validators.required],
      area: [data.area || "", Validators.required],
      city: [data.city || "", Validators.required],
      startDate: [{value: data.projStartDate, disabled: this.enableFields}, [Validators.required]],
      endDate: [{value: data.projEndDate || "", disabled: this.enableEditableFields}, [Validators.required]], 
      directlink: [data.directlink || "", [Validators.required]],
      projectStatus: [data.projectStatusId],
      projectDeploymentType: [
        data.deploymentProjectTypeId || "",
        [Validators.required],
      ],
      hosting: [data.deploymentHostingTypeId || "", [Validators.required]],
      description: [data.description || "", Validators.required],
     // remoteMonitoring: ["", Validators.required]
    });
  }

  /**
   * Set master data
   * @param data - master data
   */
  setMasterData(data) {
    this.masterData = data;
  }
  isname() {
    if (this.form.value.projectName) {
      return false;
    } else {
      return true;
    }
  }
  resetChanges() {
    if (this.form.touched && !this.submitted) {
      const modelRef = this.modalService.open(AlertModalComponent, {
        centered: true,
      });
      modelRef.componentInstance.heading = "Confirmation";
      modelRef.componentInstance.message = "Save changes?";
      modelRef.componentInstance.masterButton = "Save";
      modelRef.result.then((ret) => {
        if (ret == "continue") {
          if (this.status === "Draft") {
            this.save();
          } else {
            this.saveChanges();
          }
          this.router.navigate(["/projectdashboard"]);
        } else if (ret == "cancel") {
          this.router.navigate(["/projectdashboard"]);
        }
      });
    } else {
      this.showButton = false;
      this.submitted = false;
      this.ngOnInit();
      this.subTitle = "Project information";
      if (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && this.status === "Draft") {
        this.enableFields = true;
        this.enableEditableFields = true;
      } else if (
        (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && this.status !== "Draft")) {
        this.enableEditableFields = true;
      }
    }
  }
  editChanges() {
    this.showButton = true;
    this.subTitle = "Project information (edit)";
    if (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && this.status === "Draft") {
      this.enableFields = false;
      this.enableEditableFields = false;
    } else if (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && this.status === "Active") {
      this.enableEditableFields = false;
    }
  }
  addDirectLink() {
    if (this.type && this.type !== "External") {
      this.form.patchValue({
        directlink:
          window.location.origin +
          "/" +
          encodeURI(this.form.get("projectName")?.value),
      });
    }
  }
  discard() {
    const modelRef = this.modalService.open(AlertModalComponent, {
      centered: true,
    });
    modelRef.componentInstance.heading = "Confirmation";
    modelRef.componentInstance.message =
      "All information will be lost. Please confirm";
    modelRef.componentInstance.masterButton = "Continue";
    modelRef.result.then((ret) => {
      if (ret == "continue") {
        this.setProjectData({});
        this.isDiscardCont = true;
        this.form.reset(this.data);
        if (this.id) {
          this.loading = true;
          this.dataService.deleteProject(this.id).subscribe({
            next: (res) => {
              this.loading = false;
            },
            error: () => {
              this.loading = false;
            },
          });
        } else {
         // this.router.navigate(["/projectdashboard"]);
        }
      }
      this.enableFields = true;
      if(!this.id) {
        this.enableFields = false;
      }
    });
    this.enableDiscardButton = false;
  }
  @HostListener('document:keydown.control.z',['$event'])
   handleKeyDown(event: Event) {
      event.preventDefault();
      this.enableDiscardButton = true;
  }
  create() {
    this.submitted = true;
    if(!this.isDateValid) {
      return;
    }
    this.timeZoneSelected = this.form.value.timeZone;
    if (this.timeZoneSelected != null || this.timeZoneSelected != "") {
      this.timeZoneOffset = tzList.find(
        (x) => x.tzCode == this.timeZoneSelected
      ).utc;
    }
    if (this.projectHasUserDtoListnew.length === 0) {
      this.showforNotManager = true;
    }
    let status = "";
    this.ProjectStatusList.forEach((element) => {
      if (this.type === "External") {
        if (element.projectStatusName === "Active") {
          status = element.projectStatusId;
        }
      } else {
        if (element.projectStatusName === "Provisioning") {
          status = element.projectStatusId;
        }
      }
    });
    if (!this.id && !this.form.invalid) {
      const dateString = new Date(this.form.get('startDate').value);
      const newStartDate = dateString.toDateString();
      const dateString2 = new Date(this.form.get('endDate').value);
      const newEndDate = dateString2.toDateString();
      // stop here if form is invalid
      if (this.projectHasUserDtoListnew.length === 0) {
        this.showforNotManager = true;
      }
      if (this.form.invalid) {
        this.showAreaError = true;
        return;
      }
      if (new Date(dateString).getTime() > new Date(dateString2).getTime()) {
        const modalRef = this.modalService.open(SuccesspopupComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = modalConstant.ProjectDate.message;
        return;
      }
      if (this.projectHasUserDtoListnew.length === 0) {
        this.showforNotManager = true;
      } else {
        const formData = {
          custName: this.form.value.name,
          custAreaId: this.form.value.area,
          custCountryId: this.form.value.country,
          custStateId: this.form.value.state,
          custCityId: this.form.value.city,
          projectDtoList: [
            {
              projName: this.form.value.projectName,
              projTypeId: this.form.value.projectType,
              projCurrencyId: this.form.value.projectCurrency,
              projectCountryId: this.form.value.operatingUnit,
              projDiagSymbolId: this.form.value.diagramSymbols,
              projStartDate: newStartDate,
              projEndDate: newEndDate,
              projectHasUserDtoList: this.projectHasUserDtoListnew,
              decimalDelimiter:
                this.form.value.decimalDelimiter ||
                this.projectDecimalDelimiter,
              isOpeningSWMS:
                this.form.value.openingSWMS === "Mandatory" ? "Y" : "N",
              timeZone: this.form.value.timeZone,
              timeZoneOffset: this.timeZoneOffset,
              directlink: this.form.value.directlink,
              discription: this.form.value.description,
              projectStatusId: status,
              projectDeployment: {
                projectDeploymentTypeId: this.form.value.projectDeploymentType,
                projectHostingTypeId: this.form.value.hosting,
              },
              recurringJobTypeDtoList: this.recurringJobTypeDtoList
            },
          ],
        };
        sessionStorage.setItem(
          "diagramSymbols",
          this.form.value.diagramSymbols
        );
        this.loading = true;
        this.dataService.createProject(formData).subscribe({
          next: (res) => {
            const modalRef = this.modalService.open(SuccesspopupComponent, {
              centered: true,
            });
            modalRef.componentInstance.title = "Message";
            modalRef.componentInstance.message =
              res.returnObj.customerName + " " + modalConstant.Project.success;
            if (this.type === "External") {
              this.deploy(res.returnObj.projectId);
            } else {
              this.loading = false;
              this.router.navigate(["/projectdashboard"]);
            }
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
    if (this.id && !this.form.invalid) {
      const userId = [];
      if (this.projectHasUserDtoListnew.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListnew.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListnew[i]);
        }
      }
      if (this.projectHasUserDtoListExisting.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListExisting.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListExisting[i]);
        }
      }
      this.userIdDto = _.uniqBy(this.userIdDto, "userId");

      if (this.userIdDto.length > 0) {
        for (let i = 0; i < this.userIdDto.length; i++) {
          userId.push(this.userIdDto[i].userId);
        }
      }
      const dateString = new Date(this.form.get('startDate').value);
      const newStartDate = dateString.toDateString();
      const dateString2 = new Date(this.form.get('endDate').value);
      const newEndDate = dateString2.toDateString();
      if (this.form.invalid) {
        return;
      }
      if (new Date(dateString).getTime() > new Date(dateString2).getTime()) {
        const modalRef = this.modalService.open(SuccesspopupComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = modalConstant.ProjectDate.message;
      } else {
        const formData = {
          projId: this.id,
          projName: this.form.value.projectName,
          projTypeId: this.form.value.projectType,
          projCurrencyId: this.form.value.projectCurrency,
          projectCountryId: this.form.value.operatingUnit,
          decimalDelimiter: this.form.value.decimalDelimiter,
          isOpeningSWMS:
            this.form.value.openingSWMS === "Mandatory" ? "Y" : "N",
          projDiagSymbolId: this.form.value.diagramSymbols,
          projStartDate: newStartDate,
          projEndDate: newEndDate,
          timeZone: this.form.value.timeZone,
          timeZoneOffset: this.timeZoneOffset,
          custId: this.data.custId,
          custName: this.form.value.name,
          custAreaId: this.form.value.area,
          custCountryId: this.form.value.country,
          custStateId: this.form.value.state,
          custCityId: this.form.value.city,
          users: [this.projectHasUserDtoListnew[0].userId],
          description: this.form.value.description,
          directlink: this.form.value.directlink,
          projectStatusId: status,
          projectDeployment: {
            projectDeploymentTypeId: this.form.value.projectDeploymentType,
            projectHostingTypeId: this.form.value.hosting,
          },
          recurringJobTypeDtoList: this.recurringJobTypeDtoList
        };
        this.loading = true;
        this.dataService.editProject(formData).subscribe({
          next: (res) => {
            const modalRef = this.modalService.open(SuccesspopupComponent, {
              centered: true,
            });
            modalRef.componentInstance.title = "Message";
            modalRef.componentInstance.message =
              res.returnObj + " " + modalConstant.Project.success;
            if (this.type === "External") {
              this.deploy(this.id);
            } else {
              this.loading = false;
              this.router.navigate(["/projectdashboard"]);
            }
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
  }
  saveChanges() {
    this.submitted = true;
    this.timeZoneSelected = this.form.value.timeZone;
    if (this.timeZoneSelected != null || this.timeZoneSelected != "") {
      this.timeZoneOffset = tzList.find(
        (x) => x.tzCode == this.timeZoneSelected
      ).utc;
    }
    if (this.projectHasUserDtoListnew.length === 0) {
      this.showforNotManager = true;
    }
    let status = "";
    this.ProjectStatusList.forEach((element) => {
      if (element.projectStatusName === this.status) {
        status = element.projectStatusId;
      }
    });
    if (this.id && !this.form.invalid) {
      const userId = [];
      if (this.projectHasUserDtoListnew.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListnew.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListnew[i]);
        }
      }
      if (this.projectHasUserDtoListExisting.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListExisting.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListExisting[i]);
        }
      }
      this.userIdDto = _.uniqBy(this.userIdDto, "userId");

      if (this.userIdDto.length > 0) {
        for (let i = 0; i < this.userIdDto.length; i++) {
          userId.push(this.userIdDto[i].userId);
        }
      }
      const dateString = new Date(this.form.get('startDate').value);
      const newStartDate = dateString.toDateString();
      const dateString2 = new Date(this.form.get('endDate').value);
      const newEndDate = dateString2.toDateString();
      if (this.form.invalid) {
        return;
      }
      if (new Date(dateString).getTime() > new Date(dateString2).getTime()) {
        const modalRef = this.modalService.open(SuccesspopupComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = modalConstant.ProjectDate.message;
      } else {
        const formData = {
          projId: this.id,
          projName: this.form.value.projectName,
          projTypeId: this.form.value.projectType,
          projCurrencyId: this.form.value.projectCurrency,
          projectCountryId: this.form.value.operatingUnit,
          decimalDelimiter: this.form.value.decimalDelimiter,
          isOpeningSWMS:
            this.form.value.openingSWMS === "Mandatory" ? "Y" : "N",
          projDiagSymbolId: this.form.value.diagramSymbols,
          projStartDate: newStartDate,
          projEndDate: newEndDate,
          timeZone: this.form.value.timeZone,
          timeZoneOffset: this.timeZoneOffset,
          custId: this.data.custId,
          custName: this.form.value.name,
          custAreaId: this.form.value.area,
          custCountryId: this.form.value.country,
          custStateId: this.form.value.state,
          custCityId: this.form.value.city,
          users: [this.projectHasUserDtoListnew[0].userId],
          description: this.form.value.description,
          directlink: this.form.value.directlink,
          projectStatusId: status,
          projectDeployment: {
            projectDeploymentTypeId: this.form.value.projectDeploymentType,
            projectHostingTypeId: this.form.value.hosting,
          },
          recurringJobTypeDtoList: this.recurringJobTypeDtoList
        };
        this.loading = true;
        this.dataService.editProject(formData).subscribe({
          next: (res) => {
            this.loading = false;
            this.resetChanges();
            this.router.navigate(["/projectdashboard"]);
            const modalRef = this.modalService.open(SuccesspopupComponent, {
              centered: true,
            });
            modalRef.componentInstance.title = "Message";
            modalRef.componentInstance.message = modalConstant.Project.updated;
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
  }

  goToProject() {
    sessionStorage.setItem('projectId', this.id);
    sessionStorage.setItem('projectName', this.projectName?.replace(/\s/g, "").toLowerCase());
    this.goToProjectSvc.goToProject('');
  }
  
  closeProject() {
    const modelRef = this.modalService.open(AlertModalComponent, {
      centered: true,
    });
    modelRef.componentInstance.heading = "Close project";
    modelRef.componentInstance.message =
      "The project will be closed and become read-only. Please confirm.";
    modelRef.componentInstance.masterButton = "Continue";
    modelRef.result.then((ret) => {
      if (ret == "continue") {
        this.loading = true;
        this.dataService.CloseProjectById(this.id).subscribe({
          next: (res) => {
            this.loading = false;
            this.router.navigate(["/projectdashboard"]);
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    });
  }

  reopenProject() {
    const modelRef = this.modalService.open(AlertModalComponent, {
      centered: true,
    });
    modelRef.componentInstance.heading = "Re-open project";
    modelRef.componentInstance.message =
      "The project will be re-open. Please confirm.";
    modelRef.componentInstance.masterButton = "Continue";
    modelRef.result.then((ret) => {
      if (ret == "continue") {
        this.loading = true;
        this.dataService.OpenProjectById(this.id).subscribe({
          next: (res) => {
            this.loading = false;
            this.router.navigate(["/projectdashboard"]);
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    });
  }
  deploy(id) {
    let fileName = "projectSettings";
    this.loading = true;
    this.dataService.GetDownloadProjectSettings(id).subscribe({
      next: (res) => {
        let data = b64toBlob(res.returnObj);
        this._FileSaverService.save(data, fileName + ".zip");
        this.loading = false;
        this.router.navigate(["/projectdashboard"]);
      },
      error: () => {
        this.loading = false;
      },
    });
  }
  save() {
    this.submitted = true;
    if(!this.isDateValid) {
      return;
    }
    this.timeZoneSelected = this.form.value.timeZone;
    if (this.timeZoneSelected != null || this.timeZoneSelected != "") {
      this.timeZoneOffset = tzList.find(
        (x) => x.tzCode == this.timeZoneSelected
      ).utc;
    }
    if (this.projectHasUserDtoListnew.length === 0) {
      this.showforNotManager = true;
    }
    let status = "";
    this.ProjectStatusList.forEach((element) => {
      if (element.projectStatusName === "Draft") {
        status = element.projectStatusId;
      }
    });
    if (!this.id && !this.form.invalid) {
      const dateString = new Date(this.form.get('startDate').value);
      const newStartDate = dateString.toDateString();
      const dateString2 = new Date(this.form.get('endDate').value);
      const newEndDate = dateString2.toDateString();
      // stop here if form is invalid
      if (this.projectHasUserDtoListnew.length === 0) {
        this.showforNotManager = true;
      }
      if (this.form.invalid) {
        this.showAreaError = true;
        return;
      }
      if (new Date(dateString).getTime() > new Date(dateString2).getTime()) {
        const modalRef = this.modalService.open(SuccesspopupComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = modalConstant.ProjectDate.message;
        return;
      }
      if (this.projectHasUserDtoListnew.length === 0) {
        this.showforNotManager = true;
      } else {
        const formData = {
          custName: this.form.value.name,
          custAreaId: this.form.value.area,
          custCountryId: this.form.value.country,
          custStateId: this.form.value.state,
          custCityId: this.form.value.city,
          projectDtoList: [
            {
              projName: this.form.value.projectName,
              projTypeId: this.form.value.projectType,
              projCurrencyId: this.form.value.projectCurrency,
              projectCountryId: this.form.value.operatingUnit,
              projDiagSymbolId: this.form.value.diagramSymbols,
              projStartDate: newStartDate,
              projEndDate: newEndDate,
              projectHasUserDtoList: this.projectHasUserDtoListnew,
              decimalDelimiter:
                this.form.value.decimalDelimiter ||
                this.projectDecimalDelimiter,
              isOpeningSWMS:
                this.form.value.openingSWMS === "Mandatory" ? "Y" : "N",
              timeZone: this.form.value.timeZone,
              timeZoneOffset: this.timeZoneOffset,
              directlink: this.form.value.directlink,
              discription: this.form.value.description,
              projectStatusId: status,
              projectDeployment: {
                projectDeploymentTypeId: this.form.value.projectDeploymentType,
                projectHostingTypeId: this.form.value.hosting,
              },
              recurringJobTypeDtoList: this.recurringJobTypeDtoList
            },
          ],
        };
        sessionStorage.setItem(
          "diagramSymbols",
          this.form.value.diagramSymbols
        );
        this.loading = true;
        this.dataService.createProject(formData).subscribe({
          next: (res) => {
            this.loading = false;
            if (res.returnObj.projectId) {
              this.id = res.returnObj.projectId;
              this.submitted = false;
              this.showicon = false;
              this.dataService
                .getProjectById(res.returnObj.projectId)
                .subscribe({
                  next: (res) => {
                    this.projectHasUserDtoListnew = [];
                    this.loading = true;
                    this.getAllCities = [];
                    this.getAllCountries = [];
                    this.getAllStates = [];
                    this.setProjectData1.bind(res);
                    this.setProjectData1(res);
                    this.data.projectManagers = res.projectHasUserDtoList;
                    this.currencyId = res.projCurrencyId;
                    for (
                      let i = 0;
                      i < this.data.projectHasUserDtoList.length;
                      i++
                    ) {
                      if (
                        this.data.projectHasUserDtoList[i].isProjectMember ===
                        true
                      ) {
                        this.projectHasUserDtoListnew.push(
                          this.data.projectHasUserDtoList[i]
                        );
                      }
                      if (
                        this.data.projectHasUserDtoList[i].isAbbManager === true
                      ) {
                        this.projectHasUserDtoListnew.push(
                          this.data.projectHasUserDtoList[i]
                        );
                      }
                      if (
                        this.data.projectHasUserDtoList[i].isCustomerManager ===
                        true
                      ) {
                        this.projectHasUserDtoListExisting.push(
                          this.data.projectHasUserDtoList[i]
                        );
                      }
                      if (
                        this.data.projectHasUserDtoList[i].isProjectSupport ===
                        true
                      ) {
                        this.projectHasUserDtoListExisting.push(
                          this.data.projectHasUserDtoList[i]
                        );
                      }
                    }
                    this.loading = false;

                    this.ProjectStatusList.forEach((element) => {
                      if (element.projectStatusId === res.projectStatusId) {
                        this.status = element.projectStatusName;
                        if (this.rolesService.hasAnyRoles([this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT]) && this.status === "Active") {
                          this.showicon = true;
                        }
                      }
                    });
                  },
                  error: () => {
                    this.loading = false;
                  },
                });
            }
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
    if (this.id && !this.form.invalid) {
      const userId = [];
      if (this.projectHasUserDtoListnew.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListnew.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListnew[i]);
        }
      }
      if (this.projectHasUserDtoListExisting.length > 0) {
        for (let i = 0; i < this.projectHasUserDtoListExisting.length; i++) {
          this.userIdDto.push(this.projectHasUserDtoListExisting[i]);
        }
      }
      this.userIdDto = _.uniqBy(this.userIdDto, "userId");

      if (this.userIdDto.length > 0) {
        for (let i = 0; i < this.userIdDto.length; i++) {
          userId.push(this.userIdDto[i].userId);
        }
      }
      const dateString = new Date(this.form.get('startDate').value);
      const newStartDate = dateString.toDateString();
      const dateString2 = new Date(this.form.get('endDate').value);
      const newEndDate = dateString2.toDateString();
      if (this.form.invalid) {
        return;
      }
      if (new Date(dateString).getTime() > new Date(dateString2).getTime()) {
        const modalRef = this.modalService.open(SuccesspopupComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = modalConstant.ProjectDate.message;
      } else {
        const formData = {
          projId: this.id,
          projName: this.form.value.projectName,
          projTypeId: this.form.value.projectType,
          projCurrencyId: this.form.value.projectCurrency,
          projectCountryId: this.form.value.operatingUnit,
          decimalDelimiter: this.form.value.decimalDelimiter,
          isOpeningSWMS:
            this.form.value.openingSWMS === "Mandatory" ? "Y" : "N",
          projDiagSymbolId: this.form.value.diagramSymbols,
          projStartDate: newStartDate,
          projEndDate: newEndDate,
          timeZone: this.form.value.timeZone,
          timeZoneOffset: this.timeZoneOffset,
          custId: this.data.custId,
          custName: this.form.value.name,
          custAreaId: this.form.value.area,
          custCountryId: this.form.value.country,
          custStateId: this.form.value.state,
          custCityId: this.form.value.city,
          users: [this.projectHasUserDtoListnew[0].userId],
          description: this.form.value.description,
          directlink: this.form.value.directlink,
          projectStatusId: status,
          projectDeployment: {
            projectDeploymentTypeId: this.form.value.projectDeploymentType,
            projectHostingTypeId: this.form.value.hosting,
          },
          recurringJobTypeDtoList: this.recurringJobTypeDtoList
        };
        this.loading = true;
        this.dataService.editProject(formData).subscribe({
          next: (res) => {
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
      }
    }
  }
  /**
   * Delete project
   */
  deleteProject() {
    const modelRef = this.modalService.open(AlertModalComponent, {
      centered: true,
    });
    modelRef.componentInstance.heading = "Delete project";
    modelRef.componentInstance.message =
      "The project will be deleted, and all data lost. Please confirm.";
    modelRef.componentInstance.masterButton = "Continue";
    modelRef.result.then(
      (ret) => {
        if (ret == "continue") {
          this.loading = true;
          this.dataService.deleteProject(this.id).subscribe({
            next: (res) => {
              this.loading = false;
              const modalRef1 = this.modalService.open(SuccesspopupComponent, {
                centered: true,
              });
              modalRef1.componentInstance.title = "Message";
              modalRef1.componentInstance.message =
                modalConstant.Project.deleted;
              this.router.navigate(["/projectdashboard"]);
            },
            error: () => {
              this.loading = false;
            },
          });
        }
      },
      () => {}
    );
  }

  /**
   * Validate project
   * @param name - field name
   * @returns - valid or not
   */
  validation(name): any {
    return (
      this.submitted &&
      this.form.controls[name] &&
      this.form.controls[name].errors &&
      this.form.controls[name].errors.required
    );
  }

  validateDate(name) {
    if(this.submitted) {
      if(this.form.controls[name].value === undefined ||
        this.form.controls[name].value === '') {
        this.isDateValid = false;
        return true;
      } else {
        this.isDateValid = true;
        return false;
      }
    }
  }

  /**
   * get form controllers
   */
  get f(): any {
    return this.form.controls;
  }

  /**
   * Get countries
   * @returns - countries
   */
  getCountries() {
    const list = [];
    const countries = this.masterData.countries || [];
    countries.forEach(function (country) {
      list.push(country.name);
    });
    return list;
  }

  /**
   * Get states
   * @returns - states
   */
  getStates() {
    let list = [];
    const form = this.f;
    const countries = this.masterData.countries || [];
    countries.forEach(function (country) {
      if (country.name === form.country.value) {
        list = Object.keys(country.states);
      }
    });
    return list;
  }

  /**
   * Get Cities
   * @returns - Cities
   */
  getCities() {
    let list = [];
    const form = this.f;
    const countries = this.masterData.countries || [];
    countries.forEach(function (country) {
      if (country.name === form.country.value) {
        list = country.states[form.state.value];
      }
    });
    return list;
  }

  assignSystemManger() {
    this.modalService
      .open(AssignMemberComponent, {
        centered: true,
        size: "lg",
        windowClass: "large-screen-modal-window",
      })
      .result.then(
        (res) => {
          if (res instanceof Array) {
            this.data.systemManagers = res;
          }
        },
        () => {}
      );
  }

  assignProjectManger() {
    this.changeMember = true;
    const modalRef = this.modalService.open(AssignMemberComponent, {
      centered: true,
      size: "lg",
      windowClass: "large-screen-modal-window",
    });
    modalRef.componentInstance.UserRole = "ABBManager";
    if (this.id) {
      modalRef.componentInstance.teamId = this.id;
    }
    modalRef.result.then(
      (res) => {
        if (res.length >= 2) {
          const modalRef1 = this.modalService.open(SuccesspopupComponent, {
            centered: true,
          });
          modalRef1.componentInstance.title = "Warning";
          modalRef1.componentInstance.message =
            modalConstant.SelectItem.selectOneMember;
        } else {
          this.projectHasUserDtoListnew = [];
          if (res instanceof Array) {
            this.projectHasUserDtoListnew = res;
            if (this.projectHasUserDtoListnew) {
              this.showforNotManager = false;
            }
          }
        }
      },
      () => {}
    );
  }

  selectCurrency(currencyId) {
    this.getAllDecimalDelimiters = this.storeAllDecimalDelimiters.filter(
      (x) => x.currencyId === currencyId
    );
  }

  goToPreviousPage() {
    const back = this.fromPage === 'Operations center' ? '' : 'projectdashboard';
    this.router.navigateByUrl('/'+ back);    
  }
}

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

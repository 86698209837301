@if (loading) {
<div class="loading-box">
  <div class="img" alt="loading"></div>
</div>
}
<div class="risk-container" [class.chart-only]="chartOnly"
  [class.expand-container]="isExpand" #riskMatrixContainer>
  <div class="risk-matrix-wrap flexible" [class.expand-wrap]="isExpand"    
    [class.chart-false-only]="chartOnly===false" #riskMatrix>
    <span class="risk-matrix-span-wrap">
      <img  src="/assets/i/Risk Matrix.svg" height="24" width="24"
        alt="Risk-matrix" /> Risk matrix
      @if (!isExpand && !expandable) {
      <img class="icon-fullscreen" height="24" width="24"
        src="/assets/i/Fullscreen.S [D].svg" (click)="clickOnExpand()"
        alt="Expand" />
      }
      @if (isExpand && !expandable) {
      <img class="icon-collapse" height="24" width="24"
        src="/assets/sld-assets/icons/Contract.svg"
        (click)="clickOnCollapse()" alt="Contract" />
      }
    </span>
    <div class="chart-container chart-container-wrap">
      <svg viewBox="-8 4 132 131" version="1.1">
        <g transform="translate(15,11)" viewBox="0 0 140 100" width="100"
          height="80"
          class="svg-graph drag-area flexible">
          <g class="bg" [class.absolute]='isAbsoluteActive'>
            <rect width="100" height="102" />
          </g>
          @if (!isAbsoluteActive) {
          <g class="data">
            @for (row of riskMatrixValue.flexible; track row; let rowIndex =
            $index) {
            <ng-container>
              @for (column of row; track column; let colIndex = $index) {
              <ng-container>
                @for (column of row; track column; let colIndex = $index) {
                  <ng-container>
                    <polygon [class.low-risk]="column.split(',')[0] === 'low'"
                      [class.medium-risk]="column.split(',')[0] === 'medium'"
                      [class.high-risk]="column.split(',')[0] === 'high'"
                [class.no-cursor]="!hasPermissionToEdit()" [attr.points]="(colIndex * 10) +',' +(rowIndex * 10) +' ' +  ((colIndex + 1) * 10)
                       + ',' + ((rowIndex + 1) * 10) + ' '+(colIndex * 10)+','+ ((rowIndex + 1) * 10)
                       +' ' + (colIndex * 10) +',' +(rowIndex * 10)"/>
                    <polygon [class.low-risk]="column.split(',')[1] === 'low'"
                      [class.medium-risk]="column.split(',')[1] === 'medium'"
                      [class.high-risk]="column.split(',')[1] === 'high'"
                [class.no-cursor]="!hasPermissionToEdit()" [attr.points]="(colIndex * 10) +',' +(rowIndex * 10) +' ' + ((colIndex + 1) * 10)
                       + ',' + ((rowIndex + 1) * 10) + ' '+ ((colIndex + 1) * 10) +','+(rowIndex * 10)
                       +' ' + (colIndex * 10) +',' +(rowIndex * 10)" />
              </ng-container>
              }
            </ng-container>
            }
            </ng-container>
          }
          </g>
          }
          @if (!isAbsoluteActive) {
          <g class="coord">
            <line x1="10" y1="0" x2="10" y2="100" />
            <line x1="20" y1="0" x2="20" y2="100" />
            <line x1="30" y1="0" x2="30" y2="100" />
            <line x1="40" y1="0" x2="40" y2="100" />
            <line x1="50" y1="0" x2="50" y2="100" />
            <line x1="60" y1="0" x2="60" y2="100" />
            <line x1="70" y1="0" x2="70" y2="100" />
            <line x1="80" y1="0" x2="80" y2="100" />
            <line x1="90" y1="0" x2="90" y2="100" />
            <line y1="10" x1="0" y2="10" x2="100" />
            <line y1="20" x1="0" y2="20" x2="100" />
            <line y1="30" x1="0" y2="30" x2="100" />
            <line y1="40" x1="0" y2="40" x2="100" />
            <line y1="50" x1="0" y2="50" x2="100" />
            <line y1="60" x1="0" y2="60" x2="100" />
            <line y1="70" x1="0" y2="70" x2="100" />
            <line y1="80" x1="0" y2="80" x2="100" />
            <line y1="90" x1="0" y2="90" x2="100" />
            <line x1="10" y1="0" x2="100" y2="90" />
            <line x1="20" y1="0" x2="100" y2="80" />
            <line x1="30" y1="0" x2="100" y2="70" />
            <line x1="40" y1="0" x2="100" y2="60" />
            <line x1="50" y1="0" x2="100" y2="50" />
            <line x1="60" y1="0" x2="100" y2="40" />
            <line x1="70" y1="0" x2="100" y2="30" />
            <line x1="80" y1="0" x2="100" y2="20" />
            <line x1="90" y1="0" x2="100" y2="10" />
            <line x1="0" y1="0" x2="100" y2="100" />
            <line y1="10" x1="0" y2="100" x2="90" />
            <line y1="20" x1="0" y2="100" x2="80" />
            <line y1="30" x1="0" y2="100" x2="70" />
            <line y1="40" x1="0" y2="100" x2="60" />
            <line y1="50" x1="0" y2="100" x2="50" />
            <line y1="60" x1="0" y2="100" x2="40" />
            <line y1="70" x1="0" y2="100" x2="30" />
            <line y1="80" x1="0" y2="100" x2="20" />
            <line y1="90" x1="0" y2="100" x2="10" />
          </g>
          }

          @if (isAbsoluteActive) {
          <g class="absolute" transform="translate(0,40)">
            <circle class="medium-risk" cx="0" cy="60"
              [attr.r]="riskMatrixValue.absolute.highRisk" />
            <circle class="low-risk" cx="0" cy="60"
              [attr.r]="riskMatrixValue.absolute.lowRisk" />
          </g>
          }

          <g class="data">
            @if (resizeOnHover && isHomePage) {
            <ng-container>
              @for (val of riskValues; track val) {
              <ng-container>
                @if (viewTab === 'Substation') {
                <circle
                  (mouseover)="setTooltipData(val); hoverOnSubstation(val.substationId)"
                  (mouseleave)="hoverOnSubstation(null)"
                  [ngbTooltip]="substationTip" container="body"
                  tooltipClass="planner-tooltip"
                  [attr.cx]="val.risk1" [attr.cy]="100 - val.risk"
                  [attr.r]="isActiveSubstation(val.substationId) ? 3.5 : 2"
                  [attr.stroke]="isActiveSubstation(val.substationId) ? 'white' : null"
                  stroke-width="1.5"
                  fill="black" />
                }
                @if (viewTab !== 'Substation') {
                <polygon [contextMenu]="contextMenu ? infoContextMenu : null"
                  class="point" container="body"
                  (mouseover)="resizeOnHover ? val.scale = 3 : null;" 
                  (mouseleave)="val.scale = 1" [attr.fill]="'black'"
                  [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2) +' '+ (val.risk - pointSize/2 ) +',' +(100-val.risk1 + pointSize/2) +' '+(val.risk + pointSize/2) +',' +(100-val.risk1 + pointSize/2)" />
                }
              </ng-container>
              }
            </ng-container>
            }
            @if (resizeOnHover && !isHomePage) {
            <ng-container>
              @for (val of riskValues; track val; let colIndex = $index) {
              <ng-container>
                <polygon [contextMenu]="contextMenu ? infoContextMenu : null"
                  class="point" #myTip="ngbTooltip"
                  (click)="myTip.close()"
                  [autoClose]="true" [ngbTooltip]="riskTip"
                  container="body" tooltipClass="risk-tooltip"
                  placement="bottom"
                  (mouseover)="setTooltipData(val); resizeOnHover ? val.scale = 3 : null"
                  (mouseleave)="resizeOnHover ? val.scale = 1 : null"
                  [attr.fill]="'black'"
                  [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * val.scale) +' '+ (val.risk - pointSize/2 * val.scale ) +',' +(100-val.risk1 + pointSize/2 * val.scale) +' '+ (val.risk + pointSize/2 * val.scale) +',' +(100-val.risk1 + pointSize/2 * val.scale)" />
                @if (viewTab !== 'Substations' && viewTab !== 'Substation-L') {
                <polygon class="point" [autoClose]="true"
                  #myTip="ngbTooltip" (click)="myTip.close()"
                  [ngbTooltip]="riskTip" container="body"
                  tooltipClass="risk-tooltip" placement="bottom"
                  (mouseover)="setTooltipData(val); tooltip(); hoverOnSubstation(val.substation); hoverOnAsset(val)"
                  (mouseleave)="hoverOnSubstation(null); hoverOnAsset(null)"
                  [attr.fill]="'black'"
                  [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +' '+ (val.risk - pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +' '+ (val.risk + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                                      +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id === hoverOverAsset.id) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))" />
                }
              </ng-container>
              }
            </ng-container>
            }
            @if (!resizeOnHover && !isHomePage) {
            <ng-container>
              @for (val of riskValues; track val) {
              <ng-container>
                @if (viewTab === 'Substations' || viewTab==="Substation-L") {
                <circle [ngbTooltip]="substationTip" container="body"
                  tooltipClass="planner-tooltip"
                  (mouseover)="setTooltipData(val); hoverOnSubstation(val.substation)"
                  (mouseleave)=" hoverOnSubstation(null)"
                  [attr.cx]="val.risk1"
                  [attr.cy]="100 - val.risk"
                  [attr.r]="isActiveSubstation(val.substation) ? 3.5 : 2"
                  fill="black"
                  [attr.stroke]="isActiveSubstation(val.substation) ? 'white' : null"
                  stroke-width="1.5" />
                }
                @if (viewTab !== 'Substations' && viewTab!=="Substation-L") {
                <polygon class="point" [autoClose]="true"
                  [ngbTooltip]="riskTip" #myTip="ngbTooltip"
                  (click)="myTip.close()" container="body"
                  tooltipClass="planner-tooltip" placement="bottom"
                  (mouseover)="setTooltipData(val); tooltip(); hoverOnSubstation(val.substation); hoverOnAsset(val)"
                  (mouseleave)="hoverOnSubstation(null); hoverOnAsset(null)"            
                  [attr.fill]="'black'"
                  [attr.points]="val.risk +',' +(100-val.risk1 - pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false) ) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +' '+ (val.risk - pointSize/2 * ((hoverOverAsset && val.id === (hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +' '+ (val.risk + pointSize/2 * ((hoverOverAsset && val.id === (hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))
                +',' +(100-val.risk1 + pointSize/2 * ((hoverOverAsset && val.id ===(hoverOverAsset.includes(val.id)?val.id:false)) || (hoverOverAsset === null && hoverOverMarker && hoverOverMarker.getId() === val.substation) || (selectedRegion && selectedRegion.get('region') === val.region) ? 3: 1))" />
                }
              </ng-container>
              }
            </ng-container>
            }
          </g>
        </g>
        <g class="mask">
          <rect x="0" y="-1000" width="126" height="1009"></rect>
          <rect x="0" y="112" width="126" height="1015"></rect>
          <rect y="0" x="-1000" height="126" width="1013"></rect>
          <rect y="0" x="117" height="126" width="1011"></rect>
        </g>
        @if (!chartOnly) {
        <g class="axis y-axis" [class.expand-scale]="isExpand"
          transform="translate(20,12)">
          <g class="tick" transform="translate(0,10)">
            <text dy="1.5" x="-8" y="0">90</text>
          </g>
          <g class="tick" transform="translate(0,30)">
            <text dy="1.5" x="-8" y="0">70</text>
          </g>
          <g class="tick" transform="translate(0,50)">
            <text dy="1.5" x="-8" y="0">50</text>
          </g>
          <g class="tick" transform="translate(0,70)">
            <text dy="1.5" x="-8" y="0">30</text>
          </g>
          <g class="tick" transform="translate(0,90)">
            <text dy="1.5" x="-8" y="0">10</text>
          </g>
        </g>
        }
        @if (!chartOnly) {
        <g class="axis x-axis" [class.expand-scale]="isExpand"
          transform="translate(17,108)">
          <g class="tick" transform="translate(10,0)">
            <text dx="2" x="0" y="11">10</text>
          </g>
          <g class="tick" transform="translate(30,0)">
            <text dx="2" x="0" y="11">30</text>
          </g>
          <g class="tick" transform="translate(50,0)">
            <text dx="2" x="0" y="11">50</text>
          </g>
          <g class="tick" transform="translate(70,0)">
            <text dx="2" x="0" y="11">70</text>
          </g>
          <g class="tick" transform="translate(90,0)">
            <text dx="3" x="0" y="11">90</text>
          </g>
        </g>
        }
        @if (!chartOnly) {
        <g class="legend x-legend" transform="translate(15, 3.5)">
          <text class="legend-name" [class.expand-legend]="isExpand" x="50"
            y="126" i18n>Importance</text>
        </g>
        }
        @if (!chartOnly) {
        <g class="legend y-legend" transform="translate(1,61)">
          <text class="legend-name" [class.expand-legend]="isExpand"
            transform="rotate(-90)" x="0" y="0">Condition</text>
        </g>
        }
      </svg>
    </div>
    <div class="legends-container">
      <div class="circle-container">
        @if (viewTab === 'Substations' || viewTab === 'Substation-L') {
        <div class="circle"></div>
        }
        @if (viewTab !== 'Substations' && viewTab !== 'Substation-L') {
        <div class="triangle"></div>
        }
        <div class="text">{{(viewTab !== 'Substations' && viewTab !== 'Substation-L') ? 'Assets': 'Installation'}}</div>
      </div>
      <div class="box-container">
        <div class="box low"></div>
        <div class="text">Low</div>
        <div class="box medium"></div>
        <div class="text">Medium</div>
        <div class="box high"></div>
        <div class="text">High</div>
      </div>
    </div>
    <ng-template #riskTip>
      <div>
        <perfect-scrollbar fxFlex="auto">
          <div class="tooltip-class">
            <div class="tooltip-heading ">Risk              
            </div>
            <div class="tooltip-body">
              <div class="riskMatrix-tooltip-div">
                <div class="riskMatrix-tooltip-div-space">Assets</div>
                @for (td of toolTipData; track td; let idx = $index) {
                <h5 class="tooltip-color"
                  [ngClass]="toolTipData.length == 1 ? 'border-bottom-0' : 'border-bottom-1'">
                  @if (td.sldRef) {
                    {{ td.sldRef }} - {{td.name }}
                  } @else {
                    {{ td.name }}
                  }       
                </h5>
                }    
              </div>              
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </ng-template>
    <ng-template #substationTip>
      <div class="tooltip-class">
        <div class="tooltip-heading">Risk
          <span [ngClass]="{
            'low': toolTipData[0].riskColor === 'green',
            'medium': toolTipData[0].riskColor === 'yellow',
            'high': toolTipData[0].riskColor === 'red'
          }" class="tooltip-heading-wrap box-tooltip-riskvalue">
            {{ toolTipData[0].riskValue | number : '1.0-1'}}
          </span>
        </div>
        <div class="tooltip-body">
          <div class="riskMatrix-tooltip-div">
            <div class="riskMatrix-tooltip-div-space">Installation</div>
            @for (td of toolTipData; track td; let idx = $index) {
            <h5
              [ngClass]="toolTipData.length == 1 ? 'border-bottom-0' : 'border-bottom-1'"
              class=" tooltip-color">{{td.name}}</h5>
            }
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<button id="ele"></button>
<context-menu #infoContextMenu>
  <ng-template contextMenuItem (execute)="showInformation()">
    <span>Show information</span><i class="icon icon-info-white"
      aria-hidden="true"></i>
  </ng-template>
</context-menu>

import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import * as _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import findLast from 'lodash/findLast';
import groupBy from 'lodash/groupBy';
import padStart from 'lodash/padStart';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';
import { defaults as OlInteractionDefaults } from 'ol/interaction';
import PointerInteraction from 'ol/interaction/Pointer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OlMap from 'ol/Map';
import { fromLonLat } from 'ol/proj';
import OSMSource from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import View from 'ol/View';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ApplicationService } from '../../../services/application.service';
import { GisSldStateService } from '../../../services/gis-sld-state.service';
import { DeleteAlertModalComponent } from '../../components/delete-alert-modal/delete-alert-modal.component';
// import { DeleteAlertModalComponent } from '../../containers/dashboard/modals/delete-alert-modal/delete-alert-modal.component';
import { SuccesspopupComponent } from '../../containers/dashboard/modals/successpopup/successpopup.component';
import { Interaction, NodeType } from './constant.enum';
import { Map } from './map';
import { CreateConnectionModalComponent } from './modal/create-connection-modal/create-connection-modal.component';
import { CreateJointModalComponent } from './modal/create-joint-modal/create-joint-modal.component';
import { CreateLoadModalComponent } from './modal/create-load-modal/create-load-modal.component';
import { CreateSourceModalComponent } from './modal/create-source-modal/create-source-modal.component';
import { CreateSubstationModalComponent } from './modal/create-substation-modal/create-substation-modal.component';
// import {CustomStepDefinition, LabelType, Options} from 'ng5-slider';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { SettingsService } from 'src/services/settings.service';
import { ConstService } from 'src/services/const.service';
import { InformationExplorerSideComponent } from '../information-explorer-side/information-explorer-side.component';
import { OpCenterService } from 'src/services/op-center.service';
import { AddExistingInstallationModalComponent } from './modal/add-existing-installation-modal/add-existing-installation-modal/add-existing-installation-modal.component';
import { DisplayConfiguration } from 'src/app/models/display-config';
import { RolesService } from 'src/services/roles.service';
import { Overlay } from 'ol';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @ViewChild('uploadImageRef', { static: false })
  private uploadImageRef: ElementRef;
  @Input() embedded = false;
  componentDataDialog: any = { show: false };
  public iconsPath = '/assets/sld-assets/icons/';
  public selectedMenuItem: any;
  noOfConnections = 0;
  totalConnections = 0;
  viewMode = false;
  userRolesObject: any = {};
  riskValues = [];
  riskvaluesIe = [];
  @Input() highlightSubstations = [];
  @Input() operationMode = false;
  @Input() displayConfiguration:DisplayConfiguration
  operationSearchQuery = '';
  @Output() nodeClicked = new EventEmitter();
  @Input() viewTab;
  isHomePage = false;
  isOperationsCenter = false;
  options;
  min = 0;
  max = 0;
  currentRange : [number, number]
  filterData:any;
  filterDataFetch: Subscription;
  installMainFilterList = [];
  installCompFilterList = [];
  installAllFilterListCount: number ;
  projMainFilterList = [];
  projCompFilterList = [];
  projAllFilterListCount: number;
  searchedInstallations? = [];
  clickedProjects:any = [];
  features:any = [];
  isRiskMatrixVisible=true;
  menuBar = [
    {
      name: 'Configuration',
      icon: 'file',
      subItems: [
        {
          name: 'Import background image',
          enabled: true,
          fn: () => {
            this.uploadImageRef.nativeElement.click();
          },
          icon: 'load',
          activated: false,
          isActivated: false
        }
      ]
    }
  ];

  imageSelected = true;
  nodeTypes = {
    substation: NodeType.SubStation,
    joint: NodeType.Joint,
    source: NodeType.Source,
    load: NodeType.Load,
  };
  isExternal;
  selectedNodeType: NodeType = null;
  editSubStation: any = false;
  selectedFeature: any = null;
  selectedRegion: any = null;
  hoverOverAsset: any = null;
  selectedMarker: any = null;
  hoverOverMarker: any = null;
  hoverOverJoint: any = null;
  hoverOverConnection: any = null;
  focusedCountry = 'CHE';
  legend = true;
  legendTab = 'Substations';
  sortBySubstation = 'name';
  sortDirectionSubstation = false;
  connectionQuery = '';
  sortByConnection = 'name';
  sortDirectionConnection = false;
  connectionList = [];
  markerList = [];
  public inputExcelFile: any;
  years = [];
  currentYear;
  riskMatrix;
  @ViewChild('inputConnectionCVSFileRef', { static: false })
  private inputConnectionCVSFileRef: ElementRef;
  @ViewChild('IeSideTool', { static: false }) private calendarToolElement: InformationExplorerSideComponent;
  legendQuery = '';
  masterData2: [] ;
  mapData: any = {};
  mapData1: any = {};
  masterData: any = {};
  connectionData: any = [];
  connectionMapData: any = [];
  map: any;
  dragStart = false;
  enableForConnect = false;
  connectEnable = false;
  /* ------------------------ */
  projectId: any;
  projectRoutePrefix: string;
  countryShortName: any;
  regionId: any;
  custId: any;
  latitude: any;
  longitude: any;
  altitude: any;
  isEdit = false;
  loading = false;
  gridViewMode: boolean;
  roleselected: any;
  OpCenterMap = false;
  isGridEditor = false;
  /* ------------------------ */
  mapStyle = new Style({
    fill: new Fill({
      color: 'rgba(180, 180, 180, 0)'
    }),
    stroke: null
  });

  lineStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 1)'
    }),
    stroke: new Stroke({
      color: '#BABABA',
      width: 2,
      lineDash: [.5, 5]
    })
  });

  tileLayer = new TileLayer({
    source: new OSMSource({
      url: this.mySettingsService.settings.mapboxURL
    })

  });

  connectionLineStyle = new Style({
    fill: new Fill({
      color: '#bababa'
    }),
    stroke: new Stroke({
      color: '#bababa',
      width: 5,
    })
  });

  hiddenConnectionLineStyle = new Style({
    fill: new Fill({
      color: '#bababa'
    }),
    stroke: new Stroke({
      color: 'transparent',
      width: 5,
    })
  });

  connectionLineDarkStyle = new Style({
    fill: new Fill({
      color: '#a7a7a7'
    }),
    stroke: new Stroke({
      color: '#a7a7a7',
      width: 5,
    })
  });

  connectionLineHoverStyle = new Style({
    fill: new Fill({
      color: '#7f7f7f'
    }),
    stroke: new Stroke({
      color: '#7f7f7f',
      width: 5,
    })
  });

  highlightStyle = new Style({
    stroke: new Stroke({
      color: '#BABABA',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.7)'
    })
  });
  highlightRegionStyle = new Style({
    stroke: new Stroke({
      color: '#BABABA',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(255,255,255, 0.6)'
    })
  });
  highlightJointStyle = new Style({
    stroke: new Stroke({
      color: '#89A9FF',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(255,255,255,0.1)'
    })
  });

  marker = new Style({
    image: new Icon(({
      crossOrigin: 'anonymous',
      src: '/assets/i/marker.svg',
      size: [56, 56]
    }))
  });

  joint = new Style({
    image: new Icon(({
      crossOrigin: 'anonymous',
      src: '/assets/i/joint.svg',
      size: [62, 62]
    }))
  });

  jointHover = new Style({
    image: new Icon(({
      crossOrigin: 'anonymous',
      src: '/assets/i/joint-hover.svg',
      size: [62, 62]
    }))
  });

  vectorSource = new VectorSource({
    // url: '/assets/data/countries.geojson',
    url: '/assets/data/countries.json',
    format: new GeoJSON()
  });

  mapLayer = new VectorLayer({
    source: this.vectorSource,
    style: this.mapStyle
  });


  markerLayer = new VectorLayer({
    source: new VectorSource({}),
    style: this.mapStyle
  });

  linesLayer = new VectorLayer({
    source: new VectorSource({}),
    style: this.lineStyle
  });

  dragLinesLayer = new VectorLayer({
    source: new VectorSource({}),
    style: this.lineStyle
  });

  highlightLinesLayer = new VectorLayer({
    source: new VectorSource({ useSpatialIndex: false }),
    style: this.connectionLineStyle
  });

  connectionLayer = new VectorLayer({
    source: new VectorSource({}),
    style: this.connectionLineDarkStyle
  });

  selectedCountryLayer = new VectorLayer({
    source: new VectorSource(),
    style: this.highlightStyle
  });

  overlayLayer = new VectorLayer({
    source: new VectorSource(),
    style: new Style({
      fill: new Fill({
        color: 'rgba(0, 0, 0, .75)'
      })
    })
  });

  mouseLayer = new VectorLayer({
    source: new VectorSource(),
  });

  overlay = new Feature({
    geometry: new Polygon([
      [fromLonLat([-180, -85]),
      fromLonLat([-180, 85]),
      fromLonLat([180, 85]),
      fromLonLat([180, -85]),
      fromLonLat([-180, -85])]])
  });
  isTableVisible = true;
  tickHeight: number;
  isMatrixExpanded = false;
  markerClickOptions = ['View'];
  markerMenu: Overlay
  @ViewChild(InformationExplorerSideComponent) explorerSideComponent : InformationExplorerSideComponent;
  // make makers dragable
  Drag: any = (function (olPointerInteraction, that) {
    class Drag extends olPointerInteraction{
      constructor(){
        super({
          handleDownEvent: function (evt) {
            const map = evt.map;
            if (!that.enableForConnect) {
              return false;
            }
            let feature = null;
            map.forEachFeatureAtPixel(evt.pixel,
              function (f) {
                const properties = f.getProperties();
                if (properties && (properties.markerType === 'Marker')) {
                  feature = f;
                  return true;
                }
              });
        
          if (feature) {
            this.feature_ = feature;
              that.dragLinesLayer.getSource().clear();
              that.markerLayer.getSource().getFeatures().forEach(function (marker : Feature<Point>) {
                const startCoordinate = marker.getGeometry().getCoordinates();
                const endCoordinate = feature.getGeometry().getFirstCoordinate();
                let coordinates = [startCoordinate, endCoordinate];
                const middleCoordinate = Map.getMiddleCoordinate(startCoordinate, endCoordinate);
                if (middleCoordinate.length > 0) {
                  coordinates = [startCoordinate, middleCoordinate, endCoordinate];
                }
                const line = new Feature({
                geometry: new LineString(coordinates),
                  name: 'Line'
                });
                if (Map.possibleConnection(feature.get('nodeType'), marker.get('nodeType'))) {
                  that.dragLinesLayer.getSource().addFeature(line);
                }
              });
            }
            return !!feature;
          },
          handleDragEvent: function (evt) {
            that.dragStart = true;
            that.dragStart = true;
            if (that.viewMode) {
              return false;
            }
            that.highlightLinesLayer.getSource().clear();
            const id = '';
            const feature = that.markerLayer.getSource().getClosestFeatureToCoordinate(evt.coordinate,
              function (f) {
                const properties = f.getProperties();
                if ((properties.markerType === 'Marker' || properties.markerType === 'Joint') && f.getId() !== id) {
                  return true;
                }
              });
            const dashLineFeature = that.dragLinesLayer.getSource() ?
              that.dragLinesLayer.getSource().getClosestFeatureToCoordinate(evt.coordinate,
                function (f) {
                  return true;
                }) : null;
            if (feature && dashLineFeature) {
              that.highlightLinesLayer.getSource().clear();
              const closestPoint = dashLineFeature.getGeometry().getClosestPoint(evt.coordinate);
              const startCoordinate = this.feature_.getGeometry().getFirstCoordinate();
              let coordinates = [startCoordinate, closestPoint];
              const middleCoordinate = Map.getMiddleCoordinate(closestPoint, startCoordinate);
              if (middleCoordinate.length > 0) {
                coordinates = [startCoordinate, middleCoordinate, closestPoint];
              }
              const line = new Feature({
                geometry: new LineString(coordinates),
                name: 'Highlight Line'
              });
              line.setId('HighlightLine');
              that.highlightLinesLayer.getSource().addFeature(line);
            }
          },
          handleUpEvent: function () {
            that.dragStart = false;
            if (that.viewMode) {
              return !(that.viewMode);
            }
            that.dragLinesLayer.getSource().clear();
            const highlightLine = that.highlightLinesLayer.getSource().getFeatureById('HighlightLine') as Feature<LineString>;
            if (highlightLine) {
              const coordinates = highlightLine.getGeometry().getCoordinates()
              const lastCoordinate = coordinates[coordinates.length - 1];
              let delta = 10;
              let closest = null;
              that.markerLayer.getSource().getFeatures().forEach(function (f : Feature<Point>) {
                const firstCoordinate = f.getGeometry().getCoordinates();
                const deltaX = Math.abs(firstCoordinate[0] - lastCoordinate[0]);
                const deltaY = Math.abs(firstCoordinate[1] - lastCoordinate[1]);
                if (delta > deltaX + deltaY) {
                  delta = deltaX + deltaY;
                  closest = f;
                }
              });
              if (closest) {
                if (this.feature_.getId() !== closest.getId()
                  && Map.possibleConnection(this.feature_.get('nodeType'), closest.get('nodeType'))) {
                  that.addNewConnection(this.feature_, closest, '00000000-0000-0000-0000-000000000000', true);
        
                }
        
              }
            }
            that.highlightLinesLayer.getSource().clear();
            this.feature_ = null;
            return false;
          },
        });
        return this;
      }; 

    }        
    return Drag;
  }(PointerInteraction, this));
  zoomInClicked = false;

  // sometimes URL may contain substation id in such case 
  // installation on map and substation in list to be highlighted
  substationIdfromUrl: any;
  yearsRange: any[];
  removeStyle: boolean;
  installationMasterData: string[];
  projectIdForMasteData: string;

  constructor(private dataService: ApplicationService,
    private gisSldStateService: GisSldStateService,
    private route: ActivatedRoute,
    private router: Router,
    private _FileSaverService: FileSaverService,
    config: NgbModalConfig, private modalService: NgbModal,
    private appSvc: ApplicationService,
    private mySettingsService: SettingsService,
    private constService: ConstService,
    private opService: OpCenterService,
    public rolesService: RolesService) {
      if (this.router.routerState.snapshot.url == '/operationscenter') {
        this.installationMasterData = this.constService.installationMasterDataForSystem;
        this.projectIdForMasteData = this.constService.emptyGuid;
        this.getProjectMasterData();
        this.getInstallationMasterData();
      }
      if (this.router.routerState.snapshot.url.includes('/homepage')) {
        this.installationMasterData = this.constService.installationMasterDataForSystemHomePage;
        this.projectIdForMasteData = sessionStorage.getItem('projectId');
        this.getInstallationMasterData();
      }
    config.backdrop = 'static';
    this.appSvc.setfilterData([]);
    this.getProject();
    this.userRolesObject = this.dataService.getUserPrivilages();
  }

  updateMap() {
    this.loadMapWithData();
  }

  updateFilters(){
    this.GetAllInstallationsforInstalledBaseAPI(this.isHomePage);    
  }

  getProject() {
    this.isEdit = true;
    this.loading = true;
    this.dataService.GetDefaultProjectId().subscribe({
      next: (resp) => {
        if (resp) {
          this.loading = false;
          this.projectId = resp.item2.projectid;
          this.regionId = resp.item2.custareaid;
          this.custId = resp.item2.custid;
          this.countryShortName = resp.item2.countryshortname;
          sessionStorage.setItem('diagramSymbols', resp.item2.projdiagsymbolname.toLowerCase());

          this.loadMapWithData();
        } else {
          this.loading = false;
          if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true)) {
            this.isGridEditor = false;
            this.projectNotDefined();
          } else if (this.router.isActive(RouteHelper.addProjectPrefix(`/dashboard/reliability-center`), false)) {
            this.projectNotDefinedRC();
          }
        }
      }, error: () => {
        this.loading = false;
      }
    });
  }

  projectNotDefined() {
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    modalRef.componentInstance.title = 'Warning';
    modalRef.componentInstance.message = 'This user is not assigned to any project.';
    this.router.navigate([RouteHelper.addProjectPrefix(`/dashboard/project-modelling`)]);
  }

  projectNotDefinedRC() {
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    modalRef.componentInstance.title = 'Warning';
    modalRef.componentInstance.message = 'This user is not assigned to any project.';
  }

  ngOnInit() {
    this.isExternal = RouteHelper.isExternalProject();
    this.projectRoutePrefix = RouteHelper.getProjectRoute();
    this.isEdit = true;
    
    this.dataService.getMasterData().pipe(first())
      .subscribe({
        next: () => { this.setMasterData.bind(this); }, error: () => {
          this.loading = false;
        }
      });
    if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), false)) {
      this.isGridEditor = true;
      this.gridViewMode = !this.hasPermissionToEdit();
    }
    
    this.substationIdfromUrl = this.route.snapshot.params.substationId;
    this.updateMarkerClickOptions();
  }

  updateFilterData(filterListCount, compFilterList,mainFilterList,filterDataList){
  let item={
    filterListCount:filterListCount,
    compFilterList: compFilterList,
    mainFilterList:mainFilterList,
    filterDataList:filterDataList
  }
    this.appSvc.setfilterData(item);
  }


  mainFilterTotalCount(req){
    let flag = 0;
    Object.keys(req).forEach(key =>{
      flag = flag + req[key].length;
    });
return flag;
} 
  getInstallationMasterData(){
    this.loading = true;
    this.appSvc.GetInstallationsMasterDataForOperationCenter(this.projectIdForMasteData).subscribe({next:(res) => {
      this.masterData2 = res.returnObj;
      let flag = 0;
      Object.keys(this.masterData2).forEach((key) => {
        if (this.installationMasterData.includes(key)) {
          flag = flag + this.masterData2[key].length;
          this.installMainFilterList.push({
            key: key,
            value: key
          });
        }
      });     
      Object.keys(this.masterData2).forEach(key => this.installCompFilterList.push({
        menuId: key,
        menuValues: Object.values(this.masterData2[key])
      }));
      this.installAllFilterListCount = flag;
      this.loading = false;
    }, error: () => {
      this.loading = false;      
    }});
  }

  getProjectMasterData(){
    this.loading = true;
    this.appSvc.GetProjectsMasterDataForOperationCenter().subscribe({next:(res) => {
      this.masterData2 = res.returnObj;
      let flag = 0;
      Object.keys(this.masterData2).forEach((key) => {
        flag = flag + this.masterData2[key].length;
        this.projMainFilterList.push({
        key: key,
        value: key
      });
    });
      
      Object.keys(this.masterData2).forEach(key => this.projCompFilterList.push({
        menuId: key,
        menuValues: Object.values(this.masterData2[key])
      }));
      this.projAllFilterListCount = flag;
      this.loading = false;
    }, error: () => {
      this.loading = false;      
    }});
  }
  
  operationsCenterMap() {
    this.loading = true;
    this.appSvc.GetOperationCenterMapDetails().subscribe({next:(res: any) => {
      this.mapData = res.returnObj;
      if (this.mapData?.markers.length === 0) {
        const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        modalRef.componentInstance.title = 'Message';
        modalRef.componentInstance.message = 'No substations added in any projects yet!';
      }
      if (this.mapData && this.mapData.markers.length > 0) {
        for (let index = 0; index < this.mapData.markers.length; index++) {         
            this.mapData.markers[index].type = 'substation';
          const coord = this.mapData.markers[index].coordinate;
            this.mapData.markers[index].coordinate = coord ?[coord[1], coord[0]]: null;
        }
      }
      this.mapLayer.setZIndex(3);
      this.mouseLayer.setZIndex(5);
      this.overlayLayer.setZIndex(5);
      this.linesLayer.setZIndex(8);
      this.dragLinesLayer.setZIndex(8);
      this.highlightLinesLayer.setZIndex(9);
      this.connectionLayer.setZIndex(9);
      this.markerLayer.setZIndex(10);
      this.map = new OlMap({
        interactions: OlInteractionDefaults({ doubleClickZoom: false }).extend([new this.Drag()]),
        target: 'map',
        controls: [],
        layers: [
          this.tileLayer,
          this.mapLayer,
          this.markerLayer,
          this.mouseLayer,
          this.linesLayer,
          this.dragLinesLayer,
          this.highlightLinesLayer,
          this.selectedCountryLayer,
          this.connectionLayer,
          this.overlayLayer,
        ],
        view: new View({
          center: fromLonLat([39.257809, 30.870447]),
          zoom: 2.7,
          minZoom: 2.4,
          maxZoom: 10,
        })
      });
      this.map.on('singleclick', this.clickMap.bind(this));
      this.map.on('dblclick', this.onDoubleClickMap.bind(this));
      this.map.on('pointermove', this.markerHoverEvent.bind(this));
      this.tileLayer.on('postrender', (event) => {
        this.greyscale(event.context);
      });
      this.loadDataFromJSONOperationsCenter(this.mapData);  
      this.GetAllInstallationsforInstalledBaseAPI();
    }, error: () => {
      this.loading = false;
    }
   });
  }

  GetAllInstallationsforInstalledBaseAPI(isHomePage?:boolean){
    let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
    const data1 = {
      "projectId": isHomePage?sessionStorage.getItem("projectId"):this.constService.emptyGuid,
      "pageNumber": 1,
      "pageSize": 10,
      "searchText": "",
      "sortOrder": "ASC",
      "sortColumn": "Technology",
      "startDate": "",
      "endDate": "",
      "filtersdata": filterList
    }      
    this.loading = true;
    this.appSvc.GetAllInstallationsforInstalledBase(data1).pipe(first()).subscribe({
      next: (res) => {
        this.loading = false;
        this.mapData.totalInstallations = res.returnObj;
        if (this.calendarToolElement !== undefined && this.viewTab==='Installations') {
          this.calendarToolElement.setTotalInstallations(this.mapData.totalInstallations);
        }
        this.GetAllProjectsforOperationCenterAPI();
      }, error: () => {
        this.loading = false;
      }
    });
  }


  GetAllProjectsforOperationCenterAPI(){
    let filterList  = this.filterData?.filterDataList == undefined ? [] :   (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ;
    const data = {
      "pageNumber": 1,
      "pageSize": 10,
      "searchText": "",
      "sortOrder": "ASC",
      "sortColumn": "ProjectName",
      "startDate": "",
      "endDate": "",
      "filtersdata": filterList
    }
    this.loading = true;
  this.appSvc.GetAllProjectsforOperationCenter(data).pipe(first()).subscribe({
    next: (res) => {
      this.loading = false;
      this.mapData.totalProjects = res.returnObj;
      if (this.calendarToolElement !== undefined && this.viewTab==='Projects') {
        this.calendarToolElement.setTotalProjects(this.mapData.totalProjects);
      }
      this.loading = false;
      this.operationMode = true;
    }, error: () => {
      this.loading = false;
    }
  });
  }

  loadMapWithData() {
    this.loading = true;
    const $that = this;
    if ((this.router.isActive((`/operationscenter`), false))) {
      //MAPDATA
      this.operationsCenterMap();
      this.isOperationsCenter = true;
    } else if (this.router.isActive(RouteHelper.addProjectPrefix(`/homepage`), false)) {
      this.isHomePage = true;
      this.operationMode = true;
      this.dataService.GetAllForInformationExplorer(this.projectId)
        .pipe(first())
        .subscribe({
          next: (mapData) => {
            this.loading = false;
            this.mapData = mapData.returnObj;
            this.mapData.completness = 80;
            if (this.mapData.markers.length === 0) {
              const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
              modalRef.componentInstance.title = 'Message';
              modalRef.componentInstance.message = 'No substations added in project yet!';
            }
            if (this.mapData && this.mapData.markers.length > 0) {
              for (let index = 0; index < this.mapData.markers.length; index++) {
                if (this.mapData.markers[index].type === 'External Source') {
                  this.mapData.markers[index].type = 'source';
                }
                if (this.mapData.markers[index].type === 'Joint') {
                  this.mapData.markers[index].type = 'joint';
                }
                if (this.mapData.markers[index].type === 'Substation') {
                  this.mapData.markers[index].type = 'substation';
                }
                if (this.mapData.markers[index].type === 'External Load') {
                  this.mapData.markers[index].type = 'load';
                }
                const marker = this.mapData.markers[index];
                const coord = this.mapData.markers[index].coordinate;
                this.mapData.markers[index].coordinate = [coord[1], coord[0]];
              }
            }
            if(this.viewTab!=="Substation-L"){
            this.tabChange('Substations');
            }
            else{
              this.GetAllInstallationsforInstalledBaseAPI(true);
              this.tabChange('Substation-L');
            }
            this.focusedCountry = this.mapData.country;
            if (this.focusedCountry == null) {
              this.focusedCountry = this.countryShortName;
            }
            this.mapLayer.setZIndex(3);
            this.mouseLayer.setZIndex(5);
            this.overlayLayer.setZIndex(5);
            this.linesLayer.setZIndex(8);
            this.dragLinesLayer.setZIndex(8);
            this.highlightLinesLayer.setZIndex(9);
            this.connectionLayer.setZIndex(9);
            this.markerLayer.setZIndex(10);
            this.map = new OlMap({
              interactions: OlInteractionDefaults({ doubleClickZoom: false }).extend([new this.Drag()]),
              target: 'map',
              controls: [],
              layers: [
                this.tileLayer,
                this.mapLayer,
                this.markerLayer,
                this.mouseLayer,
                this.linesLayer,
                this.dragLinesLayer,
                this.highlightLinesLayer,
                this.selectedCountryLayer,
                this.connectionLayer,
                this.overlayLayer,
              ],
              overlays:[this.markerMenu],
              view: new View({
                center: fromLonLat([73.8567, 18.5204]),
                zoom: 8,
                minZoom: 3,
                maxZoom: 10,
              })
            });
            this.map.on('singleclick', this.onClickMap.bind(this));
            this.map.on('dblclick', this.onDoubleClickMap.bind(this));
            this.map.on('pointermove', this.markerHoverEvent.bind(this));
            this.vectorSource.on('change', function () {
              $that.focus();
            });
            this.tileLayer.on('postrender', (event) => {
              this.greyscale(event.context);
            });
            if ((this.router.isActive(RouteHelper.addProjectPrefix(`/homepage`), false))) {
              this.loadDataFromJSONInformationExplorer(this.mapData);
              this.viewMode = true;
            }
          }, error: () => {
            this.loading = false;
          }
        });
    } else {
      this.dataService.getMapMarkers(this.projectId)
        .pipe(first())
        .subscribe({
          next: (mapData) => {
            this.loading = false;
            this.mapData = mapData.returnObj;
            if (this.mapData && this.mapData.markers.length > 0) {
              for (let index = 0; index < this.mapData.markers.length; index++) {
                if (this.mapData.markers[index].type === 'External Source') {
                  this.mapData.markers[index].type = 'source';
                }
                if (this.mapData.markers[index].type === 'Joint') {
                  this.mapData.markers[index].type = 'joint';
                }
                if (this.mapData.markers[index].type === 'Substation') {
                  this.mapData.markers[index].type = 'substation';
                }
                if (this.mapData.markers[index].type === 'External Load') {
                  this.mapData.markers[index].type = 'load';
                }
                this.mapData.markers[index].coordinate = [this.mapData.markers[index].coordinates[1],
                this.mapData.markers[index].coordinates[0]];
              }
            }
            if (this.mapData && this.mapData.riskMatrix) {
              this.mapData.riskMatrix.forEach((row: { risk, risk1, chart, name }, ind) => {
                this.riskValues.push(
                  {
                    risk: row.risk,
                    risk1: row.risk1,
                    chart: row.chart,
                    name: row.name,
                    scale: 1,
                  }
                );
              });
            }
            this.focusedCountry = this.countryShortName;
            this.mapLayer.setZIndex(3);
            this.mouseLayer.setZIndex(5);
            this.overlayLayer.setZIndex(5);
            this.linesLayer.setZIndex(8);
            this.dragLinesLayer.setZIndex(8);
            this.highlightLinesLayer.setZIndex(9);
            this.connectionLayer.setZIndex(9);
            this.markerLayer.setZIndex(10);
            this.map = new OlMap({
              interactions: OlInteractionDefaults({ doubleClickZoom: false }).extend([new this.Drag()]),
              target: 'map',
              controls: [],
              layers: [
                this.tileLayer,
                this.mapLayer,
                this.markerLayer,
                this.mouseLayer,
                this.linesLayer,
                this.dragLinesLayer,
                this.highlightLinesLayer,
                this.selectedCountryLayer,
                this.connectionLayer,
                this.overlayLayer,
              ],
              view: new View({
                center: fromLonLat([73.8567, 18.5204]),
                zoom: 8,
                minZoom: 3,
                maxZoom: 10,
              })
            });
            this.map.on('singleclick', this.onClickMap.bind(this));
            this.map.on('dblclick', this.onDoubleClickMap.bind(this));
            this.map.on('pointermove', this.markerHoverEvent.bind(this));
            this.vectorSource.on('change', function () {
              $that.focus();
            });
            this.tileLayer.on('postrender', (event) => {
              this.greyscale(event.context);
            });
            if ((this.router.isActive(RouteHelper.addProjectPrefix(`/homepage`), false)) || this.embedded) {
              this.loadDataFromJSON(this.mapData);
              this.viewMode = true;
            }
            if (this.mapData) {
              this.loadDataFromJSON(this.mapData);
            }

            // if there is substation id in project-modelling url,
            // then highlight installation on the map and substation on the list
            if (this.mapData && this.substationIdfromUrl) {
              this.InstallationHover(this.substationIdfromUrl);
              this.substationHover(this.substationIdfromUrl);
            }
          }, error: () => {
            this.loading = false;
          }
        });
    }
  }

  greyscale(context) {
    const canvas = context.canvas;
    const width = canvas.width;
    const height = canvas.height;
    context.getContextAttributes().willReadFrequently = true;
    const imageData = context.getImageData(0, 0, width, height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];
      // CIE luminance for the RGB
      let v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      // Show white color instead of black color while loading new tiles:
      if (v === 0.0) {
        v = 255.0;
      }
      data[i + 0] = v; // Red
      data[i + 1] = v; // Green
      data[i + 2] = v; // Blue
      data[i + 3] = 255; // Alpha
    }
    context.putImageData(imageData, 0, 0);

  }

  findSubstaion($event) {
    const query = $event.target.value;
    const marker = this.mapData.markers.find(m => m.props.name.startsWith(query));
    // set center to marker
    if (marker) {
      this.map.getView().setCenter(marker.coordinate, 8);
    }
  }

  addNewConnection(feature, closest, id, showModal, data: any = {}) {
    //debugger;
    this.noOfConnections = this.noOfConnections + 1;
    const startCoordinate1 = feature.getGeometry().getFirstCoordinate();
    const endCoordinate1 = closest.getGeometry().getFirstCoordinate();
    const middleCoordinate = Map.getMiddleCoordinate(endCoordinate1, startCoordinate1);
    let points = [
      startCoordinate1,
      endCoordinate1
    ];
    if (middleCoordinate.length > 0) {
      points = [
        endCoordinate1,
        middleCoordinate,
        startCoordinate1
      ];
    }
    const origin = closest ? (closest.get('name') || closest.get('sourceReference') ||
      closest.get('loadReference') || closest.get('nodeReference')) : '';
    const destination = feature ? (feature.get('name') || feature.get('sourceReference') ||
      feature.get('loadReference') || feature.get('nodeReference')) : '';
    //debugger;
    const line = new Feature({
      geometry: new LineString(points),
      name: data.name || (destination + '-' + origin),
      type: 'Connection Line',
      source: 'Source',
      reference: data.reference || 'SR-' + padStart(this.noOfConnections, 4, '0'),
      origin: closest.getId(),
      destination: feature.getId(),
      isNew: id === '00000000-0000-0000-0000-000000000000' ? true : false
    });
    line.setId(id);
    this.connectionLayer.getSource().addFeature(line);
    this.connectionVisibilityController(this.getConnections(line));
    if (showModal) {
      this.addConnection(line, true);
    }
  }
  loadDataFromJSONInformationExplorer(json, forceRedraw = false) {
    const $that = this;
    json.markers.forEach((marker) => {
      this.selectedNodeType = $that.nodeTypes[marker.type];
      this.onClickMap(marker, true, forceRedraw);
    });
    this.selectedNodeType = null;
    json.connections.forEach((connection) => {
      const origin = findLast(json.markers, { id: connection.props.origin });
      const destination = findLast(json.markers, { id: connection.props.destination });
      const middleCoordinate = Map.getMiddleCoordinate(destination.coordinate, origin.coordinate);
      let points = [
        origin.coordinate,
        destination.coordinate
      ];
      if (middleCoordinate.length > 0) {
        points = [
          origin.coordinate,
          middleCoordinate,
          destination.coordinate
        ];
      }
      const line = new Feature({
        geometry: new LineString(points),
        name: connection.props.name,
        type: 'Connection Line',
        source: connection.props.source,
        reference: connection.props.reference,
        origin: connection.props.origin,
        destination: connection.props.destination
      });
      line.setId(connection.props.id);
      if (connection.props.source === 'Load') {
        line.setStyle(this.connectionLineStyle);
      } else {
        line.setStyle(this.connectionLineDarkStyle);
      }
      this.connectionLayer.getSource().addFeature(line);
    });
    this.getConnectionList();
    this.getMarkers();
  }

  loadDataFromJSONOperationsCenter(json, forceRedraw = false) {
    const $that = this;
    json.markers?.forEach((marker) => {
      this.selectedNodeType = $that.nodeTypes[marker.type];
      this.onClickMap(marker, true, forceRedraw);
    });
    this.selectedNodeType = null;
        this.getMarkers();
  }

  loadDataFromJSON(json) {
    const $that = this;
    this.connectionMapData = json.markers;
    if (json.markers.length > 0) {
      json.markers.forEach((marker) => {
        this.selectedNodeType = $that.nodeTypes[marker.type];
        this.onClickMap(marker, true);
      });
    }
    this.selectedNodeType = null;
    this.connectionData = json.connections;
    json.connections.forEach((connection) => {
      //debugger;
      const origin = findLast(json.markers, { id: connection.origin });
      const destination = findLast(json.markers, { id: connection.destination });
      const middleCoordinate = Map.getMiddleCoordinate(destination.coordinate, origin.coordinate);
      let points = [
        origin.coordinate,
        destination.coordinate
      ];
      if (middleCoordinate.length > 0) {
        points = [
          origin.coordinate,
          middleCoordinate,
          destination.coordinate
        ];
      }
      const line = new Feature({
        geometry: new LineString(points),
        name: connection.name,
        type: 'Connection Line',
        source: connection.source,
        reference: connection.reference,
        origin: connection.origin,
        destination: connection.destination,
        assetId: connection.assetId
      });
      line.setId(connection.id);
      line.setStyle(this.connectionLineDarkStyle);
      if (connection.source === 'Load') {
        line.setStyle(this.connectionLineStyle);
      } else {
        line.setStyle(this.connectionLineDarkStyle);
      }
      this.connectionLayer.getSource().addFeature(line);
    });
    this.getConnectionList();
    this.getMarkers();
  }

  /**
   * Set Master data
   * @param data - master data
   */
  setMasterData(data) {
    this.masterData = data;
  }


  addMarkerEvent(nodeType: NodeType) {
    if (this.connectEnable === false) {

      if (this.selectedNodeType === nodeType) {
        this.selectedNodeType = null;
      } else {
        this.selectedNodeType = nodeType;
      }
      this.mouseLayer.getSource().clear();
    }
  }

  addExistingInst(){
    this.modalService.open(AddExistingInstallationModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
  }

  enableConnections() {
    if (this.isPossibleToConnect()) {
      this.mouseLayer.getSource().clear();
      this.selectedNodeType = null;
      this.connectEnable = !this.connectEnable;
      this.enableForConnect = !this.enableForConnect;
      this.overlayLayer.getSource().clear();
      if (this.enableForConnect) {
        this.overlayLayer.getSource().addFeature(this.overlay);
      }
    }
  }

  disableConnections() {
    this.mouseLayer.getSource().clear();
    this.selectedNodeType = null;
    this.enableForConnect = false;
    this.overlayLayer.getSource().clear();
  }

  /**
   * Add new connection
   * @param feature - feature
   */
  addConnection(feature, isNew) {
    const modalRef = this.modalService.open(CreateConnectionModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.connections = this.getConnections(feature);
    modalRef.componentInstance.types = this.masterData.connectionTypes || [];
    modalRef.componentInstance.noOfConnections = this.totalConnections;
    modalRef.componentInstance.isNew = isNew;
    modalRef.result.then(ret => {
      if (ret.type === 'confirm') {
        this.saveConnection(ret.data);
        this.noOfConnections = ret.noOfConnections;
      } else if (ret.type === 'delete') {
        const data = [];
        for (let i = 0; i < ret.data.length; i++) {
          if (ret.data[i].id !== '00000000-0000-0000-0000-000000000000') {
            data.push(ret.data[i].id);
          }
        }
        const modalRef1 = this.modalService.open(DeleteAlertModalComponent, { centered: true });
        modalRef1.componentInstance.deleteItemName = 'connection';
        modalRef1.result.then(ret1 => {
          if (ret1 === 'delete' && data.length > 0) {
            this.loading = true;
            this.dataService.deleteSubstationConnectionById(data).subscribe({
              next: res => {
                const modalRef2 = this.modalService.open(SuccesspopupComponent, { centered: true });
                modalRef2.componentInstance.title = 'Message';
                modalRef2.componentInstance.message = 'Connection deleted successfully!';
                modalRef2.componentInstance.isSubstation = true;
                this.reloadPage();
                this.loading = false;
              }, error: () => {
                this.loading = false;
              }
            });
          }
        });
      } else if (ret.type === 'closeModal') {
        this.reloadPage();
        this.closePopup();
      } else {
        this.closePopup();
      }
      this.disableConnections();
    }, () => {
      this.closePopup();
      this.disableConnections();
    });
  }

  zoomOutClick() {
    if (this.zoomInClicked === true) {
      this.zoomInClick()
    } else {
      if (this.map.getView().getZoom() > 3) {
        this.map.getView().setZoom(this.map.getView().getZoom() - 1);
      } else {
        this.zoomInClicked = true;
        this.zoomInClick();
      }
    }
  }
  zoomInClick() {
    if (this.map.getView().getZoom() < 10) {
      this.map.getView().setZoom(this.map.getView().getZoom() + 1);
    } else {
      this.zoomInClicked = false;
    }
  }

  /**
   * Zoom out
   */
  zoomOut() {
    if (this.map.getView().getZoom() > 2) {
      this.map.getView().setZoom(this.map.getView().getZoom() - 1);
    }
  }

  /**
   * Zoom In
   */
  zoomIn() {
    this.map.getView().setZoom(this.map.getView().getZoom() + 1);
  }
  //Operations center reset zoom
  opCenterFocus() {
    this.map.getView().setZoom(2.7);
  }

  /**
   * Focus country area
   */
  focus() {
    const $that = this;
    const source = $that.mapLayer.getSource();
    $that.selectedCountryLayer.setZIndex(3);
    $that.selectedCountryLayer.getSource().clear();
    const feature = source.getFeatureById($that.focusedCountry) as Feature<Geometry>;
    $that.selectedCountryLayer.getSource().addFeature(feature);
    $that.selectedFeature = feature;
    $that.map.getView().fit(feature.getGeometry(), $that.map.getSize());
  }

  onDoubleClickMap(args): void {
    this.mouseLayer.getSource().clear();
  
    if (this.selectedNodeType === null) {
      const feature = this.map.forEachFeatureAtPixel(args.pixel, (f) => {
        const properties = f.getProperties();
  
        if (properties && properties.markerType === 'Marker') {
          this.selectedMarker = f;
        }
        return f;
      });
  
      if (feature) {
        const properties = feature.getProperties();
  
        if (this.isOperationsCenter && properties.markerType === 'Marker') {
          this.handleOperationsCenterClick(feature);
        }
  
        if (this.viewMode) {
         return;
        }
  
        if (properties && properties.markerType === 'Marker' && !this.isOperationsCenter) {
          this.componentData(properties);
        }
      }
    }
  }
  
  private handleOperationsCenterClick(feature): void {
    if (this.viewTab === 'Installations') {
      if (feature.isExternal === 'External') {
        this.showExternalProjectsAccessMessage();
        return;
      }
  
      this.router.navigate([RouteHelper.addProjectPrefix(`/substationinfo`)],
      { queryParams: { substationId: this.selectedMarker.id_, classId: this.constService.substationClassId,
        equipmentName: this.constService.markerType, name: feature.substationName, isOpCenter: true, from: 'Operations center', country: '' }});
  
      sessionStorage.setItem('projectIdfromOpCenter', feature.projectId);
      sessionStorage.setItem('projectNamefromOpCenter', feature.projectName);
    } else {
      this.router.navigate([`/viewProject/${feature.projectId}`], {queryParams : {from:'Operations center'}});
    }
  }
  
  private handleViewModeClick(properties): void {
    if (properties.nodeType === NodeType.SubStation) {
      if (this.router.isActive(RouteHelper.addProjectPrefix(`/operations/view`), false)) {
        sessionStorage.setItem('substationId', this.selectedMarker.id_);
        this.nodeClicked.emit(properties);
      } else {
        this.goToSLD(true);
      }
    }
  }
  
  private showExternalProjectsAccessMessage(): void {
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    modalRef.componentInstance.title = 'Message';
    modalRef.componentInstance.message = 'External projects cannot be accessed.';
  }

  ngAfterViewInit() {
    this.markerMenu = new Overlay(
      {
        element: document.getElementById('marker-menu-container'),
        autoPan:
        {
          animation: {
            duration: 250
          }
        }
      }
    );
  }

  clickMap(args) {
     this.mouseLayer.getSource().clear();
      const feature = this.map.forEachFeatureAtPixel(args.pixel, (f) => {
        const properties = f.getProperties();
        if (properties && properties.markerType === 'Marker') {
          this.selectedMarker = f;
        }
        return f;
      });
      if (feature) {
        const properties = feature.getProperties();
        if(this.viewTab === 'Installations') {
          let joinInstallations;
            let index = this.searchedInstallations?.findIndex(name => name == feature.substationName);
            if(index == -1) {
              this.OpCenterMap = true;
              this.searchedInstallations.push(feature.substationName);
              feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal,
                this.operationMode ? 'blue' : null));
                this.features.push(feature);
            } else {
              this.searchedInstallations.splice(index, 1);
              this.features.splice(index, 1);
              feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal, null));
            }
           joinInstallations = this.searchedInstallations?.join(',');
           if (this.searchedInstallations?.length === 0) { 
              this.OpCenterMap = false;
              this.opService.installationSearch(null);
           } 
           this.opService.installationSearch(joinInstallations);
        } else {
          if(feature.projectName === this.clickedProjects[this.clickedProjects.length - 1]){
            this.opService.projectSearch(null);
            feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal, null));
          } else {
            this.opService.projectSearch(feature.projectName);
            feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal,
              this.operationMode ? 'blue' : null));
          }
        }
        this.clickedProjects.push(feature.projectName);
      }
  }
  
  /**
   * Click event on map
   * @param args - event
   * @param fromJSON - loading from json data
   */
  onClickMap(args, fromJSON, forceRedraw = false) {
    this.selectedMarker = null;
    if (this.viewMode && !forceRedraw) {
      //check whether the clicked element is a marker
      this.map.forEachFeatureAtPixel(args.pixel, (f) => {
        const properties = f.getProperties();
        if (properties && properties.markerType === 'Marker') {
          this.selectedMarker = f;
        }
        return f;
      });
      //display the marker menu if clicked on marker.
      if (this.selectedMarker) {
        this.markerMenu.setOffset([0, 0]);
        this.markerMenu.setPosition(args.coordinate);
      }
      else {
        //close the marker menu.
        this.markerMenu?.setPosition(undefined)
      }
      return false;
    }
    this.mouseLayer.getSource().clear();
    if (this.selectedNodeType !== null) { // check add sub station is active
      const coords = args?.coordinate;
        this.latitude = args.coordinate? args.coordinate[0]: null;
        this.longitude = args.coordinate? args.coordinate[1]: null;
      let isInside = true;
      if (!fromJSON) {
        const polygonGeometry = this.selectedFeature.getGeometry();
        isInside = coords? polygonGeometry.intersectsCoordinate(coords): null;
      }
      if (isInside) {
        const marker = new Feature({
          markerType: 'Marker',
          nodeType: this.selectedNodeType,
          latitude: args?.coordinate? args.coordinate[0]: null,
          longitude: args?.coordinate? args.coordinate[1]: null,
          geometry: coords? new Point(coords): null,
        });
        Object.assign(marker, {
          'substationName': this.isOperationsCenter? args?.substationName: args?.props?.name
        });
        if(this.isOperationsCenter){
          Object.assign(marker, {
          'projectName': args?.projectName,
          'projectId': args?.projectId,
          'isExternal' : args?.isExternal
          });
        } else {
          Object.assign(marker, {
          'riskValue': args?.props?.riskValue,
          'riskColor': args?.props?.risk,
          'assetsCount': args?.props?.assets?.length,
          'technology': args?.props?.switchgear
        });
        }
        marker.setId((args.props && args.props.id) || args.substationId || uuid());
        // only show risk colour values when url is reliability center
        if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true) || 
        (this.router.isActive((`/operationscenter`), false))) {
                 marker.setStyle(Map.getNodeIcon(this.selectedNodeType, Interaction.Normal, null));
        } else {
          marker.setStyle(Map.getNodeIcon(this.selectedNodeType, Interaction.Normal,
            args.props ? args.props.risk : null,
            args.props && args.props.assets ? args.props.assets.length : '')
          );
        }
        marker.setProperties({
          ...args.props,
          possibilityToExtend: '',
          voltageLevel: '',
          switchgear: '',
          latitude: args.coordinate? args.coordinate[0]: null,
          longitude: args.coordinate? args.coordinate[1]: null,
        });
        this.markerLayer.getSource().addFeature(marker);
        this.selectedMarker = marker;
        if (!fromJSON) {
          let selectedNode = this.selectedNodeType;
          this.selectedNodeType = null;
          this.openCreateNodeModal(selectedNode);
        }

      }
    } else {
      const feature = this.map.forEachFeatureAtPixel(args.pixel, function (f) {
        const properties = f.getProperties();
        if (properties && properties.markerType === 'Marker' || properties.type === 'Connection Line') {
          return f;
        }
      });
      if (feature) {
        const properties = feature.getProperties();
        if (properties && properties.markerType === 'Marker') {
          this.selectedMarker = feature;
        } else if (properties && properties.type === 'Connection Line') {
          this.addConnection(feature, false);
        }
      }
    }
  }

  shouldDisplaySubstationNotificationSignal(substationId) {
    return this.appSvc.notifications.some(x => x.substationIds.some(t => t.id == substationId && !t.isRead))
  }

  openCreateNodeModal(nodeType: NodeType) {
    let component: any = CreateSubstationModalComponent;
    if (nodeType === NodeType.Source) {
      component = CreateSourceModalComponent;
    } else if (nodeType === NodeType.Load) {
      component = CreateLoadModalComponent;
    } else if (nodeType === NodeType.Joint) {
      component = CreateJointModalComponent;
    }
    const modalRef = this.modalService.open(component, { centered: true, size: 'lg' });
    modalRef.componentInstance.marker = this.selectedMarker;
    modalRef.componentInstance.masterData = this.masterData;
    modalRef.componentInstance.edit = this.editSubStation;
    modalRef.result.then(ret => {
      if (ret.type === 'delete') {
        this.deleteMarker(ret.data);
      } else if (ret.type === 'save') {
        this.saveMarker(ret);
      } else if (ret.type === 'sld') {
        this.closePopup();
        this.saveMarker(ret);
      } else {
        this.closePopup();
      }
    }, () => {
      this.closePopup();
    });
  }

  goToSLD(forceViewMode?: boolean, substationId?: string) {
    const allConnectedNodes = new Set();

    const makeUniqueGISRef = () => {
      const refCountVar = {};

      allConnectedNodes.forEach(node => {
        if (!(node['ref'] in refCountVar)) {
          refCountVar[node['ref']] = 1;
        } else {
          refCountVar[node['ref']] += 1;
        }
      });

      allConnectedNodes.forEach(node => {
        if (node['ref'] in refCountVar && refCountVar[node['ref']] > 1) {
          refCountVar[node['ref']] -= 1;
          node['uniqueGISRef'] = node['ref'] + '_' + (refCountVar[node['ref']] + 1);
        } else {
          node['uniqueGISRef'] = node['ref'];
        }
      });
    };

    this.connectionLayer.getSource().getFeatures().forEach(feature => {
      const originId = feature.get('origin');
      const destinationId = feature.get('destination');
      // const dest = this.markerLayer.getSource().getFeatureById(originId);
      // const ori = this.markerLayer.getSource().getFeatureById(destinationId);
      // Swaped for conections
      const ori = this.markerLayer.getSource().getFeatureById(originId) as Feature<Geometry>;
      const dest = this.markerLayer.getSource().getFeatureById(destinationId) as Feature<Geometry>;
      const nameToSldComponentMap = {
        'Source': 'Power supply',
        'Load': 'Load',
        'Line': 'Overhead transmission line',
        'Joint': 'Joint'
      };

      if (this.selectedMarker === ori) { // && NodeType[dest.get('nodeType')] !== 'Joint'
        [dest, feature].forEach(n => {
            if (n.get('type') !== 'Connection Line') {
              let modifiedName = NodeType[n.get('nodeType')];
              if (modifiedName === 'SubStation' || modifiedName === 'Joint') {
                modifiedName = 'Load';
              }
              let ref = null;
              if (n.get('name')) {
                ref = n.get('name');
              } else if (n.get('nodeReference')) {
                ref = n.get('nodeReference');
              } else if (n.get('sourceReference')) {
                ref = n.get('sourceReference');
              } else if (n.get('loadReference')) {
                ref = n.get('loadReference');
              }
              allConnectedNodes.add({
                ref: ref,
                name: nameToSldComponentMap[modifiedName || 'Line'],
                properites: n.getProperties()
              });
            }
        });
      } else if (this.selectedMarker === dest) { //  && NodeType[ori.get('nodeType')] !== 'Joint'
        [ori, feature].forEach(n => {
          if (n.get('type') !== 'Connection Line') {
            let modifiedName = NodeType[n.get('nodeType')];
            if (modifiedName === 'SubStation' || modifiedName === 'Joint') {
              modifiedName = 'Source';
            }
            let ref = null;
            if (n.get('name')) {
              ref = n.get('name');
            } else if (n.get('nodeReference')) {
              ref = n.get('nodeReference');
            } else if (n.get('sourceReference')) {
              ref = n.get('sourceReference');
            } else if (n.get('loadReference')) {
              ref = n.get('loadReference');
            }
            allConnectedNodes.add({
              ref: ref,
              name: nameToSldComponentMap[modifiedName || 'Line'],
              properites: n.getProperties()
            });
          }
        });
      }
    });

    makeUniqueGISRef();
    if (!substationId) {
      sessionStorage.setItem('substationId', this.selectedMarker.id_);
      this.gisSldStateService.setSubstationId(this.selectedMarker.id_);
    } else {
      sessionStorage.setItem('substationId', substationId);
      this.gisSldStateService.setSubstationId(substationId);
    }
    forceViewMode = !this.hasPermissionToEdit();
    sessionStorage.setItem('fromEditGIS', 'true');
    sessionStorage.setItem('fromAIF', 'false');
    this.gisSldStateService.setInitialComponentList(Array.from(allConnectedNodes));
    if ((this.router.isActive(RouteHelper.addProjectPrefix(`/homepage`), false))) {
      this.router.navigate([RouteHelper.addProjectPrefix(`/sld-editor/true`)], { queryParams: {isFromHomePage: true }});
    }
    else {
      this.router.navigate([RouteHelper.addProjectPrefix(forceViewMode ? `/sld-editor/true` : `/sld-editor`)]);
    }
  }

  /**
   * Hover over marker
   * e - position
   */
  markerHoverEvent(e, selectedFeature,OC) {
    if (this.selectedRegion) {
      this.selectedRegion.setStyle(this.highlightStyle);
      this.selectedRegion = null;
    }
    if (this.hoverOverMarker) {
      const properties = this.hoverOverMarker.getProperties();
      if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true)) {
        this.hoverOverMarker.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal, null));   
      } else {
        this.hoverOverMarker.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal,
          properties.risk,
          properties && properties.assets ? properties.assets.length : ''));
      }
      this.hoverOverMarker = null;
    }
    if (this.OpCenterMap && this.features.length !== 0) {
      this.features.forEach( feature => {
       const properties = feature.getProperties();
       feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal,
         this.operationMode ? 'blue' : null));
      });
     }
    if (this.selectedNodeType !== null) {
      const marker = new Feature({
        geometry: new Point(e.coordinate),
      });
      this.mouseLayer.setStyle(Map.getNodeIcon(this.selectedNodeType, Interaction.Active, null));
      marker.setId(JSON.stringify(marker));
      this.mouseLayer.getSource().clear();
      this.mouseLayer.getSource().addFeature(marker);
    }
    let feature = null;
    if (selectedFeature === null || selectedFeature === undefined) {
      if (e.pixel) {
        feature = this.map.forEachFeatureAtPixel(e.pixel, function (f) {
          const properties = f.getProperties();
          if (properties && (properties.markerType === 'Marker'
            || properties.markerType === 'Joint'
            || properties.type === 'Connection Line'
            || properties.type === 'Region')) {
            return f;
          }
        });
      }
    } else if (selectedFeature !== null && selectedFeature !== undefined) {
      feature = selectedFeature;
    }

    if (feature) {
      const properties = feature.getProperties();
      if (properties && properties.nodeType !== undefined && this.dragStart === false) {
        // only show risk colour values when url is reliability center
        if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true)) {
          feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Hover, 'blue', null, feature.substationName));
        } else if(this.router.isActive((`/operationscenter`), false) && OC != 'OC'){          
          feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Hover,
            this.operationMode ? 'blue' : null ,null ,feature.substationName,feature.projectName));
        } else if(this.router.isActive((`/operationscenter`), false)){          
          feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Hover,
            this.operationMode ? 'blue' : null));
        } else if(this.router.isActive(RouteHelper.addProjectPrefix(`/homepage`), false) && OC != 'OC') {
          if (this.selectedMarker?.getProperties().id !== feature.getProperties().id) {
            feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Hover, properties.risk, null, feature.substationName,
              null, feature.riskValue, feature.assetsCount, properties.technology));
          }
        } else {
          feature.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Hover, properties.risk));
        }
        this.hoverOverMarker = feature;
        this.linesLayer.getSource().clear();
        const $that = this;
        this.markerLayer.getSource().getFeatures().forEach(function (marker : Feature<Point>) {
          const endCoordinate = feature.getGeometry().getFirstCoordinate();
          const startCoordinate = marker.getGeometry().getFirstCoordinate();
          let coordinates = [startCoordinate, endCoordinate];
          if (endCoordinate && startCoordinate) {
            const middleCoordinate = Map.getMiddleCoordinate(startCoordinate, endCoordinate);
            if (middleCoordinate.length > 0) {
              coordinates = [startCoordinate, middleCoordinate, endCoordinate];
            }
          }
          const line = new Feature({
            geometry: new LineString(coordinates),
            name: 'Line'
          });
          if ($that.enableForConnect === true && Map.possibleConnection(feature.get('nodeType'), marker.get('nodeType'))) {
            $that.linesLayer.getSource().addFeature(line);
          }
        });
        if (this.selectedRegion) {
          this.selectedRegion.setStyle(this.highlightStyle);
          this.selectedRegion = null;
        }
      } else if (properties && properties.type === 'Connection Line') {
        this.hoverOverConnection = feature;
        this.hoverOverConnection.setStyle(this.connectionLineHoverStyle);
        if (this.selectedRegion) {
          this.selectedRegion.setStyle(this.highlightStyle);
          this.selectedRegion = null;
        }
      } else if (properties && properties.type === 'Region') {
        this.selectedRegion = feature;
        this.selectedRegion.setStyle(this.highlightRegionStyle);
      }
    } else {
      if (this.hoverOverMarker) {
        const properties = this.hoverOverMarker.getProperties();
        // only show risk colour values when url is reliability center
        if (this.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true)) {
          this.hoverOverMarker.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal, null));
        } else {
          this.hoverOverMarker.setStyle(Map.getNodeIcon(properties.nodeType, Interaction.Normal,
            this.operationMode ? 'blue' : properties.risk));
        }
        this.hoverOverMarker = null;
      }
      if (this.hoverOverJoint) {
        this.hoverOverJoint.setStyle(this.joint);
        this.hoverOverJoint = null;
      }
      if (this.hoverOverConnection) {
        const p = this.hoverOverConnection.getProperties();
        if (p.source === 'Load') {
          this.hoverOverConnection.setStyle(this.connectionLineStyle);
        } else {
          this.hoverOverConnection.setStyle(this.connectionLineDarkStyle);
        }
        this.hoverOverConnection = null;
      }
      if (this.selectedRegion) {
        this.selectedRegion.setStyle(this.highlightStyle);
        this.selectedRegion = null;
      }
      this.linesLayer.getSource().clear();
    }
  }

  componentData(data: any = {}) {
    this.componentDataDialog = {
      show: true,
      data: data,
      nodeType: NodeType[data.nodeType]
    };
  }

  saveComponentData(data) {
    this.isEdit = data.isEdit;
    this.saveAndUpdate(data);
  }
  reloadPage() {
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/dashboard/project-modelling`), { skipLocationChange: true }).then(() =>
      this.router.navigate([RouteHelper.addProjectPrefix(`/project-modelling/1`)]));
    // this.activeModal.dismiss('close');

  }
  saveAndUpdate(values) {
    // values.data.longitude = values.data.longitude;
    // values.data.latitude = values.data.latitude;
    const commentData = {
      'projectId': this.projectId,
      'regionId': this.regionId,
      'custId': this.custId,
      'eqptMfgId': '1f111df2-21a5-5fda-8dd2-7439d45cee01',
      'longitude': values.data.longitude,
      'latitude': values.data.latitude,
      'altitude': this.altitude,

    };
    if (values.nodeType === 'external source' || values.nodeType === 'Source') {
      const formData = {
        'nodeType': 'external source',
        'externalSourceId': this.isEdit ? '00000000-0000-0000-0000-000000000000' : values.id,
        'sourceReference': values.data.sourceReference,
        'substationReference': values.data.substationReference,
        'typeOfSource': values.data.typeOfSource !== 'Others' ? values.data.typeOfSource : values.data.typeOfSourceEtc,
        'ratedPower': values.data.ratedPower,
        ...commentData,
        //'altitude': 0
      };
      this.loading = true;
      this.dataService.addAndUpdateSource(formData).subscribe({
        next: resp => {
          // this.reloadPage();
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'External source created successfully!';
          modalRef.componentInstance.isSubstation = true;
          this.loading = false;
        }, error: () => {
          this.reloadPage();
          this.loading = false;
        }
      });
    }
    if (values.nodeType === 'external load' || values.nodeType === 'Load') {
      const formData = {
        'nodeType': 'external load',
        'externalLoadId': this.isEdit ? '00000000-0000-0000-0000-000000000000 ' : values.id,
        // 'externalLoadId': '00000000-0000-0000-0000-000000000000',
        'loadReference': values.data.loadReference,
        'substationReference': values.data.substationReference,
        'typeOfLoad': values.data.typeOfLoad !== 'Others' ? values.data.typeOfLoad : values.data.typeOfLoadEtc,
        'ratedPower': values.data.ratedPower,
        'ratedPowerFactor': values.data.ratedPowerFactor,
        ...commentData,
        'altitude': values.data.altitude,

      };
      this.loading = true;
      this.dataService.addAndUpdateLoad(formData).subscribe({
        next: resp => {
          // this.reloadPage();
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'External load created successfully!';
          modalRef.componentInstance.isSubstation = true;
          this.loading = false;
        }, error: () => {
          this.reloadPage();
          this.loading = false;
        }
      });
    }
    if (values.nodeType === 'joint' || values.nodeType === 'Joint') {
      const formData = {
        'nodeType': 'joint',
        'externalJointId': this.isEdit ? '00000000-0000-0000-0000-000000000000 ' : values.id,
        'nodeReference': values.data.nodeReference,
        'locationReference': values.data.locationReference,
        ...commentData,
        'altitude': values.data.altitude
      };
      this.loading = true;
      this.dataService.addAndUpdateJoint(formData).subscribe({
        next: resp => {
          // this.reloadPage();
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'Joint created successfully!';
          modalRef.componentInstance.isSubstation = true;
          this.loading = false;
        }, error: () => {
          this.reloadPage();
          this.loading = false;
        }
      });
    }

    if (values.nodeType === 'substation' || values.nodeType === 'Substation') {
      const formData = {
        ...commentData,
        'nodeType': values.nodeType,
        'altitude': values.data.altitude,
        'substationId': this.isEdit ? '00000000-0000-0000-0000-000000000000 ' : values.id,
        'substationName': values.data.name,
        'ssClassId': '00000000-0000-0000-0000-000000000000',
        'ssClassName': values.nodeType,
        'reference': values.data.name,
        'installationOfAssets': values.data.type,
        'owner': values.data.owner,
        'substationOperator': values.data.administer,
        'constructionArea': values.data.constructionArea,
        'totalArea': values.data.totalArea,
        'freeSpace': values.data.freeSpace,
        'controlRooms': values.data.controlRooms,
        'switchgearType': values.data.switchgear,
        'installationYear': values.data.installationYear,
        'commisioningYear': values.data.commisioningYear,
      };
      this.loading = true;
      this.dataService.addAndUpdateSubstation(formData).subscribe({
        next: (resp) => {
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'Substation created successfully!';
          if (values.type === 'sld') {
            // this.router.navigate(['/sld-editor']);
            if (resp.returnObj) {
              const substationId = resp.returnObj.substationId;
              this.goToSLD(undefined, substationId);
            }
          } else {
            modalRef.componentInstance.isSubstation = true;
          }
          this.loading = false;
        }, error: () => {
          this.reloadPage();
          this.loading = false;
        }
      });
    }
  }
  /**
   * Save marker
   * @param values - Marker values
   */
  saveMarker(values) {
    this.saveAndUpdate(values);
    const feature = this.markerLayer.getSource().getFeatureById(values.data.id) as Feature<Geometry>;
      feature.setProperties(values.data);
    this.selectedNodeType = null;
    this.editSubStation = false;
    this.mouseLayer.getSource().clear();
  }

  /**
   * Delete marker
   * @param id - id
   */
  deleteMarker(data) {
    // deleteSubstationById
    if (data === undefined) {
      this.selectedNodeType = null;
      this.mouseLayer.getSource().clear();
      var features = this.markerLayer.getSource().getFeatures();
      features.forEach((feature) => {
        if (feature.get('values_').longitude === this.longitude && feature.get('values_').latitude === this.latitude) {
          this.markerLayer.getSource().removeFeature(feature);
        }
      });
    }
    else {
      const id = data.id;
      const formdata = {
        'sourceType': data.nodeType,
        'sourceId': id,
      };
      if (data.id) {
        // debugger
        const modalRef = this.modalService.open(DeleteAlertModalComponent, { centered: true });
        modalRef.componentInstance.deleteItemName = data.nodeType.toLowerCase();
        modalRef.result.then(ret => {
          if (ret === 'delete') {
            this.loading = true;
            this.dataService.deleteSubstationInformation(formdata).subscribe({
              next: (resp) => {
                const modalRef1 = this.modalService.open(SuccesspopupComponent, { centered: true });
                modalRef1.componentInstance.title = 'Message';
                modalRef1.componentInstance.message = resp.errorMessage;
                modalRef1.componentInstance.isSubstation = true;
                this.loading = false;
                // this.dataService.RefreshUnplannedAssetMaterializedView()
                //   .subscribe(resp=>{});
                const $that = this;
                const feature = this.markerLayer.getSource().getFeatureById(id) as Feature<Geometry>;
                  this.markerLayer.getSource().removeFeature(feature);
                this.connectionLayer.getSource().getFeatures().forEach(function (f) {
                  if (f.getId().toString().indexOf(id) !== -1) {
                    $that.connectionLayer.getSource().removeFeature(f);
                  }
                });
                this.selectedNodeType = null;
                this.editSubStation = false;
                this.componentDataDialog = {
                  show: false
                };
                this.mouseLayer.getSource().clear();

              }, error: () => {
                this.loading = false;
                this.reloadPage();
              }
            });
          }
          // else if (ret === 'cancel') {
          //   this.reloadPage();
          // }
        });
      } else {
        this.reloadPage();
      }
    }
  }

  /**
   * Close popup
   */
  closePopup() {
    this.editSubStation = false;
  }

  /**
   * Get all makers
   * @returns - all makers
   */
  getMarkers() {
    const subStations = [];
    const requiredFields = ['name', 'region', 'type'];
    const $that = this;
    this.markerLayer.getSource().getFeatures().forEach(function (feature) {
      if (feature.get('nodeType') === NodeType.SubStation) {
        let filled = true;
        each(requiredFields, (field) => {
          if (feature.get(field) === undefined || feature.get(field) === '') {
            filled = false;
          }
        });
        subStations.push({
          id: feature.getId(),
          filled: filled,
          risk: $that.router.isActive(RouteHelper.addProjectPrefix(`/project-modelling/1`), true) ? null : feature.get('risk'),
          name: $that.router.isActive((`/operationscenter`), false)? feature.get('substationName'): feature.get('name'),
          region: feature.get('country'),
          visible: ($that.legendQuery === '' || feature.get('name').toLowerCase().indexOf($that.legendQuery.toLowerCase()) !== -1)
        });
      }
    });
    let items = _.orderBy(subStations, [subStation => subStation.name?.toLowerCase()], ['desc']);
    if (!this.sortDirectionSubstation) {
      items = _.reverse(items);
    }
    this.markerList = _.cloneDeep(items);
  }

  sortSubstation(field) {
    if (this.sortBySubstation === field) {
      this.sortDirectionSubstation = !this.sortDirectionSubstation;
    } else {
      this.sortDirectionSubstation = true;
    }
    this.sortBySubstation = field;
    this.getMarkers();
  }

  isSubStainsNotAvailable() {
    return (this.markerLayer.getSource().getFeatures().length === 0);
  }

  isPossibleToConnect() {
    return this.markerLayer.getSource().getFeatures().length > 1;
  }

  reDrawConnections(node) {
    const that = this;
    const id = node.getId();
    this.connectionLayer.getSource().getFeatures().forEach(function (feature) {
      if (feature.get('origin') === id) {
        that.connectionLayer.getSource().removeFeature(feature);
        that.addNewConnection(node, that.markerLayer.getSource().getFeatureById(feature.get('destination')), feature.getId(), false);
      } else if (feature.get('destination') === id) {
        that.connectionLayer.getSource().removeFeature(feature);
        that.addNewConnection(that.markerLayer.getSource().getFeatureById(feature.get('origin')), node, feature.getId(), false);
      }
    });
  }

  /**
   * Get all connections
   * @param connection - feature
   * @returns - connections
   */
  getConnections(connection: any) {
    const connections = [];
    const $that = this;
    let ori: any;
    let dest: any;
    this.connectionLayer.getSource().getFeatures().forEach(function (feature) {
      const properties = feature.getProperties();
      const originId = feature.get('origin');
      const destinationId = feature.get('destination');

      if (properties.assetId != undefined) {
        ori = $that.markerLayer.getSource().getFeatureById(originId);
        dest = $that.markerLayer.getSource().getFeatureById(destinationId);
      } else {
        dest = $that.markerLayer.getSource().getFeatureById(originId);
        ori = $that.markerLayer.getSource().getFeatureById(destinationId);
      }

      if ((connection.get('origin') === originId && connection.get('destination') === destinationId)
        || (connection.get('destination') === originId && connection.get('origin') === destinationId)) {
        connections.push({
          id: feature.getId(),
          assetId: properties.assetId,
          reference: properties.reference,
          source: properties.source,
          name: properties.name,
          isSubStation: dest.get('nodeType') === NodeType.SubStation || ori.get('nodeType') === NodeType.SubStation,
          canExchange: (dest.get('nodeType') === NodeType.SubStation && ori.get('nodeType') === NodeType.SubStation) ||
            (dest.get('nodeType') === NodeType.Joint && ori.get('nodeType') === NodeType.SubStation) ||
            (ori.get('nodeType') === NodeType.Joint && dest.get('nodeType') === NodeType.SubStation),
          origin: ori ? (ori.get('name') || ori.get('sourceReference') || ori.get('loadReference') || ori.get('nodeReference')) : '',
          destination: dest ? (dest.get('name') || dest.get('sourceReference') || dest.get('loadReference')
            || dest.get('nodeReference')) : ''
        });
      }
      // connections = _.uniqBy(connections, 'reference');
      // connections = _.uniqBy(connections, 'substationSourceId');
    });
    this.totalConnections = connections.length;
    return connections;
  }
  getConnectionList() {
    const connections = [];
    const $that = this;
    this.connectionLayer.getSource().getFeatures().forEach(function (feature) {
      const properties = feature.getProperties();
      const originId = feature.get('origin');
      const destinationId = feature.get('destination');
      const dest = $that.markerLayer.getSource().getFeatureById(destinationId);
      const ori = $that.markerLayer.getSource().getFeatureById(originId);
      const isBothFeatures = ori instanceof Feature && dest instanceof Feature;
      if (isBothFeatures) {
      connections.push({
        id: feature.getId(),
        feature: feature,
        reference: properties.reference,
        source: properties.source,
        name: properties.name,
        dest: dest.getId(),
        ori: ori.getId(),
        isSubStation: dest.get('nodeType') === NodeType.SubStation || ori.get('nodeType') === NodeType.SubStation,
        canExchange: dest.get('nodeType') === NodeType.SubStation && ori.get('nodeType') === NodeType.SubStation,
        origin: ori ? (ori.get('name') || ori.get('sourceReference') || ori.get('loadReference') || ori.get('nodeReference')) : '',
        destination: dest ? (dest.get('name') || dest.get('sourceReference') || dest.get('loadReference')
          || dest.get('nodeReference')) : ''
      });
     }
    });
    const list = [];
    const groups = groupBy(connections, 'name');
    each(groups, (i, name) => {
      list.push({
        name,
        line: i[0].feature,
        items: i
      });
    });
    let items = cloneDeep(list);
    items = sortBy(items, this.sortByConnection);
    if (!this.sortDirectionConnection) {
      items = reverse(items);
    }
    this.connectionList = cloneDeep(items);
  }

  showConnections(e, line) {
    e.stopPropagation();
    this.addConnection(line, false);
  }

  sortConnection(field) {
    if (this.sortByConnection === field) {
      this.sortDirectionConnection = !this.sortDirectionConnection;
    } else {
      this.sortDirectionConnection = true;
    }
    this.sortByConnection = field;
    this.getConnectionList();
  }

  importConnection() {
    this.inputConnectionCVSFileRef.nativeElement.click();
  }

  arrayToObject(header, row) {
    const obj = {};
    each(header, (head, i) => {
      if (!(head === 'type' || head === 'geometry')) {
        obj[head] = row[i];
      }
    });
    return obj;
  }

  onConnectionCVSSelected(event) {
    const file = event.target.files[0];
    if (!file) { return; }
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && this.projectId) {
      this.inputExcelFile = null;
      const formData = new FormData();
      formData.append("files", file);
      formData.append('projectId', this.projectId);
      this.loading = true;
      this.dataService.importExcelFile(formData).subscribe({
        next: () => {
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
          modalRef.componentInstance.message = 'Substation Connections successfully imported';
          this.reloadPage();
          this.loading = false;
        }, error: () => {
          // const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          //   modalRef.componentInstance.message = 'Error importing Substation Connections';
          this.loading = false;
          this.reloadPage();

        }
      });
    } else {
      const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
      modalRef.componentInstance.title = 'Warning';
      modalRef.componentInstance.message = 'Uploaded File is not valid';
      this.reloadPage();
      this.loading = false;
    }
  }

  exportMarkers() {
    const markers = [];
    let header = [];
    this.markerLayer.getSource().getFeatures().forEach(function (feature : Feature<Point>) {
      const properties = feature.getProperties();
      const geometry = feature.getGeometry();
      const obj = {
        id: feature.getId(),
        ...properties,
        lng: geometry.getFlatCoordinates[0],
        lat: geometry.getFlatCoordinates[1],
      };
      const values = ['MARKER', ...Object.values(obj)];
      header = ['type', ...Object.keys(obj)];
      markers.push(header.join(','));
      markers.push(values.join(','));
    });
    return markers;
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type: type.toString() });
    this._FileSaverService.save(blob, 'Substation Connections.xlsx');
    // let url = window.URL.createObjectURL(blob);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     alert('Please disable your Pop-up blocker and try again.');
    // }
  }
  exportConnection() {

    let projectId = this.projectId
    if (projectId != null) {
      this.loading = true;
      this.dataService.exportExcel(projectId).subscribe({
        next: (respData) => {
          this.downLoadFile(respData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          this.loading = false;
        }, error: () => {
          this.reloadPage();
          this.loading = false;
        }
      });
    }
  }

  connectionVisibilityController(connections) {
    const $that = this;
    let firstConnection;
    connections.forEach(function (connection) {
      if (!connection.removed) {
        const feature = $that.connectionLayer.getSource().getFeatureById(connection.id);
        if (feature instanceof Feature) {
          if (!firstConnection) {
            if (connection.source === 'Load') {
              feature.setStyle($that.connectionLineStyle);
            } else {
              feature.setStyle($that.connectionLineDarkStyle);
            }
            firstConnection = true;
          } else {
            feature.setStyle($that.hiddenConnectionLineStyle);
          }
        }
      }
    });
  }

  /**
   * Save connection
   * @param connections - connections
   */
  saveConnection(connections) {
    const $that = this;
    const formData = [];
    connections.forEach(function (connection) {
      if (connection.removed) {
        const feature = $that.connectionLayer.getSource().getFeatureById(connection.id);
        if (feature instanceof Feature) {
          $that.connectionLayer.getSource().removeFeature(feature);
        }
      } else if (connection.new) {
        const feature = $that.connectionLayer.getSource().getFeatureById(connection.connectionId);
        if (feature instanceof Feature) {
        const originId = feature.get('origin');
        const destinationId = feature.get('destination');
        const origin = $that.markerLayer.getSource().getFeatureById(originId);
        const destination = $that.markerLayer.getSource().getFeatureById(destinationId);
        $that.addNewConnection(origin, destination, connection.id, false, {
          name: connection.name,
        });
       }
      } else {
        const feature = $that.connectionLayer.getSource().getFeatureById(connection.id);
        if (feature instanceof Feature) {
          feature.setProperties({ source: connection.source });
          feature.setProperties({ name: connection.name });
          if (connection.swap) {
            const origin = feature.get('origin');
            const destination = feature.get('destination');
            feature.setProperties({ origin: destination });
            feature.setProperties({ destination: origin });
          }
          feature.setProperties({ source: connection.source });
          if (connection.source === 'Load') {
            feature.setStyle($that.connectionLineStyle);
          } else {
            feature.setStyle($that.connectionLineDarkStyle);
          }
        } 
      }

    });
    connections.forEach(connection => {
      formData.push(
        {
          'substationConnName': connection.name,
          'substationConnId': connection.id,
          'sldRefNo': connection.reference,
          'substationSourceId':
            _.find($that.connectionMapData, function (o) {
              return (o.props.name || o.props.sourceReference
                || o.props.loadReference || o.props.nodeReference) === connection.origin;
            }).props.id,
          'substationTargetId':
            _.find($that.connectionMapData, function (o) {
              return (o.props.name || o.props.sourceReference
                || o.props.loadReference || o.props.nodeReference) === connection.destination;
            }).props.id,
        }
      );
    });
    this.loading = true;
    this.dataService.createSubstationConnect(formData).subscribe({
      next: resp => {
        this.reloadPage();
        // const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
        // modalRef.componentInstance.message = 'Connection created successfully!';
        // modalRef.componentInstance.isSubstation = true;
        this.loading = false;
      }, error: () => {
        this.reloadPage();
        this.loading = false;
      }
    });
    this.connectionVisibilityController(connections);
    this.getConnectionList();
  }

  /**
   * Search markers
   * @param value - search text
   */
  search(value) {
    let feature = null;
    this.markerLayer.getSource().getFeatures().forEach(function (f) {
      const props = f.getProperties();
      if ((feature === null && props.nodeType === NodeType.SubStation
        && props.name && props.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
        (feature === null && props.nodeType === NodeType.Source
          && props.sourceReference && props.sourceReference.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
        (feature === null && props.nodeType === NodeType.Joint
          && props.nodeReference && props.nodeReference.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
        (feature === null && props.nodeType === NodeType.Load
          && props.loadReference && props.loadReference.toLowerCase().indexOf(value.toLowerCase()) !== -1)) {
        feature = f;
      }
    });
    if (feature) {
      this.map.getView().fit(feature.getGeometry(), this.map.getSize());
    }
  }

  /**
   * seletct menu item
   * called when user clicks on one of the buttons representing a section of the menu-bar
   * @param item: the menu-item selected
   */
  public toggleMenuItem(item: any) {
    // if already selected, then de-select
    if (this.selectedMenuItem === item) {
      return this.selectedMenuItem = null;
    }
    // iterate through all sub-menus and mark them as enabled or activated etc.
    item.subItems.forEach(subItem => {
      if (subItem.isActivated) {
        subItem.activated = subItem.isActivated();
      }
    });
    this.selectedMenuItem = item;
  }

  /**
   * seletct menu sub-item
   * usually, an action is performed on sub-menu-button click
   * @param subItem clicked
   */
  public onClickMenuSubItem(subItem: any) {
    if (subItem.fn) {
      subItem.fn();
    }
    this.selectedMenuItem = null;
  }

  onMapBackgroundImageUpload(event) {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt: any) => {
      this.imageSelected = true;
      const geoJSON = new GeoJSON();
     // this.vectorSource.addFeatures(geoJSON.readFeatures(JSON.parse(evt.target.result)));
      this.vectorSource.addFeatures(geoJSON.readFeatures(JSON.parse(evt.target.result)) as Feature<Geometry>[]);
    };
  }

  getParameters() {
    let load = 0;
    let source = 0;
    this.markerLayer.getSource().getFeatures().forEach(function (f) {
      const props = f.getProperties();
      if (props.nodeType === NodeType.Load) {
        load++;
      } else if (props.nodeType === NodeType.Source) {
        source++;
      }
    });
    return { load, source };
  }

  isSLDNavigationDisabled() {
    let substation = 0;
    this.markerLayer.getSource().getFeatures().forEach(function (f) {
      const props = f.getProperties();
      if (props.nodeType === NodeType.SubStation) {
        substation++;
      }
    });
    return substation === 0;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.selectedNodeType = null;
    this.mouseLayer.getSource().clear();
  }

  onDragStart(evt, nodeType) {
    if (evt.preventDefault) {
      evt.preventDefault();
    }
    // this.dragStart = true;
    this.addMarkerEvent(nodeType);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    if (this.selectedNodeType !== null) {
      if (evt.preventDefault) {
        evt.preventDefault();
      }
      const mapCoordinate = this.map.getEventCoordinate(evt);
      const args = {
        coordinate: mapCoordinate
      };
      this.onClickMap(args, false);
      // this.dragStart = false;
      this.selectedNodeType = null;
      this.mouseLayer.getSource().clear();
    }
  }

  substationFiltered($event: { disableFilter: boolean, filtered: Array<any> }) {
    if (!$event.disableFilter) {
      $event.filtered.forEach(el => this.substationHover(el.id));
    } else {
      $event.filtered.forEach(el => this.substationHover(null));
    }
  }

  hoverOnSubstation(id) {
    if (this.substationHover) {
      this.substationHover(id);
    }
  }

  substationHover(id) {
    let feature = null;
    if (id) {
      feature = this.markerLayer.getSource().getFeatureById(id);
    }
    if (feature) {
      const geometry = feature.getGeometry();
      const coordinate = geometry.getCoordinates();
      const pixel = this.map.getPixelFromCoordinate(coordinate);
      this.markerHoverEvent({ pixel, coordinate }, feature,null);
    } else {
      this.markerHoverEvent({ pixel: null, coordinate: {} }, null,null);
    }
  }

  InstallationHover(installationIds) {
    let feature = null;
    if (installationIds) {
      if (!Array.isArray(installationIds)) {
        installationIds = [installationIds];
      }
      if (installationIds.length === 1) {
        this.hoverOverMarker = null;
      } else {
        this.hoverOverMarker = [];
      }
      installationIds.forEach(element => {
        const filtered = this.mapData.markers.filter(item => {
          return item.substationId === element;
        });
        feature = this.markerLayer.getSource().getFeatureById(element);
        if (feature) {
          feature.setStyle(Map.getNodeIcon(NodeType.SubStation, Interaction.Hover, this.isOperationsCenter? 'blue' : feature.riskColor));
          if (installationIds.length === 1) {
            this.hoverOverMarker = feature;
          } else {
            this.hoverOverMarker.push(feature);
          }
        }
      });
    } else {
      let subs = this.mapData.markers.map((installation) => installation.id || installation.substationId);
      this.hoverOverMarker = null;
      subs.forEach(element => {
        feature = this.markerLayer.getSource().getFeatureById(element);
        if (feature) {
          feature.setStyle(Map.getNodeIcon(NodeType.SubStation, Interaction.Normal, feature.riskColor));
        }
      });
    }
  }
  

  projectHover(projectId) {
    let feature = null;
    let filteredSubstations = this.mapData.markers.filter(item => {
      return item.projectId === projectId;
    }).map((m) => m.substationId);
      if(projectId) { 
        filteredSubstations.forEach(element => {
          feature = this.markerLayer.getSource().getFeatureById(element);
          feature.setStyle(Map.getNodeIcon(NodeType.SubStation, Interaction.Hover, 'blue'));
        });
    } else{
      let subs = this.mapData.markers.map((m) => m.substationId);
      subs.forEach(element => {
        feature = this.markerLayer.getSource().getFeatureById(element);
        feature.setStyle(Map.getNodeIcon(NodeType.SubStation, Interaction.Normal, null));
      })
    }       
  }

  assetHover(asset) {
    if (asset == null) {
      this.hoverOverAsset = [];
    }
    else if (asset && asset.id && asset.id != null) {
      this.hoverOverAsset.push(asset.id);
    }
  }

  tabChange(tab) {
    this.filterData = undefined;
    this.viewTab = tab;
    this.riskvaluesIe = [];
    if (tab==='Installations' || tab==='Projects'){
      this.appSvc.setfilterData([]);
    }
    else if (tab === 'Substations' || tab==="Substation-L") {
      this.mapData.markers.forEach((row: { risk, risk1, chart, name, id, props, type }, ind) => {
        if (row.type === 'substation') {
          this.riskvaluesIe.push(
            {
              id: row.id,
              risk: row.risk,
              region: row.props.region,
              substation: row.id,
              risk1: row.risk1,
              chart: row.chart,
              name: row.props.name,
            }
          );
        }
      });
      this.GetProjectSubstationsRiskForGrid();
    } else {
      this.mapData.riskMatrix.forEach((row: { id, risk, risk1, chart, name, scale, substation, region }, ind) => {
        this.riskvaluesIe.push(
          {
            id: row.id,
            risk: row.risk,
            region: row.region,
            substation: row.substation,
            risk1: row.risk1,
            chart: row.chart,
            name: row.name,
            scale: row.scale || 1,
          }
        );
      });
      this.GetProjectSubstationsRiskForGrid();
    }
    
  }
  sliderRange(direction: string){
    if(direction === 'up'){
      this.currentRange[1] = this.currentRange[1]-4
      if(this.currentRange[0]-4 < this.yearsRange[0]){
        this.currentRange[0] = this.yearsRange[0];
        this.currentRange[1] = this.currentRange[0]+4;
      }
      else{
        this.currentRange[0] = this.currentRange[0]-4;
      }
    }
    else if(direction === 'down'){
      this.currentRange[0] = this.currentRange[0]+4;
      if(this.yearsRange[this.yearsRange.length-1] < this.currentRange[1]+4){
        this.currentRange[1] = this.yearsRange[this.yearsRange.length - 1];
        this.currentRange[0] = this.currentRange[1]-4;
      }
      else{
        this.currentRange[1] = this.currentRange[1]+4;
      }
    }
    this.years = [];
    for (let i = this.currentRange[1]; i >= this.currentRange[1] - 4; i--) {
      this.years.push(i);
    }
    this.min = parseInt(_.cloneDeep(this.years[this.years.length - 1]), 10);
    this.max = this.currentYear = parseInt(_.cloneDeep(this.years[0]), 10);
  }

  GetProjectSubstationsRiskForGrid() {
    this.riskValues = [];
    if (this.viewTab === 'Substations' || this.viewTab==="Substation-L") {
      const projectId = sessionStorage.getItem('projectId');
      // this.loading = true;
      if (projectId != null) {
        this.years = []; 
        this.appSvc.GetProjectSubstationsRiskForGrid(projectId).subscribe({
          next: (data) => {
            this.loading = false;
            this.yearsRange = Object.keys(data.returnObj).map(key => parseInt(key, 10)); 
            if(this.yearsRange.length < 5){
              this.currentRange = [this.yearsRange[0], this.yearsRange[this.yearsRange.length-1]]
            }
            else{
              this.currentRange = [this.yearsRange[this.yearsRange.length -1]-4, this.yearsRange[this.yearsRange.length -1]];
            }
            for (let i = this.currentRange[1]; i >= this.currentRange[0]; i--) {
              this.years.push(i);
            }
            //this.years.reverse();      
            this.min = parseInt(_.cloneDeep(this.years[this.years.length - 1]), 10);
            this.max = this.currentYear = parseInt(_.cloneDeep(this.years[0]), 10);
            this.setSlider();
            this.riskMatrix = data.returnObj;
            data.returnObj[this.currentYear].forEach((row: { substationId, risk, risk1, substation, riskValue, riskColor }, ind) => {
              const riskPush = _.find(this.riskvaluesIe, function (r) { return (r.id === row.substationId); });
              
              if (riskPush) {
                this.riskValues.push({
                    id: riskPush.id,
                    risk: riskPush.risk,
                    region: riskPush.region,
                    substation: riskPush.substation,
                    risk1: riskPush.risk1,
                    chart: riskPush.chart,
                    name: riskPush.name,
                    scale: riskPush.scale || 1,
                    riskValue: row.riskValue,
                    riskColor: row.riskColor
                });
              } else {
                // Handle the case where riskPush is undefined
                console.warn(`No matching risk found for substationId: ${row.substation}`);
              }
            });
          }, error: () => {
            this.loading = false;
          }
        });
      }
    } else {
      this.riskMatrix[this.currentYear].forEach((row: { substationId, risk, risk1, substation }, ind) => {
        const riskPush = _.filter(this.riskvaluesIe, function (r) { return (r.substation === row.substationId); });
        // const riskPush = _.find(this.riskvaluesIe, { 'id': row.substationId, 'risk': row.risk, 'risk1': row.risk1, 'name': row.substation });
        if (riskPush !== undefined) {
          riskPush.forEach(element => {
            this.riskValues.push(
              {
                id: element.id,
                risk: element.risk,
                region: element.region,
                substation: element.substation,
                risk1: element.risk1,
                chart: element.chart,
                name: element.name,
                scale: element.scale || 1,
              }
            )
          });
        }
      });
    }
  }
  sliderChange(event) {
    if (this.viewTab === 'Substations' || this.viewTab === "Substation-L") {
      this.currentYear = event.target.value;
      this.riskValues = [];
      this.riskMatrix[this.currentYear].forEach((row: { substationId, risk, risk1, substation, riskValue, riskColor}, ind) => {
        const riskPush = _.find(this.riskvaluesIe, function (r) { return (r.id === row.substationId); });
        if (riskPush !== undefined) {
          this.riskValues.push(
            {
              id: riskPush.id,
              risk: row.risk,
              region: riskPush.region,
              substation: riskPush.substation,
              risk1: row.risk1,
              chart: riskPush.chart,
              name: riskPush.name,
              scale: riskPush.scale || 1,
              riskValue: row.riskValue,
              riskColor: row.riskColor
            }
          );
        }
      });
    } else {
      this.currentYear = event.target.value;
      this.riskValues = [];
      this.riskMatrix[this.currentYear].forEach((row: { substationId, risk, risk1, substation }, ind) => {
        const riskPush = _.filter(this.riskvaluesIe, function (r) { return (r.substation === row.substationId); });
        if (riskPush !== undefined) {
          riskPush.forEach(element => {
            this.riskValues.push(
              {
                id: element.id,
                risk: element.risk,
                region: element.region,
                substation: element.substation,
                risk1: element.risk1,
                chart: element.chart,
                name: element.name,
                scale: element.scale || 1,
              }
            )
          });
        }
      });
    }
  }
  filterItemPassEmit(event) {
    this.filterData = event;
  }
  setSlider() {
    if (this.years.length > 0) {
      let height=(this.isMatrixExpanded?304:198);
      this.tickHeight = (height+(height/this.years.length))/this.years.length
    }
    else {
      this.tickHeight = null;
    }
  }
  toggleHandler(event){
    this.isTableVisible=event.isTableVisible;
    this.isRiskMatrixVisible=event.isRiskMatrixVisible;
    if(this.isRiskMatrixVisible){
      this.setSlider();
    }
  }
  hasPermissionToEdit() {
    return this.rolesService.hasPermissionToEdit();
  }

  updateMarkerClickOptions(): void {
    if (this.rolesService.hasAnyRoles(this.rolesService.maintenanceRoles)) {
      this.markerClickOptions.push('Maintenance');
    }
    if (this.rolesService.hasAnyRoles(this.rolesService.operationsRoles)) {
      this.markerClickOptions.push('Operations');
    }
  }

  onMarkerMenuSelect(option) {
    switch (option) {
      case "View":
        sessionStorage.setItem('substationId', this.selectedMarker.id_);
        this.router.navigate([RouteHelper.addProjectPrefix(`/sld-editor/true`)], { queryParams: { isFromHomePage: true }});
        break;

      case "Maintenance":
        this.router.navigate([RouteHelper.addProjectPrefix(`/maintenance-management`)], {
          queryParams: { defaultInstallation: this.selectedMarker.getProperties().id, activeTab: 'events' }
        });
        break;

      case "Operations":
        this.router.navigate([RouteHelper.addProjectPrefix(`/operations/create`)],
          { queryParams: { substationId: this.selectedMarker.getProperties().id } })
        break;
    }
  }
}

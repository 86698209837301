import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleIconsComponent } from './toggle-icons.component';



@NgModule({
  declarations: [ToggleIconsComponent],
  imports: [
    CommonModule
  ],
  exports:[ToggleIconsComponent]
})
export class ToggleIconsModule { }

<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>{{title}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
    </button>
  </div>
  <form>
    <div class="modal-body">
      <div class="about-container" [class.clear-bottom]="!this.isAboutPanelOpen">
        <div class="row">
          <div class="category" (click)="clickAboutPanel()">
            <span>Notify me about</span>
            <i class="top-arrow" aria-hidden="true" [class.about-close]="!this.isAboutPanelOpen"></i>
          </div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="title">Tasks</div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;" class="option">
              <span>New tasks (assigned to me)</span>
              <label for="enableNewTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableNewTasks')">
                <input id="enableNewTasks" type="checkbox" [checked]="getStatus('tasks', 'enableNewTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableNewTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;" [ngClass]="{'checked': getStatus('tasks', 'enableNewTasks')}"></i>
              </label>
            </div>
          }
          @if (!isSystemRole()) {
            <div class="option">
              <span>New tasks (assigned to me)</span>
              <label for="enableNewTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableNewTasks')">
                <input id="enableNewTasks" type="checkbox" [checked]="getStatus('tasks', 'enableNewTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableNewTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('tasks', 'enableNewTasks')}"></i>
              </label>
            </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;" class="option">
              <span>Overdue tasks</span>
              <label for="enableOverdueTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableOverdueTasks')">
                <input id="enableOverdueTasks" type="checkbox" [checked]="getStatus('tasks', 'enableOverdueTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableOverdueTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;" [ngClass]="{'checked': getStatus('tasks', 'enableOverdueTasks')}"></i>
              </label>
            </div>
          }
          @if (isSystemRole()) {
            <div class="option">
              <span>Overdue tasks</span>
              <label for="enableOverdueTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableOverdueTasks')">
                <input id="enableOverdueTasks" type="checkbox" [checked]="getStatus('tasks', 'enableOverdueTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableOverdueTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('tasks', 'enableOverdueTasks')}"></i>
              </label>
            </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;" class="option">
              <span>Rejected tasks</span>
              <label for="enableRejectedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableRejectedTasks')">
                <input id="enableRejectedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableRejectedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableRejectedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;" [ngClass]="{'checked': getStatus('tasks', 'enableRejectedTasks')}"></i>
              </label>
            </div>
          }
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;" class="option">
              <span>Rejected tasks</span>
              <label for="enableRejectedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableRejectedTasks')">
                <input id="enableRejectedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableRejectedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableRejectedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"  [ngClass]="{'checked': getStatus('tasks', 'enableRejectedTasks')}"></i>
              </label>
            </div>
          }
          @if (!isSystemRole()) {
            <div class="option">
              <span>Rejected tasks</span>
              <label for="enableRejectedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableRejectedTasks')">
                <input id="enableRejectedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableRejectedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableRejectedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('tasks', 'enableRejectedTasks')}"></i>
              </label>
            </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;" class="option no-bottom">
              <span>Validated tasks</span>
              <label for="enableValidatedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableValidatedTasks')">
                <input id="enableValidatedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableValidatedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableValidatedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;" [ngClass]="{'checked': getStatus('tasks', 'enableValidatedTasks')}"></i>
              </label>
            </div>
          }
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;"  class="option no-bottom">
              <span>Validated tasks</span>
              <label for="enableValidatedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableValidatedTasks')">
                <input id="enableValidatedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableValidatedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableValidatedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;" [ngClass]="{'checked': getStatus('tasks', 'enableValidatedTasks')}"></i>
              </label>
            </div>
          }
          @if (!isSystemRole()) {
            <div class="option no-bottom">
              <span>Validated tasks</span>
              <label for="enableValidatedTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableValidatedTasks')">
                <input id="enableValidatedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableValidatedTasks')"
                  (change)="onChangeChecked($event, 'tasks', 'enableValidatedTasks')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('tasks', 'enableValidatedTasks')}"></i>
              </label>
            </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="title">Performance</div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;"  class="option">
              <span>Substation risk levels</span>
              <label for="enableSubstationRiskLevels" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableSubstationRiskLevels')">
                <input id="enableSubstationRiskLevels" type="checkbox" [checked]="getStatus('performance', 'enableSubstationRiskLevels')"
                  (change)="onChangeChecked($event, 'performance', 'enableSubstationRiskLevels')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"  [ngClass]="{'checked': getStatus('performance', 'enableSubstationRiskLevels')}"></i>
              </label>
            </div>
          }
          @if (!isSystemRole()) {
            <div class="option">
              <span>Substation risk levels</span>
              <label for="enableSubstationRiskLevels" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableSubstationRiskLevels')">
                <input id="enableSubstationRiskLevels" type="checkbox" [checked]="getStatus('performance', 'enableSubstationRiskLevels')"
                  (change)="onChangeChecked($event, 'performance', 'enableSubstationRiskLevels')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('performance', 'enableSubstationRiskLevels')}"></i>
              </label>
            </div>
          }

        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
            <div style="cursor: not-allowed;pointer-events: none;"   class="option bottom-16">
              <span>Asset risk levels</span>
              <label for="enableAssetRiskLevels" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableAssetRiskLevels')">
                <input id="enableAssetRiskLevels" type="checkbox" [checked]="getStatus('performance', 'enableAssetRiskLevels')"
                  (change)="onChangeChecked($event, 'performance', 'enableAssetRiskLevels')">
                <i class="icon icon-switchbox" aria-hidden="true" style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"  [ngClass]="{'checked': getStatus('performance', 'enableAssetRiskLevels')}"></i>
              </label>
            </div>
          }
          @if (!isSystemRole()) {
            <div class="option bottom-16">
              <span>Asset risk levels</span>
              <label for="enableAssetRiskLevels" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableAssetRiskLevels')">
                <input id="enableAssetRiskLevels" type="checkbox" [checked]="getStatus('performance', 'enableAssetRiskLevels')"
                  (change)="onChangeChecked($event, 'performance', 'enableAssetRiskLevels')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('performance', 'enableAssetRiskLevels')}"></i>
              </label>
            </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="option bottom-14">
            <span>Account updates</span>
            <label for="enableAccountUpdates" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableAccountUpdates')">
              <input id="enableAccountUpdates" type="checkbox" [checked]="getStatus('performance', 'enableAccountUpdates')"
                (change)="onChangeChecked($event, 'performance', 'enableAccountUpdates')">
              <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('performance', 'enableAccountUpdates')}"></i>
            </label>
          </div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="option">
            <span>System events</span>
            <label for="enableSystemEvents" class="checkbox-switch" [class.checked]="getStatus('performance', 'enableSystemEvents')">
              <input id="enableSystemEvents" type="checkbox" [checked]="getStatus('performance', 'enableSystemEvents')"
                (change)="onChangeChecked($event, 'performance', 'enableSystemEvents')">
              <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('performance', 'enableSystemEvents')}"></i>
            </label>
          </div>
        </div>
      </div>
      <div class="through-container" [class.clear-bottom]="!this.isThroughPanelOpen">
        <div class="row">
          <div class="category" (click)="clickThroughPanel()">
            <span>Notify me through</span>
            <i class="top-arrow" aria-hidden="true" [class.through-close]="!this.isThroughPanelOpen"></i>
          </div>
        </div>
        <div class="row" [class.hide]="!isThroughPanelOpen">
          <div class="option email">
            <div class="email-check">
              <span>Email</span>
              <label for="enableEmail" class="checkbox-switch" [class.checked]="getStatus('email', 'enable')">
                <input id="enableEmail" type="checkbox" [checked]="getStatus('email', 'enables')"
                  (change)="onChangeChecked($event, 'email', 'enable')">
                <i class="icon icon-switchbox" aria-hidden="true" [ngClass]="{'checked': getStatus('email', 'enable')}"></i>
              </label>
            </div>
            <span class="sub-title" [class.active]="data.email.enable">Send me email notifications:</span>
            <div class="email-switch" [class.active]="data.email.enable" (click)="clickedInside($event)">
              <div class="selected">
                @if (this.data.email.option !== null) {
                  <p>{{this.data.email.option}}</p>
                }
                @if (this.data.email.option === null) {
                  <p>Once an hour</p>
                }
                <i class="icon icon-dropdown" aria-hidden="true" [class.up]="emailPopup"></i>
              </div>
              @if (emailPopup) {
                <div class="email-popup">
                  @for (item of emailOptions; track item) {
                    <a href="javascript:;" (click)="changeEmailOption(item)"
                      [class.hidden]="this.data.email.option === item">
                      {{item}}
                    </a>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" i18n (click)="onSubmit()">Save</button>
    </div>
  </form>
</div>

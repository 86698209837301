export class Settings {
    managesystemApiURL: string;
    manageProjectApiURL: string;
    managePlanApiURL: string;
    SLDApiURL: string;
    exaURL: string;
    loadDataApiURL:string;
    performanceModelPropertyApiURL:string;
    performanceModelSubsystemApiURL:string;
    KeycloakAuthority: string;
    KeycloakClient: string;
    KeycloakSecret: string;
    identiqApiURL: string;
    enableIdentiqConnection: string;
    reportEnabled: string;
    CommonAPIUrl: string;
    tenantAdminAPIUrl:string;
    domain:string;
    IsFromSettings:string;
    auditApiURL : string;
    mapboxURL: string;
    operationCenter:string;
}

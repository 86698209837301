import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { Observable, of, Subject } from 'rxjs';
import { MainHeaderService } from '../components/main-header/main-header.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { IndexDBService } from 'src/services/indexdb.service';

@Injectable({
  providedIn: 'root',
})
export class AppAuthGuard extends KeycloakAuthGuard {

  constructor(protected readonly router: Router,
              protected readonly keycloak: KeycloakService,    private mainHeaderService: MainHeaderService,
              protected appSvc: ApplicationService, private indexDBService: IndexDBService) {
    super(router, keycloak);
  }

  public showLoader$ = new Subject<boolean>();
  getDecodedAccessToken(token: string): any {
    try {
        return jwtDecode(token);
    }  catch (Error) {
        return null;
    }
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.showLoader$.next(true);

    // if user not logged in through keycloak
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    // if session storage doesn't have user profile details
    if (!sessionStorage.getItem('UserRoles') && !state.url.includes('/login')) {
      let tokenInfo;

      try {
        // get access token from keycloak
        await this.keycloak.getToken().then(token => {
          sessionStorage.setItem('AccessToken', 'Bearer ' + token);
          tokenInfo = this.getDecodedAccessToken(token);
        });

        // check the projectname in route
        // (user profile is dependent on what project they are viewing)
        if (!route.params.projectName) {
          sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
          sessionStorage.removeItem('projectName');
        } else {
          await this.appSvc
            .getProjectIdFromProjectName(route.params.projectName)
            .pipe(tap(projectId => {
              sessionStorage.setItem('projectName', route.params.projectName?.replace(/\s/g, "").toLowerCase());
              sessionStorage.setItem('projectId', projectId);
            })).toPromise();
        }

        // set the session storage variables with user profile for this project
        await this.appSvc.loginUserKeycloak()
          .pipe(mergeMap(res => {
            if (route.params.projectName || res.isExternalProject) {
              return this.appSvc.GetDefaultProjectId().pipe(tap(projResp => {
                if (projResp) {
                  sessionStorage.setItem('projectId', projResp.item2.projectid);
                  sessionStorage.setItem('diagramSymbols', projResp.item2.projdiagsymbolname.toLowerCase());
                }
              })).pipe(map(() => res));
            } else {
              return of(res);
            }
          }))
          .pipe(tap((res: any) => {
            sessionStorage.setItem('isFromLogin', 'true');
            sessionStorage.setItem('previleges', JSON.stringify(res));
            sessionStorage.setItem('isUserLogin', 'true');
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('Email', tokenInfo && tokenInfo.email);
            sessionStorage.setItem('UserName', res.userfullName);
            sessionStorage.setItem('UserImage', res.userPhoto);
            sessionStorage.setItem('UserRoles', res.roles);
            sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
            sessionStorage.setItem('isExternalProject', res.isExternalProject || false);
              let projectRoles = res.roles;
              const projectName = sessionStorage.getItem('projectName');
              const projectNameRoutePrefix = projectName ? `/${projectName}` : '';
              this.mainHeaderService.onRolesUpdated();
            if (['Quality SME', 'Execution Team Lead', 'Execution Owner', 'Project Manager', 'Project Support',
             'Project Support', 'Project Manager (C)', 'Policy SME', 'Maintenance Manager', 'Operation SME',
             'Remote Operator'].includes(projectRoles[0])) {
              this.router.navigateByUrl(`${projectNameRoutePrefix}/homepage`);
            } else {
                this.router.navigateByUrl('/operationscenter');
            }
            //const userRolesObject = this.appSvc.getUserPrivilages();
            // this.loading = true; // hide the spinner if success
          })).toPromise();


      } catch(error) {
        this.showLoader$.next(false);
        return false;
      }

      location.reload();

    } else if(state.url.includes('/login?order=popular') || state.url.includes('/operations/scenario-execution')){

    } else if (!RouteHelper.isExternalProject()) {
      // if session storage already has user profile deetails
      // we still need to check if they are the correct ones for the project url the user is trying to access
      if (!route.params.projectName) {
        // if there's no project name in the url, we simply set the project id and name to empy
        sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
        sessionStorage.removeItem('projectName');

      } else {
        // if project name in storage doesn't match that mentioned in route,
        // we have to get the projectid of the project mentioned in route,
        // and then re-fetch the user profile for that project
        if (sessionStorage.getItem('projectName') !== route.params.projectName
          || sessionStorage.getItem('projectId') === '00000000-0000-0000-0000-000000000000'
          || !sessionStorage.getItem('projectId')) {

            await this.appSvc
              .getProjectIdFromProjectName(route.params.projectName)
              .pipe(tap(projectId => {
                sessionStorage.setItem('projectName', route.params.projectName?.replace(/\s/g, "").toLowerCase());
                sessionStorage.setItem('projectId', projectId);
              })).toPromise();

            let tokenInfo;
            await this.keycloak.getToken().then(token => {
              tokenInfo = this.getDecodedAccessToken(token);
            });

            await this.appSvc.loginUserKeycloak().pipe(tap(
              (res: any) => {
                sessionStorage.setItem('isFromLogin', 'true');
                sessionStorage.setItem('previleges', JSON.stringify(res));
                sessionStorage.setItem('isUserLogin', 'true');
                sessionStorage.setItem('UserId', res.userId);
                sessionStorage.setItem('Email', tokenInfo && tokenInfo.email);
                sessionStorage.setItem('UserName', res.userfullName);
                sessionStorage.setItem('UserImage', res.userPhoto);
                sessionStorage.setItem('UserRoles', res.roles);
                sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
                sessionStorage.setItem('isExternalProject', res.isExternalProject || false);

                //const userRolesObject = this.appSvc.getUserPrivilages();
                // this.loading = true; // hide the spinner if success
              })).toPromise();
        }
      }
    }

    this.showLoader$.next(false);

    let granted = true;
    return granted;
  }

}

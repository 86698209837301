<div class="tab tab-members">
  <div class="project-listing">
    <div class="main_div">
      <ng-container ng-diable="disable">
        <div class="search-wrapper">
          <input type='text' class="input-ctrl search-ctrl" placeholder='Search' [(ngModel)]="filterStr"
            (keypress)="!isSpecialCharacter($event)" (keyup)='updateFilter($event.target.value,$event)' />
        </div>
      </ng-container>
      <perfect-scrollbar fxFlex="auto" class="scrollbarInfo">
        <ngx-datatable (activate)="onActivate($event)" #installationTable
          [rows]="installationData | paginate: installationConfigPlan" [columnMode]="'force'" [rowHeight]="42"
          [count]="installationConfigPlan.totalItems" [offset]="installationConfigPlan.currentPage"
          [limit]="installationConfigPlan.itemsPerPage" (sort)="onSort($event)" (page)="pageChange($event)">

          <ngx-datatable-column [draggable]="false" name="Name" prop="installation" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.installation">{{ row.installation }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Country" prop="country" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.country">{{
                  row.country
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Owner" prop="substationOwner" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.substationOwner">{{ row.substationOwner }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Operator" prop="substationOperator" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.substationOperator">{{ row.substationOperator
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Location" prop="location" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.location">{{ row.location
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Latitude" prop="latitude" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.latitude">{{
                  row.latitude
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Longitude" prop="longitude" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.longitude">{{
                  row.longitude
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="N. of assets" prop="assetCount" [width]="100">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.assetCount">{{
                  row.assetCount
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" name="Technology" prop="technology" [width]="150">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="company" [ngbTooltip]="row.technology">{{
                  row.technology
                }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column [draggable]="false" [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <button cursorTooltip [tooltipOptions]="{
                        arrow: false,
                        placement: 'bottom',
                        followCursor: true,
                        content: 'Installation information'
                        }" class="last-icon" (click)="openInstallationInfo(row)">
                @if (type == 'Internal') {
                  <i class="icon square-menu-icon" aria-hidden="true"></i>
                }
              </button>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
              <div class="page-count"></div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </perfect-scrollbar>
      <div class="pagination">
        <pagination-controls id="pagination1" (pageChange)="pageChange($event)" previousLabel="Previous"
          nextLabel="Next">
        </pagination-controls>
      </div>
      @if (type === 'External' && enableUpload()) {
        <a class="item add-new" (click)="uploadExternalFile()">
          <label>
            <i class="icon icon-add-new" aria-hidden="true"></i>
          </label>
          <span i18n>Upload installation data from external project</span>
        </a>
      }
      <div class="custom-fileUpload">
        <form>
          <input type="file" class="custom-file-input" id="customFile" name="myfile" (change)="onFileSelect($event)"
            #UploadFileInput />
        </form>
        <label class="custom-file-label" for="customFile">{{
          fileInputLabel || "Choose File"
        }}</label>
      </div>
    </div>
  </div>
</div>
@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}
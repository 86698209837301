import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TippyDirective } from './cursortooltip.directive';
import { NumberOnlyDirective } from './number.directive';
import { NgTemplateOutlet } from './ng_template_outlet';
import { NoHTMLTagsDirective } from './noHtmlTags.directive';
import { PopperDirective } from './popper.directive';

@NgModule({
  declarations: [
    NumberOnlyDirective,
    TippyDirective,
    NgTemplateOutlet,
    NoHTMLTagsDirective,
    PopperDirective
  ],
  exports: [
    NumberOnlyDirective,
    TippyDirective,
    NgTemplateOutlet,
    NoHTMLTagsDirective,
    PopperDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule {
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DisplayConfiguration } from 'src/app/models/display-config';

@Component({
  selector: 'app-toggle-icons',
  templateUrl: './toggle-icons.component.html',
  styleUrl: './toggle-icons.component.scss'
})
export class ToggleIconsComponent {
@Input() displayConfiguration : DisplayConfiguration;
@Input() isRiskMatrixVisible=true;
@Input() isTableVisible = true;
@Output() emitState =new EventEmitter();
toggleClicked(){
this.emitState.emit({isRiskMatrixVisible:this.isRiskMatrixVisible,isTableVisible:this.isTableVisible});
}

}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { detectIncognito } from './app/detect-incognito';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

detectIncognito().then((isIncognito) => {
    if (isIncognito) {
      console.log('Application cannot load in incognito mode.');
    } else {
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
    }
  });
@if (loading) {
  <div class="loading-box" >
    <div class="img"  alt="loading"></div>
  </div>
}
<div class="map-wrapper" [class.view-mode]="viewMode" [class.embedded]="embedded" [class.operations-mode]="operationMode">
  @if (mapData && markerList.length !== 0 && ((!embedded && viewMode) || operationMode)) {
    <app-information-explorer-side #IeSideTool
      (substationHover)="substationHover($event)"
      (assetHover)="assetHover($event)"
      [hoverOverAsset]="hoverOverAsset"
      (InstallationHover)="InstallationHover($event)"
      (projectHover) = "projectHover($event)"
      (tabChange)="tabChange($event)"
      (searchEnd)="substationFiltered($event)"
      [onlySubstations]="operationMode"
      (updateMap)="updateMap()"
      (updateFilters)="updateFilters()"
      [tab]="viewTab"
      [installMainFilterList]="installMainFilterList"
      [installCompFilterList]="installCompFilterList"
      [installAllFilterListCount]="installAllFilterListCount"
      [projMainFilterList]="projMainFilterList"
      [projCompFilterList]="projCompFilterList"
      [projAllFilterListCount]="projAllFilterListCount"
      [data]="mapData ? { assets: mapData.riskMatrix, substations: mapData.markers, totalProjects:mapData.totalProjects, totalInstallations:mapData.totalInstallations} : null"
      [hoverOverMarker]="hoverOverMarker"
      [selectedRegion]="selectedRegion"
      (filterItemPassEmit)="filterItemPassEmit($event)"
      [toggleTable]="displayConfiguration?.toggleTable"
      [isTableVisible]="isTableVisible"
    ></app-information-explorer-side>
  }
  @if (markerList.length !== 0 && !embedded && isGridEditor) {

    <div class="markers">
      <div class="marker-tab">
        <a (click)="legendTab = 'Substations'" [class.active]="legendTab === 'Substations'" href="javascript:;">Installations</a>
        <a (click)="legendTab = 'Connections'" [class.active]="legendTab === 'Connections'" href="javascript:;">Connections</a>
      </div>
      @if (legendTab === 'Substations') {
        @if (legend) {
          <div class="search-wrapper">
            <input [(ngModel)]="legendQuery" class="form-input" type="search"
              placeholder="Search {{markerList.length}} Installations"/>
            <a href="javascript:;">
              <i class="icon icon-export" aria-hidden="true"></i>
              <div>
                @if (!gridViewMode) {
                  <a href="javascript:;" (click)="importConnection()">Import .csv</a>
                }
                <a href="javascript:;" (click)="exportConnection()">Export .csv</a>
              </div>
            </a>
          </div>
        }
        @if (legend) {
          <ul>
            <div class="table-header">
              <div class="name" (click)="sortSubstation('name')">Name
                <i class="sort-btn" aria-hidden="true"
                  [class.sort-asc]="this.sortDirectionSubstation && (this.sortBySubstation === 'name')"
                [class.sort-desc]="!this.sortDirectionSubstation && (this.sortBySubstation === 'name')"></i>
              </div>
              <div (click)="sortSubstation('region')" class="region">Country
                <i class="sort-btn" aria-hidden="true"
                  [class.sort-asc]="this.sortDirectionSubstation && (this.sortBySubstation === 'region')"
                [class.sort-desc]="!this.sortDirectionSubstation && (this.sortBySubstation === 'region')"></i>
              </div>
            </div>
            <perfect-scrollbar fxFlex="auto">
              <div class="substation-list">
                @for (marker of markerList; track marker) {
                  @if (marker.name.toLowerCase().indexOf(legendQuery.toLowerCase()) !== -1) {
                    <li
                      (mouseover)="hoverOnSubstation(marker.id)"
                      (mouseleave)="hoverOnSubstation(null)"
                      [class.active]="hoverOverMarker !== null ? hoverOverMarker.getId() === marker.id : false">
                      <div class="name" title="{{marker.name}}">{{marker.name}}</div>
                      <div class="region">{{marker.region}}</div>
                    </li>
                  }
                }
              </div>
            </perfect-scrollbar>
          </ul>
        }
      }
      @if (legendTab === 'Connections') {
        <div class="search-wrapper">
          <input [(ngModel)]="connectionQuery" class="form-input" type="search"
            placeholder="Search {{connectionList.length}} connections"/>
          <a href="javascript:;">
            <i class="icon icon-export" aria-hidden="true"></i>
            <div>
              @if (!gridViewMode) {
                <a href="javascript:;" (click)="importConnection()">Import .csv</a>
              }
              <a href="javascript:;" (click)="exportConnection()">Export .csv</a>
            </div>
          </a>
        </div>
        <div class="table-header">
          <div class="name" (click)="sortConnection('name')">Connection
            @if (this.sortByConnection === 'name') {
              <i class="sort-btn" aria-hidden="true"
                [class.sort-desc]="this.sortDirectionConnection"
              [class.sort-asc]="!this.sortDirectionConnection"></i>
            }
          </div>
        </div>
        <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true">
          <div class="accordion-wrapper">
            @for (con of connectionList; track con) {
              <div ngbAccordion>
                <div ngbAccordionItem>
                  @if (con.name.toLowerCase().indexOf(connectionQuery.toLowerCase()) !== -1) {
                    <h5 ngbAccordionHeader class="accordion-header">
                      <button ngbAccordionButton>{{con.name}}</button>
                    </h5> }
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>
                          <ul>
                            @for (itm of con.items; track itm) {
                              <li>
                                <div class="label text-truncate">{{itm.reference}}</div>
                                <div class="label text-truncate">{{itm.origin}}</div>
                                <div class="label text-truncate">→</div>
                                <div class="label text-truncate">{{itm.destination}}</div>
                              </li>
                            }
                          </ul>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div> }
              </div>
            </perfect-scrollbar>
          }
        </div>
      }
      <app-toggle-icons
        [displayConfiguration]="displayConfiguration"
      (emitState)="toggleHandler($event)"></app-toggle-icons>
      <!-- } -->
      <div id="map" class="map" [class.image-selected]="imageSelected"></div>

      @if (!viewMode && userRolesObject.GISSLDEditorEdit && isGridEditor) {
        <div class="right-side-controls">
          @if (isExternal) {
            <a [class.fade-out]="enableForConnect" (click)="addMarkerEvent(nodeTypes.substation)"
              [class.active]="selectedNodeType === nodeTypes.substation" class="add-button add-substation" href="javascript:;">
              <small i18n>Add<br />
    Installation
  </small>
              <i aria-hidden="true"></i>
            </a>
          }
          @if (!isExternal) {
            <a [class.fade-out]="enableForConnect" [ngbTooltip]="tipInstallations" [autoClose]="true" triggers="manual"
              #t="ngbTooltip" (click)="t.open()" placement="left"
              [class.active]="selectedNodeType === nodeTypes.substation" class="add-button add-substation" href="javascript:;">
              <small i18n>Add<br />
        Installation
      </small>
              <i aria-hidden="true"></i>
            </a>
          }
          <ng-template #tipInstallations>
            <div class="pop-container">
              <div class="menu-option" (click)="addExistingInst()">
                <div>Select installation</div>
              </div>
              <div class="menu-option" (click)="addMarkerEvent(nodeTypes.substation)">
                <div>Create new installation</div>
              </div>
            </div>
          </ng-template>
          @if (userRolesObject.GISSLDEditorEdit) {
            <a [class.fade-out]="enableForConnect" class="add-button add-load-source"
              href="javascript:;">
              <small i18n>Source
        and Load
      </small>
              <div class="icon-wrapper">
                <i (click)="addMarkerEvent(nodeTypes.source)" [class.active]="selectedNodeType === nodeTypes.source"
                class="source" aria-hidden="true"></i>
                <i (click)="addMarkerEvent(nodeTypes.load)" [class.active]="selectedNodeType === nodeTypes.load"
                class="load" aria-hidden="true"></i>
              </div>
            </a>
          }
          @if (userRolesObject.GISSLDEditorEdit) {
            <a [class.fade-out]="enableForConnect"
              (click)="addMarkerEvent(nodeTypes.joint)" [class.active]="selectedNodeType === nodeTypes.joint"
              class="add-button add-joint" href="javascript:;">
              <small i18n>Joint</small>
              <i aria-hidden="true"></i>
            </a>
          }
          <a [class.fade-out]="userRolesObject.GISSLDEditorEdit" [class.disabled]="!isPossibleToConnect()"
            (click)="enableConnections();" class="add-button add-connection" href="javascript:;">
            <small i18n>Connect</small>
            <i aria-hidden="true"></i>
          </a>
        </div>
      }
      @if (!embedded || operationMode) {
        <div class="zoom" [class.zoom_bottom-margin]="!embedded && !isHomePage && !isOperationsCenter">
          <a href="javascript:;" (click)="zoomOut()"></a>
          <a href="javascript:;" (click)="isOperationsCenter ? opCenterFocus() : focus()"></a>
          <a href="javascript:;" (click)="zoomIn()"></a>
        </div>
      }

      @if (!embedded && !isHomePage && !isOperationsCenter) {
        <div class="view">
          <button></button>
          @if (!gridViewMode) {
            <button [disabled]="isSLDNavigationDisabled()" [routerLink]="projectRoutePrefix + '/sld-editor'" [queryParams]="getParameters()"></button>
          }
          @if (gridViewMode) {
            <button [disabled]="isSLDNavigationDisabled()" [routerLink]="projectRoutePrefix + '/sld-editor/true'"></button>
          }
          <button></button>
        </div>
      }

      @if (!imageSelected) {
        <div class="menu-bar-container">
          <div #menuBarDiv class="menu-bar">
            @for (item of menuBar; track item) {
              <div class="menu-item" (click)="toggleMenuItem(item)">
                <div class="menu-item-box" [class.active]="selectedMenuItem===item">
                  <div class="icon-box">
                    <img [src]="iconsPath + item.icon + '.svg'" alt="Box" [hidden]="selectedMenuItem===item">
                    <img [src]="iconsPath + item.icon + '-white.svg'" alt="Box" [hidden]="selectedMenuItem!==item">
                  </div>
                  <div class="separator"></div>
                  <div class="chevron">
                    <img [src]="iconsPath + 'chevron.svg'" alt="Chevron" [hidden]="selectedMenuItem===item">
                    <img [src]="iconsPath + 'chevron-white.svg'" alt="Chevron" [hidden]="selectedMenuItem!==item">
                  </div>
                </div>
                @if (selectedMenuItem === item) {
                  <div class="menu-sub-item-list-container">
                    <div class="menu-sub-item-list">
                      @for (subItem of item.subItems; track subItem) {
                        <div class="menu-sub-item" [class.disabled]="!subItem.enabled" [class.disabled]="!subItem.enabled"
                          (click)="subItem.enabled && onClickMenuSubItem(subItem)">
                          {{ subItem.name }}
                          @if ((subItem.icon && !subItem.isActivated) || (subItem.icon && subItem.activated)) {
                            <img [src]="iconsPath + subItem.icon + '-white.svg'" alt="">
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            <div class="input-file-container">
              <input #uploadImageRef type="file" accept=".geojson" (change)="onMapBackgroundImageUpload($event)">
            </div>
          </div>
        </div>
      }

      @if (componentDataDialog.show) {
        <div class="component-data-dialog-container-wrapper">
          <div class="component-data-dialog-container">
            <app-asset-info (close)="componentDataDialog={ show: false }" (deleteMarker)="deleteMarker($event)"
              (sld)="goToSLD()" [viewMode]="viewMode" [queryParam]="getParameters()" [nodeType]="componentDataDialog.nodeType"
              (save)="saveComponentData($event)" [data]="componentDataDialog.data">
            </app-asset-info>
          </div>
        </div>
      }
      @if (!embedded && viewMode) {
        <div [ngClass]="{'hideRiskMatrix': !isRiskMatrixVisible}" class="risk-matrix-wrapper" id="draggable-container">
          <app-budget-risk-matrix [chartOnly]="false"
            (substationHover)="InstallationHover($event)"
            (assetHover)="assetHover($event)"
            (matrixExpand)="isMatrixExpanded=$event;setSlider()"
            [pointSize]="3"
            [viewTab]="viewTab"
            [riskValues]="riskValues"
            [hoverOverMarker]="hoverOverMarker"
            [hoverOverAsset]="hoverOverAsset"
          [selectedRegion]="selectedRegion"></app-budget-risk-matrix>
          @if (isHomePage && currentYear) {
            <div [ngClass]="isMatrixExpanded?'expanded-matrix':''">
              <div class="slider-margin">
                <button mat-icon-button [ngClass]="isMatrixExpanded?'expanded-matrix-arrowUp':'arrow-button up'" (click)="sliderRange('up')" [disabled]="this.currentRange[0] == this.yearsRange[0]">
                  <mat-icon>keyboard_arrow_up</mat-icon>
                </button>
                <mat-slider class="slider" id="slider" [min]="min" [max]="max" [step]="1" discrete="true"
                  (input)="sliderChange($event)" showTickMarks thumbLabel tickInterval="auto" discrete="true">
                  <input matSliderThumb [(ngModel)]="currentYear" [(ngValue)]="currentYear">
                </mat-slider>
                <button mat-icon-button [ngClass]="isMatrixExpanded?'expanded-matrix-arrowDown':'arrow-button down'" (click)="sliderRange('down')" [disabled]="this.currentRange[1] == this.yearsRange[this.yearsRange.length - 1]">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
              <div class="slider-margin ticks-wrapper">
                @for (year of years.slice().reverse(); track year) {
                  <div  class="tick" [ngClass]="currentYear!==year?'faded-text':'highlight-year'" [value]="year" [style.height.px]="tickHeight?tickHeight:20">
                    {{year}}
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }


    </div>
    <input #inputConnectionCVSFileRef type="file" class="hide" accept="text/csv"
      (change)="onConnectionCVSSelected($event)">
    <div id="marker-menu-container">
      @for(option of markerClickOptions ; track option){
        <button class="marker-menu-button" (click)="onMarkerMenuSelect(option)">{{option}}</button>
      }
    </div>
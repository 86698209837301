@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="modal-container">
  <div class="modal-header">
    @if (mode === 'retrieve') {
      <span class="modal-title" id="modal-basic-title">Reauthenticate</span>
    } @else {
      <span class="modal-title" id="modal-basic-title">Create password</span>
    }
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    @if (mode === 'set') {
    <span class="input-label">New password</span>
    }
    @if (mode === 'retrieve') {
    <div class="refresh-warning-text">
      <div class="refresh-warning"><img class="onboard-img" src="/assets/icons/Info.svg" alt="Avatar" />Refreshing of page
        required reauthenticate</div>
    </div>
    <div class="username-div">User ID: <span class="username">{{email}}</span></div>
    <span class="input-label">Password</span>
    }
    <div class="input-padding">
    <input class="input-ctr" maxlength="8" minlength="8" type="password" placeholder="Enter password" [(ngModel)]="password">
    </div>
    @if (mode === 'set') {
      <span class="input-label">Confirm password</span>
      <input class="input-ctr" maxlength="8" minlength="8" type="password" placeholder="Re-enter password" [(ngModel)]="repeatPassword" [class.error]="repeatPassword !== password">
    }
    @if (mode === 'retrieve') {
      <div class="logout">To logout to <a (click)="navigateToLogin()" class="logout-link">click here</a></div>
    }
    <div class="password-info-text">
    <div class="password-info">
      <img class="onboard-img" src="/assets/icons/Info.svg" alt="Avatar" />
      This password is authorization for RelCare offline access.</div>
    </div>
  </div>
  <div class="modal-footer">
    @if (mode === 'retrieve') {
      <a class="btn btn-primary" (click)="modal.close(password)" [class.disabled]="!password" i18n>Login</a>
    }
    @else {
      <a class="btn btn-cancel" (click)="modal.dismiss('back')" i18n>Cancel</a>
      <a class="btn btn-primary" (click)="setPassword()" [class.disabled]="!password || password !== repeatPassword || password.length &gt; 8 || password.length &lt; 8" i18n>Set</a>
    }
  </div>
</div>

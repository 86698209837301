import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { forkJoin, Observable, Subject, of, from} from 'rxjs';
import { map, tap, switchMap, delay } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as go from 'gojs';
import { config } from '../config';
import { HelperService } from './helper.service';
import { RiseToRelcareService } from './rise-to-relcare.service';

import { SuccesspopupComponent } from '../../dashboard/modals/successpopup/successpopup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionToTake } from '../alarms-events/alarms-events-container/models/actionToTake.model';
// const apiURL = environment.SLDApiURL;

export type UserDrawConfig = {
  [key: string]: {
    label: string;
    nameKey: string;
    idKey: string; // only can be used on saved areas (backend sets the id)
    getAreaList?: () => any[];
    getChildAreaList?: (area: any, childCategory?: string) => any[];
    getTemplate: (parentArea?: any) => any;
    disallowOverlap: string[];
    parentCategory?: string;
    getParentAreaName?: () => string;
    markComponentInside: (comp: go.Node, area: go.Node) => void;
  }
};

/**
 * SLD Util service
 */
@Injectable()
export class SldUtilService {
    isSubstationUpdated = 0;
    public toast: any = {};
    public specialUserActionSubject = new Subject<void>();
    public linkCreatedSubject = new Subject<any>();
    public userAreaDrawnSubject = new Subject<go.Node>();
    public diagramLoadedSubject = new Subject<void>();
    public nodeAddedSubject = new Subject<any>();
    public widgetImageDataLoadedSubject = new Subject<any>();
    // substation JSON specific objects
    public substation: any = {};
    // public substationName = null;
    public isValid: boolean;
    public assetData: any;
    // private mainBay: any;
    private nodes: any;
    private links: any;
    public data: any = [];
    currency: any;
    // for excel export beautify
    public keyPropertyMap: any = {
        'basicInformation': 'Basic information',
        'technicalInformation': 'Technical information',
        'reliability': 'Reliability',
        'sldReference': 'SLD reference',
        'calculatedCondition': 'Calculated condition',
        'calculatedImportance': 'Calculated importance',
        'importance': 'Importance',
        'risk': 'Risk',
        'manufacturer': 'Manufacturer',
        'ratedPower': 'Rated power',
        'equipmentCategoryName': 'Equipment category',
        'assetConfiguration': 'Asset configuration',
        'manufacturingYear': 'Manufacturing year',
        'sldBayReference': 'SLD bay/branch reference',
        'sldEquipmentReference': 'SLD equipment reference',
        'operatingVoltage': 'Operating voltage',
        'ratedLineVoltageUr': 'Rated line voltage (Ur)',
        'ratedReactivePower': 'Rated reactive power',
        'ratedCurrent': 'Rated current',
        'numberOfTaps': 'Number of taps',
        'failureRateFr': 'Failure rate (FR)',
        'meanTimeToRepairMttr': 'Mean time to repair (MTTR)',
        'meanTimeToSwitchMtts': 'Mean time to switch (MTTS)',
        'maintenanceDuration': 'Maintenance duration',
        'maintenanceFrequency': 'Maintenance frequency',
        'netResistance': 'Net resistance',
        'typeOfContactor': 'Type of contactor',
        'application': 'Application',
        'frequencyBand': 'Frequency band',
        'ratedContinuousCurrentIr': 'Rated continuous current (Ir)',
        'type': 'Type',
        'typeOfVtFixation': 'Type of VT fixation',
        'ratedSecondaryVoltage': 'Rated secondary voltage',
        'ratedAccuracyClass': 'Rated accuracy class',
        'ratedOutput': 'Rated output',
        'ratedContinuousNeutralCurrent': 'Rated continuous neutral current',
        'impedance': 'Impedance',
        'ratedPrimaryCurrent': 'Rated primary current',
        'typeOfCt': 'Type of CT',
        'ratedSecondaryCurrent': 'Rated secondary current',
        'loadReference': 'Load reference',
        'substationReference': 'Substation reference',
        'ratedPowerFactor': 'Rated power factor',
        'typeOfLoad': 'Type of load',
        'sourceReference': 'Source reference',
        'typeOfSource': 'Type of source',
        'numberOfStagesCapacitorStepUnits': 'Number of stages (Capacitor step units)',
        'ratedOperatingPowerQn': 'Rated operating power (Qn)',
        'typeOfSurgeArrester': 'Type of surge arrester',
        'ratedReactance': 'Rated reactance',
        'ratedVoltageHv': 'Rated voltage-HV',
        'ratedVoltageLv': 'Rated voltage-LV',
        'vectorGroupConnectionSymbol': 'Vector group-connection symbol',
        'typeOfCooling': 'Type of cooling',
        'typeOfDisconnector': 'Type of disconnector',
        'typeOfOperatingMechanism': 'Type of operating mechanism',
        'typeOfOutdoorCircuitBreakerTank': 'Type of outdoor circuit breaker tank',
        'operatingMechanismType': 'Operating mechanism-type',
        'ratedCurrentOfFuseLink': 'Rated current of fuse link',
        'typeOfSealingEnd': 'Type of sealing end',
        'totalLength': 'Total length',
        'arcQuenchingMedium': 'Arc quenching medium',
        'typeOfBreakerFixation': 'Type of breaker fixation',
        'operationType': 'Operation type',
        'typeOfBusbar': 'Type of busbar',
        'typeOfTower': 'Type of tower',
        'materialOfTower': 'Material of tower',
        'layingCondition': 'Laying condition',
        'insulationMaterial': 'Insulation material',
        'typeOfInsulatingMaterial': 'Type of insulating material',
        'typeOfCapacitorUnit': 'Type of capacitor unit',
        'parentAsset': 'Parent asset'
    };
    private factsTechnologyOptions: string[];
    private branchCriticalityOptions: string[];
    private _branchTypeOptions: any[];
    private set branchTypeOptions(options: any[]) {
      this._branchTypeOptions = options.map(bt => Object.assign({}, bt));
    }
    private get branchTypeOptions(): any[] {
      return this._branchTypeOptions.map(bt => Object.assign({}, bt));
    }

    public TwoWayMap = class {
        map = {};
        reverseMap = {};
        constructor(mapArg: any) {
            this.map = mapArg;
            this.reverseMap = {};
            for (const key of Object.keys(mapArg)) {
                const value = mapArg[key];
                this.reverseMap[value] = key;
            }
        }
        get(key) { return this.map[key]; }
        revGet(key) { return this.reverseMap[key]; }
    };
    public propertyNameMap = new this.TwoWayMap(this.keyPropertyMap);

    public userDrawConfig: UserDrawConfig  = {
      'bay': {
        label: 'Bay',
        nameKey: 'bayName',
        idKey: 'bayId',
        getTemplate: () => this.getBayTemplate(),
        disallowOverlap: ['bay', 'facts'],
        markComponentInside: (component, area) => {
          component.data.bay = area.data.name;
          if (component.data.sldData) { component.data.sldData.bayBranchReference = area.data.name; }
        },
      },
      'outage-area': {
        label: 'Outage Area',
        nameKey: 'outageAreaName',
        idKey: 'outageAreaId',
        getTemplate: () => this.getOutageAreaTemplate(),
        disallowOverlap: ['outage-area'],
        markComponentInside: (component, area) => component.data.outageArea = area.data.name,
      },
      'gas': {
        label: 'Gas Compartment',
        nameKey: 'InnovativeModuleId',
        idKey: 'InnovativeModuleId',
        getTemplate: () => this.getGasTemplate(),
        disallowOverlap: ['gas'],
        markComponentInside: (component, area) => {
          component.data.sldData['InnovativeModuleId'] = area.data['InnovativeModuleId'];
          component.data.sldData['InnovativeModuleType'] = area.data['InnovativeModuleType'];
        },
      },
      'facts': {
        label: 'FACTS Installation',
        nameKey: 'factsName',
        idKey: 'factsId',
        getTemplate: () => this.getFactsInstallationTemplate(),
        disallowOverlap: ['facts', 'bay'],
        getChildAreaList: (facts) => facts.branches,
        markComponentInside: (component, area) => component.data.facts = area.data.name,
      },
      'branch': {
        label: 'Shunt Branch',
        nameKey: 'branchName',
        idKey: 'branchId',
        getTemplate: (factsArea: any) => this.getShuntBranchTemplate(factsArea.factsId),
        disallowOverlap: ['branch'],
        parentCategory: 'facts',
        markComponentInside: (component, area) => {
          component.data.branch = area.data.name
          if (component.data.sldData) { component.data.sldData.bayBranchReference = area.data.name; }
        },
      },
      'shutdown': {
        label: 'Shutdown Area',
        nameKey: 'shutdownAreaName',
        idKey: 'shutdownAreaId',
        getTemplate: () => this.getShutdownAreaTemplate(),
        disallowOverlap: ['shutdown'],
        markComponentInside: (component, area) => component.data.shutdownArea = area.data.name
      }
    };

    // a large object that contains the image data URIs (the actual encoded content of the equipment images)
    public widgetImageDataMap = {};

    public branchTypeIdToRatingPrefixMap = {
        '15dff7bf-078a-48ab-9908-6898e775240b': '+',
        '18dff7bf-078a-48ab-9908-6898e775240b': '+',
        '13dff7bf-078a-48ab-9908-6898e775240b': '+',
        '11dff7bf-078a-48ab-9908-6898e775240b': '+',
        '12dff7bf-078a-48ab-9908-6898e775240b': '-',
        '14dff7bf-078a-48ab-9908-6898e775240b': '-',
        '17dff7bf-078a-48ab-9908-6898e775240b': '-',
        '10dff7bf-078a-48ab-9908-6898e775240b': '-',
        '16dff7bf-078a-48ab-9908-6898e775240b': '-/+',
        '20dff7bf-078a-48ab-9908-6898e775240b': '',
        '19dff7bf-078a-48ab-9908-6898e775240b': null,
        '21dff7bf-078a-48ab-9908-6898e775240b': null
    };

    public secondarySystemNameToTypeMap = {
      'Cooling system': {
        'secondarySystemTypeId': '2daa2fff-e222-4f22-840b-698551bcde91',
        'secondarySystemTypeName': 'Cooling'
      },
      'Protection system': {
        'secondarySystemTypeId': 'fb0ee4b0-0020-4728-ae26-d588e1359530',
        'secondarySystemTypeName': 'Protection'
      },
      'Control system': {
        'secondarySystemTypeId': '451bb234-3b58-4951-b787-c2af46e6f755',
        'secondarySystemTypeName': 'Control'
      }
    };

    get emptySerializedSld() {
      return {
        nodes: [],
        links: [],
        sldReferenceIter: 1,
        selectedKeys: [],
        standard: sessionStorage.getItem('diagramSymbols') || 'iec',
        baysList: [{
            bayId: '00000000-0000-0000-0000-000000000000',
            bayName: 'default',
            allComponents: [],
            isDefault: true,
            userValidatedWeight: null,
            calculatedWeight: null
        }],
        outageAreasList: [],
        gasCompartmentList: [],
        diagramName: 'My diagram'
      }
    }

    constructor(
      private http: HttpClient,
      private helper: HelperService,
      private modalService: NgbModal,
      private riseToRelcareService: RiseToRelcareService,
      private _FileSaverService: FileSaverService) {

        // const numbers = interval(config.TIME_INTERVAL);
        // one time api call to get dropdown options for facts and branches
        // results stored inn utilService itself
        if(navigator.onLine){
            this.getFactsAndBranchPropertyOptions().subscribe(() => {});
         }
    }
    /**
     * returns a deep copy of object
     * @param obj the object to be deep coped
     */
    public static deepCopy(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public showToast(message, timeout?: number) {
        this.toast = {
            show: true,
            message: message
        };
        setTimeout(() => {
            this.toast = {};
        }, (timeout || 2000));
    }

    /**
     * load widget json file, fetch the SLD-data template json for each widget
     * compile it alltogether and serve
     */
    public getWidgetDefinitions(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'widgets.json')
            .pipe(switchMap(allWidgets => {
                return forkJoin((allWidgets as any[]).map(widget => {
                    return this.http
                        .get(config.ASSETS_DIR + 'data-templates/' + widget.name + '.json')
                        .pipe(map(template => {
                            widget.sldData = template;
                            return widget;
                        }));
                }));
            }));
    }

    /**
     * load widget image data maps
     * what is this: to reduce load time of equipment images, we would like to load all widget images in a single file like a sprite sheet
     * this reduces the number of network calls and hence load time
     * since we're using svgs though, a sprite sheet isn't exactly possible, so we load a larg json file that contains the svg xml code
     * for each equipment image
     * caveat: instead of loading only one large file, we keep a separate file for each color (so that we can load the black ones first)
     */
    public loadWidgetImageDataMaps(color?: string) {
      // either fetch the image map for the given color or fetch all colors that are not yet loaded
      const colorsToFetch = color ? [color] :
        ['000000', '0ca919', '0f6cff', 'cb2bd5', 'f0b900', 'ff000f'].filter(c => !this.widgetImageDataMap[c]);

      // wait for all api calls to finish before notifying the subject
      forkJoin(colorsToFetch.map(c => this.http.get(config.ASSETS_DIR + 'image-data-maps/' + c + '.json')
        .pipe(tap(imageDataMap => {
          this.widgetImageDataMap[c] = imageDataMap;
        })))
      ).subscribe(() => this.widgetImageDataLoadedSubject.next(null));

      return this.widgetImageDataLoadedSubject;
    }

    /**
     * get link data template
     * fetches the data template for links (aka ideal cables)
     */
    public getLinkDataTemplate(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/ideal-cable.json');
    }

    public getSubstationId() {
        return this.substation.substationId;
    }

    public getSubstation() {
        return this.substation;
    }

    /**
     * Bay Assessment APIs
     */
    public getBayAssessmentCriteriaList() {
        return this.helper.get('/Sld/GetBayAssessmentCriteriaList');
    }

    public calculateBayWeight(formData) {
        return this.helper.patch('/Bay/CalculateBayWeight', formData);
    }

    public saveBayAssessmentDetails(formData) {
        return this.helper.put('/Sld/SaveBayAssessment', formData);
    }
    public CheckEquipmentReferenceName(formData) {
        return this.helper.patch('/Sld/CheckEquipmentReferenceName', formData);
    }

    public getFactsAndBranchPropertyOptions() {
      return this.helper.get('/Facts/GetFactsBranchDetails').pipe(tap(res => {
        this.factsTechnologyOptions = res.factsTechnology;
        this.branchTypeOptions = res.branchType;
        this.branchCriticalityOptions = res.branchCriticality;
      }));
    }

    public getFactsTechnologyOptions() {
      if (this.factsTechnologyOptions) { return of(this.factsTechnologyOptions); }
      return this.getFactsAndBranchPropertyOptions().pipe(map(() => {
        return this.factsTechnologyOptions;
      }));
    }

    public getBranchPropertyOptions() {
      if (this.branchTypeOptions && this.branchCriticalityOptions) {
        return of({
          typeOptions: this.branchTypeOptions,
          criticalityOptions: this.branchCriticalityOptions
        });
      }

      return this.getFactsAndBranchPropertyOptions().pipe(map(() => ({
        typeOptions: this.branchTypeOptions,
        criticalityOptions: this.branchCriticalityOptions
      })));
    }

    /**
     * get outage area template
     * fetches the data template for outage area
     */
    public getOutageAreaTemplate(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/outage-area.json');
    }

    // public getAssessmentFields() {
    //     return this.http.get(config.ASSETS_DIR + 'data-templates/BayWeightAssessmentCriteria.json');// .pipe(delay(MOCK_API_DELAY));
    // }
    // public getAssessmentFieldsForAPI() {
    //     return this.http.get(config.ASSETS_DIR + 'data-templates/BayWeightAssessmentCriteriaForAPI.json');//.pipe(delay(MOCK_API_DELAY));
    // }


    /**
     * get gas compartment template
     * fetches the data template for gas compartment
     */
    public getGasTemplate(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/gas-compartment.json');
    }


    /**
     * get bay template
     * fetches the data template for bay
     */
    public getBayTemplate(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/bay.json');
    }

    /**
     * get facts installation template
     * fetches the data template for facts installation
     */
    public getFactsInstallationTemplate(): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/facts-installation.json');
    }

    /**
     * get shutdown area template
     * fetches the data template for shutdown area
     */
     public getShutdownAreaTemplate(): Observable<any> {
      return this.http.get(config.ASSETS_DIR + 'data-templates/shutdown-area.json');
  }

    /**
     * get shunt branch template
     * fetches the data template for shunt branch
     */
    public getShuntBranchTemplate(factsId: string): Observable<any> {
        return this.http.get(config.ASSETS_DIR + 'data-templates/shunt-branch.json').pipe(tap(res => {
            res.factsInstallationId = factsId;
        }));
    }

    /**
     * extract all component data from substation
     * @param substation - the substation object to get the values from
     * parse through the given substation dto structure, and extract all component data for all equipments inside
     */
    public extractAllComponentsDataFromSubstation(substationDto: any): { [key: string]: any } {
      let components = [].concat(
          substationDto.psLs,
          substationDto.busbars,
          ...substationDto.bays.map(bay => bay.allComponents)
      );
      if (substationDto.facts) {
        components = components.concat(
          ...substationDto.facts.map(facts => facts.busbars),
          ...substationDto.facts.map(facts => facts.psLs),
          ...substationDto.facts.map(facts => [].concat(...facts.branches.map(branch => branch.allComponents))),
          ...substationDto.facts.map(facts => facts.secondarySystems.map(ss => ss.component))
        );
      }
      const componentMap = {};
      components.forEach(component => {
        if (!component.sldReference) { return console.log('NULL SLD_REFERENCE RECEIVED:', component); }
        componentMap[component.sldReference] = component;
      });
      return componentMap;
    }

    /**
     * assign reliability data
     * fetches the mock reliability data json file,
     * and inserts the values into the selected comopnents
     */

    setSubstation(substation) {
        this.substation = substation;
    }

    setAssetData(assetData: any) {
        this.assetData = assetData;
    }

    getAssetData() {
        return this.assetData;
    }

    setSubstationFromAPI(substation, forImportJson?: boolean) {

        this.substation = substation;

        if (!this.substation.serializedSld) {
            this.substation.serializedSld = this.emptySerializedSld;
        }
        if (substation.bays.length === 0) {
            this.substation.bays = this.emptySerializedSld.baysList;
        }

        this.nodes = this.substation.serializedSld.nodes;
        this.defaultBayMarkingforBayWeightCalculations();
        this.links = this.substation.serializedSld.links;
        this.substation.bays.forEach(bay => {
            if (bay.bayName.indexOf('default') !== -1) {
                bay.isDefault = true;
            }
        });

        // helper function to mathc areas from substation dto and serializedSld
        const isSameArea = (category, area1, area2): boolean => {
            const nameKey = this.userDrawConfig[category].nameKey;
            const idKey = this.userDrawConfig[category].idKey;
            // if one of the areas has 0000 as id, then only name can be used to compare
            if (area1[idKey] === '00000000-0000-0000-0000-000000000000' || area2[idKey] === '00000000-0000-0000-0000-000000000000') {
                return area1[nameKey] === area2[nameKey];
            }
            // else check  that the ids match
            return area1[idKey] === area2[idKey];
        }

        const setAreaNodeNamesToId = (category, oldName, id) => {
            this.nodes
                .filter(n => n.category === category)
                .filter(n => n.name === oldName)
                .forEach(n => n.name = id);

            // any children nodes also need to be appropriately tagged (their data contains the parent area name)
            Object.keys(this.userDrawConfig).forEach(key => {
                if (this.userDrawConfig[key].parentCategory === category) {
                    this.nodes
                        .filter(n => n.category === key)
                        .filter(n => n.parentName === oldName)
                        .forEach(n => n.parentName = id);
                }
            });
        }

        // this.substation.serializedSld.baysList = SldUtilService.deepCopy(this.substation.bays);
        // set Alcomponents to []
        if (this.substation.serializedSld.baysList) {
            this.substation.serializedSld.baysList.forEach(baySld => {
                this.substation.bays.forEach(bay => {
                    if (isSameArea('bay', bay, baySld)) {
                        baySld.allComponents = bay.allComponents;
                        baySld.isCalculated = bay.isCalculated;
                        baySld.bayId = bay.bayId;
                        if (baySld.bayName !== bay.bayName) {
                            setAreaNodeNamesToId('bay', baySld.bayName, baySld.bayId);
                            baySld.bayName = bay.bayName;
                        }
                       baySld.calculatedWeight = bay.calculatedWeight;
                        if(bay.bayName === 'default'){
                            baySld.isCalculated = !(Number(bay.userValidatedWeight) > 0 && Number(bay.userValidatedWeight) < 1);
                            if(baySld.calculatedWeight == null ){
                                baySld.calculatedWeight = 0.74;
                            }

                        }
                        // baySld.validatedWeight = {
                        //     'floatProperty': bay.userValidatedWeight || bay.validatedWeight.floatProperty || 0
                        // };
                        baySld.userValidatedWeight = bay.userValidatedWeight;
                    }
                });
            });
            this.substation.bays = SldUtilService.deepCopy(this.substation.serializedSld.baysList);
            this.substation.serializedSld.baysList.forEach(bay => {
                bay.allComponents = [];
            });
        }
        if (this.substation.serializedSld.outageAreasList) {
            this.substation.serializedSld.outageAreasList.forEach(outageAreaSld => {
                this.substation.outageAreasList.forEach(outageArea => {
                    if (isSameArea('outage-area', outageArea, outageAreaSld)) {
                        outageAreaSld.outageAreaComponentsList = outageArea.outageAreaComponentsList;
                        outageAreaSld.outageAreaId = outageArea.outageAreaId;
                        if (outageAreaSld.outageAreaName !== outageArea.outageAreaName) {
                            setAreaNodeNamesToId('outage-area', outageAreaSld.outageAreaName, outageAreaSld.outageAreaId);
                            outageAreaSld.outageAreaName = outageArea.outageAreaName;
                        }
                    }
                });
            });
            this.substation.outageAreasList = SldUtilService.deepCopy(this.substation.serializedSld.outageAreasList);
        }

        if (this.substation.serializedSld.shutdownAreaList) {
          this.substation.serializedSld.shutdownAreaList.forEach(shutdownAreaSld => {
              this.substation.shutDownAreasList.forEach(shutdownArea => {
                  if (isSameArea('shutdown', shutdownArea, shutdownAreaSld)) {
                      shutdownAreaSld.shutdownAreaComponentsList = shutdownArea.shutdownAreaComponentsList;
                      shutdownAreaSld.shutdownAreaId = shutdownArea.shutdownAreaId;
                      shutdownAreaSld.reminderDate = shutdownArea.reminderDate;
                      shutdownAreaSld.isReminderRequired = shutdownArea.isReminderRequired;
                      shutdownAreaSld.isEmailRequired = shutdownArea.isEmailRequired;
                      shutdownAreaSld.shutdownAreaDesc = shutdownArea.shutdownAreaDesc;
                      shutdownAreaSld.disableAlarm = shutdownArea.disableAlarm;
                      if (shutdownAreaSld.shutdownAreaName !== shutdownArea.shutdownAreaName) {
                          setAreaNodeNamesToId('shutdown', shutdownAreaSld.shutdownAreaName, shutdownArea.shutdownAreaId);
                          shutdownAreaSld.shtudownAreaName = shutdownArea.shutdownAreaName;
                      }
                  }
              });
          });
          this.substation.shutDownAreasList = SldUtilService.deepCopy(this.substation.serializedSld.shutdownAreaList);
        }


        if (this.substation.serializedSld.factsList) {
            this.substation.serializedSld.factsList.forEach(factsSld => {
                const facts = this.substation.facts.filter(f => isSameArea('facts', f, factsSld))[0];
                if (!facts) { return; }
                factsSld.factsId = facts.factsId;
                if (factsSld.factsName !== facts.factsName) {
                    setAreaNodeNamesToId('facts', factsSld.factsName, factsSld.factsId);
                    factsSld.factsName = facts.factsName;
                }
                factsSld.factsRating = facts.factsRating;
                factsSld.technology = facts.technology;
                factsSld.isFactsRatingManual = facts.isFactsRatingManual;
                factsSld.busbars = facts.busbars;
                factsSld.psLs = facts.psLs;
                factsSld.emergencyModes = facts.emergencyModes || [];
                factsSld.factsGTBranches = facts.factsGTBranches || [];
                factsSld.emAuxiliary = facts.emAuxiliary || [];
                factsSld.secondarySystems = facts.secondarySystems || [];
                factsSld.branches.forEach(branchSld => {
                    const branch = facts.branches.filter(b => isSameArea('branch', b, branchSld))[0];
                    if (!branch) { return; }
                    branchSld.branchId = branch.branchId;
                    if (branchSld.branchName !== branch.branchName) {
                        setAreaNodeNamesToId('branch', branchSld.branchName, branchSld.branchId);
                        branchSld.branchName = branch.branchName;
                    }
                    branchSld.branchRating = branch.branchRating;
                    branchSld.branchType = branch.branchType;
                    branchSld.branchCriticality = branch.branchCriticality;
                    branchSld.allComponents = branch.allComponents;
                    branchSld.factsInstallationId = branch.factsInstallationId;
                    branchSld.isCalculated = branch.isCalculated;
                    branchSld.calculatedWeight = branch.calculatedWeight;
                    branchSld.userValidatedWeight = branch.userValidatedWeight;
                    branchSld.copiFailureInterruption = branch.copiFailureInterruption;
                    branchSld.copiFailureOutage = branch.copiFailureOutage;
                    branchSld.copiMaintenanceInterruption = branch.copiMaintenanceInterruption;
                    branchSld.copiMaintenanceOutage = branch.copiMaintenanceOutage;
                });
            });
            this.substation.facts = SldUtilService.deepCopy(this.substation.serializedSld.factsList);
        }

        const sldDataObjects = this.extractAllComponentsDataFromSubstation(this.substation);
        const nodesAndLinks = this.nodes
          .filter(node => !node.category || node.category === 'secondary-system')
          .concat(this.links.filter(link => !link.category));
        nodesAndLinks.forEach(component => {
          const sldData = sldDataObjects[component.sldReference];
          if (!sldData) { return console.log('NO SLD_DATA MAPPED FOR COMPONENT:', component); }

          component.sldData = sldData;
          // get the sld equipment reference from the basic information object and update in serializedSld
          // this value can change in import of component data and ordinarily serializedSld won't know anything about it
          // hence this step is required here
          component.sldEqptReference = (sldData.basicInformation != null && sldData.basicInformation.sldEquipmentReference) || component.sldEqptReference;
          // we also need to update the sldReferenceIter (in case user has used same format and just increatesed the number)
          // this is another variable only inside serializedSld, which again won't know about any external changes outside of sld save diagram
          if (component.sldEqptReference) {
            substation.serializedSld.sldReferenceIter = this.calculateSldReferenceIter(component.sldEqptReference, substation.serializedSld.sldReferenceIter);
          }


          if (component.class === 'load' && component.sldData) {
              if (!component.sldData.basicInformation) {
                  component.sldData.basicInformation = {
                      'isInImpactArea': 'No'
                  };
              } else if (component.sldData.basicInformation && component.sldData.basicInformation.isInImpactArea === null) {
                  component.sldData.basicInformation.isInImpactArea = 'No'; // default state is false
              }
          } else {
              delete component.sldData.reliabilityResults.r_COPI_ACC_FM;
              delete component.sldData.reliabilityResults.r_COPI_ACC_FN;
              delete component.sldData.reliabilityResults.r_COPI_ACC_SM;
              delete component.sldData.reliabilityResults.r_COPI_ACC_TOT;
          }
        });

        if (this.substation.outageAreasList) {
            this.substation.outageAreasList.forEach(outageAreaSLDData => {
                this.nodes
                    .filter(n => n.category === 'outage-area')
                    .filter(n => n.name === outageAreaSLDData.outageAreaName || n.name === outageAreaSLDData.outageAreaId)
                    .forEach(n => {
                        n.sldData = outageAreaSLDData;
                        n.name = outageAreaSLDData.outageAreaName;
                    });
            });
        }

        if (this.substation.shutDownAreasList) {
          this.substation.shutDownAreasList.forEach(shutdownAreaSLDData => {
              this.nodes
                  .filter(n => n.category === 'shutdown')
                  .filter(n => n.name === shutdownAreaSLDData.shutdownAreaName || n.name === shutdownAreaSLDData.shutodwnAreaId)
                  .forEach(n => {
                      n.sldData = shutdownAreaSLDData;
                      n.name = shutdownAreaSLDData.shutdownAreaName;
                  });
          });
      }

        if (this.substation.serializedSld.baysList) {
            this.substation.serializedSld.baysList.forEach(baySLDData => {
                this.nodes
                    .filter(n => n.category === 'bay')
                    .filter(n => n.name === baySLDData.bayName || n.name === baySLDData.bayId)
                    .forEach(n => {
                        n.sldData = baySLDData;
                        n.name = baySLDData.bayName;
                    });
            });
        }
        if (this.substation.serializedSld.gasCompartmentList) {
            this.substation.serializedSld.gasCompartmentList.forEach(gasCompartment => {
                if (!gasCompartment) { return; }
                const components = this.nodes.filter(n =>
                    n.sldReference === gasCompartment.InnovativeModuleId
                );
                components.forEach(component => {
                    component.sldData = gasCompartment;
                });
            });
        }
        if (this.substation.serializedSld.factsList) {
            this.substation.serializedSld.factsList.forEach(factsSLDData => {
                this.nodes
                    .filter(n => n.category === 'facts')
                    .filter(n => n.name === factsSLDData.factsName || n.name === factsSLDData.factsId)
                    .forEach(n => {
                        n.sldData = factsSLDData;
                        n.name = factsSLDData.factsName;
                    });

                factsSLDData.branches.forEach(branchSldData => {
                    this.nodes
                        .filter(n => n.category === 'branch')
                        .filter(n => n.name === branchSldData.branchName || n.name === branchSldData.branchId)
                        .filter(n => n.parentName === factsSLDData.factsName || n.parentName === factsSLDData.factsId)
                        .forEach(n => {
                            n.sldData = branchSldData;
                            n.name = branchSldData.branchName;
                            n.parentName = factsSLDData.factsName;
                        });
                });
            });
        }
        if (forImportJson) {
            this.substation = this.resetAssetIdsAndRef(this.substation);
        }
        return this.substation.serializedSld;
    }

    resetAssetIdsAndRef(substation: any) {
        substation.substationId = sessionStorage.getItem('substationId');
        substation.projectId = '00000000-0000-0000-0000-000000000000';
        substation.regionId = '00000000-0000-0000-0000-000000000000';

        if (substation.bays) {
            substation.bays.forEach(bay => {
                bay.bayId = '00000000-0000-0000-0000-000000000000';
            });
        }

        if (substation.outageAreasList) {
          substation.outageAreasList.forEach(outageArea => {
              outageArea.outageAreaId = '00000000-0000-0000-0000-000000000000';
          });
        }

        if (substation.shutDownAreasList) {
          substation.shutDownAreasList.forEach(shutdownArea => {
              shutdownArea.shutdownAreaId = '00000000-0000-0000-0000-000000000000';
          });
        }

        if (substation.facts) {
          substation.facts.forEach(facts => {
            facts.factsId = '00000000-0000-0000-0000-000000000000';
            facts.branches.forEach(branch => {
              branch.branchId = '00000000-0000-0000-0000-000000000000';
              branch.factsInstallationId = '00000000-0000-0000-0000-000000000000';
            });
            facts.secondarySystems.forEach(ss => {
              ss.secondarySystemId = '00000000-0000-0000-0000-000000000000';
              ss.assetId = '00000000-0000-0000-0000-000000000000';
              ss.factsInstallationId = '00000000-0000-0000-0000-000000000000';
            });
          });
        }

        // gather all the components in the substation and set their ids to empty
        // NOTE: we do not use the helper fn "extractAllComponents" here because, since we're reading from a JSON file,
        // .. the same busbar object listed in `allComponents` will not be the same object as the one listed in `busbars` array.
        // That helper method uses a map with sldReference as key, and will hence return only one of the objects.
        let components = [].concat(
          substation.psLs,
          substation.busbars,
          ...substation.bays.map(bay => bay.allComponents)
        );
        if (substation.facts) {
         components = components.concat(
            ...substation.facts.map(facts => facts.busbars),
            ...substation.facts.map(facts => facts.psLs),
            ...substation.facts.map(facts => [].concat(...facts.branches.map(branch => branch.allComponents))),
            ...substation.facts.map(facts => facts.secondarySystems.map(ss => ss.component))
          );
        }

        components.forEach(component => {
          component.assetId = '00000000-0000-0000-0000-000000000000';
          component.copyAssetId = '00000000-0000-0000-0000-000000000000';
          if (component.linkedAssetComponent1 && component.linkedAssetComponent2) {
              component.linkedAssetComponent1.assetId = '00000000-0000-0000-0000-000000000000';
              component.linkedAssetComponent2.assetId = '00000000-0000-0000-0000-000000000000';
              component.linkedAssetId1 = '00000000-0000-0000-0000-000000000000';
              component.linkedAssetId2 = '00000000-0000-0000-0000-000000000000';
          }
        });

        if (substation.serializedSld) {
          if (substation.serializedSld.baysList) {
              substation.serializedSld.baysList.forEach(bay => {
                  bay.bayId = '00000000-0000-0000-0000-000000000000';
              });
          }
          if (substation.serializedSld.outageAreasList) {
              substation.serializedSld.outageAreasList.forEach(outageArea => {
                  outageArea.outageAreaId = '00000000-0000-0000-0000-000000000000';
              });
          }
          if (substation.serializedSld.shutdownAreaList) {
              substation.serializedSld.shutdownAreaList.forEach(shutdownArea => {
                  shutdownArea.shutdownAreaId = '00000000-0000-0000-0000-000000000000';
              });
          }
          if (substation.serializedSld.gasCompartmentList) {
              substation.serializedSld.gasCompartmentList.forEach(gasCompartment => {
                  gasCompartment.gasCompartmentId = '00000000-0000-0000-0000-000000000000';
              });
          }
          if (substation.serializedSld.factsList) {
              substation.serializedSld.factsList.forEach(facts => {
                  facts.factsId = '00000000-0000-0000-0000-000000000000';
                  facts.branches.forEach(branch => {
                      branch.branchId =  '00000000-0000-0000-0000-000000000000';
                      branch.factsInstallationId = '00000000-0000-0000-0000-000000000000';
                  });
                  facts.secondarySystems.forEach(ss => {
                    ss.secondarySystemId = '00000000-0000-0000-0000-000000000000';
                    ss.assetId = '00000000-0000-0000-0000-000000000000';
                    ss.component.assetId = '00000000-0000-0000-0000-000000000000';
                    ss.factsInstallationId = '00000000-0000-0000-0000-000000000000';
                  });
              });
          }
        }

        return substation;
    }

    saveSnippetAssetPropertiesResult(data: any) {
        const components = Object.values(this.extractAllComponentsDataFromSubstation(this.substation));
        components.forEach(sldDataComponents => {
          if (sldDataComponents.uniqueReference !== undefined) {
              data.propertiesByCategory.forEach(sldByCategory => {
                  if (sldByCategory['category'] === 'Basic information') {
                      sldByCategory.properties.forEach(properties => {
                          if (properties.value === sldDataComponents.uniqueReference) {
                              sldDataComponents.assetId = data.assetId;
                              if (sldDataComponents.linkedAssetComponent1 != null && sldDataComponents.linkedAssetComponent2 != null) {
                                  sldDataComponents.linkedAssetComponent1.assetId = data.linkedAsset2;
                                  sldDataComponents.linkedAssetComponent2.assetId = data.linkedAsset3;
                              }
                          }
                      });
                  }
              });
          }
        });
    }

    assessReliabilityData(formdata) {
        return this.helper.patch('/Sld/AssessReliabilty', formdata);
    }

    /**
     * import substation json file
     * reads an existing substation JSON,
     * and holds that new object in memory
     * used in importing a diagram / creating a new diagram
     */
    public importSubstationJSONFile(standard: string, substationJSONFile?: any): Observable<any> {

        let fileLoadedObservable = null;
        if (substationJSONFile) {
            // read the substation json file
            const reader = new FileReader();
            fileLoadedObservable = new Observable(observer => {
                reader.onload = (event) => {
                    const standards = sessionStorage.getItem('diagramSymbols');
                    const resultTemp = (event.target as any).result.toString().replace(/(?<="standard":\s*")(iec|ansi)(?=")/gi, standards);
                    const result = JSON.parse(resultTemp);

                    observer.next(result);
                    observer.complete();
                };
                reader.readAsText(substationJSONFile);
            });
        } else {
            // get the initial template of substation and set the appropriate fields
            fileLoadedObservable = this.http.get(config.ASSETS_DIR + 'data-templates/substation.json');
        }

        // setup in memory substation and return the serialized version to SLD editor
        return fileLoadedObservable
          .pipe(switchMap((substation: any) => {
            if (this.riseToRelcareService.isSubstationDTOFromRise(substation)) {
             console.log('Converting RISE Export to RELCARE Format');
              return from(this.riseToRelcareService.convertRiseSubstationDTOToRelcare(substation))
                .pipe(map(subs => ({ substation: subs, fromRise: true })));
            }
            return of({ substation, fromRise: false });
          })).pipe(map((details: any) => {
            details.substation = this.resetAssetIdsAndRef(details.substation);
            details.substation.standards = sessionStorage.getItem('diagramSymbols');
            if (details.substation.serializedSld) {
              details.substation.serializedSld.standard = sessionStorage.getItem('diagramSymbols');
            }
            console.log('importing substation', details.substation);
            return details;
          }));
    }



    /**
     * update the Substation JSON
     */
    public updateSubstationJSON(diagramName: string, sldReferenceIter: number, simulation: string,
        selectedKeys: any[], outageAreasList: any[], baysList: any[], gasCompartmentList: any[],
        factsList: any[], shutdownAreaList: any[], standard: string) {

        if (!this.substation) { return; }

        this.substation.substationId = sessionStorage.getItem('substationId') || this.substation.substationId;
        this.substation.projectId = sessionStorage.getItem('projectId');
        this.substation.serializedSld.nodes = this.nodes
            .map(node => Object.assign({}, node, { sldData: null }));
        this.substation.serializedSld.links = this.links
            .map(node => Object.assign({}, node, { sldData: null }));
        this.substation.serializedSld.diagramName = diagramName;
        this.substation.serializedSld.simulation = simulation;
        this.substation.serializedSld.sldReferenceIter = sldReferenceIter;
        this.substation.serializedSld.selectedKeys = selectedKeys;
        this.substation.serializedSld.outageAreasList = outageAreasList;
        this.substation.serializedSld.baysList = baysList;
        this.substation.serializedSld.factsList = factsList;
        this.substation.serializedSld.gasCompartmentList = gasCompartmentList;
        this.substation.serializedSld.shutdownAreaList = shutdownAreaList;
        // this.substation.serializedSld.outageAreasList = outageAreasList // = this.subOutageAreaListy.length > 0 ? this.subOutageAreaListy : outageAreasList;
        // this.substation.serializedSld.baysList = baysList //= this.subBayListy.length > 0 ? this.subBayListy : baysList;
        this.substation.standard = standard;
        // this.subBayListy = this.substation.bays;
        // this.subOutageAreaListy = result.serializedSld.outageAreasList;

        // update all the standard components + all links list in the bays
        if (baysList) {
            this.substation.bays = baysList.map(bay => Object.assign(
                {},
                bay,
                {
                    allComponents: this.nodes.concat(this.links)
                        .filter(component => !component.category && (!component.class || component.class === "busbar"))
                        .filter(component => component.bay === bay.bayName || (!component.bay && !component.facts && bay.isDefault))
                        .map(component => component.sldData)
                }
            ));
        }
        // update the power-supply and loads
        this.substation.psLs = this.nodes
            .filter(node => !node.facts)
            .filter(node => node.class === 'power-supply' || node.class === 'load' || node.class == 'ground-component')
            .map(node => node.sldData);

        // busbars
        this.substation.busbars = this.nodes
            .filter(node => !node.facts)
            .filter(node => node.class === 'busbar')
            .map(node => node.sldData);

        // outage areas
        if (outageAreasList) {
            this.substation.outageAreasList = outageAreasList
                .map(outageArea => Object.assign(
                    {},
                    outageArea,
                    {
                        outageAreaComponentsList: this.nodes
                            .filter(node => (!node.category || node.category === 'secondary-system') && node.outageArea === outageArea.outageAreaName)
                            .map(node => node.sldReference)
                    }
                ));
        }

        // shutdown areas
        if (shutdownAreaList) {
          this.substation.shutDownAreasList = shutdownAreaList
              .map(shutdownArea => Object.assign(
                  {},
                  shutdownArea,
                  {
                      shutdownAreaComponentsList: this.nodes
                          .filter(node => (!node.category || node.category === 'secondary-system') && node.shutdownArea === shutdownArea.shutdownAreaName)
                          .map(node => node.sldReference)
                  }
              ));
      }

        if (factsList) {
          this.substation.facts = factsList.map(facts => Object.assign({}, facts));
          this.substation.facts.forEach(facts => {
            facts.branches.forEach(branch => {
              branch.allComponents = this.nodes.concat(this.links)
                .filter(component => !component.category && (!component.class || component.class === "busbar"))
                .filter(component => component.facts === facts.factsName && (component.branch === branch.branchName || (!component.branch && branch.isDefault)))
                .map(component => component.sldData);
            });

            facts.psLs = this.nodes
              .filter(node => node.facts === facts.factsName)
              .filter(node => node.class === 'power-supply' || node.class === 'load' || node.class == 'ground-component')
              .map(node => node.sldData);

              facts.busbars = this.nodes
              .filter(node => node.facts === facts.factsName)
              .filter(node => node.class === 'busbar')
              .map(node => node.sldData);

            // build a hash map of all secondary system sld data with sldReferences as key
            const secondarySystemsNodeDataMap = {};
            this.nodes
              .filter(nd => nd.category === 'secondary-system')
              .forEach(nd => secondarySystemsNodeDataMap[nd.sldReference] = nd.sldData);
            // inside each facts update the component data for each secondary system connected
            // using the node data for the corresponding secondary system
            facts.secondarySystems.forEach(ss => {
              ss.factsInstallationId = facts.factsId;
              // take the latest instance of sldData from the node directly, and set it here
              const componentData = secondarySystemsNodeDataMap[ss.component.sldReference];
              if (componentData) { ss.component = componentData; }
              ss.assetId = ss.component.assetId;
              // for control and protection types, set the branches array to empty
              // i.e. let backend figure out what branches are connected to control and protection systems
              const type = ss.secondarySystemType.secondarySystemTypeName;
              if (type === 'Control' || type === 'Protection') {
                ss.branches = [];
              }
            });

          });
        }

        Object.values(this.extractAllComponentsDataFromSubstation(this.substation)).forEach(component => {
          component.bayBranchReference = component.bayBranchReference || 'default';
          component.substationReference = sessionStorage.getItem('substationName');
        });

        console.log(this.substation);
    }

    // if the user changes the sld equipment reference, we need to check if the sldRefIter needs to be updated
    // to avoid duplication when a new equipment is added to the diagram
    calculateSldReferenceIter(sldReferenceValue, currentIter) {
      const refIterMatch = sldReferenceValue.match(/(?<=SLD_Ref_)\d+(?=$)/g); // regex match with the format used to create sldReference
      // if new reference uses the same format, we need to check if counter has been manually increased by the user
      if (refIterMatch) {
        // if newly set ref iter is greater than current iter, then we'll have a conflict in the future
        if (Number(refIterMatch[0]) >= currentIter) {
          // so update the iter used by the diagram
          currentIter = Number(refIterMatch[0]) + 1;
        }
      }
      return currentIter;
    }

    saveSubstation(substation) {
        return this.helper.patch('/Sld/SaveSubstation', substation);
    }

    saveSerializedSld(substationId, serializedSld) {
      serializedSld = SldUtilService.deepCopy(serializedSld);
      serializedSld.nodes = this.nodes
          .map(node => Object.assign({}, node, { sldData: null }));
      serializedSld.links = this.links
          .map(node => Object.assign({}, node, { sldData: null }));
      return this.helper.patch('/Sld/SaveSerializedSld', { substationId, serializedSld });
    }

    saveShutdownAreas() {
      return this.helper.patch('/Sld/SaveShutdownArea', {
        substationId: this.substation.substationId,
        shutdownAreaList: this.substation.shutDownAreasList
      });
    }

    saveSubstationImage(formData) {
        return this.helper.post('/Sld/SaveSLDImage', formData);
    }

    getSubstationById(substationId) {

        return this.helper.get('/Sld/GetSubstationById?substationId=' + substationId);
    }

    saveAssignReliabilityData(substation) {

        return this.helper.patch('/Sld/AssignReliabilityData', substation);
    }

    simulateClearFault(formData) {
        return this.helper.patch('/Sld/SimulateClearFault', formData);
    }
    simulateIsolateFault(formData) {
        return this.helper.patch('/Sld/SimulateIsolateFault', formData);
    }
    simulateIsolateForMaintenance(formData) {
        return this.helper.patch('/Sld/SimulateIsolateForMaintenance', formData);
    }


    getEquipmentCategoryType(equipmentNameId) {
        // return this.helper.get(`/assets/data/equipment_category_type.json`).pipe(delay(0));
        return this.helper.get(`/Assets/GetEquipmentCategoriesAndTypeByEqptNameId?eqptNameId=` + equipmentNameId);
    }

    getEquipmentFields(formData) {
        // return this.helper.get(`/assets/data/equipment_category_type.json`).pipe(delay(0));

        return this.helper.patch('/Assets/GetSnippetAssetProperties', formData);
    }

    saveSnippetAssetProperties(formData) {
        return this.helper.patch('/Assets/SaveAssetProperties', formData);
    }

    saveSnippetAreaProperties(formData, type: 'facts' | 'branch') {
        return this.helper.patch(`/Facts/SaveSnippetFacts${type === 'facts' ? 'Installation' : 'Branch'}`, formData);
    }

    getPropertyCategories() {
        return this.helper.get('/Assets/GetPropertyCategories');
    }

    getAssetInformationByPropCategory(formData) {
        return this.helper.patch('/Assets/GetAssetInformationByPropCategory', formData);
    }
     getDownloadExcelFormJob(id,boo) {
         return this.helper.getData('/Assets/DownloadExcelFormJob?substationId='+id+'&isExport='+boo);
     }
    getAssetsSubSystems(id) {
        return this.helper.get(`/Assets/GetAssetsSubSystems/` + id);
    }

    addAssetsSubSystemItem(data) {
        return this.helper.post(`/Assets/GetAssetsSubSystems/`, data);
    }

    saveAssetInformationByCategory(formData) {
        return this.helper.patch('/Assets/SaveAssetInformationByCategory', formData);
    }

    saveDocuments(formData) {
        return this.helper.post('/Assets/SaveDocuments', formData);
    }

    getDocumentById(docId) {
        return this.helper.get('/Assets/GetDocumentById?docId=' + docId);
    }

    saveAssetImages(formData) {
        return this.helper.post('/Assets/SaveAssetImages', formData);
    }

    getAssetDocuments(assetId) {
        return this.helper.get('/Assets/GetAssetDocuments?assetId=' + assetId);
    }

    getAssetImages(assetId) {
        return this.helper.get('/Assets/GetAssetImages?assetId=' + assetId);
    }

    getMaintanancePlan(assetId) {
        return this.helper.get('/Assets/GetMaintanancePlan?assetId=' + assetId);
    }

    getMaintananceRecords(assetId) {
        return this.helper.get('/Assets/GetMaintananceRecords?assetId=' + assetId);
    }

    deleteDocumentByIds(formData: any) {
        return this.helper.put('/Assets/DeleteDocumentsByIds', formData);
    }

    getSubstationAssetRiskMatrixValue(data: string) {
        return this.helper.get('/Sld/GetSubstationAssetRiskMatrixValue?substationId=' + data);
    }

    getFactsProperties(factsId: string) {
        // return this.http.get(config.ASSETS_DIR + 'data-templates/facts-properties.json');
        return this.helper.get(`/Facts/GetSnippetFactsInstallation?FactsInstallationId=${factsId}`);
    }

    getBranchProperties(branchId: string) {
        return this.helper.get(`/Facts/GetSnippetForFactsBranch?FactsBranchId=${branchId}`);
    }

    /**
     * export component data API called
     */
    public exportExcel(substationId: string) {
        return this.helper.get('/Assets/RequestForExcel?substationId=' + substationId);
    }

    public importExcelFile(formData: any) {
        return this.helper.post('/Assets/SaveAssetPropertiesFromExcel', formData);
    }
    GetAggregationValidationSubsystemDetails(type, num, size, search, order, col) {
        // tslint:disable-next-line: max-line-length
        return this.helper.get('/Assets/GetAggregationValidationDetails?aggregationType=' + type + '&PageNumber=' + num + '&PageSize=' + size +  '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + col);
      }
    GetPropertyValidationInfo(num, size, search, order, col) {
        // tslint:disable-next-line:max-line-length
        return this.helper.get('/Assets/GetPropertyValidationInfo?PageNumber=' + num + '&PageSize=' + size + '&SearchText=' + search + '&SortOrder=' + order + '&SortColumn=' + col);
    }
    /**
     * Method is use to download file from server.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        const blob = new Blob([data], { type: type.toString() });
        this._FileSaverService.save(blob, 'Component Data.xlsx');
        // let url = window.URL.createObjectURL(blob);
        // let pwa = window.open(url);
        // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        //     alert('Please disable your Pop-up blocker and try again.');
        // }
    }


    public export() {
        let substationId = sessionStorage.getItem("substationId");
        if (substationId != null) {
            this.exportExcel(substationId).subscribe({next:respData => {
                //this.downLoadFile(respData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                if(respData.errorMessage !==null){
                     const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
                     modalRef.componentInstance.title = 'Message';
                     modalRef.componentInstance.message = respData.errorMessage;
                }

                //}

            }, error:() => {

            }});
        }
    }
    // this.getSS().subscribe(res => {
    // }, (error) => {
    // });

    substationResult() {
        return this.substation;
    }

    exportSLDConfig(substationId) {
        return this.helper.get('/Sld/ExportSLDConfig?substationId=' + substationId);
    }

    importSLDConfig(substation) {
        return this.helper.patch('/Sld/ImportSLDConfig', substation)
          .pipe(switchMap(() => {
            return this.getSubstationById(substation.substationId);
          }));
    }

    /**
     * export the substation JSON
     */
    public exportSubstationJSON() {
        let substationId = this.getSubstationId();
        if (substationId != '' && substationId !== '00000000-0000-0000-0000-000000000000')
            this.exportSLDConfig(substationId).subscribe(res => {
                const blob = new Blob([JSON.stringify(res, null, 4)], { type: 'application/json' });
                this._FileSaverService.save(blob, this.substation.serializedSld.diagramName + '.json');
            });
    }

    /**
        * export the component data
        */
    public exportComponentData() {

        function exportJsonHelper(jsonObject: any) {
            //
            for (let i = 0; i < jsonObject.length; ++i) {
                if (jsonObject[i].basicInformation) {
                    const basicInformation = Object.keys(jsonObject[i].basicInformation).map(function (key) {
                        return [key, jsonObject[i].basicInformation[key]];
                    });
                    for (let j = 0; j < basicInformation.length; ++j) {
                        if (basicInformation[j][1] != null) {
                            jsonObject[i][basicInformation[j][0]] = basicInformation[j][1];
                        }
                    }
                }

                if (jsonObject[i].technicalInformation) {
                    const technicalInformation = Object.keys(jsonObject[i].technicalInformation).map(function (key) {
                        return [key, jsonObject[i].technicalInformation[key]];
                    });
                    for (let j = 0; j < technicalInformation.length; ++j) {
                        if (technicalInformation[j][1] != null) {
                            jsonObject[i][technicalInformation[j][0]] = technicalInformation[j][1];
                        }
                    }
                }

                if (jsonObject[i].reliability) {
                    const reliability = Object.keys(jsonObject[i].reliability).map(function (key) {
                        return [key, jsonObject[i].reliability[key]];
                    });
                    for (let j = 0; j < reliability.length; ++j) {
                        if (reliability[j][1] != null) {
                            jsonObject[i][reliability[j][0]] = reliability[j][1];
                        }
                    }
                }

                // creating an array of [key, value] pair for the respective object (here : componentReliabilityData) and
                // later adding those key: value to the parent level in the json
                // var componentReliabilityData = Object.keys(jsonObject[i].componentReliabilityData).map(function (key) {
                //     return [key, jsonObject[i].componentReliabilityData[key]];
                // });

                // for (let j = 0; j < componentReliabilityData.length; ++j) {
                //     jsonObject[i][componentReliabilityData[j][0]] = componentReliabilityData[j][1];
                // }

                // if (jsonObject[i].interruptionCosts && jsonObject[i].assetName === 'Load') {
                //     //
                //     jsonObject[i].failureInterruptionCost = jsonObject[i].interruptionCosts.failureInterruptionCost.floatProperty;
                //     jsonObject[i].failureOutageCost = jsonObject[i].interruptionCosts.failureOutageCost.floatProperty;
                //     jsonObject[i].failureOutageDuration = jsonObject[i].interruptionCosts.failureOutageDuration.floatProperty;
                //     jsonObject[i].maintenanceInterruptionCost = jsonObject[i].interruptionCosts.maintenanceInterruptionCost.floatProperty;
                //     jsonObject[i].maintenanceOutageCost = jsonObject[i].interruptionCosts.maintenanceOutageCost.floatProperty;
                //     jsonObject[i].maintenanceOutageDuration = jsonObject[i].interruptionCosts.maintenanceOutageDuration.floatProperty;
                // }

            }
            return jsonObject;
        }

        const sldDataAsIs = this.nodes
            .filter(node => !node.category && !node.externalComponent)
            .concat(this.links)
            .map(node => node.sldData);

        if (!sldDataAsIs.length) {
            return false;
        }

        const jsonSLDData = JSON.parse(JSON.stringify(sldDataAsIs));

        const jsonSLDDataWithLinkedAssets = [];
        jsonSLDData.forEach(node => {
            jsonSLDDataWithLinkedAssets.push(node);
            if (node && node.linkedAssetComponent1 && node.linkedAssetComponent2) {
                jsonSLDDataWithLinkedAssets.push(node.linkedAssetComponent1);
                jsonSLDDataWithLinkedAssets.push(node.linkedAssetComponent2);
            }
        });


        const sldDatas = exportJsonHelper(jsonSLDDataWithLinkedAssets);

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        const workbookData = {};
        // compile the list of data keys for each component type
        const blacklistedKeys = [
            'bayName',
            'equipmentCategoryId',
            'equipmentTypeId',
            'assetVoltage',
            'connectedComponentList',
            'technology',
            'tOLTechnologies',
            'applyType',
            'applyOperationType',
            'insulationTypes',
            'configurationTypes',
            'secondaryVoltage',
            'ratedMinAmbientTemp',
            'ratedMaxAmbientTemp',
            'internalProtection',
            'surgeArrType',
            'noofStacks',
            'installation',
            'typeOfMounting',
            'temprature',
            'humidity',
            'corrosionCondition',
            'pollutionLevel',
            'surgerCounterReading',
            'resistiveLeakage',
            'insulationResistTest',
            'earthingCheck',
            'supplier',
            'installationYear',
            'opeXbudgetarea',
            'sparePartBudgetArea',
            'capeXbudgetarea',
            'assetgroup',
            'assetconfiguration',
            'dataSheet',
            'manual',
            'drawing',
            'testReports',
            'failureRateMin',
            'meanTimeToRepair',
            'meanTimeToSwitch',
            'meanTimeToMaintain',
            'serialNumber',
            'model',
            'year',
            'assetName',
            'componentTechnology',
            'componentVoltageType',
            'basicInformation',
            'operationalDiagnostics',
            'equipmentNameId',
            'equipmentCategory',
            'equipmentType',
            'equipmentId',
            'sldUniqueReference',
            'technicalInformation',
            'reliability',
            'uniqueReference',
            'assetId',
            'busbarType',
            'connectedComponentsFirst',
            'connectedComponentsSecond',
            'connectedComponentsList',
            'isOpen',
            'outForFault',
            'outForMaintenance',
            'outForIsolation',
            'insulationType',
            'configuration',
            'motorTechnology',
            'tOLTechnology',
            'isReliabilityDataAssigned',
            'colorCode',
            'assetClassId',
            'isOnPath',
            'wasFullyOutagedDueToMaintenance',
            'wasFullyOutaged',
            'normallyOpen',
            'energized',
            'isInImpactArea',
            'reliabilityResults',
            'assetTypes',
            'componentReliabilityData',
            'interruptionCosts',
            'zone',
            'calculatedFailureRate',
            'calculatedMeanTimeToRepair',
            'calculatedMeanTimeToSwitch',
            'calculatedMaintenanceFrequency',
            'calculatedMeanTimeToMaintain',
            'calculatedSource',
            'manualFailureRate',
            'manualMeanTimeToRepair',
            'manualMeanTimeToSwitch',
            'manualMaintenanceFrequency',
            'manualMaintenanceDuration',
            'manualSource',
            'useManualFailureRate',
            'useManualMeanTimeToRepair',
            'useManualMeanTimeToSwitch',
            'useManualMaintenanceFrequency',
            'useManualMeanTimeToMaintain',
            'useManualSource',
            'source',
            'linkedAssetComponent1',
            'linkedAssetComponent2',
            'innovativeModuleId',
            'innovativeModuleType',
            'isAssetInsideSs',
            'comments',
            'description',
            'maximumPower',
            'condition',
            'voltage',
            'year2',
            'cableTechnology',
            'cableType',
            'calculatedCondition',
            'calculatedImportance',
            'voltageTransformerType',
            'capacitorsBankType',
            'gisBushingType',
            'transformerType',
            'length',
            'reactorType',
            'tolCableType',
            'tolCableTechnology',
            'combinedDisconnectEarthSwitchType',
            'manulLength', 'manulRatedPower', 'classification'
        ];
        // const allowedKeys = [
        //     'basicInformation',
        //     'technicalInformation',
        //     'reliability',
        //     'sldReference',
        //     'condition',
        //     'risk',
        //     'importance'
        // ];
        sldDatas.forEach(data => {
            const excelKeysInternal = ['sldReference'].concat(
                Object.keys(data).filter(key => blacklistedKeys.indexOf(key) === -1 && key !== 'sldReference')
            );
            // for excel export beautify
            const excelKeys = excelKeysInternal.map(key => this.propertyNameMap.get(key)).filter(Boolean);
            // set the header row if required
            let assetName = (data.assetName === "Combined disconnector switch / earth switch") ? "Combined D_G Switches" : data.assetName;
            if (assetName === "Cable circuit (cable and cable sealing)") {
                assetName = "Cable circuit";
            }
            if (assetName !== "IdealCable" && assetName !== "Joint" && assetName !== "Clamp") {
                workbookData[assetName] = workbookData[assetName] || [excelKeys];
                // if the particular component instance has more fields than present in
                // in the current excel header, push those keys in the header
                excelKeys.forEach(key => {
                    if (workbookData[assetName][0].indexOf(key) === -1) {
                        workbookData[assetName][0].push(key);
                    }
                });
            }
        });
        // define the groups of keys in excel
        //const allowedGroups = ['basicInformation', 'reliability', 'technicalInformation'];

        const merges = {};
        // group the keys in excel data sheets
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];

            const groups = [
                {
                    name: 'Basic information',
                    keys: []
                },
                {
                    name: 'Reliability',
                    keys: []
                },
                {
                    name: 'Technical information',
                    keys: []
                },
                {
                    name: 'Reliability Data',
                    //  keys: ['calculatedCondition', 'importance', 'risk']
                    keys: ['Calculated condition', 'Calculated importance', 'Risk']
                },
                {
                    name: 'Additional Info',
                    keys: ['Parent asset', 'SLD reference']
                }
            ];

            // code to add keys to each of the group
            jsonSLDDataWithLinkedAssets.forEach(data => {
                if (data.assetName === sheetName) {
                    // || (data.assetName === 'Cable circuit (cable and cable sealing)' && sheetName === 'Cable circuit')
                    // || (data.assetName === 'Combined disconnector switch / earth switch' && sheetName === 'Combined D_G Switches')) {
                    if (data.basicInformation) {
                        let ke = Object.keys(data.basicInformation);
                        let group = groups.filter(x => x.name === this.propertyNameMap.get('basicInformation'))[0];
                        if (group) {
                            ke.forEach(k => {
                                if (this.propertyNameMap.get(k) && group.keys.indexOf(this.propertyNameMap.get(k)) === -1) {
                                    group.keys.push(this.propertyNameMap.get(k));
                                }
                            });
                        }
                    }
                    if (data.technicalInformation) {
                        let ke = Object.keys(data.technicalInformation);
                        let group = groups.filter(x => x.name === this.propertyNameMap.get('technicalInformation'))[0];
                        if (group) {
                            ke.forEach(k => {
                                if (this.propertyNameMap.get(k) && group.keys.indexOf(this.propertyNameMap.get(k)) === -1) {
                                    group.keys.push(this.propertyNameMap.get(k));
                                }
                            });
                        }
                    }
                    if (data.reliability) {
                        let ke = Object.keys(data.reliability);
                        let group = groups.filter(x => x.name === this.propertyNameMap.get('reliability'))[0];
                        if (group) {
                            ke.forEach(k => {
                                if (this.propertyNameMap.get(k) && group.keys.indexOf(this.propertyNameMap.get(k)) === -1) {
                                    group.keys.push(this.propertyNameMap.get(k));
                                }
                            });
                        }
                    }
                }
            });

            const allGroupedKeys = [].concat(...groups.map(group => group.keys));
            sheetData[1] = sheetData[0].filter(key => allGroupedKeys.indexOf(key) === -1);
            sheetData[0] = ['General'].concat(sheetData[1].slice(1).map(() => ''));

            // merges[sheetName] = [{ s: { r: 1, c: 0 }, e: { r: sheetData[1].length - 1, c: 0 } }];
            merges[sheetName] = [{ s: { r: 1, c: 0 }, e: { r: sheetData[1].length - 1 === 0 ? 1 : 0, c: 0 } }];

            groups.forEach(group => {
                merges[sheetName].push(
                    { s: { r: sheetData[1].length + 1, c: 0 }, e: { r: sheetData[1].length + group.keys.length - 1, c: 0 } }
                );
                sheetData[0] = sheetData[0].concat([group.name].concat(group.keys.slice(1).map(() => '')));
                sheetData[1] = sheetData[1].concat(group.keys);
            });
        });
        // add all the data rows
        sldDatas.forEach(data => {
            // push the data row of the component
            let assetName = (data.assetName === "Combined disconnector switch / earth switch") ? "Combined D_G Switches" : data.assetName;
            if (assetName === "Cable circuit (cable and cable sealing)") {
                assetName = "Cable circuit";
            }
            if (assetName !== "IdealCable" && assetName !== "Joint" && assetName !== "Clamp") {
                // for excel export beautify
                const dataRow = workbookData[assetName][1].map(key => data[this.propertyNameMap.revGet(key)]);
                //  const dataRow = workbookData[assetName][1].map(key => data[key]);
                workbookData[assetName].push(dataRow);
            }
        });

        // traspose the data matrix so that it's in "vertical-format" in excel
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];
            const transposedSheetData = sheetData[0].map((col, i) => sheetData.map(row => row[i]));
            workbookData[sheetName] = transposedSheetData;
        });

        // compile all the data into xlsx workbook
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];
            const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = merges[sheetName];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        // write the file and download
        XLSX.writeFile(workbook, 'Component Data.xlsx');

        return true;
    }

    /**
     * import component data
     * read the excel file imported and replace the sld data for each component with the imported values
     */
    public importComponentData(file): Observable<any> {
        const reader: FileReader = new FileReader();
        const componentDataImportedObservable = new Observable(observer => {

            reader.onload = (e: any) => {
                try {
                    // read workbook
                    const binaryString: string = e.target.result;
                    const workbook: XLSX.WorkBook = XLSX.read(binaryString, { type: 'binary' });

                    // parse through all the sheets
                    workbook.SheetNames.forEach(sheetName => {

                        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                        const sheetData = (XLSX.utils.sheet_to_json(worksheet, { header: 1 }));
                        const sldRefs = (sheetData[sheetData.length - 1] as any[]).slice(2);
                        const parentAsset = (sheetData[sheetData.length - 2] as any[]).slice(2);
                        const dataPropCat = sheetData.slice(1).map(row => row[0]);
                        let lastVal = "";
                        let propCat = [];
                        dataPropCat.forEach(prop => {
                            if (prop === "") {
                                propCat.push(lastVal);
                            } else {
                                lastVal = prop;
                                propCat.push(lastVal);
                            }
                        });
                        const dataKeys = sheetData.slice(1).map(row => row[1]);
                        const dataValues = sheetData.slice(1).map(row => (row as any[]).slice(2));

                        // compile all the modified sldDatas
                        const modifiedSldDatas: any = sldRefs.map(() => ({}));
                        dataKeys.forEach((key, keyIndex) => {
                            const fieldValues = dataValues[keyIndex];
                            const category = propCat[keyIndex];
                            const categoryName = this.propertyNameMap.revGet(category);
                            if (category !== '' && categoryName) {
                                fieldValues.forEach((value, componentIndex) => {
                                    const actualKey = this.propertyNameMap.revGet(key);
                                    if (value && actualKey) {
                                        if (!modifiedSldDatas[componentIndex][categoryName]) {
                                            modifiedSldDatas[componentIndex][categoryName] = {};
                                        }
                                        modifiedSldDatas[componentIndex][categoryName][actualKey] = value;
                                    }
                                });
                            }
                        });
                        modifiedSldDatas.forEach((data, index1) => {
                            if (parentAsset[index1] !== '') {
                                sldRefs.forEach((ref, index2) => {
                                    if (ref === parentAsset[index1]) {
                                        const depSldRef = sldRefs[index1].split('_');
                                        if ((depSldRef[depSldRef.length - 1] === '1' && !isNaN(depSldRef[depSldRef.length - 2])) ||
                                            (depSldRef[depSldRef.length - 1] === '2' && !isNaN(depSldRef[depSldRef.length - 2]))) {
                                            modifiedSldDatas[index2].linkedAssetComponent1 = modifiedSldDatas[index1];
                                        }
                                    }
                                });
                            }
                        });
                        // if (modifiedSldDatas.assetName = "Combined D_G Switches")
                        // edit the sldDatas of components in diagram
                        sldRefs.forEach((ref, index) => {
                            const component = this.nodes.concat(this.links)
                                .filter(c => c.sldReference === ref)[0];
                            if (!component) { return; }

                            component.sldData = Object.assign({}, component.sldData, modifiedSldDatas[index]);
                        });
                    });
                    observer.next();
                    observer.complete();

                } catch (error) {
                    observer.error();
                    throw error;
                }
            };
            reader.readAsBinaryString(file);
        });
        return componentDataImportedObservable;
    }

    public downloadReliabilityData() {
        this.currency = sessionStorage.getItem('currency');
        const reliabilityDescriptions: any = [
            '', '',
            'Outage Frequency(OF) : Total outage frequency: /yr', 'Outage Duration (OD) : Total outage duration: hr/yr',
            'Outage Frequency(OF) : Normal state faults: /yr', 'Outage Duration (OD) : Normal state faults: hr/yr',
            'Outage Frequency(OF) : Maint. state faults: hr/yr', 'Outage Duration (OD) : Maint. state faults: hr/yr',
            'Outage Frequency(OF) : Scheduled maintenance: hr/yr', 'Outage Duration (OD) : Scheduled maintenance: hr/yr',
            'Outage Frequency(OF) : Other maintenance: hr/yr', 'Outage Duration (OD) : Other maintenance: hr/yr',
            'Outage Frequency Impact (OFI) : Total outage frequency: /yr', 'Outage Duration Impact (ODI) : Total outage duration: hr/yr',
            'Outage Frequency Impact (OFI) : Normal state faults: /yr', 'Outage Duration Impact (ODI) : Normal state faults: hr/yr',
            'Outage Frequency Impact (OFI) : Maint. state faults: /yr', 'Outage Duration Impact (ODI) : Maint. state faults: hr/yr',
            'Outage Frequency Impact (OFI) : Scheduled maintenance: /yr', 'Outage Duration Impact (ODI) : Scheduled maintenance: hr/yr',
            'Outage Frequency(OF) : Total faults: hr/yr', 'Outage Frequency(OF) : Total due to maint.: hr/yr',
            'Outage Duration (OD) : Total faults: hr/yr', 'Outage Duration (OD) : Total due to maint.: hr/yr',
            'EI : Total impact (' + this.currency + '/yr)',
            'EI : Due to faults (normal state) (' + this.currency + '/yr)',
            'EI : Due to faults (maint. state) (' + this.currency + '/yr)',
            'EI : Scheduled maintenance(' + this.currency + '/yr)',
            'EENS : Total impact: kWh/yr', 'EENS : Due to faults (normal state): kWh/yr',
            'EENS : Due to faults (maint. state): kWh/yr', 'EENS : Due to maintenance: kWh/yr',
            'EENS ACC: Due to fault (normal state) (kWh/yr)', 'EENS ACC: Due to fault (maint. state) (kWh/yr)',
            'EENS ACC: Due to maintenance (kWh/yr)', 'EENS ACC: Total EENS (kWh/yr)', '', '', ''
        ];
        const reliabilityDescriptionsForLoad: any = [
            '', '',
            'Outage Frequency(OF) : Total outage frequency: /yr', 'Outage Duration (OD) : Total outage duration: hr/yr',
            'Outage Frequency(OF) : Normal state faults: /yr', 'Outage Duration (OD) : Normal state faults: hr/yr',
            'Outage Frequency(OF) : Maint. state faults: hr/yr', 'Outage Duration (OD) : Maint. state faults: hr/yr',
            'Outage Frequency(OF) : Scheduled maintenance: hr/yr', 'Outage Duration (OD) : Scheduled maintenance: hr/yr',
            'Outage Frequency(OF) : Other maintenance: hr/yr', 'Outage Duration (OD) : Other maintenance: hr/yr',
            'Outage Frequency Impact (OFI) : Total outage frequency: /yr', 'Outage Duration Impact (ODI) : Total outage duration: hr/yr',
            'Outage Frequency Impact (OFI) : Normal state faults: /yr', 'Outage Duration Impact (ODI) : Normal state faults: hr/yr',
            'Outage Frequency Impact (OFI) : Maint. state faults: /yr', 'Outage Duration Impact (ODI) : Maint. state faults: hr/yr',
            'Outage Frequency Impact (OFI) : Scheduled maintenance: /yr', 'Outage Duration Impact (ODI) : Scheduled maintenance: hr/yr',
            'Outage Frequency(OF) : Total faults: hr/yr', 'Outage Frequency(OF) : Total due to maint.: hr/yr',
            'Outage Duration (OD) : Total faults: hr/yr', 'Outage Duration (OD) : Total due to maint.: hr/yr',
            'EI : Total impact (' + this.currency + '/yr)',
            'EI : Due to faults (normal state) (' + this.currency + '/yr)',
            'EI : Due to faults (maint. state) (' + this.currency + '/yr)',
            'EI : Scheduled maintenance(' + this.currency + '/yr)',
            'EENS : Total impact: kWh/yr', 'EENS : Due to faults (normal state): kWh/yr',
            'EENS : Due to faults (maint. state): kWh/yr', 'EENS : Due to maintenance: kWh/yr',
            'EENS ACC: Due to fault (normal state) (kWh/yr)', 'EENS ACC: Due to fault (maint. state) (kWh/yr)',
            'EENS ACC: Due to maintenance (kWh/yr)', 'EENS ACC: Total EENS (kWh/yr)',
            'Annual Cost of Power Interruption: Due to fault (normal state) (' + this.currency + '/yr)',
            'Annual Cost of Power Interruption: Due to fault (maint. state) (' + this.currency + '/yr)',
            'Annual Cost of Power Interruption: Due to maintenance (' + this.currency + '/yr)',
            'Annual Cost of Power Interruption: Total cost (' + this.currency + '/yr)', '', '', ''
        ];
        // this function helps to show properties which are present inside a layer in the json
        function exportJsonHelper(jsonObject: any) {
            var linkedAssetArray=[] ;
            var linkedAssetCount=0;
            var linkedIndices=[];
            for (let i = 0; i < jsonObject.length; ++i) {
                if(jsonObject[i].hasOwnProperty('assetName')){
                var sldReference = jsonObject[i].sldReference;
                var assetName = jsonObject[i].assetName;
                var calculatedCondition = jsonObject[i].calculatedCondition;
                var calculatedImportance = jsonObject[i].calculatedImportance;
                var risk = jsonObject[i].risk;
                //const reliabilityResult = jsonObject[i].reliabilityResults;
                 if (['Load', 'Induction Motor', 'Ladle Furnace', 'Electric Arc Furnace'].indexOf(jsonObject[i].assetName) === -1) {
                    delete jsonObject[i].reliabilityResults.r_COPI_ACC_FM;
                    delete jsonObject[i].reliabilityResults.r_COPI_ACC_FN;
                    delete jsonObject[i].reliabilityResults.r_COPI_ACC_SM;
                    delete jsonObject[i].reliabilityResults.r_COPI_ACC_TOT;
                }
                //for linked assets excel insertion
                if(jsonObject[i].linkedAssetComponent1!=null && jsonObject[i].linkedAssetComponent2!=null){
                var emptyArray=[];
                linkedAssetArray.push(emptyArray);

                linkedAssetArray[linkedAssetCount]['sldReference']=jsonObject[i].linkedAssetComponent1.sldReference;
                linkedAssetArray[linkedAssetCount]['assetName']=jsonObject[i].linkedAssetComponent1.assetName;
                linkedAssetArray[linkedAssetCount]['calculatedCondition']=jsonObject[i].linkedAssetComponent1.reliability.condition;
                linkedAssetArray[linkedAssetCount]['calculatedImportance']=jsonObject[i].linkedAssetComponent1.reliability.importance;
                linkedAssetArray[linkedAssetCount]['risk']=jsonObject[i].linkedAssetComponent1.reliability.risk;
                var reliabilityResultslinked1 = Object.keys(jsonObject[i].reliabilityResults)
                                                                        .map(function (keys) {
                        return [keys, jsonObject[i].reliabilityResults[keys]];
                                    });
                for (let j = 0; j < reliabilityResultslinked1.length; ++j) {
                linkedAssetArray[linkedAssetCount][reliabilityResultslinked1[j][0]] = reliabilityResultslinked1[j][1];
                                    }

                linkedAssetCount++;
                var emptyArray1=[];
                linkedAssetArray.push(emptyArray1);
                linkedAssetArray[linkedAssetCount]['sldReference']=jsonObject[i].linkedAssetComponent2.sldReference;
                linkedAssetArray[linkedAssetCount]['assetName']=jsonObject[i].linkedAssetComponent2.assetName;
                linkedAssetArray[linkedAssetCount]['calculatedCondition']=jsonObject[i].linkedAssetComponent2.reliability.condition;
                linkedAssetArray[linkedAssetCount]['calculatedImportance']=jsonObject[i].linkedAssetComponent2.reliability.importance;
                linkedAssetArray[linkedAssetCount]['risk']=jsonObject[i].linkedAssetComponent2.reliability.risk;
                var reliabilityResultslinked2 = Object.keys(jsonObject[i].reliabilityResults).map(function (keys) {
                         return [keys, jsonObject[i].reliabilityResults[keys]];
                 });
                    for (let j = 0; j < reliabilityResultslinked2.length; ++j) {
                        linkedAssetArray[linkedAssetCount][reliabilityResultslinked2[j][0]] = reliabilityResultslinked2[j][1];
                    }
                    linkedIndices.push({'index':i,'count1':linkedAssetCount-1,'count2':linkedAssetCount});
                    linkedAssetCount++;
                }
                // later adding those key: value to the parent level in the json
                var reliabilityResults = Object.keys(jsonObject[i].reliabilityResults).map(function (keys) {
                    return [keys, jsonObject[i].reliabilityResults[keys]];
                });

                for (var key in jsonObject[i]) {
                    delete jsonObject[i][key];
                }
                jsonObject[i]['sldReference'] = sldReference;
                jsonObject[i]['assetName'] = assetName;
                jsonObject[i]['calculatedCondition'] = calculatedCondition;
                jsonObject[i]['calculatedImportance'] = calculatedImportance;
                jsonObject[i]['risk'] = risk;
                for (let  j = 0; j < reliabilityResults.length; ++j) {
                    jsonObject[i][reliabilityResults[j][0]] = reliabilityResults[j][1];
                }
            }
            }
            //inserting linked assets with its parent equipment
            var indexChange=0;
            if(linkedIndices.length!=0){
                for(let i=0;i<linkedIndices.length;i++){
                    jsonObject.splice(linkedIndices[i].index+indexChange,0,linkedAssetArray[linkedIndices[i].count1]);
                    indexChange++;
                    jsonObject.splice(linkedIndices[i].index+indexChange,0,linkedAssetArray[linkedIndices[i].count2]);
                    indexChange++;
                }
            }
            return jsonObject;
        }

        const sldDataAsIs = this.nodes
            .filter(node => !node.category)
            .concat(this.links)
            .map(node => node.sldData);

        if (!sldDataAsIs.length) {
            return false;
        }
        const jsonSLDData = JSON.parse(JSON.stringify(sldDataAsIs));
        var sldDatas = exportJsonHelper(jsonSLDData);

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        const workbookData = {};
        // compile the list of data keys for each component type
        const blacklistedKeys = [];
        sldDatas.forEach(data => {
            const excelKeys = ['sldReference'].concat(
                Object.keys(data).filter(key => blacklistedKeys.indexOf(key) === -1 && key !== 'sldReference')
            );

            // set the header row if required
            var assetName = (data.assetName === "Combined disconnector switch / earth switch") ? "Combined D_G Switches" : data.assetName;
            if (assetName === "Cable circuit (cable and cable sealing)") assetName = "Cable circuit";
            if (assetName !== "IdealCable" && assetName !== "Joint" && assetName !== "Clamp" && assetName !== "Ground" && assetName !== undefined) {
                workbookData[assetName] = workbookData[assetName] || [excelKeys];
                // if the particular component instance has more fields than present in
                // in the current excel header, push those keys in the header
                excelKeys.forEach(key => {
                    if (workbookData[assetName][0].indexOf(key) === -1) {
                        workbookData[assetName][0].push(key);
                    }
                });
            }
        });
        // define the groups of keys in excel
        const groups = [
            {
                name: 'Reliability Data',
                keys: ['calculatedCondition', 'calculatedImportance', 'risk']
            }
        ];
        const merges = {};
        // group the keys in excel data sheets
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];
            const allGroupedKeys = [].concat(...groups.map(group => group.keys));
            sheetData[1] = sheetData[0].filter(key => allGroupedKeys.indexOf(key) === -1);
            sheetData[0] = ['Reliability Results'].concat(sheetData[1].slice(1).map(() => ''));

            merges[sheetName] = [{ s: { r: 1, c: 0 }, e: { r: sheetData[1].length - 1, c: 0 } }];

            groups.forEach(group => {
                merges[sheetName].push(
                    { s: { r: sheetData[1].length + 1, c: 0 }, e: { r: sheetData[1].length + group.keys.length - 1, c: 0 } }
                );
                sheetData[0] = sheetData[0].concat([group.name].concat(group.keys.slice(1).map(() => '')));
                sheetData[1] = sheetData[1].concat(group.keys);
            });
           if (['Load', 'Induction Motor', 'Ladle Furnace', 'Electric Arc Furnace'].indexOf(sheetName) !== -1) {
            workbookData[sheetName].push(reliabilityDescriptionsForLoad);
           } else {
            workbookData[sheetName].push(reliabilityDescriptions);
           }
        });

        // add all the data rows
        sldDatas.forEach(data => {
            // push the data row of the component
            var assetName = (data.assetName === 'Combined disconnector switch / earth switch') ? 'Combined D_G Switches' : data.assetName;
            if (assetName === 'Cable circuit (cable and cable sealing)') assetName = 'Cable circuit';
            if (assetName !== 'IdealCable' && assetName !== 'Joint' && assetName !== 'Clamp' && assetName !== "Ground" && assetName !== undefined) {
                const dataRow = workbookData[assetName][1].map(key => data[key]);
                workbookData[assetName].push(dataRow);
            }
        });

        // traspose the data matrix so that it's in "vertical-format" in excel
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];
            const transposedSheetData = sheetData[0].map((col, i) => sheetData.map(row => row[i]));
            workbookData[sheetName] = transposedSheetData;
        });

        // compile all the data into xlsx workbook
        Object.keys(workbookData).forEach(sheetName => {
            const sheetData = workbookData[sheetName];
            const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
            worksheet['!merges'] = merges[sheetName];
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        // write the file and download
        XLSX.writeFile(workbook, 'Reliability Results.xlsx');

        return true;
    }

    /**
     * download blob as image
     */
    public downloadBlobAsImage(blob, name, withSaveSubstation?: boolean) {
        if (!withSaveSubstation) {
            this._FileSaverService.save(blob, name + '.png');
        } else {
            const formData = new FormData();
            const substationName = sessionStorage.getItem('substationName');
            const substationId = sessionStorage.getItem('substationId');
            const image = this.blobToFile(blob, substationName + '.png');
            formData.append('substationId', this.getSubstation().substationId);
            formData.append("files", image);
            this.saveSubstationImage(formData).subscribe({next:res => {
            }, error:(error) => {
                // show a toast message describing the error
                this.showToast(error, 4000);
            }});
        }
    }

    public blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob;
        // A Blob() is almost a File() - it's just missing
        // the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;
        b.fileName = fileName;
        // Cast to a File() type
        return <File>theBlob;
    }

    public findComponentsWithUnassignedCategory(substation: any): any[] {
        const invalidComponents = [];

        const components = Object.values(this.extractAllComponentsDataFromSubstation(substation));
        components.forEach(component => {
          if (component.equipmentTypeId === '00000000-0000-0000-0000-000000000000'
              || component.equipmentCategoryId === '00000000-0000-0000-0000-000000000000') {
              invalidComponents.push(component);
          }
        });

        return invalidComponents;
    }

    // public getAssetSnippetDataFromSubstation(assetId: string) {
    //    //debugger;
    //    this.substation.substationSnippetProperties.forEach(snippet => {
    //        if (snippet.assetId === assetId) {
    //           return snippet.snippetProperties;
    //       }
    //    });
    //   return null;
    // }
    // public saveAssetSnippetDataFromSubstation(properties: any) {
    //     //debugger;
    //     this.substation.substationSnippetProperties.forEach(snippet => {
    //         if (snippet.assetId === properties.assetId) {
    //             snippet.snippetProperties = properties;
    //         }
    //     });
    // }

    getAssetHealthScore(formData) {
        return this.helper.put(`/Assets/GetAssetHealthScore`, formData);
    }

    defaultBayMarkingforBayWeightCalculations() {
        //var isAlreadyApplied = this.nodes.filter((e) => { return e.category === "bay" && e.name === "default" }).length;
        var _nodes = this.nodes;
        var _defaultNodes = _nodes.filter((e) => { return e.category !== 'bay' && e.categoryName != 'outage-area' && e.bay === null && e.name !== "Power supply" && e.name !== "Load" });
       // if (isAlreadyApplied >= 0 || _defaultNodes.length > 0) {
            //This code fetches all the nodes which doesn't have a parent bay//

            var isAlreadyApplied = this.nodes.filter((e) => { return e.category === "bay" && e.name === "default" });
            isAlreadyApplied.map((element) => {
                var indexOfRemove = this.nodes.indexOf(element);
                this.nodes.splice(indexOfRemove, 1);
            });
       // }
    }

    getNodesWithoutBayAssingment(){
        var _nodes = this.nodes;
        var _defaultNodes = _nodes.filter((e)=>{ return e.category !== 'bay' &&(typeof e.categoryName!='undefined'  ? e.categoryName!='outage-area' : e.category!='outage-area' ) &&  (typeof e.bay=='undefined' ||  e.bay==null ) && e.name !=="Power supply" && e.name!== "Load" });
        return _defaultNodes.length;
    }

    getSLDDataOfAnyoneDefaultBayasset(){
        var _nodes = this.nodes;
        var _defaultNodes = _nodes.filter((e)=>{ return e.category !== 'bay' && e.categoryName!='outage-area' && e.bay === null && e.name !=="Power supply" && e.name!== "Load" });
        return _defaultNodes[0].sldData;
    }

    CheckIfExportIsInProgress(substationId) {

        return this.helper.get('/Sld/CheckIfExportIsInProgress?substationId=' + substationId);
    }

    getAlarmsEventsSldItems(substationId: string) { //todo remove
      return this.helper.get(`/AlarmsAndEvents/Sld/GetItems/${substationId}`);
    }

    searchAlarmsEventsSldItems(criteria) {
      return this.helper.post(`/AlarmsAndEvents/Sld/SearchItems`, criteria);
    }

    alarmsEventsSldAcknowledge(id: string) {
      return this.helper.patch(`/AlarmsAndEvents/Sld/Acknowledge/${id}`, {});
    }

    takeAction(action: ActionToTake){
      return this.helper.post('/AlarmsAndEvents/Sld/TakeAction',action);
    }

    getActionNo(refName: string){
      return this.helper.post('/AlarmsAndEvents/Sld/GetActionNo',{ name: refName});
    }

    checkActionNo(genRefName: string){
      return this.helper.post('/AlarmsAndEvents/Sld/CheckActionNo',{ name: genRefName});
    }

    alarmsAndEventsAssetErrorMarkers(substationId: string) {
      return this.helper.get(`/AlarmsAndEvents/Sld/AssetErrorMarkers/${substationId}`);
    }

    alarmsAndEventsAnyUnAcknowledAlarm(substationId: string) {
      return this.helper.get(`/AlarmsAndEvents/Sld/AnyUnAcknowledAlarm/${substationId}`);
    }

    getAssetAlarmsAndEvents(assetId:string,pageNumber:number,pageSize:number,searchText:string,sortOrder:string,sortColumn:string) {
        return this.helper.get(`/Assets/GetAssetAlarmsAndEvents?AssetId=` + assetId+'&PageNumber='+pageNumber + '&PageSize=' + pageSize + '&SearchText='+ searchText + '&SortOrder='+sortOrder+ '&SortColumn='+ sortColumn);
    }
    getAssessReliabiltyJobStatus() {
        return this.helper.getAssessReliability('/Sld/GetAssessReliabiltyJobStatus');
    }
    cancelAssessReliability(requestId) {
        return this.helper.get('/Sld/CancelAssessReliability?assessReliabilityRequestId='+requestId);
    }
}
